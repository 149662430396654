import React, {useCallback} from 'react'

import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'

import HeaderPhone from './HeaderPhone'
import {useAuth} from 'auth'
import logo from 'assets/img/CashstoreLogo.png'
import Content from 'components/Content'
import styles from 'components/Header/Header.module.scss'
import Menu from 'components/Menu'
import {ContentSlot} from 'models/Content'
import {AnalyticsEventNames} from 'models/Analytics'
import {Routes} from 'models/Routes'

const disabledNavRoutes = ['/find-loan']

const Header: React.FC = () => {
  /**
   * Analytics
   */
  const appInsightsContext = useAppInsightsContext()

  /**
   * State, Hooks
   */
  const {isLoggedIn, logout: authLogout, user} = useAuth()
  const location = useLocation()
  const logoLink = isLoggedIn ? Routes.HOME : Routes.LOGIN

  /**
   * Callbacks
   */
  const logout = useCallback(logoutCallback, [authLogout])
  const showHamburger = useCallback(showHamburgerCallback, [
    isLoggedIn,
    location,
  ])

  /**
   * Filter function for HTMLMetaElemets
   * @param metaElement HTMLMetaElemet
   * @return filtered array
   */
  function filterRobotsMeta(metaElement: HTMLMetaElement) {
    return metaElement.name === 'robots'
  }

  if (!isLoggedIn) {
    const metaTags = Array.from(
      document.getElementsByTagName('META') as HTMLCollectionOf<
        HTMLMetaElement
      >,
    )

    const robotsMeta = metaTags.filter(filterRobotsMeta)

    if (robotsMeta.length > 0) {
      robotsMeta[0].content = 'nofollow'
    }
  }

  return (
    <header className={styles.Header}>
      <Container fluid className="fill h-100">
        <Row className="fill align-items-center">
          <Col className="pl-3" md={4} xs={3}>
            {showHamburger() && <Menu />}
          </Col>

          <Col className="text-center" md={4} xs={6}>
            <a aria-label={logoLink} data-ref="logo" href={logoLink}>
              <img
                alt="Cash Store Logo"
                className={`w-100 ${styles.logo}`}
                src={logo}
              />
            </a>
          </Col>

          <Col className="pl-0 pl-lg-md" md={4} xs={3}>
            {isLoggedIn && (
              <>
                <Row className="align-items-center justify-content-end ">
                  <Col className="d-none d-md-block" xs="auto">
                    <Row className="align-items-center">
                      {user?.personReferenceNumber &&
                        user.personReferenceNumber !== '' && <HeaderPhone />}
                    </Row>
                  </Col>
                  <Col xs="auto">
                    <Button
                      className="p-0"
                      data-ref="logout"
                      variant="link"
                      onClick={logout}
                    >
                      <Content type={ContentSlot.HEADER_LOGOUT} />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  )

  /**
   * Quickly ascertains if the hamburger menu should be displayed based on the authentication status
   * or the list of routes that authenticated users can visit
   * @return boolean
   */
  function showHamburgerCallback() {
    return isLoggedIn && !disabledNavRoutes.includes(location.pathname)
  }

  /**
   * Simply calls logout function provided by AuthContext
   */
  function logoutCallback() {
    appInsightsContext.trackEvent({name: AnalyticsEventNames.LOGOUT})

    authLogout()
  }
}

/**
 * Global Header
 * @return rendered header based on user authentication
 */
export default Header
