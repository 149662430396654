import React from 'react'

import {Col, Container, Row} from 'react-bootstrap'
import {MdDone} from 'react-icons/md'

import styles from 'components/NumberStepper/NumberStepper.module.scss'

interface StepProps {
  active?: boolean
  align?: 'start' | 'center' | 'end'
  completed?: boolean
  number: number
  text: string
}

const Step: React.FC<StepProps> = ({
  active = false,
  align = 'center',
  completed = false,
  number,
  text,
}: StepProps) => (
  <Container fluid>
    <Row className={`justify-content-${align} align-items-center text-center`}>
      <Col xl="auto" xs="12">
        <div
          className={`${active ? styles.active : ''} ${
            completed ? styles.completed : ''
          } ${styles.number}`}
        >
          {!completed && <span className={styles.numberText}>{number}</span>}
          {completed && <MdDone className={styles.check} />}
        </div>
      </Col>
      <Col className="d-none d-md-block" xl="auto" xs="12">
        <Row>
          <span
            className={`${active ? styles.active : ''} ${
              completed ? styles.completed : ''
            } ${styles.text}`}
          >
            {text}
          </span>
        </Row>
      </Col>
    </Row>
  </Container>
)

/**
 * Numbered Step
 * @param props the properties passed to the component
 * @return rendered numbers showing progress in create account flow
 */
export default Step
