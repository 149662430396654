import React from 'react'
import {LoanPaymentFormValues} from 'models/Edge'
import styles from 'pages/Home/PaymentCard/MinimumPaymentError/MinimumPaymentError.module.scss'
import {formatCurrency} from 'utils/data-formatting'

interface MinimumPaymentErrorProps {
  paymentFormValues?: LoanPaymentFormValues | null
  minimumPayment: number
}

const MinimumPaymentError: React.FC<MinimumPaymentErrorProps> = props => {
  const paymentAmountNumber = Number(
    props.paymentFormValues?.paymentAmount.replace(/[^0-9.-]+/g, ''),
  )

  if (paymentAmountNumber && props.minimumPayment > paymentAmountNumber) {
    return (
      <small
        className="form-text"
        data-ref="min-pay-notice"
        id={styles.smallDivError}
      >
        Your minimum amount due is {formatCurrency(props.minimumPayment)}
      </small>
    )
  }
  return null
}

/**
 * Popup Component for displaying messages.
 */
export default MinimumPaymentError
