import React, {useEffect, useState} from 'react'
import styles from 'pages/ContactUs/ContactUs.module.scss'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Card, Container, Spinner} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {base64ToArrayBuffer} from 'utils/forms/helpers'
import {AnalyticsPageNames} from 'models/Analytics'
import {Routes} from 'models/Routes'
import {getConfigValue} from 'utils/environment'
import {formatPhone} from 'utils/data-formatting'
import {getDecisionLetter} from 'utils/edge'
import {FileDecisionLetter} from 'models/Edge'
import {useAuth} from 'auth'

const ContactUs: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const location = useLocation()
  const {user} = useAuth()
  const userCustomerId = Number(user?.personReferenceNumber)
  const storePhoneNumber =
    localStorage.getItem('userStorePhoneNumber') ??
    formatPhone(getConfigValue('DEFAULT_PHONE_NUMBER'))

  /**
   * Hooks
   */
  const [docData, setDocData] = useState<FileDecisionLetter | undefined>(
    undefined,
  )
  const [showSpinner, setShowSpinner] = useState(true)
  useEffect(() => {
    getDeclineLetter(userCustomerId)
  }, [userCustomerId])

  /**
   * Create url from base64 string
   * @param base64 Base64 string for the pdf document
   * @return ulr
   */
  function createUrlFromBase64String(base64: string) {
    const file = new Blob([base64ToArrayBuffer(base64)], {
      type: 'application/pdf',
    })
    return URL.createObjectURL(file)
  }

  let link
  if (docData) {
    let letterText = 'Decline Letter'
    if (location.pathname === Routes.CONTACT_US_NOIA) {
      letterText = 'Notice of Incomplete Application'
    }

    link = (
      <a
        download={docData.fileDownloadName}
        href={createUrlFromBase64String(docData.fileContents)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {letterText}
      </a>
    )
  }

  let contactUsText
  let analyticsPageName
  switch (location.pathname) {
    case Routes.CONTACT_US_MLA:
      analyticsPageName = AnalyticsPageNames.CONTACT_US_MLA
      contactUsText = `We are unable to move forward with your application. ${
        docData
          ? 'Please click on the below links for additional details.'
          : ''
      }`
      break
    case Routes.CONTACT_US_NOIA:
      analyticsPageName = AnalyticsPageNames.CONTACT_US_NOIA
      contactUsText = `We are missing some needed information. Please contact us at ${storePhoneNumber} so we can help you complete your application. ${
        docData ? 'Please click on the below link for additional details.' : ''
      }`
      break
    case Routes.CONTACT_US_NOAA:
      analyticsPageName = AnalyticsPageNames.CONTACT_US_NOAA
      contactUsText = `We are unable to verify your covered borrow status. Please contact us at ${storePhoneNumber} so we can review this with you. ${
        docData
          ? 'Please click on the below links for additional information.'
          : ''
      }`
      break
    default:
      analyticsPageName = AnalyticsPageNames.CONTACT_US
      contactUsText = ``
      break
  }

  const trackMetric = useTrackMetric(appInsightsContext, analyticsPageName)
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Gets Decline letter
   * @param customerId CustomerId of the logged in user.
   */
  async function getDeclineLetter(customerId: number) {
    try {
      setShowSpinner(true)
      const response = await getDecisionLetter(customerId)
      const {decisionLetter} = response
      setDocData(decisionLetter)
      setShowSpinner(false)
    } catch (error) {
      setShowSpinner(false)
    }
  }

  return (
    <>
      {showSpinner ? (
        <Container>
          <Spinner
            animation="border"
            className={`${styles.spinner} text-primary`}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      ) : (
        <Container>
          <Card className="mt-4 full-page">
            <Card.Header className="text-center">
              <h1>Please Give Us a Call</h1>
            </Card.Header>
            <Card.Body className={`${styles.customcardbody} text-center`}>
              <div>
                <p className="mb-3">{contactUsText}</p>
                {link}
              </div>
            </Card.Body>
          </Card>
        </Container>
      )}
    </>
  )
}

/**
 * Contact us page with decline letter
 */
export default ContactUs
