import React, {useCallback, useState} from 'react'

import {slide as SideMenu, State} from 'react-burger-menu'
import {MdMenu} from 'react-icons/md'
import {Link, useLocation} from 'react-router-dom'
import {BsBoxArrowInRight} from 'react-icons/bs'

import {appPages, externalPages} from 'pages/index'
import 'components/Menu/Menu.scss'
import {ContentSlot} from 'models/Content'
import {useAuth} from 'auth'
import Content from 'components/Content'

const Menu: React.FC = () => {
  /**
   * State, Hooks
   */
  const [menuOpen, setMenuOpen] = useState(false)
  const {logout} = useAuth()
  const location = useLocation()
  /**
   * Callbacks
   */
  const handleStateChange = useCallback(setMenuOpenCallback, [setMenuOpen])
  const closeMenu = useCallback(closeMenuOpenCallback, [setMenuOpen])

  return (
    <SideMenu
      customBurgerIcon={<MdMenu />}
      isOpen={menuOpen}
      onStateChange={handleStateChange}
    >
      {appPages.map((appPage, index) => (
        <div key={index}>
          <Link
            className={`menu-item ${
              location.pathname === appPage.url ? 'selected' : ''
            }`}
            to={appPage.url}
            onClick={closeMenu}
          >
            <div className="menu-item-icon">
              <appPage.icon />
            </div>
            {appPage.title}
          </Link>
        </div>
      ))}
      {externalPages.map((externalPage, index) => (
        <div key={index}>
          <a
            className="menu-item"
            href={externalPage.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="menu-item-icon">
              <externalPage.icon />
            </div>
            {externalPage.title}
          </a>
        </div>
      ))}
      <div>
        <Link className="menu-item" to="#" onClick={logout}>
          <div className="menu-item-icon">
            <BsBoxArrowInRight />
          </div>
          <Content type={ContentSlot.HAMBURGER_LOGOUTTEXT} />
        </Link>
      </div>
    </SideMenu>
  )

  /**
   * Resets local state
   * @param state the state passed
   */
  function setMenuOpenCallback(state: State) {
    setMenuOpen(state.isOpen)
  }

  /**
   * Closes the menu
   */
  function closeMenuOpenCallback() {
    setMenuOpen(false)
  }
}

/**
 * Application side menu
 */
export default Menu
