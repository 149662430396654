import {TypeCreditCard} from 'models/PaymentMethods'

import visaLogo from 'assets/img/visa_logo.svg'
import amexLogo from 'assets/img/amex_logo.png'
import discoverLogo from 'assets/img/discover_logo.svg'
import masterCardLogo from 'assets/img/master_card_logo.svg'
import dinersCardLogo from 'assets/img/diners_logo.svg'
import jcbCardLogo from 'assets/img/jcb_logo.svg'
import insightLogo from 'assets/img/insight_logo.svg'
import cardDefaultLogo from 'assets/img/card_default_logo.svg'

/**
 * Returns null
 * @param type TypeCreditCard
 * @return string
 */
export const getImageCreditCard = (type: TypeCreditCard) => {
  switch (type) {
    case TypeCreditCard.VISA:
      return visaLogo
    case TypeCreditCard.MASTERCARD:
      return masterCardLogo
    case TypeCreditCard.AMEX:
      return amexLogo
    case TypeCreditCard.AMERICAN_EXPRESS:
      return amexLogo
    case TypeCreditCard.DISCOVER:
      return discoverLogo
    case TypeCreditCard.DINERS:
      return dinersCardLogo
    case TypeCreditCard.DINERS_CLUB:
      return dinersCardLogo
    case TypeCreditCard.JCB:
      return jcbCardLogo
    case TypeCreditCard.INSIGHT:
      return insightLogo
    default:
      return cardDefaultLogo
  }
}

/**
 * Returns null
 * @param type TypeCreditCard
 * @param cardTypes object
 * @return string
 */
export const getTypeCreditCard = (
  type: TypeCreditCard | undefined,
  cardTypes: object,
) => {
  const key = Object.keys(cardTypes)
  const values = Object.values(cardTypes)
  const index = key.findIndex(k => k.toUpperCase() === type)
  return values[index] as number
}
