import React, {useState} from 'react'

import {Button, Card, Col, Row} from 'react-bootstrap'
import {GoPencil} from 'react-icons/go'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import AccountInfoReadOnly from 'pages/Account/AccountInfo/AccountInfoReadOnly'
import AccountInfoEditing from 'pages/Account/AccountInfo/AccountInfoEditing'
import {useAuth} from 'auth'

const AccountInfo: React.FC = () => {
  /**
   * State, Hooks
   */
  const {user} = useAuth()

  const [editMode, setEditMode] = useState(false)

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  return (
    <Card className="mb-2 pb-0">
      <Card.Header className="text-left">
        <h2
          className="border-bottom pb-2 border-medium font-weight-light d-flex justify-content-between"
          data-ref="accountInfo"
        >
          <Content type={ContentSlot.ACCOUNT_ACCOUNTINFOHEADER} />
          <Button
            className="p-0"
            data-ref="toggle-edit-account"
            variant="link"
            onClick={toggleEditMode}
          >
            {(editMode && 'Cancel') || (
              <>
                <GoPencil /> Edit
              </>
            )}
          </Button>
        </h2>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col>
            <p className="mb-1">Username</p>
            <p>
              <b>{user?.username}</b>
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p className="mb-1">Email Address</p>
            <p>
              <b>{user?.email}</b>
            </p>
          </Col>
        </Row>
        {editMode ? (
          <AccountInfoEditing onSubmit={toggleEditMode} />
        ) : (
          <AccountInfoReadOnly />
        )}
      </Card.Body>
    </Card>
  )
}

/**
 * Account Info Card
 */
export default AccountInfo
