import React, {SyntheticEvent, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {Row, Col, Form as BootstrapForm} from 'react-bootstrap'

import Dropdown, {InputOpt} from 'components/Form/Dropdown'
import {
  PaymentMethodTypes,
  RefinanceTitleFormValues,
  CustomerCard,
} from 'models/Edge'
import {FormikErrors, FormikTouched} from 'formik'

/**
 * Props for Debit Card
 */
export interface Props {
  cardInfo?: CustomerCard[]
  paymentMethod: string
  cvv?: string
  errors: FormikErrors<RefinanceTitleFormValues>
  touched: FormikTouched<RefinanceTitleFormValues>
  handleBlur: (event: SyntheticEvent<HTMLInputElement>) => void
  handleChange: (event: SyntheticEvent<HTMLInputElement>) => void
  idSuffix?: string
  showOneTimeCard?: boolean
  showAddCard?: boolean
  onAddCard?: () => void
  showAddCardAndPay?: boolean
}

const DebitCardsDropdownAutopayment: React.FC<Props> = ({
  cardInfo,
  handleBlur,
  handleChange,
  paymentMethod,
  errors,
  touched,
  idSuffix = '',
  showOneTimeCard,
  onAddCard,
  showAddCard,
  showAddCardAndPay,
}) => {
  const history = useHistory()

  const customHandleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (
        (event.target as HTMLInputElement).value ===
        PaymentMethodTypes.NEW_CARD
      ) {
        onAddCard?.()
        history.push(`/add-card`, {
          from: history.location.pathname,
        })
      } else {
        handleChange(event)
      }
    },
    [handleChange, history, onAddCard],
  )

  const paymentOptions: InputOpt[] = []

  const oneTimeCard: InputOpt = {
    value: PaymentMethodTypes.ONE_TIME_CARD,
    label: (
      <span className="font-weight-bold text-primary">
        Use a Card for a One-Time Payment
      </span>
    ),
  }
  const addCard: InputOpt = {
    value: PaymentMethodTypes.NEW_CARD,
    label: <span className="font-weight-bold text-primary">Add a Card</span>,
  }
  const addCardAndPay: InputOpt = {
    value: PaymentMethodTypes.ADD_CARD_AND_PAY,
    label: <span className="font-weight-bold text-primary">Add a Card</span>,
  }

  if (showAddCard) {
    paymentOptions.push(addCard)
  }

  if (showOneTimeCard) {
    paymentOptions.push(oneTimeCard)
  }

  if (showAddCardAndPay) {
    paymentOptions.push(addCardAndPay)
  }

  cardInfo?.forEach(card => {
    if (card.accountNumberMasked) {
      paymentOptions.push({
        value: `${PaymentMethodTypes.EXISTING_CARD}:${card.id}`,
        label: (
          <span>
            {card.cardType ?? ''} ending in {card.accountNumberMasked}
          </span>
        ),
      })
    }
  })

  const showError = touched.autoPaymentMethod && errors.autoPaymentMethod
  const error = errors.autoPaymentMethod

  return (
    <>
      <Row>
        <Col>
          <BootstrapForm.Group controlId={`autoPaymentMethod${idSuffix}`}>
            <BootstrapForm.Label>
              Select the debit card you would like to use for your automatic
              payments.*
            </BootstrapForm.Label>

            <Dropdown
              formValue={paymentMethod}
              handleBlur={handleBlur}
              handleChange={customHandleChange}
              id={`autoPaymentMethod${idSuffix}`}
              name="autoPaymentMethod"
              options={paymentOptions}
            />
            {showError && (
              <BootstrapForm.Text className="text-danger">
                {error}
              </BootstrapForm.Text>
            )}
          </BootstrapForm.Group>
        </Col>
      </Row>
    </>
  )
}

/**
 * Auto Payment form for Title loans
 */
export default DebitCardsDropdownAutopayment
