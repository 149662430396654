import React, {useCallback, useEffect, useMemo} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import dayjs from 'dayjs'
import {Button, Card, Container, Row} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Content from 'components/Content'
import SpinnerButton from 'components/SpinnerButton'
import TwoColumnTable, {ListItem} from 'components/TwoColumnTable'
import {ContentSlot} from 'models/Content'
import {PostPaymentResponse} from 'models/Edge'

import {PaymentData, getPaymentData, getPaymentReceiptData} from 'utils/cache'
import {formatCurrency} from 'utils/data-formatting'
import styles from 'pages/MakePaymentReceipt/MakePaymentReceipt.module.scss'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'

const today = new Date()

const MakePaymentReceipt: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.MAKE_PAYMENT_RECEIPT,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.PAYMENT_RECEIPT_VISIT,
    })
  }, [appInsightsContext])
  /**
   * State, Hooks
   */
  const history = useHistory()
  const paymentData = getPaymentData()
  const paymentReceiptData = getPaymentReceiptData()

  /**
   * Callbacks
   */
  const goHome = useCallback(goHomeCallback, [])
  const handlePrintClick = useCallback(handlePrintCallback, [window])

  /**
   * Memoizations
   */
  const {personalDetails, paymentDetails, appliedDetails} = useMemo(
    () => createSuccessTableData(paymentData, paymentReceiptData),
    [paymentData, paymentReceiptData],
  )

  /**
   * Kentico Konent
   */
  const paymentDetailsTitle = (
    <Content type={ContentSlot.SUCCESSFULPAYMENT_PAYMENTDETAILSTITLE} />
  )
  const paymentReceivedTitle = (
    <Content type={ContentSlot.SUCCESSFULPAYMENT_PAYMENTRECEIVEDTITLE} />
  )
  const paymentAppliedTitle = (
    <Content type={ContentSlot.SUCCESSFULPAYMENT_PAYMENTAPPLIEDTITLE} />
  )
  if (!paymentData || !paymentReceiptData) {
    history.push('/home')
  }

  return (
    <Container className={styles.printReceipt}>
      <Card className="my-2">
        <Card.Header className="text-center">
          <h1>
            <Content type={ContentSlot.SUCCESSFULPAYMENT_TITLE} />
          </h1>
          {/* Commented out until CFL provides description in kentico <p>
            <Content type={ContentSlot.SUCCESSFULPAYMENT_DESCRIPTION} />
          </p> */}
        </Card.Header>
        <Card.Body>
          <Container
            fluid
            className={`${styles.cardContainer} card-container container-fluid border px-3 rounded`}
          >
            <TwoColumnTable
              list={personalDetails}
              title={paymentDetailsTitle}
            />
            <TwoColumnTable
              list={paymentDetails}
              title={paymentReceivedTitle}
            />
            <TwoColumnTable
              list={appliedDetails}
              title={paymentAppliedTitle}
            />
            <small>
              <Content type={ContentSlot.SUCCESSFULPAYMENT_FINEPRINTTEXT} />
            </small>
            <div className="my-3">
              <Button
                className="text-primary p-0"
                variant="link"
                onClick={handlePrintClick}
              >
                <Content
                  type={ContentSlot.SUCCESSFULPAYMENT_PRINTBUTTONTEXT}
                />
              </Button>
            </div>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Container>
            <Row className="justify-content-center">
              <SpinnerButton
                color="primary"
                data-ref="homeButton"
                size="lg"
                type="submit"
                onClick={goHome}
              >
                <Content type={ContentSlot.SUCCESSFULPAYMENT_BUTTONTEXT} />
              </SpinnerButton>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </Container>
  )

  /**
   * Redirects user to homepage
   */
  function goHomeCallback() {
    history.push('/home')
  }

  /**
   * Opens the print dialog
   */
  function handlePrintCallback() {
    window.print()
  }

  /**
   * Generates the rows to be shown in the table for confirmation
   * @param pData gathered on previous screens and stored in query cache
   * @param successData the response from payment request
   * @return array of ListItems
   */
  function createSuccessTableData(
    pData: PaymentData | undefined,
    successData: PostPaymentResponse | undefined,
  ) {
    if (!pData || !successData || !successData.paymentReceipt)
      return {
        personalDetails: [],
        paymentDetails: [],
        appliedDetails: [],
      }

    const details = {
      personalDetails: [] as ListItem[],
      paymentDetails: [] as ListItem[],
      appliedDetails: [] as ListItem[],
    }

    details.personalDetails = [
      {
        label: 'Name',
        value: `${pData.customerName}`,
      },
      {
        label: 'Payment Method',
        value: `${pData.cardType} ending in ${pData.cardTokenLast4}`,
      },
      {
        label: 'Date',
        value: `${dayjs(today).format('MM/DD/YYYY')}`,
      },
      {
        label: 'Reference Number',
        value: `${successData.paymentReceipt.transactionItemId}`,
      },
    ]

    details.paymentDetails = [
      {
        label: 'Amount Paid by You',
        value: formatCurrency(pData.paymentAmount),
      },
    ]

    details.appliedDetails.push({
      label: 'Finance Charges*',
      value: `${formatCurrency(Math.abs(successData.paymentReceipt.fee))}`,
    })

    details.appliedDetails.push({
      label: 'Principal Amount',
      value: `${formatCurrency(
        Math.abs(successData.paymentReceipt.principal),
      )}`,
    })

    details.appliedDetails.push({
      label: 'Loan Balance as of Today',
      value: `${formatCurrency(successData.paymentReceipt.balance)}`,
    })

    return details
  }
}

/**
 * Screen that displays the receipt after making the payment
 */
export default MakePaymentReceipt
