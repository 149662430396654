import {vergentService} from 'utils/vergent-service'
import type {
  NewPasswordRequest,
  NewPasswordResponse,
  VerifyResetEmailResponse,
} from 'models/Auth'
import {StandardError, VergentError} from 'models/Errors'

interface ResetPasswordActionArgs {
  resetPasswordRequestBody: NewPasswordRequest
  resetToken: string
}

/**
 * Request password recover error types
 */
export enum ResetPasswordErrorTypes {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
}

/**
 * Reset password action
 */
export const resetPasswordAction = async ({
  resetPasswordRequestBody,
  resetToken,
}: ResetPasswordActionArgs) => {
  let shouldReturnSameError = false
  try {
    const {
      data: {isVerified, resetToken: newResetToken},
    } = await vergentService.get<VerifyResetEmailResponse>(
      `/VergentCustomerPasswordReset/VerifyResetEmail/${resetToken}`,
    )

    if (!isVerified) {
      shouldReturnSameError = true
      throw new StandardError({
        type: ResetPasswordErrorTypes.VergentServiceError,
      })
    }

    const {
      data: {success},
    } = await vergentService.post<NewPasswordResponse>(
      `/VergentCustomerPasswordReset/NewPassword/${newResetToken}`,
      resetPasswordRequestBody,
    )

    if (!success) {
      shouldReturnSameError = true
      throw new StandardError({
        type: ResetPasswordErrorTypes.VergentServiceError,
      })
    }
  } catch (error) {
    if (shouldReturnSameError) throw error

    const {errorInstance, errorWasHandled} = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: ResetPasswordErrorTypes.InternalServerError,
      })
    }

    throw new StandardError({
      type: ResetPasswordErrorTypes.VergentServiceError,
    })
  }
}
