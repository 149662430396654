import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {vergentService} from 'utils/vergent-service'
import {setCookie, getCookie} from 'utils/cookies'
import {VergentError} from 'models/Errors'

const SEARCH_CUSTOMER_ATTEMPTS_COOKIE_NAME = 'searchCustomerAttempts'

const setSearchCustomerAttemptsCookie = (
  value: number,
  daysToExpire: number,
) => {
  setCookie({name: SEARCH_CUSTOMER_ATTEMPTS_COOKIE_NAME, value, daysToExpire})
}

/**
 * Gets the search customer attempts from the cookies
 * @return search customer attempts
 */
export const getSearchCustomerAttemptsCookie = () => {
  const searchCustomerAttemptsCookieValue = getCookie(
    SEARCH_CUSTOMER_ATTEMPTS_COOKIE_NAME,
  )

  if (
    searchCustomerAttemptsCookieValue === '' ||
    Number.isNaN(Number(searchCustomerAttemptsCookieValue))
  ) {
    setSearchCustomerAttemptsCookie(0, 365)
    return 0
  }

  return Number(searchCustomerAttemptsCookieValue)
}

/**
 * Key to identify if the account search is locked
 */
export const LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY = 'LOCKED_ACCOUNT_SEARCH'
/**
 * Limit of customer search attempts
 */
export const CUSTOMER_SEARCH_ATTEMPTS_LIMIT = 4
const DAYS_TO_LOCK_ACCOUNT_SEARCH = 1
const CUSTOMER_SEARCH_VERNGET_ENDPOINT = '/VergentCustomer/Customer/Search'

/**
 * Setup vergenr service interceptors to lock the customer search when the user exceeds
 * the limits of attepmts to find a customer
 */
export const handleLockSearchCustumer = () => {
  vergentService.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.url === CUSTOMER_SEARCH_VERNGET_ENDPOINT) {
      const searchAccountAttempts = getSearchCustomerAttemptsCookie()
      if (searchAccountAttempts >= CUSTOMER_SEARCH_ATTEMPTS_LIMIT) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new axios.Cancel(LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY)
      }
    }
    return config
  })

  vergentService.interceptors.response.use(
    (response: AxiosResponse) => {
      if (
        response.config.url === CUSTOMER_SEARCH_VERNGET_ENDPOINT &&
        response.data.customerSearchResults &&
        response.data.customerSearchResults.length
      ) {
        const searchAccountAttempts = getSearchCustomerAttemptsCookie()
        if (searchAccountAttempts + 1 >= CUSTOMER_SEARCH_ATTEMPTS_LIMIT) {
          setSearchCustomerAttemptsCookie(
            CUSTOMER_SEARCH_ATTEMPTS_LIMIT,
            DAYS_TO_LOCK_ACCOUNT_SEARCH,
          )
        } else {
          setSearchCustomerAttemptsCookie(searchAccountAttempts + 1, 365)
        }
        response.data = {
          ...response.data,
        }
      }
      return response
    },
    (error: VergentError) => {
      const errorInstance = error.errorInstance as AxiosError

      if (
        errorInstance.response?.config.url ===
          CUSTOMER_SEARCH_VERNGET_ENDPOINT &&
        error.status === 429
      ) {
        setSearchCustomerAttemptsCookie(
          CUSTOMER_SEARCH_ATTEMPTS_LIMIT,
          DAYS_TO_LOCK_ACCOUNT_SEARCH,
        )
        error.errorInstance = new Error(
          LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY,
        ) as AxiosError
      }

      if (
        errorInstance.response?.config.url ===
          CUSTOMER_SEARCH_VERNGET_ENDPOINT &&
        error.status === 404
      ) {
        const searchAccountAttempts = getSearchCustomerAttemptsCookie()
        if (searchAccountAttempts + 1 >= CUSTOMER_SEARCH_ATTEMPTS_LIMIT) {
          setSearchCustomerAttemptsCookie(
            CUSTOMER_SEARCH_ATTEMPTS_LIMIT,
            DAYS_TO_LOCK_ACCOUNT_SEARCH,
          )
          error.errorInstance = new Error(
            LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY,
          ) as AxiosError
        } else {
          setSearchCustomerAttemptsCookie(searchAccountAttempts + 1, 365)
          error.additionalProp1 = `${searchAccountAttempts + 1}`
        }
      }

      throw error
    },
  )
}
