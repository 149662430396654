/* eslint-disable no-case-declarations */
import React, {useMemo} from 'react'

import {ContentItem} from '@kentico/kontent-delivery'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'

import {LinksList} from 'components/Content/LinksListContent'
import styles from 'components/Content/Content.module.scss'
import PreviewLink from 'components/PreviewLink'
import {
  ButtonContent,
  ContentTypes,
  CopyContent,
  EmbeddedContent,
  LinkContent,
  LinksListContent,
  TelephoneLinkContent,
  TextContent,
} from 'models/Content'
import {useContent} from 'utils/hooks/useContent'
import {formatPhone} from 'utils/data-formatting'
import {EmbeddedRenderable} from 'components/Content/EmbeddedRenderable'

interface ContentProps {
  type: string
  primaryLinkColor?: boolean
}

const Content: React.FC<ContentProps> = ({
  type,
  primaryLinkColor,
}: ContentProps) => {
  /**
   * State, Hooks
   */
  const content = useContent(type)
  const contentType = content?.system.type

  const usesRelativeLink = useMemo(usesRelativeLinkMemo, [
    content,
    contentType,
  ])

  if (!content) {
    return <></>
  }

  switch (contentType) {
    case ContentTypes.LINKS_LIST_CONTENT:
      const linksContent = content as LinksListContent
      const links = linksContent.links.value
      return (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={linksContent} />
          <LinksList items={links as ContentItem[]} />
        </div>
      )
    case ContentTypes.LINK_CONTENT:
      const linkContent = content as LinkContent
      return usesRelativeLink ? (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={linkContent} />
          <Link to={linkContent.link_url.value}>
            {content.link_text.value}
          </Link>
        </div>
      ) : (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={linkContent} />
          <a
            aria-label={content.link_text.value}
            className={primaryLinkColor ? '' : 'text-dark'}
            href={linkContent.link_url.value}
          >
            {content.link_text.value}
          </a>
        </div>
      )
    case ContentTypes.BUTTON_CONTENT:
      const buttonContent = content as ButtonContent
      return usesRelativeLink ? (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={buttonContent} />
          <Link to={buttonContent.url.value}>
            <Button>{buttonContent.text.value}</Button>
          </Link>
        </div>
      ) : (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={buttonContent} />
          <a
            aria-label={buttonContent.text.value}
            href={buttonContent.url.value}
          >
            <Button>{buttonContent.text.value}</Button>
          </a>
        </div>
      )
    case ContentTypes.COPY_CONTENT:
      const copyContent = content as CopyContent
      return (
        // Purposely ignored to give client flexibility
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={copyContent} />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{__html: copyContent.copy.value}} // eslint-disable-line react/no-danger
          />
        </div>
      )
    case ContentTypes.TEXT_CONTENT:
      const textContent = content as TextContent
      return (
        <span className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={textContent} />
          {textContent.value.value}
        </span>
      )
    case ContentTypes.TELEPHONE_LINK_CONTENT:
      const telephoneLinkContent = content as TelephoneLinkContent
      const telephoneNumber = telephoneLinkContent.telephone_number.value
      const telephoneText = telephoneLinkContent.telephone_link_text.value
      return (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={telephoneLinkContent} />
          <a href={`tel:${telephoneNumber}`}>
            {telephoneText ? telephoneText : formatPhone(telephoneNumber)}
          </a>
        </div>
      )
    case ContentTypes.EMBEDDED_CONTENT:
      const embeddedContent = content as EmbeddedContent
      return (
        <div className={styles.DisplayPreviewOnHover}>
          <PreviewLink contentItem={embeddedContent} />
          <EmbeddedRenderable
            contentHtml={embeddedContent.embedded_content_field.value.toString()}
          />
        </div>
      )
    case ContentTypes.EMPTY:
      return <></>
    default:
      return <></>
  }

  /**
   * Checks if the Kentico link is relative
   * @return returns if the link is relative
   */
  function usesRelativeLinkMemo() {
    return (
      (contentType === 'link' || contentType === 'button') &&
      /^\/.*/g.test(content?.link_url.value)
    )
  }
}

/**
 * Renders content populated from Kentico Kontent
 * @param n the content type as assigned on Kontent CMS
 * @return rendered content according to data type
 *
 * @example
 *
 * <Content type={ContentSlot.FOOTER_LINKS} />
 */
export default Content
