import React, {useCallback, useContext, useEffect, useState} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {
  Button,
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
} from 'react-bootstrap'
import {GoChevronLeft} from 'react-icons/go'
import {IoIosCheckmarkCircleOutline} from 'react-icons/io'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'

import {useAuth} from 'auth'
import {ToastContext} from 'components/Toast'
import Content from 'components/Content'
import SpinnerButton from 'components/SpinnerButton'
import {RequestPasswordRecoverValues} from 'models/AccountFormValues'
import {ContentSlot} from 'models/Content'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {StandardError} from 'models/Errors'

const FormSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
})

const ForgotPassword: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.FORGOT_PASSWORD,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Context
   */
  const history = useHistory()
  const {requestPasswordRecover} = useAuth()
  const {pushToast} = useContext(ToastContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  /**
   * Callbacks
   */
  const goToLogin = useCallback(goToLoginCallback, [history])
  const submitForm = useCallback(submitFormValues, [
    requestPasswordRecover,
    setIsSubmitting,
    setIsSuccessful,
  ])

  const initialFormValues: RequestPasswordRecoverValues = {
    username: '',
  }

  /**
   * Submit form values
   * @param values form values
   */
  async function submitFormValues(values: RequestPasswordRecoverValues) {
    setIsSubmitting(true)
    try {
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.FORGOT_PASSWORD_ATTEMPT},
        values,
      )
      await requestPasswordRecover(values.username)
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.FORGOT_PASSWORD_SUCCESS},
        values,
      )
      setIsSuccessful(true)
    } catch (error) {
      const {trackableMessage} = error as StandardError

      if (trackableMessage) {
        pushToast({
          title: 'Unable to reset password',
          message: trackableMessage,
          variant: 'danger',
        })
      }

      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.FORGOT_PASSWORD_FAILURE},
        values,
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  /**
   * Resets flowId and navigates to login screen
   */
  function goToLoginCallback() {
    history.push('/login')
  }

  return (
    <Container>
      <Card className="mt-4 full-page">
        {isSuccessful && (
          <Card.Header>
            <Container>
              <Row className="justify-content-center">
                <IoIosCheckmarkCircleOutline color="#59d1a9" size="6rem" />
              </Row>
              <Row className="justify-content-center h1 mt-2">
                <h2>
                  <Content type={ContentSlot.FORGOTPASSWORD_SUCCESSTITLE} />
                </h2>
              </Row>
              <Row className="justify-content-center h5 text-center">
                <Content type={ContentSlot.FORGOTPASSWORD_SUCCESSMESSAGE} />
              </Row>
            </Container>
          </Card.Header>
        )}

        {!isSuccessful && (
          <>
            {' '}
            <Card.Header className="text-center">
              <h1>
                <Content type={ContentSlot.FORGOTPASSWORD_TITLE} />
              </h1>
              <Content type={ContentSlot.FORGOTPASSWORD_SUMMARY} />
            </Card.Header>
            <Formik
              validateOnBlur
              initialValues={initialFormValues}
              validateOnChange={false}
              validationSchema={FormSchema}
              onSubmit={submitForm}
            >
              {(props: FormikProps<RequestPasswordRecoverValues>) => (
                <Form>
                  <Card.Body>
                    <Row className="justify-content-center">
                      <Col lg="6" xs="12">
                        <BootstrapForm.Group controlId="username">
                          <BootstrapForm.Label>Username</BootstrapForm.Label>
                          <BootstrapForm.Control
                            data-ref="username"
                            name="username"
                            type="text"
                            value={props.values.username}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />
                          {props.touched.username && props.errors.username && (
                            <BootstrapForm.Text className="text-danger">
                              {props.errors.username}
                            </BootstrapForm.Text>
                          )}
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                  </Card.Body>

                  <Card.Footer>
                    <Container>
                      <Row className="justify-content-center">
                        <Col className="col-12 p-0 text-center">
                          <SpinnerButton
                            color="primary"
                            data-ref="submit"
                            loading={isSubmitting}
                            size="lg"
                            type="submit"
                          >
                            <Content
                              type={ContentSlot.FORGOTPASSWORD_SUBMITBUTTON}
                            />
                          </SpinnerButton>
                        </Col>
                        <Col className="col-12 p-3 text-center">
                          <Button
                            className="text-dark"
                            data-ref="backToLogin"
                            variant="link"
                            onClick={goToLogin}
                          >
                            <GoChevronLeft /> Back to Login
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Footer>
                  <FormikErrorFocus
                    align="middle"
                    duration={500}
                    ease="linear"
                    focusDelay={200}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </Card>
    </Container>
  )
}

/**
 * Forgot Password page
 */
export default ForgotPassword
