import React, {createContext, useContext, useCallback, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {ToastContext} from 'components/Toast'
import {AuthActionTypes, useAuthReducer} from './useAuthReducer'
import PageSpinner from 'components/PageSpinner'
import type {AuthContextType} from './auth.types'
import {
  loginAction,
  loginAfterMfaAction,
  logoutAction,
  resetPasswordAction,
  requestPasswordUpdateAction,
  searchCustomerAccountAction,
  requestPasswordRecoverAction,
  registerCustomerAccountAction,
} from './actions'
import type {
  NewPasswordRequest,
  AuthenticateRequest,
  CustomerSearchRequest,
  RegisterAccountRequest,
  AuthenticateAfterMFARequest,
} from 'models/Auth'
import InactivityModal from 'components/InactivityModal'
import {setupVergentServiceErrorHandler} from './setup-vergent-service-error-handler'
import {verifySession} from './verify-session'
import {verifyTokenExpiration} from './verify-token-expiration'
import {handleLockSearchCustumer} from './handle-lock-search-customer'
import {LocalStorageKeys} from 'utils/common'

/**
 * Authentication context provider
 * @return Authentication context provider
 */
export const AuthProvider: React.FC = ({children}) => {
  const {pushToast} = useContext(ToastContext)
  const {authState, dispatchAuthState} = useAuthReducer()
  const history = useHistory()

  const logout = useCallback(
    () => logoutAction({history, dispatchAuthState}),
    [dispatchAuthState, history],
  )

  useEffect(() => {
    setupVergentServiceErrorHandler({logout, pushToast, history})
  }, [history, logout, pushToast])

  useEffect(() => {
    verifySession({
      logout,
      dispatchAuthState,
      isInitialVerification: true,
      isLoggedIn: authState.isLoggedIn,
    })

    const verifySessionOnFocus = async () =>
      verifySession({
        logout,
        dispatchAuthState,
        isInitialVerification: false,
        isLoggedIn: authState.isLoggedIn,
      })

    window.addEventListener('focus', verifySessionOnFocus)
    return () => window.removeEventListener('focus', verifySessionOnFocus)
  }, [logout, dispatchAuthState, authState.isLoggedIn])

  useEffect(() => {
    const verifyTokenTimeout = verifyTokenExpiration({
      logout,
      isLoggedIn: Boolean(
        authState.isLoggedIn &&
          localStorage.getItem(LocalStorageKeys.AccessToken),
      ),
    })
    return () => {
      if (verifyTokenTimeout) {
        clearTimeout(verifyTokenTimeout)
      }
    }
  }, [logout, authState.user, authState.isLoggedIn])

  useEffect(() => {
    handleLockSearchCustumer()
  }, [])

  const login = useCallback(
    async (loginRequestBody: AuthenticateRequest) =>
      loginAction({loginRequestBody, dispatchAuthState}),
    [dispatchAuthState],
  )

  const loginAfterMFA = useCallback(
    (loginRequestBody: AuthenticateAfterMFARequest) =>
      loginAfterMfaAction({loginRequestBody, dispatchAuthState}),
    [dispatchAuthState],
  )

  const searchCustomerAccount = useCallback(
    async (customerSearchRequestBody: CustomerSearchRequest) =>
      searchCustomerAccountAction({
        customerSearchRequestBody,
        dispatchAuthState,
      }),
    [dispatchAuthState],
  )

  const registerCustomerAccount = useCallback(
    async (registerAccountRequestBody: RegisterAccountRequest) =>
      registerCustomerAccountAction({
        registerAccountRequestBody,
        dispatchAuthState,
      }),
    [dispatchAuthState],
  )

  const resetRegisterFlow = useCallback(() => {
    dispatchAuthState({
      type: AuthActionTypes.ResetRegisterFlow,
    })
  }, [dispatchAuthState])

  const requestPasswordRecover = useCallback(
    async (userNameOrEmail: string) =>
      requestPasswordRecoverAction({userNameOrEmail}),
    [],
  )

  const resetPassword = useCallback(
    async (resetPasswordRequestBody: NewPasswordRequest, resetToken: string) =>
      resetPasswordAction({resetPasswordRequestBody, resetToken}),
    [],
  )

  const requestPasswordUpdate = useCallback(
    async () => requestPasswordUpdateAction(authState.user?.username ?? ''),
    [authState.user],
  )

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        loginAfterMFA,
        logout,
        resetPassword,
        resetRegisterFlow,
        searchCustomerAccount,
        requestPasswordUpdate,
        requestPasswordRecover,
        registerCustomerAccount,
      }}
    >
      {authState.isLoading && <PageSpinner />}
      <InactivityModal />
      {!authState.isLoading && children}
    </AuthContext.Provider>
  )
}

/**
 * Authentication Context
 */
export const AuthContext = createContext<AuthContextType | null>(null)

/**
 *  Hook to get auth info and auth utilities
 * @return Authentication context
 */
export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('AuthContext must be inside AuthProvider')

  return context
}
