import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {
  Button,
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
} from 'react-bootstrap'
import {GoChevronLeft} from 'react-icons/go'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'

import {useAuth} from 'auth'
import Content from 'components/Content'
import SpinnerButton from 'components/SpinnerButton'
import {PasswordResetValues} from 'models/AccountFormValues'
import {ContentSlot} from 'models/Content'
import PasswordRules, {passwordRules} from 'components/PasswordRules'
import {AnalyticsPageNames} from 'models/Analytics'
import {ToastContext} from 'components/Toast'

const FormSchema = Yup.object().shape({
  ...passwordRules,
})

const RESET_TOKEN_QUERY_PARAM_KEY = 'key'

const ResetPassword: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.RESET_PASSWORD,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Context
   */
  const history = useHistory()
  const {resetPassword, isLoggedIn} = useAuth()
  const {pushToast} = useContext(ToastContext)

  const [isSubmitting, setIsSubmitting] = useState(false)

  /**
   * Memo
   */
  const resetToken = useMemo(() => {
    const {location} = window
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get(RESET_TOKEN_QUERY_PARAM_KEY)
  }, [])

  useEffect(() => {
    if (!resetToken) {
      if (isLoggedIn) {
        history.push('/home')
      } else {
        history.push('/forgot-password')
      }
    }
  }, [resetToken, history, isLoggedIn])

  /**
   * Callbacks
   */
  const goBack = useCallback(goBackCallback, [history, isLoggedIn])

  const initialFormValues: PasswordResetValues = {
    password: '',
    confirmPassword: '',
  }

  /**
   * Submit form values
   * @param values form values
   */
  async function submitFormValues(values: PasswordResetValues) {
    setIsSubmitting(true)
    try {
      if (!resetToken) throw new Error('Reset token not found.')
      await resetPassword({updatedPassword: values.password}, resetToken)
      if (isLoggedIn) {
        history.push('/home')
      } else {
        history.push('/login')
      }
    } catch {
      pushToast({
        title: isLoggedIn
          ? 'Unable to update password'
          : 'Unable to reset password',
        message: isLoggedIn
          ? 'There was an error updating password.'
          : 'There was an error resetting password.',
        variant: 'danger',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  /**
   * Resets flowId and navigates to login screen
   */
  function goBackCallback() {
    if (isLoggedIn) {
      history.push('/home')
    } else {
      history.push('/login')
    }
  }

  return (
    <Container>
      <Card className="mt-4 full-page">
        <Card.Header className="text-center mb-3">
          <h1>
            <Content type={ContentSlot.RESETPASSWORD_TITLE} />
          </h1>
          <div>
            <Content type={ContentSlot.RESETPASSWORD_SUMMARY} />
          </div>
        </Card.Header>
        <Formik
          validateOnBlur
          initialValues={initialFormValues}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={submitFormValues}
        >
          {(props: FormikProps<PasswordResetValues>) => (
            <Form>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="password">
                      <BootstrapForm.Label>Password</BootstrapForm.Label>
                      <BootstrapForm.Control
                        data-ref="password"
                        name="password"
                        type="password"
                        value={props.values.password}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.password && props.errors.password && (
                        <BootstrapForm.Text className="text-danger">
                          {props.errors.password}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-2">
                  <Col lg="6" xs="12">
                    <PasswordRules
                      error={Boolean(
                        props.touched.password && props.errors.password,
                      )}
                      password={props.values.password}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="confirmPassword">
                      <BootstrapForm.Label>
                        Retype Password
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        data-ref="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        value={props.values.confirmPassword}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.confirmPassword &&
                        props.errors.confirmPassword && (
                          <BootstrapForm.Text className="text-danger">
                            {props.errors.confirmPassword}
                          </BootstrapForm.Text>
                        )}
                    </BootstrapForm.Group>
                  </Col>
                </Row>
              </Card.Body>

              <Card.Footer>
                <Container>
                  <Row className="justify-content-center">
                    <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                      <Button
                        className="pl-0 text-dark"
                        data-ref="backToLogin"
                        variant="link"
                        onClick={goBack}
                      >
                        <GoChevronLeft /> Back to{' '}
                        {isLoggedIn ? 'Home' : 'Login'}
                      </Button>
                    </Col>
                    <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                      <SpinnerButton
                        color="primary"
                        data-ref="submit"
                        loading={isSubmitting}
                        size="lg"
                        type="submit"
                      >
                        <Content
                          type={ContentSlot.RESETPASSWORD_SUBMITBUTTON}
                        />
                      </SpinnerButton>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
              <FormikErrorFocus
                align="middle"
                duration={500}
                ease="linear"
                focusDelay={200}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

/**
 * Forgot Password page
 */
export default ResetPassword
