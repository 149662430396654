import React, {useEffect} from 'react'

import {useLocation} from 'react-router'

import {getConfigValue} from 'utils/environment'

const GoogleWrapper: React.FC<{children: React.ReactElement}> = ({
  children,
}) => {
  const location = useLocation()
  const trackingId = getConfigValue('GOOGLE_TRACKING_ID')

  useEffect(() => {
    if (trackingId && window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        send_to: trackingId,
      })
    }
  }, [trackingId, location])

  return children
}

/**
 * Wrapper component for google analytics tracking
 */
export default GoogleWrapper
