import React from 'react'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {useLoanData} from 'utils/hooks/useLoanData'

const Disclaimer: React.FC = () => {
  const {currentLoan, reactivationApprovalDetails, isLoading} = useLoanData()
  return (
    <>
      {!isLoading &&
        currentLoan &&
        currentLoan.todaysPayoffAmount === 0 &&
        reactivationApprovalDetails && (
          <Content type={ContentSlot.REACTIVATE_DISCLAIMER} />
        )}
    </>
  )
}

/**
 * Disclaimer
 * @return rendered Disclaimer
 */
export default Disclaimer
