import React from 'react'
import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri'
import SpinnerButton from 'components/SpinnerButton'
import styles from './FormButtons.module.scss'

interface FormButtonsProps {
  onBackClick?: () => void
  backButtonLabel?: string
  nextButtonLabel?: string
  backButtonTestId?: string
  nextButtonTestId?: string
  nextButtonLoading?: boolean
  backButtonDisabled?: boolean
  nextButtonDisabled?: boolean
  hideNextButtonIcon?: boolean
}

const FormButtons = ({
  onBackClick,
  backButtonTestId,
  nextButtonTestId,
  nextButtonLoading,
  backButtonDisabled,
  nextButtonDisabled,
  hideNextButtonIcon,
  backButtonLabel = 'Back',
  nextButtonLabel = 'Next',
}: FormButtonsProps) => (
  <div className={styles.container}>
    <button
      className={styles.backButton}
      data-ref={backButtonTestId}
      disabled={backButtonDisabled}
      type="button"
      onClick={onBackClick}
    >
      <RiArrowLeftSLine />
      {backButtonLabel}
    </button>
    <SpinnerButton
      color="primary"
      data-ref={nextButtonTestId}
      disabled={nextButtonDisabled}
      loading={nextButtonLoading}
      size="lg"
      type="submit"
    >
      {nextButtonLabel}
      {hideNextButtonIcon ? null : <RiArrowRightSLine />}
    </SpinnerButton>
  </div>
)

/**
 * From buttons component
 */
export default FormButtons
