import React, {useState, useRef} from 'react'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import SpinnerButton from 'components/SpinnerButton'
import ContactInfoForm from 'components/ContactInfo/Edit'
import {Props as ContactInfoProps} from 'pages/Account/ContactInfo'
import {FormikValues} from 'formik'
import {Row, Col} from 'react-bootstrap'
import styles from 'components/ContactInfo/ContactInfo.module.scss'

dayjs.extend(customParseFormat)

interface EditContactInfoProps extends ContactInfoProps {
  toggleEditMode: () => void
}

const ContactEditForm: React.FC<EditContactInfoProps> = ({
  userData,
  toggleEditMode,
}: EditContactInfoProps) => {
  /**
   * State, Hooks
   */
  const formRef = useRef<FormikValues>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const navigate = () => {
    window.location.hash = ''

    setTimeout(() => {
      window.location.hash = '#contact-info'
    }, 10)
  }

  return (
    <>
      {Boolean(userData) && (
        <>
          <ContactInfoForm
            formRef={formRef}
            navigate={navigate}
            setIsSubmitting={setIsSubmitting}
            toggleEditMode={toggleEditMode}
            userData={userData}
          />
          <Row className="justify-content-center">
            <Col className={`${styles.Row}`} lg="auto" xs="12">
              <SpinnerButton
                block
                color="primary"
                data-ref="submit"
                loading={isSubmitting}
                size="lg"
                onClick={handleSubmit}
              >
                Update
              </SpinnerButton>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

/**
 * Contact Info Edit Form
 */
export default ContactEditForm
