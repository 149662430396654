import React, {useContext, useEffect, useState} from 'react'

import {Col, Row, Form as BootstrapForm} from 'react-bootstrap'
import {Formik, Form, FormikProps} from 'formik'
import {
  numberCardInputMask,
  cvvMask,
  dateMonthYearInputMask,
} from 'utils/forms/masks'
import * as Yup from 'yup'
import stylesPayment from 'pages/Account/PaymentMethods/PaymentMethods.module.scss'

import MaskedInput from 'react-text-mask'

import {CreateCreditCardFormValues} from 'models/AccountFormValues'
import SpinnerButton from 'components/SpinnerButton'
import Modal from 'components/Modal'
import valid from 'card-validator'

import {useAuth} from 'auth'
import {StatusAction} from 'models/Edge'
import {CardNumberVerification} from 'card-validator/dist/card-number'
import {TypeCreditCard} from 'models/PaymentMethods'
import {getImageCreditCard} from 'utils/payment-methods'
import {ToastContext} from 'components/Toast'
import {useMutation} from 'react-query'
import {useGetCardType} from 'utils/hooks/useGetCustomerCardTypes'
import {addCustomerCard, getProfile} from 'utils/edge'
import {useLoanData} from 'utils/hooks/useLoanData'

const errorMessages = {
  default: 'There was an error creating card.',
  invalidCardNumber: 'The card number is invalid.',
  invalidCardCVV: 'The cvv is invalid',
  invalidCardExpiry: 'The date expiry is invalid',
}

const FormSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required('Card number is required')
    .test(
      'validate-number',
      errorMessages.invalidCardNumber,
      (value: string | undefined) => {
        const val = value ? value.replace(/(_|-)/g, '') : ''
        if (!valid.number(val, {maxLength: 16}).isValid) {
          return false
        }
        return true
      },
    ),
  nameOnCard: Yup.string().required('Name on card is required'),
  cardCVV: Yup.string()
    .required('CVV is required')
    .test('validate-cvv', errorMessages.invalidCardCVV, (value: string) => {
      if (!valid.cvv(value).isValid) {
        return false
      }
      return true
    }),
  cardDateExpiry: Yup.string()
    .required('Date expiry is required')
    .test(
      'validate-date-expiry',
      errorMessages.invalidCardExpiry,
      (value: string) => {
        if (!valid.expirationDate(value).isValid) {
          return false
        }
        return true
      },
    ),
})

interface PaymentMethodsAddProps {
  onCancelAdd: () => void
}

const PaymentMethodsAdd: React.FC<PaymentMethodsAddProps> = ({
  onCancelAdd,
}) => {
  /**
   * State, Hooks
   */
  const {user} = useAuth()
  const {customer} = useLoanData()

  const {data: {data: cardTypes = {}} = {}} = useGetCardType()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccessfullyAdd, setIsSuccessfullyAdd] = useState(false)
  const [cardType, setCardType] = useState<CardNumberVerification>()
  const {pushToast} = useContext(ToastContext)

  const {mutate: addCard} = useMutation(addCustomerCard, {
    onSuccess: async () => {
      if (customer?.personReferenceNumber && user?.username) {
        await getProfile(customer.personReferenceNumber, user.username)
      }
      setIsSubmitting(false)
      setIsSuccessfullyAdd(true)
    },
    onError: () => {
      setIsSubmitting(false)
      pushToast({
        title: 'Unable to create card',
        variant: 'danger',
      })
    },
  })

  const initialFormValues: CreateCreditCardFormValues = {
    cardNumber: '',
    nameOnCard: '',
    cardCVV: '',
    cardDateExpiry: '',
    cardReference: '',
  }

  /**
   * /**
   * Submit form values
   * @param values form values
   */
  function submitFormValues(values: CreateCreditCardFormValues) {
    setIsSubmitting(true)
    addCard({
      values,
      cardTypes,
      isOneTimePaymentCard: false,
      personReferenceNumber: Number(customer?.personReferenceNumber),
    })
  }

  const toggleCloseModal = () => {
    setIsSuccessfullyAdd(false)
  }

  useEffect(() => {
    if (isSuccessfullyAdd) {
      setTimeout(() => {
        setIsSuccessfullyAdd(false)
        onCancelAdd()
      }, 2000)
    }
  }, [isSuccessfullyAdd, onCancelAdd])

  return (
    <>
      <Formik
        validateOnBlur
        initialValues={initialFormValues}
        validationSchema={FormSchema}
        onSubmit={submitFormValues}
      >
        {(props: FormikProps<CreateCreditCardFormValues>) => {
          const onCardNumberChange = (
            event: React.FormEvent<HTMLInputElement>,
          ) => {
            props.handleChange(event)
            const {value} = event.currentTarget
            const val = value.replace(/(_|-)/g, '')
            if (
              value.length > 1 &&
              valid.number(val, {maxLength: 16}).card === null
            ) {
              setCardType({
                card: {
                  type: 'default',
                  niceType: 'default',
                  code: {
                    name: '',
                    size: 0,
                  },
                  patterns: [],
                  gaps: [],
                  lengths: [],
                },
                isValid: false,
                isPotentiallyValid: false,
              })
            } else {
              setCardType(valid.number(val, {maxLength: 16}))
            }
          }

          const onNameCardChange = (
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            const onlyAlphabets = /[^a-zA-Z\s]*$/
            event.currentTarget.value = event.currentTarget.value.replace(
              onlyAlphabets,
              '',
            )
            props.handleChange(event)
          }

          return (
            <Form>
              <Row>
                <Col lg="10" xs="12">
                  <BootstrapForm.Group controlId="cardNumber">
                    <BootstrapForm.Label>Card Number</BootstrapForm.Label>
                    <div className={`${stylesPayment.logoCardContainer}`}>
                      <MaskedInput
                        className="form-control fs-exclude"
                        data-ref="input-cardNumber"
                        guide="false"
                        id="input-cardNumber"
                        mask={numberCardInputMask}
                        name="cardNumber"
                        placeholder="0000-0000-0000-0000"
                        title="Card Number"
                        type="tel"
                        value={props.values.cardNumber}
                        onChange={onCardNumberChange}
                      />
                      {cardType?.card && (
                        <img
                          alt="logo"
                          className={`${stylesPayment.imageCardLogo}`}
                          src={getImageCreditCard(
                            cardType.card.type.toUpperCase() as TypeCreditCard,
                          )}
                        />
                      )}
                    </div>

                    {props.touched.cardNumber && props.errors.cardNumber && (
                      <BootstrapForm.Text
                        className="text-danger"
                        data-ref="card-number-error"
                      >
                        {props.errors.cardNumber}
                      </BootstrapForm.Text>
                    )}
                  </BootstrapForm.Group>
                </Col>
                <Col lg="2" xs="12">
                  <BootstrapForm.Group controlId="cardCVV">
                    <BootstrapForm.Label>CVV</BootstrapForm.Label>
                    <MaskedInput
                      className="form-control fs-exclude"
                      data-ref="input-cardCVV"
                      guide="false"
                      id="input-cardCVV"
                      mask={cvvMask}
                      name="cardCVV"
                      placeholder="***"
                      title="CVV Card"
                      type="password"
                      value={props.values.cardCVV}
                      onChange={props.handleChange}
                    />

                    {props.touched.cardCVV && props.errors.cardCVV && (
                      <BootstrapForm.Text
                        className="text-danger"
                        data-ref="card-cvv-error"
                      >
                        {props.errors.cardCVV}
                      </BootstrapForm.Text>
                    )}
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="10" xs="12">
                  <BootstrapForm.Group controlId="nameOnCard">
                    <BootstrapForm.Label>Name On Card</BootstrapForm.Label>
                    <BootstrapForm.Control
                      data-ref="input-nameOnCard"
                      name="nameOnCard"
                      placeholder="E.g. Jhon Smith"
                      title="Name on Card"
                      type="text"
                      value={props.values.nameOnCard}
                      onBlur={props.handleBlur}
                      onChange={onNameCardChange}
                    />
                    {props.touched.nameOnCard && props.errors.nameOnCard && (
                      <BootstrapForm.Text
                        className="text-danger"
                        data-ref="card-name-error"
                      >
                        {props.errors.nameOnCard}
                      </BootstrapForm.Text>
                    )}
                  </BootstrapForm.Group>
                </Col>
                <Col lg="2" xs="12">
                  <BootstrapForm.Group controlId="cardDateExpiry">
                    <BootstrapForm.Label>Expiry</BootstrapForm.Label>
                    <MaskedInput
                      className="form-control fs-exclude"
                      data-ref="input-cardDateExpiry"
                      guide="false"
                      id="input-cardDateExpiry"
                      mask={dateMonthYearInputMask}
                      name="cardDateExpiry"
                      placeholder="MM/YYYY"
                      title="Card Date Expiry"
                      type="tel"
                      value={props.values.cardDateExpiry}
                      onChange={props.handleChange}
                    />

                    {props.touched.cardDateExpiry &&
                      props.errors.cardDateExpiry && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="card-expiry-error"
                        >
                          {props.errors.cardDateExpiry}
                        </BootstrapForm.Text>
                      )}
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="auto" xs="12">
                  <SpinnerButton
                    block
                    color="primary"
                    data-ref="submit"
                    loading={isSubmitting}
                    size="lg"
                    type="submit"
                  >
                    Add Card
                  </SpinnerButton>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>

      <Modal
        message="You card was added successfully"
        open={isSuccessfullyAdd}
        title="Card Added"
        type={StatusAction.SUCCESS}
        onClose={toggleCloseModal}
      />
    </>
  )
}

/**
 * Payment Method Add
 */
export default PaymentMethodsAdd
