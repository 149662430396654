import React from 'react'

import {LoadScript} from '@react-google-maps/api'

import {getConfigValue} from 'utils/environment'

const googleMapsApiKey = getConfigValue('GOOGLE_MAPS_KEY')
const GOOGLE_LIBRARIES = ['places']

/**
 * Ensure Google API is loaded.
 * @return React component
 */
export const GoogleLoader = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => (
  <LoadScript
    googleMapsApiKey={googleMapsApiKey}
    libraries={GOOGLE_LIBRARIES}
    region="US"
  >
    {children}
  </LoadScript>
)
