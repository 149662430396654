import {useQuery} from 'react-query'

import {getStoreById} from 'utils/edge'

/**
 * Handles fetching the store data
 * @param id store id
 * @return store data in a QueryResponse
 */
export function useStoreDetails(id?: string) {
  return useQuery(['cashStoreLocation', id], async () =>
    id ? getStoreById(id) : null,
  )
}
