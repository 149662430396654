import React, {useCallback, useState} from 'react'
import {Button} from 'react-bootstrap'
import {MdRemoveRedEye} from 'react-icons/md'
import MaskedInput from 'react-text-mask'
import {ssnInputMask} from 'utils/forms/masks'

import styles from './SSNInput.module.scss'

interface SsnProps {
  hiddenValue: string
  trueValue: string
  onBlur: Function
  onChange: Function
  isChanged: boolean
  onKeyDown: Function
  onSelect: Function
}

const SSNLoginInput: React.FC<SsnProps> = ({
  hiddenValue,
  trueValue,
  onChange,
  onBlur,
  isChanged,
  onKeyDown,
  onSelect,
}: SsnProps) => {
  /**
   * State, hooks
   */
  const [hidden, setHidden] = useState(true)

  /**
   * Callbacks
   */
  const toggleSsn = useCallback(() => {
    setHidden(!hidden)
  }, [hidden, setHidden])

  return (
    <>
      <MaskedInput
        className="form-control fs-exclude"
        data-ref="input-ssn"
        guide="false"
        id="input-ssn"
        ischanged={isChanged.toString()}
        mask={ssnInputMask}
        name="ssn"
        title="SSN"
        type="tel"
        value={hidden ? hiddenValue : trueValue}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onSelect={onSelect}
      />
      <div className="input-group-append">
        <Button className="btn-medium" onClick={toggleSsn}>
          <MdRemoveRedEye className={styles.icon} />
        </Button>
      </div>
    </>
  )
}

/**
 * Masked input for SSN
 */
export default SSNLoginInput
