import {Button, Card, Container} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {
  getDocumentForSigning,
  getDownloadDocuments,
  getSigningStatus,
  postFundLoan,
} from 'utils/edge'
import FolderSpinner from 'components/FolderSpinner'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {getConfigValue} from 'utils/environment'
import {useLoanData} from 'utils/hooks/useLoanData'
import {Routes} from 'models/Routes'
import {queryClient} from 'client'
import {ContentSlot} from 'models/Content'
import styles from 'pages/Home/SignDocument/SignDocument.module.scss'
import {DownloadDocuments, SigningStatus} from 'models/Edge'
import Content from 'components/Content'
import {useHistory} from 'react-router'
import {ToastContext} from 'components/Toast'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {AnalyticsEventNames} from 'models/Analytics'

interface SignDocumentProps {
  changeVisible: (boll: boolean) => void
}

const SignDocument = ({changeVisible}: SignDocumentProps) => {
  const history = useHistory()
  const {pushToast} = useContext(ToastContext)
  const appInsightsContext = useAppInsightsContext()

  const {currentLoan, customer} = useLoanData()
  const [downloadDocuments, setDownloadDocuments] = useState<
    DownloadDocuments[]
  >()
  const [loading, setLoading] = useState(false)
  const [isSignedDocumentOpen, setIsSignedDocumentOpen] = useState(false)

  const {
    data: documentsData,
    isLoading: documentLoadingData,
  } = useQuery('documents', async () =>
    getDocumentForSigning({loanHeaderId: currentLoan?.loanId ?? 0}),
  )
  const checkSignedDocuments = useCallback(checkSignedDocumentsCallback, [])
  const goHome = useCallback(goHomeCallback, [history])
  useEffect(() => {
    document.addEventListener('visibilitychange', checkSignedDocuments)

    return () =>
      document.removeEventListener('visibilitychange', checkSignedDocuments)
  }, [checkSignedDocuments])
  return downloadDocuments && downloadDocuments.length > 0 ? (
    <>{getRenderThankYou()}</>
  ) : (
    <Container>
      {documentLoadingData || loading ? (
        <FolderSpinner loadingText="Please wait while we get your documents ready for you!" />
      ) : (
        <Card className="full-page mb-3 text-center align-items-center">
          <>
            <div className="text-center m-3">
              <h1>You are almost done</h1>
            </div>
            <Container className="justify-content-center p-2">
              <p className="mb-4 text-center">
                Click the link below to finish signing your <br />
                documents and complete your loan!
              </p>
              <Button
                data-ref="sign-docs-button justify-content-center"
                onClick={openSignDocuments}
              >
                Review and Sign Documents
              </Button>
            </Container>
            {isSignedDocumentOpen && (
              <div className="my-3">
                <span>Already signed documents?</span>
                <Button
                  className="pl-1 pr-0"
                  variant="link"
                  onClick={checkSignedDocuments}
                >
                  Click here
                </Button>
              </div>
            )}
          </>
        </Card>
      )}
    </Container>
  )

  /**
   * Open the sign documents in a new tab
   */
  function openSignDocuments() {
    const url = getConfigValue('APP_URL')
    if (documentsData) {
      setIsSignedDocumentOpen(true)
      const urlRedirect = `${documentsData.eSignUrl}&returnUrl=${url}${Routes.FINISH_SIGNATURE}`
      window.open(urlRedirect, '_blank')
    }
  }

  /**
   * A timeout function used for introducing a delay.
   * @param milliseconds The number of milliseconds to set a timeout/delay for.
   */
  async function timeout(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * Attempts to fund the loan & log custom events
   * @param customerId The Id of the Customer
   * @param loanId The Id of the Loan
   * @param currentLoanIsRefi Whether or not the Loan is reactivation
   */
  async function fundLoan(
    customerId: string | undefined,
    loanId: number | undefined,
    currentLoanIsRefi: boolean | undefined,
  ) {
    if (currentLoanIsRefi === false) {
      if (customerId && loanId) {
        await postFundLoan(loanId, customerId)
      } else {
        pushToast({
          title: 'Could not Fund Loan',
          message:
            'Please reach out to us for help on getting your loan funded.',
          variant: 'danger',
        })
        appInsightsContext.trackEvent(
          {name: AnalyticsEventNames.REACTIVATION_FUNDLOAN_NOT_CALLED},
          {
            customerId,
            loanId,
            currentLoanIsRefi,
          },
        )
      }
    } else {
      appInsightsContext.trackEvent(
        {
          name:
            AnalyticsEventNames.REACTIVATION_SIGNED_DOCS_NOT_ELIGIBLE_FOR_FUNDLOAN,
        },
        {
          customerId,
          loanId,
          currentLoanIsRefi,
        },
      )
    }
  }

  /**
   * CheckSignedDocumentsCallback
   */
  async function checkSignedDocumentsCallback() {
    try {
      if (!downloadDocuments || downloadDocuments.length === 0) {
        setLoading(true)
        await timeout(2000)
        const loanId = currentLoan?.loanId as number
        const customerId = customer?.personId
        const currentLoanIsRefi = currentLoan?.isRefinance
        const signingStatus = await getSigningStatus(loanId)
        if (signingStatus.signingStatus === SigningStatus.Completed) {
          if (
            currentLoan?.operatingStatus !== 'Open' &&
            currentLoan?.operatingStatus !== 'Default'
          ) {
            await fundLoan(customerId, loanId, currentLoanIsRefi)
          }
          const documents = await getDownloadDocuments(
            Number(customer?.personId),
            Number(currentLoan?.loanId),
          )
          if (documents == null || documents.length === 0) {
            pushToast({
              title: 'Could not retrieve signed documents',
              message:
                'Please try again, and reach out to us if the error persists.',
              variant: 'danger',
            })
          }
          setDownloadDocuments(documents)
        } else {
          pushToast({
            title: 'Documents have not been signed',
            message:
              'Please try again, and reach out to us if the error persists.',
            variant: 'danger',
          })
        }

        setLoading(false)
        changeVisible(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  /**
   * Thank You Page
   * @return JSX.Element
   */
  function getRenderThankYou() {
    return (
      <Container>
        <Card className="my-3 text-center align-items-center">
          <h1>Thank You!</h1>

          <Card.Body className={styles.card}>
            <Container
              fluid
              className={`${styles.cardContainer} card-container container-fluid border px-3 rounded`}
              data-ref="confirm-table"
            >
              <h3 className="mt-5">Download Documents</h3>
              <div className={`my-3 ${styles.documentDownload}`}>
                {downloadDocuments?.map(signedLoan => {
                  const openSignedDocument = () => {
                    const externalSignedLoanAgreement = window.open(
                      signedLoan.documentUrl,
                      '_blank',
                    )
                    if (externalSignedLoanAgreement) {
                      externalSignedLoanAgreement.focus()
                    }
                  }
                  return (
                    <Button
                      className="text-primary p-0"
                      data-ref="document-download"
                      variant="link"
                      onClick={openSignedDocument}
                    >
                      {signedLoan.documentName}
                    </Button>
                  )
                })}
              </div>
            </Container>
          </Card.Body>
          <Card.Footer>
            <Container className={styles.thankYouButton}>
              <Button data-ref="homeButton" variant="primary" onClick={goHome}>
                <Content type={ContentSlot.REFINANCETHANKYOU_BUTTON} />
              </Button>
            </Container>
            <Container className="card-container my-3 text-center">
              <Content type={ContentSlot.REFINANCE_THANKS_POLICY_DESC} />
            </Container>
          </Card.Footer>
        </Card>
      </Container>
    )
  }
  /**
   * Clears the cache and redirects the user back to the home screen
   */
  function goHomeCallback() {
    changeVisible(true)
    queryClient.clear()
    history.push('/home')
  }
}

/**
 * Document sign
 */
export default SignDocument
