import React, {useCallback, useState} from 'react'
import {Button} from 'react-bootstrap'
import {MdRemoveRedEye} from 'react-icons/md'
import MaskedInput from 'react-text-mask'
import {ssnInputMask} from 'utils/forms/masks'

import styles from './SSNInput.module.scss'

interface Props {
  value: string
  onBlur: Function
  onChange: Function
}

const SSNInput: React.FC<Props> = ({value, onChange}: Props) => {
  /**
   * State, hooks
   */
  const [hidden, setHidden] = useState(true)

  /**
   * Callbacks
   */
  const toggleSsn = useCallback(() => {
    setHidden(!hidden)
  }, [hidden, setHidden])

  return (
    <>
      <MaskedInput
        className="form-control fs-exclude"
        data-ref="input-ssn"
        guide="false"
        id="input-ssn"
        mask={ssnInputMask}
        name="idNumber"
        title="SSN"
        type={hidden ? 'password' : 'tel'}
        value={value}
        onChange={onChange}
      />
      <div className="input-group-append">
        <Button className="btn-medium" onClick={toggleSsn}>
          <MdRemoveRedEye className={styles.icon} />
        </Button>
      </div>
    </>
  )
}

/**
 * Masked input for SSN
 */
export default SSNInput
