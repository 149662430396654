import {vergentService} from 'utils/vergent-service'
import type {
  SearchUserNameOrEmailResponse,
  SendResestEmailResponse,
} from 'models/Auth'
import {StandardError, VergentError} from 'models/Errors'
import {getConfigValue} from 'utils/environment'

interface RequestPasswordRecoverActionArgs {
  userNameOrEmail: string
}

const appUrl = getConfigValue('APP_URL')

/**
 * Request password recover error types
 */
export enum RequestPasswordRecoverErrorTypes {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  UserNameNotFound = 'USERNAME_NOT_FOUND',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
  EmailSendError = 'EMAIL_SENT_ERROR',
}

const errorMessages = {
  default: 'There was an error resetting password.',
  userNameNotFound: 'User name not found.',
  emailSentError: 'There was an error sending the email.',
}

/**
 * Action to search an email or username and if it is valid,
 * send a recover password email
 */
export const requestPasswordRecoverAction = async ({
  userNameOrEmail,
}: RequestPasswordRecoverActionArgs) => {
  let shouldReturnSameError = false
  try {
    if (!appUrl) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordRecoverErrorTypes.InternalServerError,
        trackableMessage: errorMessages.default,
      })
    }

    const {
      data: {resetToken},
    } = await vergentService.get<SearchUserNameOrEmailResponse>(
      `/VergentCustomerPasswordReset/Search/${userNameOrEmail}`,
    )
    const formattedResetToken = resetToken.replace(/"/g, '')

    if (!formattedResetToken) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordRecoverErrorTypes.UserNameNotFound,
        trackableMessage: errorMessages.userNameNotFound,
      })
    }

    const {
      data: {isEmailSentSuccessfully},
    } = await vergentService.post<SendResestEmailResponse>(
      `/VergentCustomerPasswordReset/SendResetEmail/${formattedResetToken}`,
      `"${appUrl}/reset-password"`,
    )

    if (!isEmailSentSuccessfully) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordRecoverErrorTypes.EmailSendError,
        trackableMessage: errorMessages.emailSentError,
      })
    }
  } catch (error) {
    if (shouldReturnSameError) throw error

    const {errorInstance, errorWasHandled} = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: RequestPasswordRecoverErrorTypes.InternalServerError,
        trackableMessage: errorMessages.default,
      })
    }

    throw new StandardError({
      type: RequestPasswordRecoverErrorTypes.VergentServiceError,
      trackableMessage: errorMessages.default,
    })
  }
}
