import React from 'react'

import {Col, Row} from 'react-bootstrap'
import {MdErrorOutline} from 'react-icons/md'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'

const HoursWarning: React.FC = () => (
  <Row className="justify-content-center py-3" data-ref="hours-warning">
    <Col className="justify-content-center d-flex" lg="1" md="3" sm="3">
      <MdErrorOutline color="#efd36d" size="3.5rem" />
    </Col>
    <Col className="text-center" md="7" sm="7">
      <Content type={ContentSlot.CONFIRMPAYMENT_PAYMENTALERT} />
    </Col>
  </Row>
)

/**
 * Exports a component that shows the warning if the store will close within 2 hours
 */
export default HoursWarning
