import React, {useState, useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {useHistory} from 'react-router-dom'
import * as FullStory from '@fullstory/browser'

import {appInsights} from 'AppInsights'
import PageSpinner from 'components/PageSpinner'
import WelcomeMessage from 'components/WelcomeMessage'
import CashStoreCard from 'pages/Home/CashStoreCard'
import InactiveLoanCard from 'pages/Home/InactiveLoanCard'
import LoanSummary from 'pages/Home/LoanSummaryCard'
import PaymentCard from 'pages/Home/PaymentCard'
import RefinanceCard from 'pages/Home/RefinanceCard'
import {
  isDuringStoreHours,
  isFinalPayPeriodBeforeDueDate,
  shouldShowDuplicatePaymentWarningByPaymentToday,
  shouldShowDuplicatePaymentWarningByStoreHours,
} from 'utils/dates'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'
import {useLoanData} from 'utils/hooks/useLoanData'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import NoLoans from 'pages/NoLoans'
import BannerInfo from 'pages/Home/BannerInfo'
import CRNPopup from 'components/Popup/CRNPopup'
import UPTNPopup from 'components/Popup/UPTNPopup'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {
  getUtmParametersFromLocalStorage,
  trackUtmEvent,
  clearUtmFromLocalStorage,
} from 'utils/analytics'
import {clearFlowData} from 'utils/cache'
import {getConfigValue} from 'utils/environment'
import {
  formatPhone,
  getClosingStoreHours,
  getOpeningStoreHours,
  formatCurrency,
  isStoreOpenToday,
} from 'utils/data-formatting'
import {CashStoreLocation} from 'models/CashStoreLocation'
import styles from 'pages/Home/Home.module.scss'
// Import {isDebitCardAssociatedToLoan} from 'utils/loans'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import InactiveCustomerWithoutCard from 'pages/Home/InactiveCustomerWithoutCard'
import DuplicatePaymentPopup from 'components/Popup/DuplicatePaymentPopup'
import OnHoldStatusPopup from 'components/Popup/OnHoldStatusPopup'
import {Routes as Path} from 'models/Routes'
import VergentStoreGoLivePopup from 'components/Popup/VergentStoreGoLivePopup'
import {useAuth} from 'auth'
import SignDocument from './SignDocument'
import {CustomerStatusType} from 'models/Edge'

const Home: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.HOME,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const {user} = useAuth()
  const {
    data,
    customer,
    currentLoan,
    reactivationApprovalDetails,
  } = useLoanData()
  const {status, refiOptions} = useRefinanceData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)
  const [
    vergentStoreGoLivePopupVisibility,
    setvergentStoreGoLivePopupVisibility,
  ] = useState(true)
  const [popupVisibility, setPopupVisibility] = useState(true)
  const [isVisibleThankYou, setIsVisibleThankYou] = useState(true)
  const [duplicatePaymentWarning, setDuplicatePaymentWarning] = useState(false)
  const [identified, setIdentified] = useState(false)
  const utmParameters = getUtmParametersFromLocalStorage()
  const utmKeys = Object.keys(utmParameters)
  const hasUtmParameters = utmKeys.length > 0
  const history = useHistory()
  const {location} = history

  /**
   * Effects
   */
  useEffect(() => {
    // Starting on homepage is indirectly "starting" point for all flows, so clear the cache so no previous activity is considered here
    clearFlowData()
  }, [])

  useEffect(() => {
    // When a user visits the homepage and we can see their account info, we should identify their session in fullstory
    if (customer?.personId && user && !identified) {
      const fullstoryDetails = {
        email: user.email ?? '',
        displayName: user.username ?? '',
        appInsights_str: appInsights.context.getSessionId(),
      }
      if (hasUtmParameters) {
        utmKeys.forEach(k => {
          fullstoryDetails[`${k}_str` as keyof typeof fullstoryDetails] =
            utmParameters[k]
        })
      }
      FullStory.identify(customer.personId, fullstoryDetails)
      setIdentified(true)
    }

    // We call this here in an effect, in case an already-authenticated user clicks a link with a UTM parameter in it, this will "record" it
    // We also wait to "clear" the UTM parameters below until fullstory above has a chance to send that metadata
    if (hasUtmParameters && identified) {
      trackUtmEvent(appInsightsContext, utmParameters)
      clearUtmFromLocalStorage()
    }
  }, [
    customer,
    identified,
    user,
    setIdentified,
    hasUtmParameters,
    utmKeys,
    utmParameters,
    appInsightsContext,
  ])

  useEffect(() => {
    if (store?.phone) {
      localStorage.setItem('userStorePhoneNumber', formatPhone(store.phone))
    }
  }, [store])

  useEffect(() => {
    if (location.pathname.includes(Path.LOGIN)) {
      history.push(Path.HOME)
    }
  }, [history, location])

  useEffect(() => {
    if (!currentLoan || !store) return
    const storeHours = getClosingStoreHours(store)
    if (isStoreOpenToday(store)) {
      const closingTime = parseInt(storeHours)

      const shouldShow = shouldShowDuplicatePaymentWarningByStoreHours(
        currentLoan,
        new Date(),
        store.timezone.codename,
        closingTime,
      )

      const shouldShowByPayments = shouldShowDuplicatePaymentWarningByPaymentToday(
        currentLoan,
        new Date(),
      )

      setDuplicatePaymentWarning(shouldShow || shouldShowByPayments)
    }
  }, [currentLoan, store])

  let nextPayment: number =
    currentLoan === null || currentLoan === undefined
      ? 0
      : currentLoan.nextPaymentAmount

  if (
    currentLoan?.operatingStatus === 'PastDue' &&
    (customer?.customerAddress.state === 'NM' ||
      customer?.customerAddress.state === 'ID') &&
    currentLoan !== undefined
  ) {
    nextPayment =
      currentLoan.amountToBecomeCurrent === undefined
        ? 0
        : currentLoan.amountToBecomeCurrent
  }

  const currentDateAndTime = new Date()
  const todaysDate = new Date(
    currentDateAndTime.getFullYear(),
    currentDateAndTime.getMonth(),
    currentDateAndTime.getDate(),
  )
  const showVergentStoreGoLivePopup =
    data?.vergentStoreGoLiveDate !== null &&
    data?.vergentStoreGoLiveDate !== undefined &&
    todaysDate >= new Date(data.vergentStoreGoLiveDate)

  let popupFlag = ''
  if (currentLoan?.activeLoanFlags?.includes('CRN')) {
    popupFlag = 'CRN'
  } else if (currentLoan?.activeLoanFlags?.includes('UPTN')) {
    popupFlag = 'UPTN'
  }

  let popupRefinanceDetails = null
  if (currentLoan?.refinanceApprovalDetails && customer) {
    popupRefinanceDetails = {
      hasCashbackOption:
        currentLoan.refinanceApprovalDetails.hasCashbackOption,
      hasRefinanceOption:
        currentLoan.refinanceApprovalDetails.hasRefinanceOption,
      loanId: currentLoan.loanId,
      numberOfPeriods: currentLoan.refinanceApprovalDetails.numberOfPeriods,
      personReferenceNumber: customer.personReferenceNumber,
      personId: customer.personId,
    }
  }

  if (status === 'loading') {
    return <PageSpinner />
  }

  if (
    customer?.portalDisabled ||
    (!currentLoan?.refinanceApprovalDetails &&
      !currentLoan?.reactivationApprovalDetails &&
      currentLoan?.todaysPayoffAmount === 0) ||
    (currentLoan?.operatingStatus === 'PaidOff' &&
      customer?.reactivationRefiDisabled) ||
    (currentLoan?.operatingStatus === 'Deleted' &&
      customer?.reactivationRefiDisabled)
  ) {
    // If at any time we notice these criteria aren't passing, we should redirect accordingly
    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.ACCOUNT_ISSUE_REDIRECT},
      {
        customer,
        currentLoan,
      },
    )
    history.push('/account-issue')
    return null
  }

  /**
   * Handles the conditional rendering of the Payment card.
   * This is split out so that it can render in different columns depending
   * on screen size.
   * @return jsx for the payment card
   * @param idSuffix string
   */
  function RenderPaymentCard(idSuffix: string) {
    if (!customer || !currentLoan) return null
    return (
      currentLoan.todaysPayoffAmount && (
        <div className="mb-3">
          <PaymentCard
            actualLoanStatus={currentLoan.actualLoanStatus}
            amountToBecomeCurrent={currentLoan.amountToBecomeCurrent}
            cardInfo={customer.customerCards}
            customerName={`${customer.firstName} ${customer.lastName}`}
            customerStatus={customer.customerStatus}
            idSuffix={idSuffix}
            isFinalPayPeriodBeforeDueDate={isFinalPayPeriodBeforeDueDate(
              currentLoan,
              new Date(),
              store?.timezone?.codename,
            )}
            isLoanRescindable={currentLoan.isRescindable}
            maxPayment={currentLoan.todaysPayoffAmount}
            state={currentLoan.storeState}
            upcomingPayment={nextPayment}
          />
        </div>
      )
    )
  }

  const envFlag = getConfigValue('TOKENIZER_AUTOMATION') === 'true'
  const cfpbPopUpEnabled: boolean =
    getConfigValue('CFPB_POPUP_ENABLED') === 'true'
  const vergentStoreGoLivePopupEnabled =
    getConfigValue('ENABLE_VERGENTSTORE_GOLIVE_POPUP') === 'true'
  const customerIsSet = customer !== undefined

  const noReactivationApprovalDetails =
    reactivationApprovalDetails === undefined ||
    reactivationApprovalDetails === null ||
    Object.keys(reactivationApprovalDetails).length === 0
  const loanHasNoDebitCard = !currentLoan || !customer?.customerCards.length
  const inactiveCustomerScreen: boolean =
    envFlag &&
    customerIsSet &&
    noReactivationApprovalDetails &&
    loanHasNoDebitCard

  if (inactiveCustomerScreen && store !== undefined && store !== null)
    return (
      <Container>
        <InactiveCustomerWithoutCard
          name={customer?.firstName}
          store={store}
        />
      </Container>
    )
  if (!customer || !currentLoan) return <NoLoans />

  const customerInDefault =
    currentLoan.actualLoanStatus === 'Default' &&
    (currentLoan.storeState === 'TX' ||
      currentLoan.storeState === 'WI' ||
      currentLoan.storeState === 'ID')
  let bannerInfoMessage = (
    <Content type={ContentSlot.HOME_BANNER_CUSTOMERINDEFAULT_NO_REFI_OPTION} />
  )

  if (
    currentLoan.refinanceApprovalDetails?.refinanceOptions
    // TODO: related to isFundable
    // && CardInfo?.isFundable
  ) {
    bannerInfoMessage = (
      <Content
        type={ContentSlot.HOME_BANNER_CUSTOMERINDEFAULT_WITH_REFI_OPTION}
      />
    )
  }

  return (
    <>
      {customerInDefault && (
        <BannerInfo showWarning verbiage={bannerInfoMessage} />
      )}

      {currentLoan.actualLoanStatus === 'Delinquent' &&
        (currentLoan.storeState === 'WI' ||
          currentLoan.storeState === 'ID' ||
          currentLoan.storeState === 'TX') &&
        currentLoan.amountToBecomeCurrent && (
          <BannerInfo
            showWarning
            verbiage={`You are currently behind on your payments. To catch up, please pay ${formatCurrency(
              currentLoan.amountToBecomeCurrent,
            )}.`}
          />
        )}
      {/* TODO: related to debit card
          !isDebitCardAssociatedToLoan(currentLoan) &&
        */}
      {store !== undefined &&
        store !== null &&
        getConfigValue('TOKENIZER_AUTOMATION') === 'true' &&
        (isDuringStoreHours(store) ? (
          <BannerInfo
            verbiage={getActiveCustomerStoreOpenTokenizerVerbiage(store)}
          />
        ) : (
          <BannerInfo
            verbiage={getActiveCustomerStoreClosedTokenizerVerbiage(store)}
          />
        ))}
      <Container>
        <WelcomeMessage name={customer.firstName} />
        {!currentLoan.isSigningPending && isVisibleThankYou ? (
          <>
            {vergentStoreGoLivePopupEnabled && showVergentStoreGoLivePopup && (
              <VergentStoreGoLivePopup
                toggleVisibility={setvergentStoreGoLivePopupVisibility}
                visibility={vergentStoreGoLivePopupVisibility}
              />
            )}
            {popupFlag === 'UPTN' && cfpbPopUpEnabled === true && (
              <UPTNPopup
                refinanceDetails={popupRefinanceDetails}
                toggleVisiblity={setPopupVisibility}
                visibility={popupVisibility}
              />
            )}
            {popupFlag === 'CRN' && cfpbPopUpEnabled === true && (
              <CRNPopup
                toggleVisiblity={setPopupVisibility}
                visibility={popupVisibility}
              />
            )}
            {customer.customerStatus === CustomerStatusType.OnHold && (
              <OnHoldStatusPopup
                toggleVisibility={setPopupVisibility}
                visibility={popupVisibility}
              />
            )}
            <DuplicatePaymentPopup
              toggleVisibility={setDuplicatePaymentWarning}
              visibility={duplicatePaymentWarning}
            />
            {currentLoan.todaysPayoffAmount === 0 &&
            reactivationApprovalDetails ? (
              <InactiveLoanCard
                approvalDetails={reactivationApprovalDetails}
                customerDetails={customer}
              />
            ) : (
              <Row>
                <Col>
                  {currentLoan.refinanceApprovalDetails &&
                    (currentLoan.refinanceApprovalDetails.hasCashbackOption ||
                      currentLoan.refinanceApprovalDetails
                        .hasRefinanceOption) &&
                    !currentLoan.isRescindable && (
                      <div className="mb-3">
                        <RefinanceCard
                          cashbackAmount={
                            refiOptions?.cashback.cashbackAmount ?? 0
                          }
                          hasCashbackOption={
                            currentLoan.refinanceApprovalDetails
                              .hasCashbackOption
                          }
                          hasRefinanceOption={
                            currentLoan.refinanceApprovalDetails
                              .hasRefinanceOption
                          }
                          refinanceAmount={
                            refiOptions?.refinance.requiredToPay ?? 0
                          }
                          refinanceDetails={{
                            loanId: currentLoan.loanId,
                            numberOfPeriods:
                              currentLoan.refinanceApprovalDetails
                                .numberOfPeriods,
                            personReferenceNumber:
                              customer.personReferenceNumber,
                            personId: customer.personId,
                          }}
                        />
                      </div>
                    )}
                  {currentLoan.operatingStatus !== 'PaidOff' &&
                    currentLoan.operatingStatus !== 'Deleted' && (
                      <>
                        <div className="mb-3">
                          <LoanSummary
                            autopay={currentLoan.isAutoPaymentEnabled}
                            paymentDate={
                              currentLoan.actualLoanStatus === 'Default' ||
                              (currentLoan.actualLoanStatus === 'Delinquent' &&
                                (currentLoan.storeState === 'ID' ||
                                  currentLoan.storeState === 'WI' ||
                                  currentLoan.storeState === 'TX'))
                                ? currentLoan.nextPaymentScheduledDate
                                : currentLoan.nextPaymentDate
                            }
                            previousPayment={currentLoan.paymentTransactions}
                            upcomingPayment={currentLoan.nextPaymentAmount}
                          />
                        </div>

                        <div className="d-block d-lg-none">
                          {RenderPaymentCard('col1')}
                        </div>
                        {currentLoan.servicingStoreNumber &&
                          !currentLoan.isServicingStoreOnlineStore && (
                            <div className="mb-3">
                              <CashStoreCard
                                storeNumber={currentLoan.servicingStoreNumber}
                              />
                            </div>
                          )}
                      </>
                    )}
                </Col>
                <Col className="d-none d-lg-block">
                  {RenderPaymentCard('col2')}
                </Col>
              </Row>
            )}
          </>
        ) : (
          <SignDocument changeVisible={setIsVisibleThankYou} />
        )}
      </Container>
    </>
  )

  /**
   * Gets the verbiage element for an active customer within open store hours
   * @param referenceStore CashStoreLocation
   * @return JSX.Element
   */
  function getActiveCustomerStoreOpenTokenizerVerbiage(
    referenceStore: CashStoreLocation,
  ): JSX.Element {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const phoneElement: JSX.Element = (
      <a
        aria-label={formatPhone(referenceStore.phone)}
        className={styles.textLight}
        href={`tel:${referenceStore.phone}`}
      >
        {referenceStore.phone && formatPhone(referenceStore.phone)}
      </a>
    )
    return (
      <div data-ref="active-customer-store-open-no-card">
        {`Want money added to your debit card without having to go to our store? Click here to call `}
        {phoneElement}
        {` now!`}
      </div>
    )
  }

  /**
   * Gets the verbiage element for an active customer within the stores closed hours
   * @param referenceStore CashStoreLocation
   * @return JSX.Element
   */
  function getActiveCustomerStoreClosedTokenizerVerbiage(
    referenceStore: CashStoreLocation,
  ): JSX.Element {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const phoneElement: JSX.Element = (
      <a
        aria-label={formatPhone(referenceStore.phone)}
        className={styles.textLight}
        href={`tel:${referenceStore.phone}`}
      >
        {referenceStore.phone && formatPhone(referenceStore.phone)}
      </a>
    )
    const openingStoreHours = getOpeningStoreHours(referenceStore, tomorrow)
    const closingStoreHours = getClosingStoreHours(referenceStore, tomorrow)
    const storeHoursElement: string =
      openingStoreHours && closingStoreHours
        ? ` from ${openingStoreHours}AM-${closingStoreHours}PM!`
        : ` during store hours!`

    return (
      <div data-ref="active-customer-store-closed-no-card">
        {`Want money added to your debit card without having to go to our store? Click here to call `}
        {phoneElement}
        {storeHoursElement}
      </div>
    )
  }
}

/**
 * Home page of the application
 */
export default Home
