import React, {useCallback, useContext, useState} from 'react'

import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {Container, Row, Col, Form as BootstrapForm} from 'react-bootstrap'
import {useMutation} from 'react-query'
import {useHistory} from 'react-router'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import SpinnerButton from 'components/SpinnerButton'
import {ToastContext} from 'components/Toast'
import {AnalyticsEventNames} from 'models/Analytics'
import {
  CashWiseInformationResponse,
  CashWiseInformationRequest,
} from 'models/Edge'
import {validateCashWiseInfo} from 'utils/edge'
import {phoneInputMask} from 'utils/forms/masks'

const FormSchema = Yup.object().shape({
  code: Yup.string().required('Access code is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
      'A valid phone number is required',
    ),
})

const CashwisePromoCodeForm: React.FC = () => {
  /**
   * State, Hooks
   */
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [optInMarketingConfirmed, setOptInMarketingConfirmed] = useState(false)
  const initialFormValues: CashWiseInformationRequest = {
    phone: '',
    code: '',
  }
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()
  const {mutateAsync: validateMutation} = useMutation(validateCashWiseInfo, {
    onSuccess: data => goToWelcomeBackPage(data),
    onError: validationError,
  })

  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()

  /**
   * Callbacks
   */
  const submitFormValues = useCallback(submitFormValuesCallback, [
    initialFormValues,
    optInMarketingConfirmed,
  ])

  return (
    <div>
      <Formik
        initialValues={initialFormValues}
        validationSchema={FormSchema}
        onSubmit={submitFormValues}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
        }: FormikProps<CashWiseInformationRequest>) => (
          <Form>
            <Row>
              <Col>
                <BootstrapForm.Group controlId="code">
                  <BootstrapForm.Label>Access Code</BootstrapForm.Label>
                  <BootstrapForm.Control
                    className="form-control"
                    data-ref="code"
                    name="code"
                    type="text"
                    value={values.code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.code && errors.code && (
                    <BootstrapForm.Text className="text-danger">
                      {errors.code}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <BootstrapForm.Group controlId="phone">
                  <BootstrapForm.Label>Phone Number</BootstrapForm.Label>
                  <MaskedInput
                    className="form-control"
                    data-ref="phone"
                    id="phone"
                    mask={phoneInputMask}
                    name="phone"
                    type="tel"
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phone && errors.phone && (
                    <BootstrapForm.Text className="text-danger">
                      {errors.phone}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <BootstrapForm.Group controlId="cashwise-terms">
                  <Container className="d-flex px-1">
                    <BootstrapForm.Check
                      className="mr-1"
                      data-ref="cashwise-terms"
                      label={
                        <Content
                          type={
                            ContentSlot.CASHWISE_LANDING_PAGE_SECTION_CHECKBOX
                          }
                        />
                      }
                      name="cashwise-terms"
                      type="checkbox"
                      value="Accepted"
                      onChange={toggleCheckbox}
                    />
                  </Container>
                </BootstrapForm.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={5} xs={10}>
                <SpinnerButton
                  block
                  color="primary"
                  data-ref="submit"
                  loading={isSubmitting}
                  size="lg"
                  type="submit"
                >
                  Submit
                </SpinnerButton>
              </Col>
            </Row>
            <FormikErrorFocus
              align="middle"
              duration={500}
              ease="linear"
              focusDelay={200}
            />
          </Form>
        )}
      </Formik>
    </div>
  )

  /**
   * Submit form values
   * @param values form values
   */
  async function submitFormValuesCallback(values: CashWiseInformationRequest) {
    setIsSubmitting(true)

    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.CASHWISE_PROMO_FORM_ATTEMPT},
      {...values},
    )

    await validateMutation(values)

    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.CASHWISE_PROMO_FORM_SUCCESS},
      {...values, optInMarketingConfirmed},
    )

    setIsSubmitting(false)
  }

  /**
   * If valid response, users are navigated to welcome back page
   * @param response CashWiseInformationResponse
   */
  function goToWelcomeBackPage(response: CashWiseInformationResponse) {
    history.push('/cashwise-customer', response)
  }

  /**
   * If incorrect response show toast message
   */
  function validationError() {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.CASHWISE_PROMO_FORM_FAILURE,
    })
    pushToast({
      title: 'Code validation failed',
      message: 'The code and phone number provided are not valid.',
      variant: 'danger',
    })
    setIsSubmitting(false)
  }

  /**
   * Simple toggle function to update state
   */
  function toggleCheckbox() {
    setOptInMarketingConfirmed(!optInMarketingConfirmed)
  }
}

/**
 * Reusable form for Cashwise inputs
 */
export default CashwisePromoCodeForm
