interface Jwt {
  aud: string
  client_id: string
  env: string
  exp: number
  iat: number
  iss: string
  org: string
  scope: string
  sid: string
  sub: string
}

/**
 * Parses a token into an object
 * @param token JWT
 * @return token as an object
 */
export function parseJwt(token: string): Jwt {
  const [, base64Url] = token.split('.')
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

/**
 * Generates a random nonce
 * @param length length of the nonce to generate
 * @return generated nonce
 */
export function generateNonce(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:;_-.()!'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
