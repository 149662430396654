import {useContext} from 'react'

import {useQuery} from 'react-query'
import {queryClient} from 'client'

import {DebitCard} from 'models/Edge'
import {ToastContext} from 'components/Toast'
import {useAuth} from 'auth'
import {getLoanDetails} from 'utils/edge'
import {formatCurrency} from 'utils/data-formatting'

const CARD_INFO_QUERY_KEY = 'card-info'
const PRESERVE_ONE_TIME_CARD_KEY = 'preserve-one-time-card'

/**
 * Retrieves cached loan data or re-fetches
 * @return data and fetching status
 */
export function useLoanData() {
  const {pushToast} = useContext(ToastContext)
  const {user} = useAuth()

  const setCardInfo = (data: DebitCard) =>
    queryClient.setQueryData(CARD_INFO_QUERY_KEY, data)

  const cardInfo = queryClient.getQueryData<DebitCard>(CARD_INFO_QUERY_KEY)

  const setPreserveOneTimeCard = (value: boolean) =>
    queryClient.setQueryData(PRESERVE_ONE_TIME_CARD_KEY, value)

  const preserveOneTimeCard = queryClient.getQueryData<boolean>(
    PRESERVE_ONE_TIME_CARD_KEY,
  )

  const {data, status, isLoading} = useQuery(
    ['loan', user?.personReferenceNumber ?? ''],
    async context =>
      getLoanDetails(
        context.queryKey[0],
        context.queryKey[1],
        user?.username,
        preserveOneTimeCard,
      ),
    {
      onError: error => {
        if (
          !(
            (error as object | undefined)?.hasOwnProperty('silent') ||
            (error as object | undefined)?.constructor?.name ===
              'CancelledError'
          )
        ) {
          pushToast({
            title: 'Unable to load loan data',
            variant: 'danger',
          })
        }
      },
      staleTime: 0,
      cacheTime: 0,
    },
  )

  const customer = data?.customerDetails
  const currentLoan = data?.currentLoanDetails
  const refinanceApprovalDetails = currentLoan?.refinanceApprovalDetails
  const reactivationApprovalDetails = currentLoan?.reactivationApprovalDetails
  const maxPaymentAmount = `${formatCurrency(
    currentLoan?.todaysPayoffAmount ?? 0,
  )}`
  const fullName = `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`

  return {
    data,
    isLoading,
    status,
    customer,
    currentLoan,
    cardInfo,
    fullName,
    refinanceApprovalDetails,
    reactivationApprovalDetails,
    maxPaymentAmount,
    setCardInfo,
    setPreserveOneTimeCard,
    preserveOneTimeCard,
  }
}

/**
 * Retrieves cached loan data or re-fetches
 * @return data and fetching status
 */
export function useLoanDataNOError() {
  const {user} = useAuth()

  const {data, status, isLoading} = useQuery(
    ['loan', user?.personReferenceNumber ?? ''],
    async context => getLoanDetails(context.queryKey[0], context.queryKey[1]),
  )

  const customer = data?.customerDetails
  const currentLoan = data?.currentLoanDetails
  const refinanceApprovalDetails = currentLoan?.refinanceApprovalDetails
  const reactivationApprovalDetails = currentLoan?.reactivationApprovalDetails
  const maxPaymentAmount = `${formatCurrency(
    currentLoan?.todaysPayoffAmount ?? 0,
  )}`
  const fullName = `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`

  return {
    data,
    isLoading,
    status,
    customer,
    currentLoan,
    fullName,
    refinanceApprovalDetails,
    reactivationApprovalDetails,
    maxPaymentAmount,
  }
}
