import React, {ReactElement, SyntheticEvent, KeyboardEvent} from 'react'
import {
  Dropdown as BootstrapDropdown,
  FormCheck,
  SafeAnchorProps,
} from 'react-bootstrap'
import {FaRegCircle, FaRegDotCircle} from 'react-icons/fa'

import styles from 'components/Form/Dropdown/Dropdown.module.scss'
import {BsPrefixProps, ReplaceProps} from 'react-bootstrap/helpers'

/**
 * Props for Dropdown component
 */
export interface Props {
  formValue: string
  name: string
  options: InputOpt[]
  handleBlur: (event: SyntheticEvent<HTMLInputElement>) => void
  handleChange: (event: SyntheticEvent<HTMLInputElement>) => void
  id: string
  isDisbursement?: boolean
}

/**
 * Input options for dropdown including the value and label
 */
export interface InputOpt {
  value: string
  label: string | ReactElement
}

const Dropdown: React.FC<Props> = ({
  name,
  options,
  handleBlur,
  handleChange,
  formValue,
  isDisbursement,
  id,
}) => {
  /**
   * Handles Keydown Event
   * @param e KeyboardEvent
   */
  function handleKeydown(
    e: KeyboardEvent<ReplaceProps<'a', BsPrefixProps<'a'> & SafeAnchorProps>>,
  ) {
    if (e.key === 'Enter') {
      ;((e.target as HTMLAnchorElement)
        .children[0] as HTMLInputElement).click()
      e.preventDefault()
    }
  }

  return (
    <BootstrapDropdown>
      <BootstrapDropdown.Toggle
        as="button"
        className={styles.dropdown}
        data-ref="dropdown"
        drop="left"
        id={id}
        name={name}
        type="button"
      >
        <div className={styles.dropdownText}>
          {renderSelectedText(formValue)}
        </div>
      </BootstrapDropdown.Toggle>

      <BootstrapDropdown.Menu
        className={styles.dropdownMenu}
        data-ref="menu"
        id={id}
      >
        {options.map(opt => (
          <BootstrapDropdown.Item
            key={opt.value}
            className={styles.radioInput}
            onKeyDown={handleKeydown}
          >
            <FormCheck.Input
              data-ref={opt.value}
              id={opt.value}
              name={name}
              type="radio"
              value={opt.value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor={opt.value}>
              {renderSelectedBullet(formValue, opt.value)} {opt.label}
            </FormCheck.Label>
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  )

  /**
   * Render Selected Label Text
   * @param selectedFormValue selected value
   * @return label text for dropdown UI based on user selection
   */
  function renderSelectedText(selectedFormValue: string) {
    const selected = options.find(opt => opt.value === selectedFormValue)

    if (selected) return selected.label

    return (
      <span className="text-muted">
        {isDisbursement
          ? 'Select Disbursement Method'
          : 'Select Payment Method'}
      </span>
    )
  }

  /**
   * Render Selected Bullet in BootstrapDropdown
   * @param selected user selected value
   * @param opt dropdown option value
   * @return ui for dotted circle if selected and method are equal
   */
  function renderSelectedBullet(selected: string, opt: string) {
    return selected === opt ? (
      <FaRegDotCircle className={styles.dropdownSelectIcon} />
    ) : (
      <FaRegCircle className={styles.dropdownSelectIcon} />
    )
  }
}

/**
 * Payment form for loans
 */
export default Dropdown
