import {DeliveryClient, ContentItem} from '@kentico/kontent-delivery'
import {useState, useEffect} from 'react'
import {getConfigValue} from 'utils/environment'

/**
 * Kontico Kontent Client
 */
export const client = new DeliveryClient({
  projectId: getConfigValue('KENTICO_PROJECT_ID'),
  previewApiKey: getConfigValue('KENTICO_PREVIEW_KEY'),
  globalQueryConfig: {
    usePreviewMode: Boolean(getConfigValue('KENTICO_PREVIEW_KEY')),
  },
})

/**
 * Fetch content from Kentico Kontent
 * @return content based on content type
 * @param type string
 * @param depth number
 */
export function useContent<T = ContentItem>(type: string, depth = 2) {
  const [content, setContent] = useState<T>()

  useEffect(() => {
    const deliveryClientSubscription = client
      .item(type)
      .depthParameter(depth)
      .toObservable()
      .subscribe(response => {
        const contentChild = response.item?.content
          ? response.item?.content.value[0]
          : response.item
        setContent(contentChild)
      })

    return () => deliveryClientSubscription.unsubscribe()
  }, [depth, type])

  return content
}
