import React, {useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'

import {ContentSlot} from 'models/Content'
import UhOh from 'components/UhOh'
import {useLoanDataNOError} from 'utils/hooks/useLoanData'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import {AnalyticsPageNames} from 'models/Analytics'

const ReactivationIssue: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.REACTIVATE_ERROR,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const {currentLoan, isLoading: loanDataLoading} = useLoanDataNOError()
  const {data: store, isLoading: storeDataLoading} = useStoreDetails(
    currentLoan?.originatingStore,
  )
  const isLoading = loanDataLoading || storeDataLoading

  if (isLoading) {
    return <></>
  }
  return (
    <UhOh
      logoutOnBack
      back={ContentSlot.REACTIVATION_ISSUE_BACK}
      callUs={ContentSlot.REACTIVATION_ISSUE_CALLUS}
      cta={ContentSlot.REACTIVATION_ISSUE_CTA}
      emailUs={ContentSlot.REACTIVATION_ISSUE_EMAILUS}
      isIneligible={false}
      representative={ContentSlot.REACTIVATION_ISSUE_REPRESENTATIVE}
      storeData={store}
      summary={ContentSlot.REACTIVATION_ISSUE_SUMMARY}
      title={ContentSlot.REACTIVATION_ISSUE_TITLE}
      visitAStore={ContentSlot.REACTIVATION_ISSUE_VISIT}
    />
  )
}

/**
 * Screen that shows when an issue occurs during a reactivate flow
 */
export default ReactivationIssue
