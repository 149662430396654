import React, {useCallback, useMemo} from 'react'

import {Button, Card, OverlayTrigger, Popover} from 'react-bootstrap'
import {useHistory} from 'react-router'

import {setRefinanceOption, setRefinanceDetails} from 'utils/cache'
import {formatCurrency} from 'utils/data-formatting'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {getConfigValue} from 'utils/environment'
import {useLoanData} from 'utils/hooks/useLoanData'

import styles from 'pages/Home/RefinanceCard/RefinanceCard.module.scss'

interface NeededRefinanceDetails {
  loanId: number
  numberOfPeriods: number
  personReferenceNumber: string
  personId: string | undefined
}

interface Props {
  cashbackAmount?: number
  hasCashbackOption: boolean
  hasRefinanceOption: boolean
  refinanceAmount?: number
  refinanceDetails: NeededRefinanceDetails
}

const RefinanceCard: React.FC<Props> = ({
  cashbackAmount,
  hasCashbackOption,
  hasRefinanceOption,
  refinanceAmount,
  refinanceDetails,
}) => {
  const history = useHistory()
  // Const {cardInfo} = useLoanData()
  const {data: loanData} = useLoanData()
  // TODO: related to isFundable
  // Const _hasCashbackOption =
  //   cardInfo?.isFundable === false ? false : hasCashbackOption
  const _hasCashbackOption = hasCashbackOption
  const isCngVariableRateProductLoan =
    loanData?.currentLoanDetails.isCngVariableRateProductLoan ?? false
  /**
   * Memo
   */
  const refinanceText = useMemo(refinanceTextMemo, [
    hasCashbackOption,
    hasRefinanceOption,
  ])

  /**
   * Callbacks
   */
  const navigate = useCallback(navigateCallback, [
    hasCashbackOption,
    hasRefinanceOption,
    history,
  ])

  return (
    <Card className="bg-primary px-3" data-ref="refinance-card">
      {getConfigValue('REFINANCE_TOOLTIP_ENABLED') === 'true' && (
        <OverlayTrigger
          overlay={
            <Popover id="refinance-tooltip-auto">
              <Popover.Content>
                <Content type={ContentSlot.HOME_REFINANCETOOLTIP} />
              </Popover.Content>
            </Popover>
          }
          placement="auto"
        >
          <span className={`family-semi ${styles.refinanceTooltip}`} />
        </OverlayTrigger>
      )}
      <h3 className="text-white pb-3 family-normal">{refinanceText}</h3>
      <Button
        block={false}
        data-ref="refinance-card-button"
        variant="light"
        onClick={navigate}
      >
        <Content type={ContentSlot.HOME_REFINANCEBUTTON} />
      </Button>
    </Card>
  )

  /**
   * Memo definition
   */

  /**
   * Gets the text to display based on refinance options
   * @return jsx
   */
  function refinanceTextMemo() {
    const cashbackFormatted = formatCurrency(cashbackAmount ?? 0)
    const refinanceFormatted = formatCurrency(refinanceAmount ?? 0)

    let refiVerbiage
    if (isCngVariableRateProductLoan) {
      refiVerbiage = 'Refinance today for'
    } else {
      refiVerbiage = 'Refinance today for only'
    }
    // This constant allows us to re-use this message in multiple spots, avoiding typescript issues
    const refiText = (
      <span>
        <strong>
          {refiVerbiage} {refinanceFormatted}
        </strong>
        !
      </span>
    )

    // TODO: related to debitCard
    // if (
    //   _hasCashbackOption ||
    //   hasRefinanceOption &&

    //   (cardInfo === undefined || cardInfo.cardType === null)
    // ) {
    //   return refiText
    //   // eslint-disable-next-line no-dupe-else-if, @typescript-eslint/no-unnecessary-condition
    // }

    if (_hasCashbackOption && hasRefinanceOption) {
      if (
        getConfigValue('DISABLE_CASHBACK_REFI_ON_DEFAULT_LOANS') === 'true' &&
        loanData?.currentLoanDetails?.actualLoanStatus === 'Default'
      ) {
        return (
          <span>
            {refiVerbiage} {refinanceFormatted}
          </span>
        )
      }
      return (
        <span>
          {refiVerbiage} {refinanceFormatted} and receive up to{' '}
          <strong>{cashbackFormatted}</strong>!
        </span>
      )
      // eslint-disable-next-line no-dupe-else-if, @typescript-eslint/no-unnecessary-condition
    } else if (_hasCashbackOption && !hasRefinanceOption) {
      return (
        <span>
          Get <strong>{cashbackFormatted} cash back</strong>
        </span>
      )
      // eslint-disable-next-line no-dupe-else-if, @typescript-eslint/no-unnecessary-condition
    } else if (!_hasCashbackOption && hasRefinanceOption) {
      return refiText
    }

    return ''
  }

  /**
   * Callback definition
   */

  /**
   * Navigates based on refi options
   */
  function navigateCallback() {
    setRefinanceDetails({
      ...refinanceDetails,
    })
    if (_hasCashbackOption && !hasRefinanceOption) {
      setRefinanceOption('cashback')
    } else if (!_hasCashbackOption && hasRefinanceOption) {
      setRefinanceOption('refinance')
    }
    history.push('/confirm-information')
  }
}

/**
 * Refinance Card
 */
export default RefinanceCard
