import React from 'react'

import {Button, Card, Container} from 'react-bootstrap'
import {CashStoreLocation} from 'models/CashStoreLocation'
import dayjs from 'dayjs'
import {
  isStoreOpenToday,
  getOpeningStoreHours,
  getClosingStoreHours,
  formatPhone,
} from 'utils/data-formatting'
import {getTimezoneFromAbbrev} from 'utils/dates'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'

interface InactiveCustomerWithoutCardProps {
  store: CashStoreLocation
  name?: string
}

const InactiveCustomerWithoutCard: React.FC<InactiveCustomerWithoutCardProps> = ({
  store,
  name,
}) => {
  /**
   * State, Hooks
   */
  const tz = getTimezoneFromAbbrev(store.timezone.codename)
  const storeOpen = getOpeningStoreHours(store)
  const storeClose = getClosingStoreHours(store)

  const closingDate = dayjs()
    .tz(tz)
    .hour((storeClose ? parseInt(storeClose) : 0) + 12)
    .minute(0)
    .second(0)
  const openingDate = dayjs()
    .tz(tz)
    .hour(storeOpen ? parseInt(storeOpen) : 0)
    .minute(0)
    .second(0)
  const todayDate = dayjs(new Date()).tz(tz)
  const storeCurrentlyOpen =
    isStoreOpenToday(store) &&
    todayDate.isAfter(openingDate) &&
    todayDate.isBefore(closingDate)

  /**
   * Callbacks
   */

  return (
    <>
      <div className="my-2 mx-2 mx-lg-0">
        <h2 className="my-0">Welcome Back, {name}!</h2>
      </div>
      <Container fluid className="my-1 my-sm-2">
        <Card data-ref="inactive-customer-without-card">
          <Card.Body className="text-center">
            {storeCurrentlyOpen ? (
              <Content
                type={ContentSlot.ADD_A_CARD_INACTIVE_CUSTOMER_WITHOUT_CARD}
              />
            ) : (
              /* TODO: NOTE: SHOWING HOURS HERE IS NOT WORKING ON SUNDAYS. */
              <p>
                It&#39;s been a while, so we need to verify some information
                first. Please give us a call at {formatPhone(store.phone)} from{' '}
                {storeOpen}AM-
                {storeClose}PM so you can get the money you want as soon as you
                need!
                <br />
                <br />
                Or click the button below to leave a voicemail so we can get
                back to you as soon as possible
              </p>
            )}
            <div className="text-center py-1">
              <Button href={`tel:${store.phone}`} variant="primary">
                Call {formatPhone(store.phone)}{' '}
                {storeCurrentlyOpen ? <>Now</> : <></>}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

/**
 * Inactive Customer Without Card Component
 * @return renders component for an inactive customer without card
 */
export default InactiveCustomerWithoutCard
