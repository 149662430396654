import React, {useCallback, useMemo, useEffect, useState} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import dayjs from 'dayjs'
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form as BootstrapForm,
} from 'react-bootstrap'
import {GoChevronLeft, GoChevronRight} from 'react-icons/go'
import {useHistory} from 'react-router-dom'

import Content from 'components/Content'
import PageSpinner from 'components/PageSpinner'
import TwoColumnTable from 'components/TwoColumnTable'
import {ContentSlot} from 'models/Content'
import {formatCurrency} from 'utils/data-formatting'
import {useLoanData} from 'utils/hooks/useLoanData'
import {getRefinanceData, getReactivationData} from 'utils/cache'
import NumberStepper from 'components/NumberStepper'
import {
  RefinanceSteps,
  ReactivationSteps,
} from 'components/NumberStepper/Config'
import FormattedDate from 'components/FormattedDate'
import {AnalyticsPageNames} from 'models/Analytics'
import {getConfirmOriginationPageViewEventName} from 'utils/analytics'
import styles from 'pages/ConfirmOrigination/ConfirmOrigination.module.scss'

enum FlowTypes {
  REACTIVATION = 'Reactivation',
  CASHBACK = 'Cash Back',
  REFINANCE = 'Refinance',
}

const nextPaymentLabel = 'First Payment Due Date'
const today = new Date()

const ConfirmOrigination: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CONFIRM_ORIGINATION,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const history = useHistory()
  const {
    currentLoan,
    fullName,
    cardInfo,
    status,
    refinanceApprovalDetails,
    reactivationApprovalDetails,
  } = useLoanData()
  const [fundingCardConfirmed, setFundingCardConfirmed] = useState(false)
  const [error, setError] = useState(false)

  const isCngVariableRateProductLoan =
    currentLoan?.isCngVariableRateProductLoan ?? false

  /**
   * Callbacks, mutations
   */
  const goForward = useCallback(goForwardCallback, [
    history,
    fundingCardConfirmed,
  ])
  const goBack = useCallback(goBackCallback, [history])
  const getReactivationDetails = useCallback(getReactivationDetailsCallback, [
    reactivationApprovalDetails,
  ])
  const getCashbackDetails = useCallback(getCashbackDetailsCallback, [
    refinanceApprovalDetails,
  ])
  const getRefinanceDetails = useCallback(getRefinanceDetailsCallback, [
    refinanceApprovalDetails,
  ])

  /**
   * Memos
   */
  const refi = useMemo(getRefinanceData, [])
  const refiOption = refi?.option
  const reactivation = useMemo(getReactivationData, [])

  useEffect(() => {
    let pageViewName = ''
    if (reactivation) {
      pageViewName = getConfirmOriginationPageViewEventName('reactivation')
    } else if (refiOption === 'cashback') {
      pageViewName = getConfirmOriginationPageViewEventName('cashback')
    } else {
      pageViewName = getConfirmOriginationPageViewEventName('refinance')
    }

    if (pageViewName !== '') {
      appInsightsContext.trackEvent({
        name: pageViewName,
      })
    }
  }, [appInsightsContext, reactivation, refi, refiOption])

  const flow = useMemo(() => {
    if (reactivation) return FlowTypes.REACTIVATION
    if (refi?.option === 'cashback') return FlowTypes.CASHBACK
    return FlowTypes.REFINANCE
  }, [reactivation, refi])

  const confirmRows = useMemo(() => {
    switch (flow) {
      case FlowTypes.REACTIVATION:
        return getReactivationDetails()
      case FlowTypes.CASHBACK:
        return getCashbackDetails()
      case FlowTypes.REFINANCE:
      default:
        return getRefinanceDetails()
    }
  }, [flow, getCashbackDetails, getReactivationDetails, getRefinanceDetails])

  /**
   * Effects
   */
  useEffect(redirectIfNotEligible, [history, refinanceApprovalDetails])
  return (
    <>
      {status === 'loading' && <PageSpinner />}
      <Container>
        <NumberStepper
          activeStep={2}
          steps={refi ? RefinanceSteps : ReactivationSteps}
        />
        <Card className="my-2">
          <Card.Header className="text-center">
            {reactivation ? (
              <h1>
                <Content type={ContentSlot.ORIGINATIONCONFIRM_TITLE} />
              </h1>
            ) : (
              <h1>
                {flow} <Content type={ContentSlot.ORIGINATIONCONFIRM_TITLE} />
              </h1>
            )}
            <h5>
              <Content type={ContentSlot.ORIGINATIONCONFIRM_DESCRIPTION} />
            </h5>{' '}
          </Card.Header>
          <Card.Body>
            <Container
              fluid
              className={`card-container container-fluid border px-3 rounded ${styles.cardContainer}`}
              data-ref="confirm-table"
            >
              <TwoColumnTable
                list={confirmRows}
                title={
                  <Content
                    type={ContentSlot.ORIGINATIONCONFIRM_DETAILSTITLE}
                  />
                }
              />
              {/* Only show this funding acknowledgement on cashback/reactivation, funding doesn't happen in standard refinance */}
              <div>
                {reactivation ? (
                  <div className={styles.termsContainer}>
                    <span className={styles.termsTitle}>Please Read:</span>
                    <span className={styles.termsText}>
                      By clicking &quot;Next&quot; you, {fullName}, authorize
                      Cash Store to:
                    </span>
                    <span className={styles.termsItem}>
                      {`Deposit your funds to the ${reactivation.disbursementCard.cardType}
                        ending in ${reactivation.disbursementCard.cardTokenLast4}.
                       `}
                    </span>

                    <span className={styles.termsItem}>
                      {`Use card ending in ${reactivation.disbursementCard.cardTokenLast4} for your
                        automatic payments associated with your account.`}
                    </span>
                    <span className={styles.termsItem}>
                      Obtain credit reports and other information about you
                      from consumer reporting agencies.
                    </span>
                  </div>
                ) : (
                  renderInformationText()
                )}
              </div>

              <div className="form-group">
                <BootstrapForm className={error ? 'text-danger' : ''}>
                  <BootstrapForm.Check
                    data-ref="confirm-funding-checkbox"
                    id="funds"
                    label="Yes, I understand and agree."
                    name="confirm-funding-checkbox"
                    type="checkbox"
                    value="Accepted"
                    onChange={toggleCheckbox}
                  />
                </BootstrapForm>
              </div>
            </Container>
          </Card.Body>
          <Card.Footer>
            <Container>
              <Row className="justify-content-between">
                <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                  <Button
                    className="pl-0 text-dark"
                    data-ref="back"
                    variant="link"
                    onClick={goBack}
                  >
                    <GoChevronLeft />{' '}
                    <Content
                      type={ContentSlot.ORIGINATIONCONFIRM_BACKBUTTON}
                    />
                  </Button>
                </Col>
                <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                  <Button data-ref="next" type="button" onClick={goForward}>
                    {reactivation ? (
                      <Content type={ContentSlot.CASHBACK_NEXTBUTTON} />
                    ) : (
                      renderRefiSubmitButtonContent()
                    )}
                    <GoChevronRight />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      </Container>
    </>
  )

  /**
   * Redirect to home if user is not eligilble or tries to enter page without entering cashback or refinance flow
   */
  function redirectIfNotEligible() {
    // Eligible for Reactivation
    if (currentLoan?.todaysPayoffAmount === 0 && reactivation) return
    // Not Eligible for Refinance
    if (!refi || !refiOption) {
      history.push('/home')
    }
  }

  /**
   * Navigates to next screen
   */
  function goForwardCallback() {
    // We allow user to move forward if they've acknowledged where funding will be, or if they are in standard refinance flow (which does not require funding acknowledgement)
    if (fundingCardConfirmed === true) {
      history.push('/sign-documents')
    } else {
      setError(true)
    }
  }

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Generates content to be shown in confirmation table for Reactivation Flow
   * @return array of ListItems
   */
  function getReactivationDetailsCallback() {
    if (!currentLoan || !cardInfo || !reactivation) return []

    return [
      {
        label: 'Amount Financed',
        value: formatCurrency(reactivation.loanAmount),
      },
      {
        label: 'How You Will Receive Your Cash',
        value: `${cardInfo.cardType.toLocaleUpperCase()} ending in ${
          cardInfo.collateralCreditCardTokenLast4
        }`,
      },
      {
        label: nextPaymentLabel,
        value: formatNextPaymentDate(reactivation.firstPaymentDate),
      },
    ]
  }

  /**
   * Generates content to be shown in confirmation table for Reactivation Flow
   * @return array of ListItems
   */
  function getCashbackDetailsCallback() {
    if (
      !currentLoan ||
      !cardInfo ||
      !refi ||
      !refi.cashback ||
      !refinanceApprovalDetails
    ) {
      return []
    }

    const cashbackDetails = [
      {
        label: 'Amount You Will Receive',
        value: formatCurrency(refi.cashback.cashbackAmount),
      },
      {
        label: 'How You Will Receive Your Cash',
        value: `${cardInfo.cardType.toLocaleUpperCase()} ending in ${
          cardInfo.collateralCreditCardTokenLast4
        }`,
      },
      {
        label: 'New Amount Financed',
        value: `${formatCurrency(
          currentLoan.currentBalance +
            refi.cashback.cashbackAmount -
            refinanceApprovalDetails.refinanceOptions[1].requiredToPay,
        )}`,
      },
      {
        label: nextPaymentLabel,
        value: formatNextPaymentDate(refi.refinance.firstPaymentDate),
      },
    ]

    if (!isCngVariableRateProductLoan) {
      cashbackDetails.push({
        label: "Today's Payment Amount",
        value: formatCurrency(refi.refinance.paymentAmount),
      })
      cashbackDetails.push({
        label: 'Payment Method',
        value: `${refi.refinance.cardType} ending in ${refi.refinance.cardTokenLast4}`,
      })
    }

    cashbackDetails.push({
      label: refi.refinance.autoPaymentCard ? 'Auto Payment Method' : '',
      value: refi.refinance.autoPaymentCard
        ? `${refi.refinance.autoPaymentCard.cardType} ending in ${refi.refinance.autoPaymentCard.cardTokenLast4}`
        : '',
    })

    return cashbackDetails
  }

  /**
   * Generates content to be shown in confirmation table for Reactivation Flow
   * @return array of ListItems
   */
  function getRefinanceDetailsCallback() {
    if (
      !currentLoan ||
      !refi ||
      !refi.refinance ||
      !refinanceApprovalDetails
    ) {
      return []
    }

    const refinanceDetails = [
      {
        label: 'New Amount Financed',
        value: `${formatCurrency(
          currentLoan.currentBalance -
            refinanceApprovalDetails.refinanceOptions[0].requiredToPay,
        )}`,
      },
      {
        label: nextPaymentLabel,
        value: formatNextPaymentDate(refi.refinance.firstPaymentDate),
      },
    ]

    if (!isCngVariableRateProductLoan) {
      refinanceDetails.push({
        label: "Today's Payment Amount",
        value: formatCurrency(refi.refinance.paymentAmount),
      })
      refinanceDetails.push({
        label: 'Payment Method',
        value: `${refi.refinance.cardType} ending in ${refi.refinance.cardTokenLast4}`,
      })
    }

    return refinanceDetails
  }

  /**
   * Formats a next payment date Date in to a human readable string
   * @param nextPaymentDate Date
   * @return string
   */
  function formatNextPaymentDate(nextPaymentDate: string): string {
    const dayPart = dayjs(nextPaymentDate).format('dddd')
    const datePart = dayjs(nextPaymentDate).format('MM/DD/YYYY')
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${dayPart} ${datePart}`
  }

  /**
   * Simple toggle function to update state
   */
  function toggleCheckbox() {
    setFundingCardConfirmed(!fundingCardConfirmed)
  }

  /**
   * Simple toggle function to update state
   * @return JSX
   */
  function renderInformationText() {
    if (refi) {
      return refi.option === 'cashback' ? (
        <div className={styles.termsContainer}>
          <span className={styles.termsTitle}>Please Read:</span>
          <span className={styles.termsText}>
            {isCngVariableRateProductLoan
              ? `By clicking "Submit" you, ${refi.refinance.customerName} , authorize Cash Store to:`
              : `By clicking "Submit Payment" you, ${refi.refinance.customerName} , authorize Cash Store to:`}
          </span>
          <span className={styles.termsItem}>
            {`Deposit your funds to the ${
              refi.refinance.cardType
            } card ending in ${
              cardInfo?.collateralCreditCardTokenLast4 ??
              refi.refinance.cardTokenLast4
            }.`}
          </span>
          <span className={styles.termsItem}>
            {`Use card ending in ${
              cardInfo?.collateralCreditCardTokenLast4 ??
              refi.refinance.cardTokenLast4
            } for your automatic payments associated with your account.`}
          </span>
          {!isCngVariableRateProductLoan && (
            <span className={styles.termsItem}>
              {`Debit your card ending in ${
                refi.refinance.cardTokenLast4
              } for the one-time payment of ${formatCurrency(
                refi.refinance.paymentAmount,
              )} on `}
              <FormattedDate dueDate={today} />.
            </span>
          )}
          <span className={styles.termsItem}>
            Obtain credit reports and other information about you from consumer
            reporting agencies.
          </span>
        </div>
      ) : (
        <div className={styles.termsContainer}>
          <span className={styles.termsTitle}>Please Read:</span>
          <span className={styles.termsText}>
            {isCngVariableRateProductLoan
              ? `By clicking "Submit" you, ${refi.refinance.customerName} , authorize Cash Store to:`
              : `By clicking "Submit Payment" you, ${refi.refinance.customerName} , authorize Cash Store to:`}
          </span>
          <span className={styles.termsItem}>
            {refi.refinance.autoPaymentCardId && refi.refinance.autoPaymentCard
              ? `Use card ending in ${refi.refinance.autoPaymentCard.cardTokenLast4} for your
            automatic payments associated with your account.`
              : `Use card ending in ${refi.refinance.cardTokenLast4} for your
            automatic payments associated with your account.`}
          </span>

          {!isCngVariableRateProductLoan && (
            <span className={styles.termsItem}>
              {`Debit your card ending in ${refi.refinance.cardTokenLast4}
         for the one-time payment of
        ${formatCurrency(refi.refinance.paymentAmount)} on `}
              <FormattedDate dueDate={today} />.
            </span>
          )}
          <span className={styles.termsItem}>
            Obtain credit reports and other information about you from consumer
            reporting agencies.
          </span>
        </div>
      )
    }
    return null
  }

  /**
   * Returns the content of the submit button for a refinance
   * @return string or JSX
   */
  function renderRefiSubmitButtonContent() {
    if (isCngVariableRateProductLoan) {
      return 'Submit'
    }
    return <Content type={ContentSlot.ORIGINATIONCONFIRM_NEXTBUTTON} />
  }
}
/**
 * Screen that confirms payment info gathered from previous screen to make request
 */
export default ConfirmOrigination
