import React, {useState, useContext, useCallback} from 'react'
import {ToastContext} from 'components/Toast'
import Modal from 'components/Modal'
import {StatusAction} from 'models/Edge'
import {useMutation} from 'react-query'
import {deletePaymentMethod, getProfile} from 'utils/edge'
import {useAuth} from 'auth'
import {useLoanData} from 'utils/hooks/useLoanData'

interface DeletePaymentMethodModalProps {
  open: boolean
  paymentMethodId: number
  closeModal: () => void
  testRender?: boolean
}

const DeletePaymentMethodModal = ({
  open,
  closeModal,
  paymentMethodId,
  testRender,
}: DeletePaymentMethodModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {pushToast} = useContext(ToastContext)
  const {user} = useAuth()
  const {customer} = useLoanData()

  const {mutate: deletePaymentMethodMutation} = useMutation(
    deletePaymentMethod,
    {
      onSuccess: async () => {
        if (customer?.personReferenceNumber && user?.username) {
          await getProfile(customer.personReferenceNumber, user.username)
        }
        pushToast({
          title: 'Card deleted',
          variant: 'success',
          message: 'The card was deleted successfully',
        })
        setIsSubmitting(false)
        closeModal()
      },
      onError: () => {
        pushToast({
          title: 'Unable to delete card',
          variant: 'danger',
        })
        setIsSubmitting(false)
        closeModal()
      },
    },
  )

  const onOk = useCallback(() => {
    setIsSubmitting(true)
    deletePaymentMethodMutation({
      customerId: Number(user?.personReferenceNumber),
      paymentMethodId,
    })
  }, [deletePaymentMethodMutation, paymentMethodId, user])

  const onClose = useCallback(() => {
    if (!isSubmitting) {
      closeModal()
    }
  }, [closeModal, isSubmitting])

  return (
    <Modal
      cancelButtonDisabled={isSubmitting}
      message="Do you really want to delete this payment method?"
      okButtonDisabled={isSubmitting}
      okButtonLoading={isSubmitting}
      okButtonText="Yes, Delete"
      okButtonVariant="danger"
      open={open}
      returnJustModalContent={testRender}
      size="lg"
      title="Are you sure?"
      type={StatusAction.DELETE}
      onCancel={onClose}
      onClose={onClose}
      onOk={onOk}
    />
  )
}

/**
 * Modal to confirm if the user really wants to delete a payment method
 */
export default DeletePaymentMethodModal
