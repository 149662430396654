import React from 'react'

import {Card, Col, Container, Row} from 'react-bootstrap'
import {BsCircleFill} from 'react-icons/bs'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {formatCurrency} from 'utils/data-formatting'
import FormattedDate from 'components/FormattedDate'
import {Transaction} from 'models/Edge'

interface LoanSummaryProps {
  autopay: boolean
  upcomingPayment: number
  paymentDate: Date
  previousPayment: Transaction[]
}

const LoanSummary: React.FC<LoanSummaryProps> = ({
  autopay,
  upcomingPayment,
  paymentDate,
  previousPayment,
}: LoanSummaryProps) => {
  const lastPayment = previousPayment.sort(
    (a, b) =>
      new Date(a.postingDate).getTime() - new Date(b.postingDate).getTime(),
  )[previousPayment.length - 1]

  const regexpVoidedPayment = /V:\d{2}\/\d{2}\/\d{4}$/
  let isVoidedPayment = false
  if (previousPayment.length > 0) {
    isVoidedPayment = regexpVoidedPayment.test(lastPayment.paymentType)
  }

  return (
    <Card bg="dark" className="px-2" data-ref="loanSummary" text="white">
      <Container fluid>
        <Row noGutters className="align-items-start">
          <Col lg="7" xs="12">
            <h3>
              <Content type={ContentSlot.LOANSUMMARY_YOURLOANSUMMARY} />
            </h3>
          </Col>
          <Col
            className="mb-3 mb-lg-0 d-flex justify-content-lg-end align-items-center"
            lg="5"
            xs="12"
          >
            {autopay && (
              <>
                <BsCircleFill className="text-success mr-1" size={9} />
                <small>
                  <Content type={ContentSlot.LOANSUMMARY_AUTOPAY} />
                </small>
              </>
            )}
          </Col>
        </Row>
        <Row noGutters className="mt-lg-3 mb-1">
          <Col className="mb-3 mb-lg-0" lg="6" xs="12">
            <strong>
              <small className="d-block family-bold">
                <Content type={ContentSlot.LOANSUMMARY_UPCOMINGPAYMENT} />
              </small>
            </strong>
            <strong className="h2">{formatCurrency(upcomingPayment)}</strong>
          </Col>
          <Col lg="6" xs="12">
            <strong>
              <small className="d-block family-bold">
                <Content type={ContentSlot.LOANSUMMARY_SCHEDULE_ON} />
              </small>
            </strong>
            <strong className="h3">
              <FormattedDate dueDate={paymentDate} />
            </strong>
          </Col>
        </Row>
        <Row className="pt-2 pl-2">
          {previousPayment.length > 0 && !isVoidedPayment && (
            <p className="mb-0">
              Previous payment of
              {` `} {formatCurrency(Math.abs(lastPayment.amount))}
              {` `}submitted on
              {` `} <FormattedDate dueDate={lastPayment.effectiveDate} />
            </p>
          )}
        </Row>
      </Container>
    </Card>
  )
}

/**
 * Card with dark background that displays loan summary
 */
export default LoanSummary
