import React from 'react'

import styles from 'components/FolderSpinner/FolderSpinner.module.scss'

interface Props {
  loadingText: string
}

const FolderSpinner: React.FC<Props> = ({loadingText}: Props) => (
  <div className={styles.container}>
    <div className={styles.contentWrapper}>
      <div className={styles.folderBackground}>
        <div className={styles.folder}>
          <div className={`${styles.file} ${styles.fileOne}`} />
          <div className={`${styles.file} ${styles.fileTwo}`} />
          <div className={`${styles.file} ${styles.fileThree}`} />
        </div>
      </div>
      <h3 className="pt-2 family-semi">Processing</h3>
      <p>{loadingText}</p>
    </div>
  </div>
)

/**
 * Folder spinner
 * @return Rendered folder spinner
 */
export default FolderSpinner
