import {CustomerDetails, ReactivationCreateBody} from 'models/Edge'
import {states} from './forms/constants'
import {getFormatedFullDate} from './dates'

/**
 * Utility to build reactivation details with the requested body for reactivation
 * @return reactivation details
 * @param customerDetails customer details
 */
export const buildReactivationDetails = (
  customerDetails: CustomerDetails,
): ReactivationCreateBody => ({
  primaryApplicant: {
    customerId: Number(customerDetails.personId),
    emailAddress: customerDetails.emailAddress,
    firstName: customerDetails.firstName,
    lastName: customerDetails.lastName,
    birthDate: getFormatedFullDate(new Date(customerDetails.birthDate)),
    addresses: [
      {
        customerAddressId: customerDetails.customerAddress.addressId,
        streetAddress: customerDetails.customerAddress.addressLine1,
        streetAddress2: customerDetails.customerAddress.addressLine2,
        city: customerDetails.customerAddress.city,
        stateAbbreviation:
          states.find(
            state => state.name === customerDetails.customerAddress.state,
          )?.abv ?? '',
        zip: customerDetails.customerAddress.postalCode,
      },
    ],
    phoneNumbers: [
      {
        customerPhoneId: customerDetails.customerPhoneNumber.phoneId,
        number: customerDetails.customerPhoneNumber.phoneNumber,
      },
    ],
    bank: {
      customerBankId: customerDetails.customerBankAccounts[0].id,
      name: customerDetails.customerBankAccounts[0].bankName,
      accountNumber: customerDetails.customerBankAccounts[0].accountNumber,
    },
    employment: {
      customerEmployerId: customerDetails.customerEmployers[0].id,
      employerName: customerDetails.customerEmployers[0].employerName,
    },
  },
})
