import {vergentService} from 'utils/vergent-service'
import {VergentError, VergentErrorConstructorArgs} from 'models/Errors'
import {AxiosResponse, AxiosError} from 'axios'
import {LocalStorageKeys} from 'utils/common'
import {logUnknownAsException} from 'AppInsights'
import {ToastMessage} from 'components/Toast/Toast'
import {History} from 'history'
import {getHealthCheckData} from 'utils/edge'

// In some cases the vergent service does not return an error
// but does not return required fields as tokens, so we need to
// validate this required values too.
const POSSIBLE_REQUIRED_KEYS = ['responseToken']

// In some cases the vergent service returns array values as null
// so with these keys we can replace them with empty arrays
const ARRAY_PROPERTY_KEYS = [
  'customerSearchResults',
  'addresses',
  'phones',
  'employers',
  'cards',
  'banks',
]

/**
 *Initial vergen error values
 */
export const INITIAL_VERGENT_ERROR: VergentErrorConstructorArgs = {
  type: null,
  title: null,
  errors: null,
  status: 0,
  instance: null,
  errorType: null,
  errorMessage: null,
  correlationId: null,
  errorInstance: null,
  additionalProp1: null,
  additionalProp2: null,
  additionalProp3: null,
  errorWasHandled: false,
}

interface SetupVergentServiceErrorHandlerArgs {
  logout: () => void
  pushToast: (toast: ToastMessage) => void
  history: History<History.PoorMansUnknown>
}

/**
 * Setup error handler for vergent service
 * @param logout action
 */
export const setupVergentServiceErrorHandler = ({
  logout,
  pushToast,
  history,
}: SetupVergentServiceErrorHandlerArgs) => {
  vergentService.interceptors.response.use(
    (response: AxiosResponse) => {
      const {
        type,
        title,
        errors,
        status,
        instance,
        errorType,
        errorMessage,
        correlationId,
        additionalProp1,
        additionalProp2,
        additionalProp3,
        ...responseData
      } = response.data

      const isLoggedIn = Boolean(
        localStorage.getItem(LocalStorageKeys.AccessToken),
      )

      if (isLoggedIn && status === 401) {
        logout()
        throw new VergentError({
          ...INITIAL_VERGENT_ERROR,
          errorWasHandled: true,
        })
      }

      const hasNoARequiredProperty = POSSIBLE_REQUIRED_KEYS.some(
        possibleRequiredKey =>
          possibleRequiredKey in response.data &&
          !response.data[possibleRequiredKey],
      )

      if (
        response.data.status >= 400 ||
        hasNoARequiredProperty ||
        type ||
        correlationId ||
        errorType ||
        errorMessage ||
        errors?.length > 0
      ) {
        throw new VergentError({
          type,
          title,
          errors,
          status,
          instance,
          errorType,
          errorMessage,
          correlationId,
          additionalProp1,
          additionalProp2,
          additionalProp3,
          errorInstance: null,
          errorWasHandled: false,
        })
      }

      ARRAY_PROPERTY_KEYS.forEach(arrayPropertyKey => {
        if (
          arrayPropertyKey in responseData &&
          responseData[arrayPropertyKey] === null
        ) {
          responseData[arrayPropertyKey] = []
        }
      })

      response.data = responseData

      return response
    },
    (error: AxiosError) => {
      const metadata = {
        response: error.response,
        request: error.request,
        message: error.message,
        config: error.config,
        online: window.navigator.onLine,
      }

      // Always log the exception, and any metadata we could have gathered
      logUnknownAsException(error, metadata)

      if (error.response?.status === 401) {
        logout()
        throw new VergentError({
          ...INITIAL_VERGENT_ERROR,
          errorWasHandled: true,
        })
      }

      if (
        error.response?.status &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        throw new VergentError({
          ...INITIAL_VERGENT_ERROR,
          status: error.response.status,
          errorInstance: error,
        })
      }

      if (!metadata.online) {
        pushToast({
          title: 'Network Error',
          message:
            'Your network connection is unstable. You may be unable to use the portal until the connection improves.',
          variant: 'danger',
        })
        throw new VergentError({
          ...INITIAL_VERGENT_ERROR,
          errorWasHandled: true,
        })
      }

      const healthCheckUrl = 'health-check'

      const isFailedHealthCheck =
        error?.response?.request?.responseURL.split('/').slice(-1)[0] ===
          healthCheckUrl && error.response.status === 500

      if (
        isFailedHealthCheck ||
        (metadata.config.url
          ? metadata.config.url.includes(healthCheckUrl)
          : false)
      ) {
        // Here, the only explanation is that the Edge Service is at fault, since we've already checked the user's connection above with isFailedHealthCheckDueToNetworkConnection
        history.push('/outage')
      } else {
        getHealthCheckData()
          .then(response => {
            if (!response.isHealthy) {
              history.push('/outage')
            }
          })
          .catch(e => {
            logUnknownAsException(e)
            history.push('/outage')
          })
        return
      }
      throw new VergentError({
        ...INITIAL_VERGENT_ERROR,
        errorInstance: error,
        status: Number(error.response?.status),
      })
    },
  )
}
