import React from 'react'

import {Helmet} from 'react-helmet'
import {QueryClientProvider} from 'react-query'
import {BrowserRouter, Route} from 'react-router-dom'
import {ReactQueryDevtools} from 'react-query-devtools'
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js'

import styles from 'App.module.scss'
import {AuthProvider} from 'auth'
import Header from 'components/Header'
import Footer from 'components/Footer'
import GoogleWrapper from 'components/Google/wrapper'
import {ToastWrapper} from 'components/Toast'
import Routes from 'Routes'
import {isIE11} from 'utils/browser-detection'
import {getConfigValue} from 'utils/environment'
import {stashUtmParameters} from 'utils/analytics'
import {reactPlugin} from './AppInsights'
import {Routes as Path} from 'models/Routes'
/* Theme stylesheet */
import 'theme/app.scss'
import {queryClient} from './client'
import FinishSignature from './pages/SignDocuments/FinishSignature'

stashUtmParameters()

const App: React.FC = () => {
  const instrumentationKey = getConfigValue('APPINSIGHTS_INSTRUMENTATION_KEY')
  const trackingId = getConfigValue('GOOGLE_TRACKING_ID')
  const googleImportSource = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
  const validInstrumentationKey =
    instrumentationKey && instrumentationKey.length > 0

  const appBody = () => (
    <BrowserRouter>
      <GoogleWrapper>
        <ToastWrapper>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {/*
                Below, we use <Helmet> to insert the <script> tags into the <head> of the document. Doing it this way (through react) allows us to conditionally measure traffic
                in Google Analytics only if there is a trackingId set in the GOOGLE_TRACKING_ID
              */}
              <Helmet defer={false}>
                <script async src={googleImportSource} />
                <script>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { window.dataLayer.push(arguments); }
                    gtag('js', new Date())
                    if (window.location.href.indexOf("utm") > -1) {
                      // if URL contains UTM parameters, track as pageview so that they are all tracked in GA session
                      gtag('config', '${trackingId}');
                    } else {
                      // if no UTM parameters in referral, do not track as pageview, we do that internally in the application with window.gtag() function
                      gtag('config', '${trackingId}', {
                        send_page_view: false
                      })
                    }
                  `}
                </script>
              </Helmet>
              {/*
                  Below, we use an isIE boolean for including/excluding the 'd-flex flex-column' classes.
                  Since this <div> is the main layout for the entire app (header, body, footer)
                  setting ONLY this outer div to not be display:flex is enough to make the layout stack in IE11.
                */}
              <div
                className={`p-0 fill ${
                  !isIE11 && 'd-md-flex flex-md-column'
                } ${styles.fullHeight} ${styles.print}`}
              >
                <Header />
                <div
                  className={`flex-grow-1 ${
                    !isIE11 && 'd-md-flex flex-md-column'
                  }`}
                >
                  <Routes />
                </div>
                <Footer />
              </div>
            </AuthProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              position="bottom-right"
            />
          </QueryClientProvider>
        </ToastWrapper>
      </GoogleWrapper>
      <Route exact component={FinishSignature} path={Path.FINISH_SIGNATURE} />
    </BrowserRouter>
  )

  return (
    <>
      {validInstrumentationKey ? (
        <AppInsightsContext.Provider value={reactPlugin}>
          {appBody()}
        </AppInsightsContext.Provider>
      ) : (
        appBody()
      )}
    </>
  )
}

/**
 * Main application
 */
export default App
