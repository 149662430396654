import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

/**
 * Scrolls window to top of window
 */
export function useScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [pathname])
}
