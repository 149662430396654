import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {useMutation, useQuery} from 'react-query'
import {Redirect, useHistory} from 'react-router-dom'

import PageSpinner from 'components/PageSpinner'
import {
  getPaymentData,
  setPaymentData,
  PaymentData,
  movePaymentDataIntoRefinanceData,
  getRefinanceData,
  getReactivationData,
} from 'utils/cache'
import {
  addCustomerCard,
  deletePaymentMethod,
  getDebitCardFormUrl,
  getProfile,
} from 'utils/edge'
import {useLoanData} from 'utils/hooks/useLoanData'
import {MdChevronLeft} from 'react-icons/md'
import NumberStepper from 'components/NumberStepper'
import {RefinanceSteps} from 'components/NumberStepper/Config'

import styles from 'pages/AddDebitCard/AddDebitCard.module.scss'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {DebitCardUrl} from 'models/Edge'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {ToastContext} from 'components/Toast'
import {getConfigValue} from 'utils/environment'
import {getAddDebitCardPageViewEventName} from 'utils/analytics'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import {TypeCreditCard} from 'models/PaymentMethods'
import {CreateCreditCardFormValues} from 'models/AccountFormValues'
import {useAuth} from 'auth'
import {useGetCardType} from 'utils/hooks/useGetCustomerCardTypes'
import SpinnerButton from 'components/SpinnerButton'

interface DebitCardData {
  paymentData: {
    card_last_four: string
    card_expiration: string
  }
  paymentResponseData: {
    saved_payment_method: {
      token: string
      is_eligible_for_disbursement: boolean
    }
    name_on_card: string
    payment_type_id: TypeCreditCard
  }
}

interface RouteState {
  from: string
}

const AddDebitCardAndPay: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.ADD_DEBIT_CARD,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Context
   */
  const history = useHistory<RouteState>()
  const {pushToast, clearAllToasts} = useContext(ToastContext)

  /**
   * State, Hooks
   */
  const {user} = useAuth()
  const refi = useMemo(getRefinanceData, [])
  const reactivation = useMemo(getReactivationData, [])
  const [iframeStartLoadTime, setIframeStartLoadTime] = useState(0)
  const [iframeHasLoaded, setIframeHasLoaded] = useState(false)
  const [iframeFinishedLoadingTime, setIframeFinishedLoadingTime] = useState(0)
  const [loadingLimitReached, setLoadingLimitReached] = useState(false)
  const [retryAttempts, setRetryAttempts] = useState(0)
  const [refetchAttempt, setRefetchAttempt] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [showError, setShowError] = useState(false)
  const {
    data: loanDetails,
    customer,
    currentLoan,
    setPreserveOneTimeCard,
  } = useLoanData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)
  const paymentData = getPaymentData() as PaymentData
  const [urlDetails, setUrlDetails] = useState<DebitCardUrl>()
  const [isSuccessfullyAdd, setIsSuccessfullyAdd] = useState(false)
  const [isContinuePayment, setIsContinuePayment] = useState(false)

  /**
   * Callbacks
   */
  const navigate = useCallback(navigateCb, [history, paymentData])
  const goBack = useCallback(goBackCallback, [history])

  /**
   * State, Hooks
   */
  const {refetch} = useQuery(
    [
      'debitCardUrl',
      loanDetails?.currentLoanDetails.originatingStore,
      customer?.personId,
      loanDetails?.customerDetails.customerMobileProfileId,
    ],
    async _context => {
      if (!store?.state) {
        pushToast({
          title: 'No Store State Found',
          variant: 'danger',
        })
        return history.push('/refinance-issue', {
          from: history.location.pathname,
        })
      }

      if (!loanDetails?.customerDetails.personReferenceNumber) {
        pushToast({
          title: 'Invalid User',
          variant: 'danger',
        })
        return history.push('/account-issue', {
          from: history.location.pathname,
        })
      }
      setUrlDetails(
        await getDebitCardFormUrl(
          loanDetails.customerDetails.personReferenceNumber,
          loanDetails.customerDetails.customerMobileProfileId,
          store.state,
          user?.username ?? '',
          0,
        ),
      )
    },
    {
      cacheTime: 0,
    },
  )
  const {
    data: {data: cardTypes = {}} = {},
    isLoading: cardTypesLoading,
  } = useGetCardType()
  const {mutate: addCard, isLoading} = useMutation(addCustomerCard, {
    onSuccess: async data => {
      if (customer?.personReferenceNumber && user?.username) {
        await getProfile(customer.personReferenceNumber, user.username)
      }

      if (!data) return
      setPaymentData({
        ...paymentData,
        cardId: data.data.paymentId,
        cardToken: data.values.cardToken ?? 0,
        cardTokenLast4: data.values.cardTokenLast4 ?? '',
        cardType: data.values.cardType ?? '',
      })
    },
    onError: () => {
      pushToast({
        title: 'Unable to create card',
        variant: 'danger',
      })
    },
  })

  const {mutate: deletePaymentMethodMutation} = useMutation(
    deletePaymentMethod,
    {
      onSuccess: async () => {
        if (customer?.personReferenceNumber && user?.username) {
          await getProfile(customer.personReferenceNumber, user.username)
        }
      },
    },
  )

  /**
   * Memo
   */
  const isRefiFlow = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => (history.location.state?.from === '/home' ? false : true),
    [history],
  )

  /**
   * Effects
   */
  useEffect(handleIframeMessage, [paymentData, cardTypesLoading])
  useEffect(() => {
    if (showError) {
      refetch()
    }
  }, [refetch, showError])
  useEffect(() => {
    let pageViewName = ''
    if (!refi) {
      pageViewName = getAddDebitCardPageViewEventName()
    } else if (refi.option === 'cashback') {
      pageViewName = getAddDebitCardPageViewEventName('cashback')
    } else {
      pageViewName = getAddDebitCardPageViewEventName('refinance')
    }

    appInsightsContext.trackEvent({
      name: pageViewName,
    })
  }, [appInsightsContext, reactivation, refi, cardTypesLoading])

  const iframeLoadingLimit =
    Number(getConfigValue('ADD_DEBIT_IFRAME_LOAD_LIMIT')) * 1000
  // Begin iframe loading and load time
  // Begin loadingLimit timer
  useEffect(() => {
    if (urlDetails?.url) {
      setIframeStartLoadTime(new Date().getTime())
      const timerId = setTimeout(() => {
        setLoadingLimitReached(true)
      }, iframeLoadingLimit)
      setTimer(timerId)
    }
  }, [iframeLoadingLimit, urlDetails, cardTypesLoading])

  // Iframe is done loading and loading time
  useEffect(() => {
    if (iframeHasLoaded) {
      const timeItTookIframeToLoadInMilliseconds = Math.abs(
        iframeFinishedLoadingTime - iframeStartLoadTime,
      )
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.IFRAME_LOADING_DONE},
        {duration: timeItTookIframeToLoadInMilliseconds},
      )
    }
  }, [
    appInsightsContext,
    iframeFinishedLoadingTime,
    iframeHasLoaded,
    iframeStartLoadTime,
    cardTypesLoading,
  ])

  // Iframe is still loading past 60 seconds, show toast message and refetch
  useEffect(() => {
    if (
      loadingLimitReached &&
      retryAttempts < 2 &&
      timer &&
      !iframeHasLoaded
    ) {
      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.IFRAME_LOADING_WARNING,
      })
      pushToast({
        title: 'Oops!',
        message: `We're sorry, this is taking longer than expected. This page will automatically reload.`,
        variant: 'danger',
      })
      setRefetchAttempt(true)
      refetch()
      setRetryAttempts(attempts => attempts + 1)
    }
  }, [
    appInsightsContext,
    iframeHasLoaded,
    loadingLimitReached,
    pushToast,
    refetch,
    retryAttempts,
    timer,
  ])

  useEffect(
    () => () => {
      if (!isContinuePayment && paymentData.cardId) {
        // eslint-disable-next-line no-console
        console.log(isContinuePayment)
        deletePaymentMethodMutation({
          customerId: Number(user?.personReferenceNumber),
          paymentMethodId: paymentData.cardId,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isContinuePayment],
  )

  // If iframe was refetch increment retry attempts
  useEffect(() => {
    if (loadingLimitReached && timer && retryAttempts < 2) {
      clearTimeout(timer)
      setLoadingLimitReached(false)
    }
  }, [loadingLimitReached, refetchAttempt, retryAttempts, timer])

  // Iframe is still loading past 60 seconds and we refetch twice already
  useEffect(() => {
    if (loadingLimitReached && retryAttempts >= 2 && !iframeHasLoaded) {
      pushToast({
        title: 'An error has occurred',
        message: `Please try again later.`,
        variant: 'danger',
      })
      setLoadingLimitReached(false)
    }
  }, [iframeHasLoaded, loadingLimitReached, pushToast, retryAttempts])

  // If user tries to "refresh" or load the page while on /add-card-and-pay, redirect to the home screen
  if (history.location.state === undefined) {
    return <Redirect to="/home" />
  }

  if (!urlDetails?.url && cardTypesLoading) {
    return <PageSpinner />
  }

  const addCardMethod = history.location.hash

  return (
    <Container>
      {urlDetails?.url && !cardTypesLoading && (
        <>
          {isRefiFlow && (
            <NumberStepper activeStep={2} steps={RefinanceSteps} />
          )}
          <Card className={`full-page ${isRefiFlow ? 'mb-3' : 'my-3'}`}>
            <Card.Header style={{textAlign: 'center'}}>
              <div className={`mt-3 ${styles.title}`}>
                <h2>
                  {addCardMethod === '#one-time'
                    ? 'Use card for one-time payment'
                    : 'Add card'}
                </h2>

                {showError && (
                  <p>
                    <span className="text-danger">
                      Error processing card. Please try again.
                    </span>
                  </p>
                )}
              </div>
            </Card.Header>
            <Card.Body className="mt-1">
              <iframe
                className={`border-0 w-100 fs-exclude ${
                  isSuccessfullyAdd
                    ? styles.fullHeightCompleted
                    : styles.fullHeight
                } `}
                data-ref="iframe"
                id="iframe"
                sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
                scrolling="yes"
                src={urlDetails.url}
                title="Add Debit Card"
                onLoad={iframeHasLoadedCallback}
              />
            </Card.Body>
            <Card.Footer>
              {showError && (
                <Button
                  className="pl-0 text-dark"
                  data-ref="back-button"
                  variant="link"
                  onClick={goBack}
                >
                  <MdChevronLeft width=".75em" />
                  <Content type={ContentSlot.REPAY_BACKBUTTON} />
                </Button>
              )}
              {isSuccessfullyAdd && !showError && (
                <Row className="mt-3 justify-content-md-center align-items-center">
                  <Col className="col-12 col-sm-4 p-0 order-2 order-sm-4">
                    <SpinnerButton
                      block
                      color="primary"
                      data-ref="submit"
                      loading={isLoading}
                      size="sm"
                      type="button"
                      onClick={navigate}
                    >
                      Continue
                    </SpinnerButton>
                  </Col>
                </Row>
              )}
            </Card.Footer>
          </Card>
        </>
      )}
    </Container>
  )

  /**
   * Effects
   */

  /**
   * Handles payment response and submits form if valid
   * @return function to unset the event listener
   */
  function handleIframeMessage() {
    const messageEventHandler = (e: MessageEvent) => {
      if (
        !e.data?.paymentData ||
        !e.data?.paymentResponseData?.saved_payment_method?.token
      )
        return

      const {data}: {data: DebitCardData} = e
      if (!data.paymentResponseData.saved_payment_method.token) {
        setShowError(true)
        return
      }

      const year = new Date().getFullYear().toString().slice(0, 2)
      const dateExpiry = data.paymentData.card_expiration
      const yearExpiry = dateExpiry.slice(2, 4)
      const monthExpiry = dateExpiry.slice(0, 2)

      const dateExpiryFormatted = `${monthExpiry}/${year}${yearExpiry}`
      const values: CreateCreditCardFormValues = {
        cardReference: data.paymentResponseData.saved_payment_method.token,
        cardNumber: data.paymentData.card_last_four,
        nameOnCard: data.paymentResponseData.name_on_card,
        cardDateExpiry: dateExpiryFormatted,
        cardType: data.paymentResponseData.payment_type_id,
        isEligibleForDisbursement:
          data.paymentResponseData.saved_payment_method
            .is_eligible_for_disbursement,
        cardToken: parseInt(
          data.paymentResponseData.saved_payment_method.token,
        ),
        cardTokenLast4: data.paymentData.card_last_four,
      }

      if (addCardMethod === '#one-time') {
        setPreserveOneTimeCard(true)
      }

      addCard({
        values,
        cardTypes,
        isOneTimePaymentCard: addCardMethod === '#one-time',
        personReferenceNumber: Number(customer?.personReferenceNumber),
      })

      setShowError(false)

      setIsSuccessfullyAdd(true)
      // Track the successful addition of the debit card
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.ADD_DEBIT_CARD},
        {cardLastFour: data.paymentData.card_last_four},
      )
    }
    window.addEventListener('message', messageEventHandler)
    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }

  /**
   * Callbacks
   */

  /**
   * Navgiates to next page in flow depending on where user came from
   * @return history navigation
   */
  function navigateCb() {
    if (history.location.state === undefined) return
    setIsContinuePayment(true)
    setIsSuccessfullyAdd(false)
    if (isRefiFlow) {
      movePaymentDataIntoRefinanceData()
      return history.push('/confirm-origination')
    }

    return history.push(`/payment-confirm${addCardMethod}`)
  }

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Iframe has loaded callback
   */
  function iframeHasLoadedCallback() {
    setIframeHasLoaded(true)
    clearAllToasts()
    const loadTime = new Date().getTime()
    setIframeFinishedLoadingTime(loadTime)
  }
}

/**
 * Add one time debit card component
 */
export default AddDebitCardAndPay
