import React from 'react'
import {FaPencilAlt} from 'react-icons/fa'
import {RiCheckboxCircleLine} from 'react-icons/ri'
import styles from './ActionCard.module.scss'

interface ActionCardProps {
  title: string
  disabled?: boolean
  actionText: string
  description?: string
  icon?: React.ReactNode
  onActionClick?: () => void
  isActionCompleted?: boolean
  actionButtonTestId?: string
  completedActionText?: string
  actionIcon?: React.ReactNode
  completedActionTestId?: string
  inputType?: string
  inputPlaceHolder?: string
}

const ActionCard = ({
  icon,
  title,
  disabled,
  actionText,
  description,
  onActionClick,
  isActionCompleted,
  actionButtonTestId,
  completedActionTestId,
  actionIcon = <FaPencilAlt />,
  completedActionText = 'Completed',
  inputType,
  inputPlaceHolder,
}: ActionCardProps) => (
  <div className={styles.container}>
    <div className={styles.details}>
      {icon}
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        {description ? (
          <span className={styles.description}>{description}</span>
        ) : null}
        {inputType ? (
          <div className="form-group">
            <input
              className="form-control input-center"
              placeholder={inputPlaceHolder}
              type={inputType}
            />
          </div>
        ) : null}
      </div>
    </div>
    {isActionCompleted ? (
      <span
        className={styles.actionCompleted}
        data-ref={completedActionTestId}
      >
        <RiCheckboxCircleLine />
        {completedActionText}
      </span>
    ) : (
      <button
        className={styles.action}
        data-ref={actionButtonTestId}
        disabled={disabled}
        type="button"
        onClick={onActionClick}
      >
        {actionIcon}
        {actionText}
      </button>
    )}
  </div>
)

/**
 * ActionCard
 */
export default ActionCard
