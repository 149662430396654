import React, {ReactNode, useState} from 'react'

import {StandaloneSearchBox} from '@react-google-maps/api'
import {Form as BootstrapForm} from 'react-bootstrap'

import {GoogleLoader} from '../loader'
import {useOnPlacesChanged} from 'utils/hooks/useOnPlacesChanged'
import {OnChangeData, US_BOUNDS} from 'models/GooglePlaces'

/**
 * GooglePlaces Props
 */
export interface Props {
  controlId: string
  label?: ReactNode
  name: string
  placeholder?: string
  disabled?: boolean
  errors?: string
  value: string
  handleBlur(e: React.FocusEvent): void
  handleChange(e: React.ChangeEvent): void
  onChange(o: OnChangeData): void
}

const GooglePlaces = ({
  controlId,
  disabled,
  label,
  name,
  placeholder,
  value,
  handleBlur,
  handleChange,
  onChange,
  errors,
}: Props) => {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>()

  const onPlacesChanged = useOnPlacesChanged({onChange, searchBox})

  return (
    <GoogleLoader>
      <BootstrapForm.Group controlId={controlId}>
        <BootstrapForm.Label>{label}</BootstrapForm.Label>
        <StandaloneSearchBox
          bounds={US_BOUNDS}
          onLoad={setSearchBox}
          onPlacesChanged={onPlacesChanged}
        >
          <BootstrapForm.Control
            data-ref="google-maps-address"
            disabled={disabled}
            name={name}
            placeholder={placeholder ?? 'Search an Address'}
            type="text"
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </StandaloneSearchBox>
        <BootstrapForm.Text className="text-danger">
          {errors}
        </BootstrapForm.Text>
      </BootstrapForm.Group>
    </GoogleLoader>
  )
}

/**
 * Render Google Places Search.
 * @return React component
 */
export default GooglePlaces
