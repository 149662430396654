import React, {useCallback, useEffect, useState} from 'react'
import {Beforeunload} from 'react-beforeunload'
import {Button, Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'

import {
  createHandleModalExtendClickCallback,
  createHandleModalTimerEffect,
  createHandleModalTimerResetsEffect,
  createOnBeforeunloadCallback,
  createResetModalTimersCallback,
} from './factories'
import Content from '../Content'
import {ContentSlot} from '../../models/Content'

import {useAuth} from 'auth'
import {noop} from 'utils/noop'
import {hasValidStoragedToken} from 'utils/has-valid-storaged-token'

const InactivityModal: React.FC = () => {
  /**
   * State, hooks
   */
  const {isLoggedIn, logout, user} = useAuth()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timer>()
  const [modalTimer, setModalTimer] = useState<NodeJS.Timer>()

  /**
   * Callbacks
   */
  const resetModalTimers = useCallback(
    createResetModalTimersCallback({
      setInactivityTimer,
      setModalTimer,
      setShowModal,
    }),
    [setInactivityTimer, setModalTimer, setShowModal],
  )
  const handleModalExtendClick = useCallback(
    createHandleModalExtendClickCallback({
      resetModalTimers,
      setShowModal,
    }),
    [resetModalTimers, setShowModal],
  )
  const onBeforeunload = useCallback(
    createOnBeforeunloadCallback({
      isLoggedIn,
      logout,
    }),
    [isLoggedIn, logout],
  )
  const onClickLogoutButton = useCallback(() => {
    setInactivityTimer(timer => {
      if (timer) clearTimeout(timer)
      return undefined
    })
    setModalTimer(timer => {
      if (timer) clearTimeout(timer)
      return undefined
    })
    setShowModal(false)
    logout()
  }, [logout])

  /**
   * Effects
   */
  useEffect(
    createHandleModalTimerEffect({
      logout,
      modalTimer,
      setModalTimer,
      setShowModal,
      showModal,
    }),
    [logout, modalTimer, setModalTimer, setShowModal, showModal],
  )
  useEffect(
    createHandleModalTimerResetsEffect({
      inactivityTimer,
      isLoggedIn,
      resetModalTimers,
      setInactivityTimer,
      setModalTimer,
    }),
    [isLoggedIn, location, resetModalTimers],
  )
  useEffect(() => {
    if (
      isLoggedIn &&
      user?.personReferenceNumber &&
      user.personReferenceNumber !== '' &&
      !hasValidStoragedToken()
    ) {
      logout()
    }
  }, [isLoggedIn, logout, user])

  return (
    <Beforeunload onBeforeunload={onBeforeunload}>
      <Modal
        centered
        data-ref="inactivityModal"
        show={showModal}
        onHide={noop}
      >
        <Modal.Header>
          <Modal.Title>
            <Content type={ContentSlot.INACTIVITY_TITLE} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Content type={ContentSlot.INACTIVITY_COPY} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickLogoutButton}>
            <Content type={ContentSlot.INACTIVITY_LOGOUTBUTTONTEXT} />
          </Button>
          <Button onClick={handleModalExtendClick}>
            <Content type={ContentSlot.INACTIVITY_EXTENDBUTTONTEXT} />
          </Button>
        </Modal.Footer>
      </Modal>
    </Beforeunload>
  )
}

/**
 * Inactivity modal
 * @return Rendered inactivity modal
 */
export default InactivityModal
