import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'

/**
 * Duplicate Payment Popup props interface
 */
export interface VergentStoreGoLivePopupProps {
  visibility: boolean
  toggleVisibility(visibility: boolean): void
}

const VergentStoreGoLivePopup: React.FC<VergentStoreGoLivePopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisibility(false), [props])

  const vergentStoreGoLivePopupContent = (
    <>
      <Content type={ContentSlot.VERGENTSTORE_GOLIVE_POPUP} />
      <div data-ref="vergentstore-golive-warning" />
      <Button
        data-ref="continue-vergentstore-golive-popup"
        variant="primary"
        onClick={handleClose}
      >
        <Content type={ContentSlot.VERGENTSTORE_GOLIVE_POPUP_BUTTON} />
      </Button>
    </>
  )
  return (
    <>
      <AbstractPopup
        warning
        content={vergentStoreGoLivePopupContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default VergentStoreGoLivePopup
