import React, {useCallback} from 'react'
import styles from './PayoffPopup.module.scss'
import {Button, Modal} from 'react-bootstrap'
import {formatCurrency} from 'utils/data-formatting'
import {dateOnlyFormattedDate} from 'utils/dates'

interface PayoffPopupProps {
  payoffValue?: number
  payoffDate?: Date
  visility: boolean
  toggleVisiblity(visibility: boolean): void
}

const PayoffPopup: React.FC<PayoffPopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisiblity(false), [props])

  return (
    <Modal
      centered
      backdrop="static"
      keyboard={false}
      show={props.visility}
      size="sm"
      onHide={handleClose}
    >
      <Modal.Body className={`${styles.ModalBody}`}>
        <div className={` ${styles.ModalHeader}`}>
          <h2 className="border-bottom pb-2 border-dark">Payoff Amount</h2>
        </div>

        {props.payoffDate && props.payoffValue && (
          <div className={` ${styles.TextContainer}`}>
            Your payoff amount as of <br />{' '}
            {dateOnlyFormattedDate(props.payoffDate)} is{' '}
            {formatCurrency(props.payoffValue)}
          </div>
        )}
        <div className={` ${styles.ButtonContainer}`}>
          <Button
            className={`${styles.CloseButton}`}
            variant="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

/**
 * ViewPayoffPopup Component for displaying messages.
 */
export default PayoffPopup
