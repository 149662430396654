/**
 * PasswordRule interface
 */
export interface PasswordRule {
  text: string
  passing: boolean
  test: Function
}

const passwordRules: PasswordRule[] = [
  {
    text: '8-20 characters',
    test: (value: string) => /^.{8,20}$/.test(value),
    passing: false,
  },
  {
    text: 'Must include a number',
    test: (value: string) => /\d/.test(value),
    passing: false,
  },
  {
    text: 'Must have at least 1 special character, such as ~!@#$%^&*_',
    test: (value: string) => /[ !@#$%^&*()_+\-=[\]{};:|,.<>/?~]/.test(value),
    passing: false,
  },
  {
    text: 'Must contain upper and lowercase letters',
    test: (value: string) => {
      const lowercase = /[a-z]/
      const uppercase = /[A-Z]/
      return lowercase.test(value) && uppercase.test(value)
    },
    passing: false,
  },
]

/**
 * Get password rules with password/email applied
 * @param password string
 * @return list of rules
 */
export const getPasswordRules = (password: string) =>
  passwordRules.map(rule => ({
    ...rule,
    passing: rule.test(password),
  }))
