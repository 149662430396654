import React, {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Content from 'components/Content'
import styles from 'components/Footer/Footer.module.scss'
import {ContentSlot} from 'models/Content'
import FooterLinks from './FooterLinks'
import Disclaimer from '../Disclaimer'
import {getConfigValue} from 'utils/environment'
import DebugSection from '../DebugSection'
import type {User} from 'models/Auth'
import {useAuth} from 'auth'

const Footer: React.FC = () => {
  /**
   * State, Hooks
   */
  const {isLoggedIn, user} = useAuth()

  /**
   * UseEffect
   */
  useEffect(() => {
    const url = getConfigValue('SECURE_SEAL_URL')
    let script: HTMLScriptElement | boolean = false
    if (url) {
      script = document.createElement('script')
      script.src = url
      script.async = true
      document.body.appendChild(script)
    }

    return () => {
      if (script instanceof HTMLScriptElement) {
        document.body.removeChild(script)
      }
    }
  }, [])

  const isCustomerValid = isLoggedIn && user && validCustomer(user)

  /**
   * Returns true if a customer has a personReferenceNumber attribute and that attribute value is not '-1
   * @param u User from AuthContext
   * @return boolean
   */
  function validCustomer(u: User) {
    return u.personReferenceNumber && u.personReferenceNumber !== ''
  }

  return (
    <footer>
      <Container fluid className={`py-2 ${styles.grid}`}>
        <Row className="align-items-center">
          <Col
            className={`${styles.footerCol} ${styles.footerColLeft}`}
            md="4"
            xs="12"
          >
            <Content type={ContentSlot.FOOTER_COPYRIGHT} />
          </Col>
          <Col
            className={`${styles.footerCol} ${styles.footerColCenter} light`}
            md="4"
            xs="12"
          >
            {isLoggedIn &&
            user?.personReferenceNumber &&
            user.personReferenceNumber !== '' ? (
              <FooterLinks />
            ) : (
              <Content type={ContentSlot.FOOTER_DEFAULTLINKS} />
            )}
          </Col>
          <Col
            className={`${styles.footerCol} ${styles.footerLogos}`}
            md="4"
            xs="12"
          >
            <span id="siteseal" />
            <a
              className="text-dark text-decoration-none text-center font-weight-bold m1 p0"
              href={getConfigValue('HOW_SSL_SECURES_YOU_URL')}
              rel="noopener noreferrer"
              target="_blank"
            >
              How SSL Secures You
            </a>
          </Col>
        </Row>
      </Container>
      {isCustomerValid && (
        <div className={`${styles.footerDisclaimer}`}>
          <Disclaimer />
        </div>
      )}
      {isCustomerValid && getConfigValue('DEBUGGER') === 'true' && (
        <DebugSection />
      )}
    </footer>
  )
}

/**
 * Global Footer
 * @return rendered Footer
 */
export default Footer
