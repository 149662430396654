import React, {useCallback, useEffect, useState, useMemo} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
} from 'react-bootstrap'
import {Redirect, useHistory} from 'react-router-dom'
import {GoChevronLeft, GoChevronRight} from 'react-icons/go'
import * as Yup from 'yup'
import {Form, Formik, FormikProps, useFormikContext} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import MaskedInput from 'react-text-mask'
import {useMutation} from 'react-query'

import {
  getLoanScheduleRequest,
  getReactivationPaymentSchedule,
} from 'utils/edge'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import NumberStepper from 'components/NumberStepper'
import {ReactivationSteps} from 'components/NumberStepper/Config'
import PageSpinner from 'components/PageSpinner'
import LoanSchedule from 'components/LoanSchedule'
import {OfferSelector} from './OfferSelector'
import DebitCardsDropdown from 'components/Form/DebitCardsDropdown'
import {useLoanData} from 'utils/hooks/useLoanData'
import {
  ReactivationFormValues,
  GetLoanScheduleValues,
  PaymentMethodTypes,
  GetReactivationPaymentScheduleArgs,
  ConfirmRefinanceLoanValues,
} from 'models/Edge'
import {formatCurrency, formatCurrencyToFloat} from 'utils/data-formatting'
import {
  getReactivationData,
  setReactivationData,
  ReactivationData,
  getReactivationCreacteData,
  getReactivationOffers,
  setReactivationLoanAmount,
  CardData,
} from 'utils/cache'
import {currencyMask} from 'utils/forms/masks'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {convertDateToCurrentTimezone} from 'utils/convert-date-to-current-timezone'
import {Breakpoints} from 'models/Breakpoints'
import {
  getFormatedFullDate,
  largeScreenFormattedDate,
  mobileScreenFormattedDate,
} from 'utils/dates'
import {useDebounce} from 'utils/hooks/useDebounce'
import {Routes} from 'models/Routes'
import {useAuth} from 'auth'

const Reactivation: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.REACTIVATE,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.REACTIVATION_VISIT,
    })
  }, [appInsightsContext])

  /**
   * Hooks
   */
  const history = useHistory()
  const {
    currentLoan,
    customer,
    setCardInfo,
    reactivationApprovalDetails,
    status: loanStatus,
    isLoading: isLoanDataLoading,
  } = useLoanData()
  const {user} = useAuth()

  const [loanSchedulePayload, setLoanSchedulePayload] = useState<
    GetLoanScheduleValues
  >()

  /**
   * Callbacks, mutations
   */
  const goBack = useCallback(goBackCallback, [history])

  const {
    mutate: requestLoanSchedule,
    isLoading: loanScheduleReactivationLoading,
    isSuccess: loanScheduleReactivationSuccess,
    data: loanScheduleReactivationData,
    error: loanScheduleReactivationError,
  } = useMutation(getReactivationPaymentSchedule)

  const {
    mutate: requestLoanScheduleEstimate,
    isLoading: loanScheduleEstimateLoading,
    isSuccess: loanScheduleEstimateSuccess,
    data: loanScheduleEstimateData,
    error: loanScheduleEstimateError,
  } = useMutation(getLoanScheduleRequest)

  const loanScheduleData =
    loanScheduleReactivationData ?? loanScheduleEstimateData
  const loanScheduleLoading =
    loanScheduleReactivationLoading || loanScheduleEstimateLoading
  const loanScheduleSuccess =
    loanScheduleReactivationSuccess || loanScheduleEstimateSuccess
  const loanScheduleError =
    loanScheduleReactivationError ?? loanScheduleEstimateError

  const firstPaymentDate = loanScheduleData
    ? (convertDateToCurrentTimezone(
        (loanScheduleData.tilaDisclosureDetailsDto
          .firstPaymentDate as unknown) as string,
      ) as Date).toISOString()
    : ''
  const submitFormValues = useCallback(submitFormValuesCallback, [
    reactivationApprovalDetails,
    firstPaymentDate,
  ])
  /**
   * Memos
   */
  const reactivation = useMemo(getReactivationData, [])
  const reactivationInitialData = useMemo(getReactivationCreacteData, [])
  const reactivationOffers = useMemo(getReactivationOffers, [])
  const [width, setWidth] = React.useState(window.innerWidth)
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  /**
   * Variables
   */
  const detail =
    reactivationApprovalDetails?.find(
      reactivate => reactivate.productId === reactivation?.productId,
    ) ?? reactivationApprovalDetails?.[0]
  const loanMinimum = 50
  const maxLoanAmount = `${formatCurrency(detail?.approvedAmount ?? 0)}`
  const minLoanAmount = formatCurrency(loanMinimum)
  const isDisbursementCards =
    customer?.customerCards.filter(card => card.isEligibleForDisbursement) ??
    []
  /**
   * Memos
   */
  const initialFormValues = useMemo(
    () =>
      ({
        loanAmount: reactivation?.loanAmount
          ? formatCurrency(reactivation.loanAmount)
          : maxLoanAmount,
        disbursementMethod: PaymentMethodTypes.NULL,
        offerId: 0,
      } as ReactivationFormValues),
    [maxLoanAmount, reactivation],
  )

  const FormSchema = Yup.object().shape({
    loanAmount: Yup.string()
      .required('Cash Back amount is required')
      .test(
        'max-cashback-amount',
        `You can only receive up to ${maxLoanAmount}`,
        (value: string) => {
          if (value === undefined) return true
          return (
            formatCurrencyToFloat(value) <=
            formatCurrencyToFloat(maxLoanAmount)
          )
        },
      )
      .test(
        'min-cashback-amount',
        `The minimum loan amount is ${minLoanAmount}. Please select more than this amount to proceed.`,
        (value: string) => {
          if (value === undefined) return true
          return (
            formatCurrencyToFloat(minLoanAmount) <=
            formatCurrencyToFloat(value)
          )
        },
      ),
    disbursementMethod: Yup.string().required(
      'Disbursement method is required',
    ),
    offerId: Yup.number()
      .required('Offer is required')
      .test('zero', 'Offer is required', (val: number) => val !== 0),
  })

  return (
    <>
      {loanStatus === 'loading' && <PageSpinner />}
      {/** Redirect to home if user is not eligble for reactivation */}
      {currentLoan &&
        currentLoan.todaysPayoffAmount !== 0 &&
        !currentLoan.reactivationApprovalDetails && <Redirect to="/home" />}
      <Container>
        <NumberStepper activeStep={2} steps={ReactivationSteps} />
        <Card className="mb-3 full-page">
          <Card.Header>
            <h1>
              <Content type={ContentSlot.REACTIVATE_HEADER} />
            </h1>
            <h5>
              <Content type={ContentSlot.REACTIVATE_DESCRIPTION} />{' '}
              {maxLoanAmount} now.
            </h5>
          </Card.Header>
          <Formik
            initialValues={initialFormValues}
            validationSchema={FormSchema}
            onSubmit={submitFormValues}
          >
            {({
              values,
              handleBlur,
              handleChange,
              touched,
              setFieldValue,
              errors,
            }: FormikProps<ReactivationFormValues>) => {
              const hanleSelectOffer = (offerId: number) => {
                setFieldValue('offerId', offerId)
              }

              const handleAddCard = () => {
                setReactivationLoanAmount(
                  formatCurrencyToFloat(values.loanAmount),
                )
              }

              return (
                <Form>
                  <SideEffect />
                  <Card.Body>
                    <Row>
                      <Col xs="12">
                        <OfferSelector
                          offers={reactivationOffers ?? []}
                          selectedOfferId={values.offerId}
                          setSelectedOfferId={hanleSelectOffer}
                        />
                        {touched.offerId && errors.offerId && (
                          <BootstrapForm.Text className="text-danger text-center">
                            {errors.offerId}
                          </BootstrapForm.Text>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="12">
                        <BootstrapForm.Group controlId="loanAmount">
                          <BootstrapForm.Label>
                            How much cash would you like to receive?
                          </BootstrapForm.Label>
                          <MaskedInput
                            className="form-control"
                            data-ref="cashback-amount"
                            inputMode="decimal"
                            mask={currencyMask}
                            name="loanAmount"
                            type="text"
                            value={values.loanAmount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.loanAmount && errors.loanAmount && (
                            <BootstrapForm.Text className="text-danger">
                              {errors.loanAmount}
                            </BootstrapForm.Text>
                          )}
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="12">
                        <DebitCardsDropdown
                          isDisbursement
                          showAddCard
                          cardInfo={isDisbursementCards}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          idSuffix=""
                          paymentMethod={values.disbursementMethod}
                          touched={touched}
                          onAddCard={handleAddCard}
                        />
                        <p>
                          *If you do not wish to enroll in automatic payments,
                          please visit one of our store locations to complete
                          your loan origination.
                        </p>
                      </Col>
                    </Row>

                    {loanScheduleData && (
                      <Row>
                        <Col lg="6" xs="12">
                          <BootstrapForm.Group controlId="firstPaymentDate">
                            <BootstrapForm.Label>
                              First Payment Date
                            </BootstrapForm.Label>
                            <BootstrapForm.Control
                              as="select"
                              data-ref="first-payment-date"
                              name="firstPaymentDate"
                              value={firstPaymentDate}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {width > Breakpoints.mobile ? (
                                <option value={firstPaymentDate}>
                                  {largeScreenFormattedDate(firstPaymentDate)}
                                </option>
                              ) : (
                                <option value={firstPaymentDate}>
                                  {mobileScreenFormattedDate(firstPaymentDate)}
                                </option>
                              )}
                            </BootstrapForm.Control>
                          </BootstrapForm.Group>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <div className="w-100 mb-2">
                          <LoanSchedule
                            error={
                              loanScheduleError as Error | null | undefined
                            }
                            isLoading={
                              loanScheduleLoading || isLoanDataLoading
                            }
                            isSuccess={loanScheduleSuccess}
                            loanSchedule={loanScheduleData?.loanSchedule}
                            maxLoanAmount={detail?.approvedAmount}
                            startDate={new Date()}
                            tilaDisclosure={
                              loanScheduleData?.tilaDisclosureDetailsDto
                            }
                            timePeriod={
                              reactivationApprovalDetails?.[0]
                                .periodLengthField
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Container>
                      <Row className="justify-content-between">
                        <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                          <Button
                            className="pl-0 text-dark"
                            variant="link"
                            onClick={goBack}
                          >
                            <GoChevronLeft />{' '}
                            <Content type={ContentSlot.CASHBACK_BACKBUTTON} />
                          </Button>
                        </Col>
                        <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                          <Button data-ref="submit" type="submit">
                            <Content type={ContentSlot.CASHBACK_NEXTBUTTON} />{' '}
                            <GoChevronRight />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Footer>
                  <FormikErrorFocus
                    align="middle"
                    duration={500}
                    ease="linear"
                    focusDelay={200}
                  />
                </Form>
              )
            }}
          </Formik>
        </Card>
      </Container>
    </>
  )

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.push(Routes.HOME)
  }

  /**
   * Auto request loan schedule if user inupts complete request payload
   * @return null
   */
  function SideEffect() {
    const {
      values: undebouncedValues,
      errors: undebouncedErrors,
    } = useFormikContext<ReactivationFormValues>()

    const values = useDebounce(undebouncedValues, 750)
    const errors = useDebounce(undebouncedErrors, 750)

    useEffect(() => {
      if (
        errors.loanAmount?.length ||
        !reactivationApprovalDetails ||
        !currentLoan ||
        !detail ||
        !values.offerId ||
        errors.offerId?.length
      )
        return

      const loanAmount = formatCurrencyToFloat(values.loanAmount)
      const {offerId} = values

      if (isNaN(loanAmount) || loanAmount < loanMinimum) return

      if (
        loanAmount === loanSchedulePayload?.loanAmount &&
        offerId === loanSchedulePayload.offerId
      )
        return

      const payload: GetLoanScheduleValues = {
        loanAmount,
        offerId: values.offerId,
        numberOfPeriods: detail.numberOfPeriods,
        loanId: currentLoan.loanId,
        amountRequested: loanAmount,
        apr: currentLoan.originalFeeApr,
        currentBalance: currentLoan.currentBalance,
        feeBalance: currentLoan.feeBalance,
        isReactivation: true,
      }

      let onlineStoreId = 229 // TX
      if (customer?.reactivationOnlineStoreId) {
        onlineStoreId = customer.reactivationOnlineStoreId
      } else if (customer?.customerAddress.state === 'ID') {
        onlineStoreId = 227
      } else if (customer?.customerAddress.state === 'WI') {
        onlineStoreId = 226
      }

      if (
        customer?.customerAddress.state === 'ID' ||
        customer?.customerAddress.state === 'WI'
      ) {
        const todaysDate = getFormatedFullDate(new Date())

        const valuesToLoanEstimate: ConfirmRefinanceLoanValues = {
          customerId: Number(user?.personReferenceNumber),
          companyId: customer.companyId,
          storeId: Number(onlineStoreId),
          loanModelId: values.offerId,
          cosignerId: 0,
          originationDate: todaysDate,
          fundingDate: todaysDate,
          loanAmount,
          dueDate: null,
          numberOfPayments: 0,
          bankId: customer.customerBankAccounts[0].id,
          fundingMethod: 'CARD',
          fundingLocation: 'NotSpecified',
          workflowId: 0,
          autopayEnrollment: {
            enrollAutopay: true,
            autopayType: 'CARD',
            autopayCardId: customer.customerCards[0]?.id,
            autopayBankId: customer.customerBankAccounts[0]?.id,
          },
          initialLoanStatus: 'None',
          initialDrawAmount: 0,
        }
        requestLoanScheduleEstimate({valuesToLoanEstimate, payload})
      } else {
        const valuesToLoanEstimate: GetReactivationPaymentScheduleArgs = {
          amount: loanAmount,
          applicationId: reactivationInitialData?.applicationId ?? '',
          loanModelId: values.offerId,
          customerId: Number(user?.personReferenceNumber),
        }
        requestLoanSchedule(valuesToLoanEstimate)
      }

      setLoanSchedulePayload(payload)
    }, [errors.loanAmount, errors.offerId, values])

    return null
  }

  /**
   * Submit form values
   * @param values form values
   * @return url redirect
   */
  function submitFormValuesCallback(values: ReactivationFormValues) {
    const loanAmount = formatCurrencyToFloat(values.loanAmount)

    const disbursementCard = customer?.customerCards?.find(
      card =>
        card.id === Number(values.disbursementMethod.replace(/[^0-9]+/g, '')),
    )

    const card: CardData = {
      cardType: disbursementCard?.cardType ?? '',
      cardToken: disbursementCard?.cardTypeId ?? 0,
      cardTokenLast4: disbursementCard?.accountNumberMasked ?? '',
      cardIsFundable: disbursementCard?.isExpired,
    }

    setCardInfo({
      cardType: disbursementCard?.cardType ?? '',
      collateralCreditCardToken: disbursementCard?.cardTypeId ?? 0,
      collateralCreditCardTokenLast4:
        disbursementCard?.accountNumberMasked ?? '',
      contactId: disbursementCard?.id ?? 0,
      isFundable: Boolean(disbursementCard?.isExpired),
    })

    const prevLoanId = Number(currentLoan?.loanId)
    const numberOfPeriods = Number(detail?.numberOfPeriods)
    const personReferenceNumber = customer?.personReferenceNumber ?? ''
    const personId = customer?.personId ?? ''
    const productId = Number(
      reactivation?.productId ? reactivation.productId : detail?.productId,
    )

    const payload: ReactivationData = {
      applicationId: reactivationInitialData?.applicationId ?? '',
      loanAmount,
      loanModelId: values.offerId,
      isAutoPay: true,
      drawAmount: 0,
      disbursementType: 2,
      disbursementId: disbursementCard?.id ?? 0,
      paymentType: 2,
      fundingMethod: 0,
      firstPaymentDate,
      prevLoanId,
      numberOfPeriods,
      personReferenceNumber,
      personId,
      productId,
      disbursementCard: card,
    }

    setReactivationData(payload)
    return history.push('/confirm-origination', {
      from: history.location.pathname,
    })
  }
}

/**
 * Reactivation Page
 */
export default Reactivation
