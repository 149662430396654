import {FormikErrors} from 'formik'
import {IdVerificationFormValues} from 'models/Edge'

/**
 * Id Verification Field Errors
 */
export const FIELD_ERRORS = {
  IS_ID_VERIFIED: {
    REQUIRED: 'Id verification is required',
  },
}

/**
 * ID LINK TOKEN API URL
 */
export const ID_LINK_TOKEN_URL =
  '/VergentCustomer/Customer/Verifications/Identity/Link/Token'

/**
 * Get ID information URL
 */
export const GET_ID_INFORMATION_URL =
  '/VergentCustomer/Customer/Verifications/Document/Identity'

/**
 * Route to contact-us page whenever there is an error in plaid process
 */
export const CONTACT_US_REDIRECT = '/id-verification-issue'

/**
 * Route to no-loan-found page whenever user is locked out
 */
export const NO_LOAN_REDIRECT = '/no-loan-found'

/**
 * Initial Form Errors
 */
export const INITIAL_FORM_ERRORS: FormikErrors<IdVerificationFormValues> = {
  isIdVerified: FIELD_ERRORS.IS_ID_VERIFIED.REQUIRED,
}

/**
 * Test IDs
 */
export const TEST_IDS = {
  BUTTONS: {
    BACK: 'back-button',
    NEXT: 'next-button',
    VERIFY: 'verify-button',
  },
}
