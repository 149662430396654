/**
 * Enum of possible payment frequency options
 */
export const PaymentFrequency = {
  Weekly: 1,
  'Bi-Weekly': 2,
  'Semi-Monthly': 3,
  Monthly: 4,
}

/**
 * Payment Frequency Type
 */
export type PaymentFrequencyType = keyof typeof PaymentFrequency
