import React from 'react'
import styles from './PaymentProcessingMessage.module.scss'

const paymentProcessingText =
  'Your payment is processing. Please do not refresh the page or close your browser.'

const PaymentProcessingMessage: React.FC = () => (
  <span className={styles.message}>{paymentProcessingText}</span>
)

/**
 * Payment Processing Message
 */
export default PaymentProcessingMessage
