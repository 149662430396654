import React from 'react'

import {ContentSlot} from 'models/Content'
import Content from 'components/Content'

interface Props {
  name?: string
}

const WelcomeMessage: React.FC<Props> = ({name}) => (
  <div className="my-2 mx-2 mx-lg-0">
    <h2 className="my-0">
      <Content type={ContentSlot.HOME_WELCOME} />
      {name}!
    </h2>
  </div>
)

/**
 * Application welcome message for customers
 */
export default WelcomeMessage
