import {CashStoreLocation} from 'models/CashStoreLocation'
import dayjs from 'dayjs'
import {getTimezoneFromAbbrev} from 'utils/dates'

const locale = 'en-US'

const currencyFormatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'USD',
})

/**
 * Prettifies a phone number
 * @param phone phone number as string or number
 * @return a prettified phone number
 */
export function formatPhone(phone: number | string | undefined) {
  if (phone === undefined) {
    return ''
  }

  return phone.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

/**
 * Strips a currency string of all symbols excluding the period
 * @param amount the number to be formatted and displayed
 * @return a floating point number
 */
export function formatCurrencyToFloat(amount: string) {
  if (typeof amount != 'string') return 0
  return parseFloat(amount.replace(/[^0-9.]/g, ''))
}

/**
 * Ensures two decimal places
 * @param number Number to be formatted to float
 * @return a floating point string fixed at 2 decimals
 */
export function formatToFloat(number: number): string {
  return parseFloat(number.toString()).toFixed(2)
}

/**
 * Formats a currency to USD
 * @param num value to format
 * @return formatted value
 */
export function formatCurrency(num: number) {
  return currencyFormatter.format(num)
}

/**
 * Returns true/false if store is open at all (comparing to "reference date")
 * @param store array of store details
 * @param referenceDate Date | undefined
 * @return number opening hour value
 */
export function isStoreOpenToday(
  store: CashStoreLocation,
  referenceDate: Date = new Date(),
): boolean {
  const storeTimezone = getTimezoneFromAbbrev(store.timezone.codename)
  const todayKey = dayjs(referenceDate)
    .tz(storeTimezone)
    .format('dddd')
    .toLocaleLowerCase()
  const openKey = `${todayKey}Open`
  const storeOpen = store[openKey]
  if (typeof storeOpen === 'undefined' || storeOpen === null) {
    return false
  }

  return true
}

/**
 * Grabs Store openingHours
 * @param store array of store details
 * @param referenceDate Date | undefined
 * @return number opening hour value
 */
export function getOpeningStoreHours(
  store: CashStoreLocation,
  referenceDate: Date = new Date(),
): string {
  const storeTimezone = getTimezoneFromAbbrev(store.timezone.codename)
  const todayKey = dayjs(referenceDate)
    .tz(storeTimezone)
    .format('dddd')
    .toLocaleLowerCase()
  const openKey = `${todayKey}Open`
  const storeOpen = store[openKey]
  if (typeof storeOpen === 'undefined' || storeOpen === null) return '0'
  return storeOpen.toString()
}

/**
 * Grabs Store closingHours
 * @param store array of store details
 * @param referenceDate Date | undefined used as a reference for store hours
 * @return closing hour value
 */
export function getClosingStoreHours(
  store: CashStoreLocation,
  referenceDate: Date = new Date(),
): string {
  const storeTimezone = getTimezoneFromAbbrev(store.timezone.codename)
  const todayKey = dayjs(referenceDate)
    .tz(storeTimezone)
    .format('dddd')
    .toLocaleLowerCase()
  const closeKey = `${todayKey}Close`
  const storeClose = store[closeKey]
  if (typeof storeClose === 'undefined' || storeClose === null) return '0'
  return storeClose.toString()
}

/**
 * Formats document name
 * @param documentName name of coument
 * @return document name without extension name
 */
export function formatDocumentName(documentName: string) {
  const regex = new RegExp(/\.[^.]+/g)
  return `${documentName.replace(regex, '')}`
}
