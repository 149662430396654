import React from 'react'
import {MdCall} from 'react-icons/md'

import styles from '../Header.module.scss'
import {useLoanData} from '../../../utils/hooks/useLoanData'
import {useStoreDetails} from '../../../utils/hooks/useStoreDetails'
import {formatPhone} from '../../../utils/data-formatting'

const HeaderPhone: React.FC = () => {
  /**
   * State, Hooks
   */
  const {currentLoan} = useLoanData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)

  if (!store) {
    return <></>
  }
  const phoneAriaLabel = formatPhone(store.phone)

  return (
    <>
      <a
        aria-label={phoneAriaLabel}
        className={`text-dark ${styles.navLink} mr-1`}
        href={`tel:${store.phone}`}
      >
        <MdCall size="1.2rem" />
      </a>
      <a
        aria-label={phoneAriaLabel}
        className="text-dark d-none d-xl-flex"
        data-ref="call-us"
        href={`tel:${store.phone}`}
      >
        <span className="pr-2">Call Us:</span>
      </a>
      <span className="d-none d-xl-flex">
        <b>
          <a
            aria-label={phoneAriaLabel}
            className="text-dark"
            href={`tel:${store.phone}`}
          >
            {store.phone && formatPhone(store.phone)}
          </a>
        </b>
      </span>
    </>
  )
}

/**
 * Header phone
 * @return rendered header phone
 */
export default HeaderPhone
