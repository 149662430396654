import React, {useState} from 'react'
// Import {useHistory} from 'react-router'

import PaymentMethodsAdd from 'pages/Account/PaymentMethods/PaymentMethodsAdd'
import stylesPayment from 'pages/Account/PaymentMethods/PaymentMethods.module.scss'

import {Button, Card, Row, Container, Col} from 'react-bootstrap'
import {GoPencil} from 'react-icons/go'

import {FiTrash} from 'react-icons/fi'
import styles from 'components/Content/Content.module.scss'
import {TypeCreditCard} from 'models/PaymentMethods'
import type {CustomerCard} from 'models/Edge'
import {getImageCreditCard} from 'utils/payment-methods'
import DeletePaymentMethodModal from './DeletePaymentMethodModal'

interface PaymentMethodsProps {
  customerCards: CustomerCard[]
  refElement: React.Ref<HTMLDivElement>
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  customerCards,
  refElement,
}) => {
  /**
   * State, Hooks
   */
  const [editMode, setEditMode] = useState(false)
  const [addCard, setAddCard] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(0)
  // Const history = useHistory()

  const toggleEditMode = () => {
    setEditMode(true)
  }

  const toggleCancelAction = () => {
    setAddCard(false)
    setEditMode(false)
  }

  // Function goToAddCard() {
  //   history.push('/add-card', {
  //     from: history.location.pathname,
  //   })
  // }

  const closeDeletePaymentMethodModal = () => setOpenDeleteModal(false)

  return (
    <Card className="mb-2 pb-0">
      <Card.Header className="text-left">
        <h2
          className="border-bottom pb-2 border-medium font-weight-light d-flex justify-content-between"
          data-ref="accountInfo"
        >
          <span className={styles.DisplayPreviewOnHover}>Payment Methods</span>
          <div ref={refElement} id="paymentMethod">
            {!editMode && !addCard && (
              <>
                {/* <Button
                  className="p-0 mr-3"
                  data-ref="add-payment"
                  variant="link"
                  onClick={goToAddCard}
                >
                  <GoPlus /> Add Card
                </Button> */}
                <Button
                  className="p-0"
                  data-ref="toggle-edit-payment"
                  variant="link"
                  onClick={toggleEditMode}
                >
                  <GoPencil /> Edit
                </Button>
              </>
            )}

            {(addCard || editMode) && (
              <Button
                className="p-0"
                data-ref="toggle-edit-account"
                variant="link"
                onClick={toggleCancelAction}
              >
                Cancel
              </Button>
            )}
          </div>
        </h2>
      </Card.Header>
      <Card.Body>
        {addCard ? (
          <PaymentMethodsAdd onCancelAdd={toggleCancelAction} />
        ) : (
          customerCards.map((card, _index) => {
            const onClickDeletePaymentMehtodButton = () => {
              setSelectedPaymentMethodId(card.id)
              setOpenDeleteModal(true)
            }

            return (
              <Container
                key={card.id}
                className={
                  _index + 1 === customerCards.length
                    ? `pb-2 `
                    : `mb-3 border-bottom pb-2 border-medium`
                }
              >
                <Row className="align-items-center">
                  <Col xs="1">
                    <img
                      alt={card.cardType ?? ''}
                      className={`${stylesPayment.image}`}
                      data-ref={`card-${String(card.id)}`}
                      src={getImageCreditCard(
                        card.cardType
                          ? (card.cardType.toUpperCase() as TypeCreditCard)
                          : TypeCreditCard.DEFAULT,
                      )}
                    />
                  </Col>
                  <Col className={`${stylesPayment.marginImage}`}>
                    <div className={`${stylesPayment.textContainer}`}>
                      <p>{card.accountNumberMasked}</p>
                      <p>
                        {card.expirationMonth}/{card.expirationYear}
                      </p>
                    </div>
                  </Col>
                  <Col xs="1">
                    {editMode && (
                      <Button
                        className="p-0"
                        data-ref="delete-payment"
                        variant="link"
                        onClick={onClickDeletePaymentMehtodButton}
                      >
                        <FiTrash color="#999999" />
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            )
          })
        )}
      </Card.Body>
      <DeletePaymentMethodModal
        closeModal={closeDeletePaymentMethodModal}
        open={openDeleteModal}
        paymentMethodId={selectedPaymentMethodId}
      />
    </Card>
  )
}

/**
 * Account Payment Methods
 */
export default PaymentMethods
