import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'
import {LoanPaymentFormValues} from 'models/Edge'

/**
 * Duplicate Payment Popup props interface
 */
export interface DuplicatePaymentPopupProps {
  paymentFormValues?: LoanPaymentFormValues | null
  visibility: boolean
  toggleVisibility(visibility: boolean): void
}

const DuplicatePaymentPopup: React.FC<DuplicatePaymentPopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisibility(false), [props])

  const duplicatePaymentContent = (
    <>
      <Content type={ContentSlot.CFPB_DUPPAY_POPUP} />
      <div data-ref="duplicate-warning" />
      <Button
        data-ref="continue-duplicate-popup"
        variant="primary"
        onClick={handleClose}
      >
        Yes, I Understand
      </Button>
    </>
  )
  return (
    <>
      <AbstractPopup
        warning
        content={duplicatePaymentContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default DuplicatePaymentPopup
