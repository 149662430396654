import {ContentItem} from '@kentico/kontent-delivery'
import React, {ReactNode} from 'react'
import {FaEdit} from 'react-icons/fa'

import styles from 'components/PreviewLink/PreviewLink.module.scss'

import {getConfigValue} from 'utils/environment'

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode
  contentItem?: ContentItem
}

const KenticoPreviewLink: React.FC = ({
  children,
  contentItem,
  ...props
}: Props) => {
  if (
    getConfigValue('KENTICO_EDIT_LINK_ENABLED') !== 'true' ||
    contentItem === undefined
  ) {
    return <></>
  }

  const previewUrl = [
    'https://app.kenticocloud.com/goto/edit-item/project',
    encodeURIComponent(getConfigValue('KENTICO_PROJECT_ID')),
    'variant-codename',
    encodeURIComponent(contentItem.system.language),
    'item',
    encodeURIComponent(contentItem.system.id),
    'element/related_articles/item',
    encodeURIComponent(contentItem.system.id),
    'element/body_copy',
  ].join('/')

  /**
   * Stop propagation on link click.
   * @param event Mouse event on click.
   */
  function handleClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    event.stopPropagation()
  }

  return (
    <a
      {...props}
      className={styles.PreviewLink}
      data-ref="previewLink"
      href={previewUrl}
      rel="noopener noreferrer"
      target="_blank"
      title="Edit item in Kentico"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

/**
 * Link to edit content in Kentico.
 * @param props Component properties
 * @return React component
 */
const PreviewLink = (props: Props) => (
  <KenticoPreviewLink {...props}>
    <FaEdit />
  </KenticoPreviewLink>
)

/**
 * Link to edit Kentico content item.
 * @return rendered preview link
 */
export default PreviewLink
