import React from 'react'
import {useLoanData} from '../../../utils/hooks/useLoanData'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'

const FooterLinks: React.FC = () => {
  /**
   * State, Hooks
   */
  const {customer} = useLoanData()
  if (customer?.customerAddress.state === 'NM') {
    return <Content type={ContentSlot.FOOTER_LINKS} />
  }

  return <Content type={ContentSlot.FOOTER_DEFAULTLINKS} />
}

/**
 * Footer Links
 * @return rendered footer links
 */
export default FooterLinks
