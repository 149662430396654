import React from 'react'
import {ContentSlot} from 'models/Content'
import Content from 'components/Content'
import {MdWarning} from 'react-icons/md'
import styles from 'pages/Login/Banner/Banner.module.scss'
import {getConfigValue} from 'utils/environment'

const Banner: React.FC = () => {
  const enableBanner =
    getConfigValue('ENABLE_LANDING_PAGE_BANNER') === 'true' ||
    getConfigValue('ENABLE_LANDING_PAGE_BANNER') === 'TRUE' ||
    getConfigValue('ENABLE_LANDING_PAGE_BANNER') === 'True'

  return enableBanner ? (
    <div className={styles.banner}>
      <MdWarning />
      <div className={styles.bannerContentContainer}>
        <Content type={ContentSlot.LANDING_PAGE_BANNER} />
      </div>
    </div>
  ) : (
    <></>
  )
}

/**
 * Landing Banner
 * @return Banner
 */
export default Banner
