import createNumberMask from 'text-mask-addons/dist/createNumberMask'

/**
 * Mask for currency
 */
export const currencyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
})

/**
 * Mask for phone number format pattern
 */
export const phoneInputMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

/**
 * Mask for phone number format pattern
 */
export const zipCodeInputMask = [/\d/, /\d/, /\d/, /\d/, /\d/]

/**
 * Mask for CVV format pattern
 */
export const cvvMask = [/\d/, /\d/, /\d/]

/**
 * Mask for social security number format pattern
 */
export const ssnInputMask = [
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  '-',
  /[\d•]/,
  /[\d•]/,
  '-',
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
]

/**
 * Mask for date strings format pattern (mm/dd/yyyy)
 */
export const dateInputMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

/**
 * Mask for date strings format pattern (mm/yyyy)
 */
export const dateMonthYearInputMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

/**
 * Mask for number card strings format pattern
 */
export const numberCardInputMask = [
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  '-',
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  '-',
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  '-',
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
  /[\d•]/,
]
