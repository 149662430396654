import React, {useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {ContentSlot} from 'models/Content'
import UhOh from 'components/UhOh'
import {useLoanData} from 'utils/hooks/useLoanData'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import {AnalyticsPageNames} from 'models/Analytics'

const EmployerInfoChanged: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.EMPLOYER_INFO_CHANGED,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const {currentLoan, isLoading: loanDataLoading} = useLoanData()
  const {data: store, isLoading: storeDataLoading} = useStoreDetails(
    currentLoan?.originatingStore,
  )
  const isLoading = loanDataLoading || storeDataLoading

  if (isLoading) {
    return <></>
  }

  return (
    <UhOh
      back={ContentSlot.EMPLOYERINFORMATIONCHANGED_BACK}
      callUs={ContentSlot.EMPLOYERINFORMATIONCHANGED_CALLUS}
      cta={ContentSlot.EMPLOYERINFORMATIONCHANGED_CTA}
      emailUs={ContentSlot.EMPLOYERINFORMATIONCHANGED_EMAILUS}
      isIneligible={false}
      representative={ContentSlot.EMPLOYERINFORMATIONCHANGED_REPRESENTATIVE}
      storeData={store}
      summary={ContentSlot.EMPLOYERINFORMATIONCHANGED_SUMMARY}
      title={ContentSlot.EMPLOYERINFORMATIONCHANGED_TITLE}
      visitAStore={ContentSlot.EMPLOYERINFORMATIONCHANGED_VISITASTORE}
    />
  )
}

/**
 * Screen that shows when your account is locked due to not finding a loan
 */
export default EmployerInfoChanged
