import React from 'react'

import styles from 'components/PageSpinner/PageSpinner.module.scss'

const PageSpinner: React.FC = () => (
  <div className={styles.container}>
    <div
      className="spinner-border text-primary"
      data-ref="page-spinner"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

/**
 * Whole page spinner
 * @return Rendered spinner page
 */
export default PageSpinner
