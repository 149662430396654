import React, {useEffect, useState} from 'react'

import {Card, Table, Dropdown, Spinner} from 'react-bootstrap'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {CurrentLoanDetails, MyAccountDownloadDocuments} from 'models/Edge'
import {getDownloadDocumentsForLoansWithinLastyear} from 'utils/edge'
import styles from './LoanDocuments.module.scss'
import {formatDocumentName, formatPhone} from 'utils/data-formatting'
import {getConfigValue} from 'utils/environment'

interface Props {
  loanData: CurrentLoanDetails
  customerId: string | undefined
  currentLoanOriginationDate: string | undefined
  currentLoanPublicId: string | undefined
}

const LoanDocuments: React.FC<Props> = ({loanData, customerId}) => {
  const [downloadDocuments, setDownloadDocuments] = useState<
    MyAccountDownloadDocuments[]
  >()
  const [areDocumentsLoading, setAreDocumentsLoading] = useState<boolean>(
    false,
  )
  const [loanIds, setLoanIds] = useState<string[]>()
  const storePhoneNumber = formatPhone(getConfigValue('DEFAULT_PHONE_NUMBER'))

  useEffect(() => {
    setAreDocumentsLoading(true)
    getDownloadDocumentsForLoansWithinLastyear(Number(customerId))
      .then(response => {
        setAreDocumentsLoading(false)
        setDownloadDocuments(response)
        setLoanIds([...new Set(response.map(document => document.loanId))])
      })
      .catch(() => {
        setAreDocumentsLoading(false)
      })
  }, [customerId, loanData])

  const renderDropdownMenu = (loanId: string) =>
    downloadDocuments
      ?.filter(doc => doc.loanId === loanId)
      .map((item, idx) => (
        <Dropdown.Item
          key={idx}
          // eslint-disable-next-line  react/jsx-no-bind
          onClick={() => openInNewTab(item.documentUrl)}
        >
          {formatDocumentName(item.documentName)}
        </Dropdown.Item>
      ))

  const renderDates = (loanId: string) =>
    downloadDocuments
      ?.filter(doc => doc.loanId === loanId)
      .map(doc => new Date(doc.originationDate).toLocaleDateString('en-US'))[0]

  return (
    <>
      <Card className="mb-4 pb-0">
        <Card.Header
          className="text-center text-md-left"
          data-ref="loanDocuments"
        >
          <h2 className="border-bottom pb-2 border-medium font-weight-light">
            <Content type={ContentSlot.ACCOUNT_LOANDOCUMENTSHEADER} />
          </h2>
          <Card.Subtitle className="mb-2">
            Documents from the past year are available below. For older
            documents, please contact {storePhoneNumber}.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {areDocumentsLoading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                className="text-primary"
                role="status"
              />
            </div>
          ) : (
            <>
              {loanData.todaysPayoffAmount > 0 ? (
                <>
                  <h4 className={`${styles.docHeaders}`}>
                    Current Loan Documents
                  </h4>
                  {loanIds?.map((loanId, index) => (
                    <div key={index}>
                      {index === 1 && loanIds.length > 1 && (
                        <h4 className={`${styles.docHeaders}`}>
                          Past Loan Documents
                        </h4>
                      )}
                      <Table
                        responsive
                        className={`d-md-table ${styles.docTable}`}
                      >
                        <thead className={`${styles.docTableHead}`}>
                          <tr>
                            <th className="border-top-0 family-bold">
                              Loan Number
                            </th>
                            <th className="border-top-0 family-bold text-center">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-top-0">
                            <td className="border-top-0">
                              <div className="family-bold d-md-none text-center">
                                Loan Number
                              </div>
                              <Dropdown className={`${styles.dropdown}`}>
                                <Dropdown.Toggle
                                  disabled={areDocumentsLoading}
                                  id="document-dropdown-toggle"
                                  variant="light"
                                >
                                  {loanId}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {downloadDocuments &&
                                  downloadDocuments.length > 0 ? (
                                    renderDropdownMenu(loanId)
                                  ) : (
                                    <></>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border-top-0">
                              <div className={`${styles.dateTitle}`}>Date</div>
                              <div className={`${styles.dateContent}`}>
                                {downloadDocuments &&
                                downloadDocuments.length > 0 ? (
                                  renderDates(loanId)
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {loanIds && loanIds.length > 0 && (
                    <>
                      <h4 className={`${styles.docHeaders}`}>
                        Past Loan Documents
                      </h4>
                      {loanIds.map((loanId, index) => (
                        <div key={index}>
                          <Table
                            responsive
                            className={`d-md-table ${styles.docTable}`}
                          >
                            <thead className={`${styles.docTableHead}`}>
                              <tr>
                                <th className="border-top-0 family-bold">
                                  Loan Number
                                </th>
                                <th className="border-top-0 family-bold text-center">
                                  Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-top-0">
                                <td className="border-top-0">
                                  <div className="family-bold d-md-none text-center">
                                    Loan Number
                                  </div>
                                  <Dropdown className={`${styles.dropdown}`}>
                                    <Dropdown.Toggle
                                      disabled={areDocumentsLoading}
                                      id="document-dropdown-toggle"
                                      variant="light"
                                    >
                                      {loanId}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {downloadDocuments &&
                                      downloadDocuments.length > 0 ? (
                                        renderDropdownMenu(loanId)
                                      ) : (
                                        <></>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td className="border-top-0">
                                  <div className={`${styles.dateTitle}`}>
                                    Date
                                  </div>
                                  <div className={`${styles.dateContent}`}>
                                    {downloadDocuments &&
                                    downloadDocuments.length > 0 ? (
                                      renderDates(loanId)
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

/**
 * Opens Document in new Tab
 * @param url name of document url
 */
function openInNewTab(url: string) {
  window.open(url, '_blank', 'noreferrer')
}

/**
 * Payment History Card
 */
export default LoanDocuments
