import React from 'react'
import {LocalStorageKeys} from 'utils/common'
import {AuthActionsType, AuthActionTypes} from '../useAuthReducer'
import type {AuthenticateAfterMFARequest} from 'models/Auth'
import {StandardError, VergentError} from 'models/Errors'
import {saveUsername} from 'utils/manage-username'

interface LoginActionArgs {
  loginRequestBody: AuthenticateAfterMFARequest
  dispatchAuthState: React.Dispatch<AuthActionsType>
}

/**
 * Login error types
 */
export enum LoginErrorTypes {
  InvalidUserNameOrPassword = 'INVALID_USERNANE_OR_PASSWORD',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
  AccountLockedError = 'ACCOUNT_LOCKED_ERROR',
}

const errorMessages = {
  default: 'There was an error logging in.',
  accountLocked: 'The account is locked.',
}

/**
 * Log in action
 */
export const loginAfterMfaAction = ({
  loginRequestBody,
  dispatchAuthState,
}: LoginActionArgs) => {
  let isInvalidUsernameOrPassword = false
  const {isAccountLocked} = loginRequestBody
  try {
    isInvalidUsernameOrPassword = false
    if (!loginRequestBody.token) isInvalidUsernameOrPassword = true
    localStorage.setItem(LocalStorageKeys.AccessToken, loginRequestBody.token)
  } catch (error) {
    const {
      errorInstance,
      errorWasHandled,
      errorMessage,
    } = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: LoginErrorTypes.InternalServerError,
        trackableMessage: errorInstance.message,
      })
    }

    throw new StandardError({
      type: LoginErrorTypes.VergentServiceError,
      trackableMessage: errorMessage ?? errorMessages.default,
    })
  }
  // Throw an error if th account is locked
  if (isAccountLocked) {
    localStorage.clear()
    throw new StandardError({
      type: LoginErrorTypes.AccountLockedError,
      trackableMessage: errorMessages.accountLocked,
    })
  }
  // Throw an error if the username or password is invalid
  if (isInvalidUsernameOrPassword) {
    localStorage.clear()
    throw new StandardError({
      type: LoginErrorTypes.InvalidUserNameOrPassword,
      trackableMessage: errorMessages.default,
    })
  }
  try {
    saveUsername(
      loginRequestBody.userName,
      String(loginRequestBody.customerId),
    )

    dispatchAuthState({
      type: AuthActionTypes.Login,
      payload: {
        user: {
          email: loginRequestBody.email,
          username: loginRequestBody.userName,
          personReferenceNumber: String(loginRequestBody.customerId),
        },
      },
    })
  } catch (error) {
    const {
      errorInstance,
      errorWasHandled,
      errorMessage,
    } = error as VergentError

    localStorage.clear()

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: LoginErrorTypes.InternalServerError,
        trackableMessage: errorInstance.message,
      })
    }

    throw new StandardError({
      type: LoginErrorTypes.VergentServiceError,
      trackableMessage: errorMessage ?? errorMessages.default,
    })
  }
}
