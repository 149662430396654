import {ContentItem} from '@kentico/kontent-delivery'

/**
 * Enum of possible ContentSlots
 */
export enum ContentSlot {
  ACCOUNT_ACCOUNTINFOHEADER = 'account_accountinfoheader',
  ACCOUNT_ACCOUNTPAGEHEADER = 'account_pageheader',
  ACCOUNT_COMMEMAILLABEL = 'account_commemaillabel',
  ACCOUNT_COMMMAILLABEL = 'account_commmaillabel',
  ACCOUNT_COMMUNICATIONSHEADER = 'account_communicationsheader',
  ACCOUNT_CONTACTINFOHEADER = 'account_contactinfoheader',
  ACCOUNT_LOANDETAILSHEADER = 'account_loandetailsheader',
  ACCOUNT_LOANSCHEDULEHEADER = 'account_loanscheduleheader',
  ACCOUNT_PAYMENTHISTORYHEADER = 'account_paymenthistoryheader',
  ACCOUNT_LOANDOCUMENTSHEADER = 'account_loandocumentsheader',
  ACCOUNT_REMAININGBALANCE = 'account_remainingbalance',
  ACCOUNT_SEEALLSCHEDULE = 'account_seeallloanschedule',
  ADD_A_CARD_INACTIVE_CUSTOMER_WITHOUT_CARD = 'add_a_card_inactive_customer_without_card_body',
  CASHBACK_BACKBUTTON = 'cashback_backbutton',
  CASHBACK_DESCRIPTION = 'cashback_description',
  CASHBACK_FUNDINGCARDNOTE = 'cashback_fundingcardnote',
  CASHBACK_HEADER = 'cashback_header',
  CASHBACK_NEXTBUTTON = 'cashback_nextbutton',
  CASHBACK_SECONDARYDESCRIPTION = 'cashback_secondarydescription',
  CASHWISE_WELCOMEBACK_HEADER = 'cashwise_welcome_back_header',
  CASHWISE_WELCOMEBACK_OPEN_STORE_BODY = 'cashwise_welcome_back_open_store_body',
  CASHWISE_WELCOMEBACK_CLOSED_STORE_BACK_BUTTON = 'cashwise_welcome_back_closed_store_back_button',
  CFPB_CRN_POPUP = 'cfpb_crn_popup',
  CFPB_DELINQUENT_OR_DEFAULT_POPUP = 'cfpb_delinquent_or_default_popup',
  CFPB_DUPPAY_POPUP = 'cfpb_duppay_popup',
  CFPB_UPTN_POPUP = 'cfpb_uptn_popup',
  CONFIRMINFO_BACKBUTTON = 'confirminfo_backbutton',
  CONFIRMINFO_EDITBUTTON = 'confirminfo_editbutton',
  CONFIRMINFO_EMPLOYMENTINFOCHECKBOX = 'confirminfo_employmentinfocheckbox',
  CONFIRMINFO_EMPLOYMENTINFOHEADER = 'confirminfo_employmentinfoheader',
  CONFIRMINFO_HEADER = 'confirminfo_header',
  CONFIRMINFO_INCOMESOURCELINK = 'confirminfo_incomesourcelink',
  CONFIRMINFO_NEXTBUTTON = 'confirminfo_nextbutton',
  CONFIRMINFO_PERSONALINFOHEADER = 'confirminfo_personalinfoheader',
  CONFIRMPAYMENT_AUTHORIZETEXT = 'confirmpayment_authorizetext',
  CONFIRMPAYMENT_BUTTONTEXT = 'confirmpayment_buttontext',
  CONFIRMPAYMENT_DESCRIPTION = 'confirmpayment_description',
  CONFIRMPAYMENT_PAYMENTALERT = 'confirmpayment_paymentalert',

  CONFIRMPAYMENT_TITLE = 'confirmpayment_title',
  CREATEACCOUNT_HEADER = 'createaccount_header',
  CREATEACCOUNT_STEPONE = 'createaccount_stepone',
  CREATEACCOUNT_STEPTHREE = 'createaccount_stepthree',
  CREATEACCOUNT_STEPTWO = 'createaccount_steptwo',
  CREATEACCOUNT_SUBMITBUTTON = 'createaccount_submitbutton',
  CREATEACCOUNT_SUMMARY = 'createaccount_summary',
  CREATEACCOUNT_TERMS = 'createaccount_tc',
  CASHWISE_LANDING_PAGE_SECTION_HEADER = 'cashwise_landing_page_section_header',
  CASHWISE_LANDING_PAGE_SECTION_INTRODUCTION = 'cashwise_landing_page_section_introduction',
  CASHWISE_LANDING_PAGE_SECTION_CHECKBOX = 'cashwise_landing_page_section_checkbox',
  DELINQUENT_MINIMUM_POPUP = 'delinquent_minimum_popup',
  UNSUPPORTED_BROWSER_POPUP = 'unsupported_browser_popup_content',
  EMPLOYERINFORMATIONCHANGED_BACK = 'employerinformationchanged_back',
  EMPLOYERINFORMATIONCHANGED_CALLUS = 'employerinformationchanged_callus',
  EMPLOYERINFORMATIONCHANGED_CTA = 'employerinformationchanged_cta',
  EMPLOYERINFORMATIONCHANGED_EMAILUS = 'employerinformationchanged_emailus',
  EMPLOYERINFORMATIONCHANGED_LINK = 'employerinformationchanged_link',
  EMPLOYERINFORMATIONCHANGED_REPRESENTATIVE = 'employerinformationchanged_representative',
  EMPLOYERINFORMATIONCHANGED_SUMMARY = 'employerinformationchanged_summary',
  EMPLOYERINFORMATIONCHANGED_TITLE = 'employerinformationchanged_title',
  EMPLOYERINFORMATIONCHANGED_VISITASTORE = 'employerinformationchanged_visitastore',
  INVALIDFLAG_BACK = 'invalidflag_back',
  INVALIDFLAG_CALLUS = 'invalidflag_callus',
  INVALIDFLAG_CTA = 'invalidflag_cta',
  INVALIDFLAG_EMAILUS = 'invalidflag_emailus',
  INVALIDFLAG_REPRESENTATIVE = 'invalidflag_representative',
  INVALIDFLAG_SUMMARY = 'invalidflag_summary',
  INVALIDFLAG_TITLE = 'invalidflag_title',
  INVALIDFLAG_VISITASTORE = 'invalidflag_visitastore',
  FINDLOAN_3ATTEMPTS = 'findloan_3attempts',
  FINDLOAN_BUTTON_TEXT = 'findloan_button_text',
  FINDLOAN_FINDLOANERROR = 'findloan_findloanerror',
  FINDLOAN_HEADER = 'findloan_form_header',
  FINDLOAN_FORM_DESCRIPTION = 'findloan_form_description',
  HEADER_PHONE = 'header_phone',
  FOOTER_COPYRIGHT = 'footer_copyright',
  FOOTER_DEFAULTLINKS = 'footer_defaultlinks',
  FOOTER_LINKS = 'footer_links',
  FORGOTPASSWORD_SUBMITBUTTON = 'forgotpassword_submitbutton',
  FORGOTPASSWORD_SUCCESSMESSAGE = 'forgotpassword_successmessage',
  FORGOTPASSWORD_SUCCESSTITLE = 'forgotpassword_successtitle',
  FORGOTPASSWORD_SUMMARY = 'forgotpassword_summary',
  FORGOTPASSWORD_TITLE = 'forgotpassword_title',
  HAMBURGER_LOGOUTTEXT = 'hamburger_logouttext',
  HEADER_LOGOUT = 'header_logout',
  HOME_REFINANCEBUTTON = 'home_refinancebutton',
  HOME_REFINANCETOOLTIP = 'home_refinancecardtooltip',
  HOME_WELCOME = 'home_welcome',
  HOME_BANNER_CUSTOMERINDEFAULT_NO_REFI_OPTION = 'customer_in_default_without_refinance_options',
  HOME_BANNER_CUSTOMERINDEFAULT_WITH_REFI_OPTION = 'customer_in_default_with_refinance_options',
  INACTIVITY_COPY = 'inactivity_copy',
  INACTIVITY_EXTENDBUTTONTEXT = 'inactivity_extendbuttontext',
  INACTIVITY_LOGOUTBUTTONTEXT = 'inactivity_logoutbuttontext',
  INACTIVITY_TITLE = 'inactivity_title',
  LOANELIGIBILITY_CASHOUTBUTTONTEXT = 'loaneligibility_cashoutbuttontext',
  LOANELIGIBILITY_CASHOUTTITLE = 'loaneligibility_cashouttitle',
  LOANELIGIBILITY_DESCRIPTION = 'loaneligibility_description',
  LOANELIGIBILITY_HEADER = 'loaneligibility_header',
  LOANELIGIBILITY_REFINANCEBUTTONTEXT = 'loaneligibility_refinancebuttontext',
  LOANELIGIBILITY_REFINANCETITLE = 'refinance_title',
  LOANSCHEDULE_COLLAPSEEXPANDBUTTONTEXT = 'loanschedule_collapseexpandbuttontext',
  LOANSCHEDULE_PLACEHOLDERTEXT = 'loanschedule_placeholdertext',
  LOANSUMMARY_AUTOPAY = 'loansummary_autopay',
  LOANSUMMARY_SCHEDULE_ON = 'loansummary_scheduledon',
  LOANSUMMARY_UPCOMINGPAYMENT = 'loansummary_upcomingpayment',
  LOANSUMMARY_YOURLOANSUMMARY = 'loansummary_yourloansummary',
  LOCKEDACCOUNT_BACK = 'lockedaccount_back',
  LOCKEDACCOUNT_CALLUS = 'lockedaccount_callus',
  LOCKEDACCOUNT_CTA = 'lockedaccount_cta',
  LOCKEDACCOUNT_EMAILUS = 'lockedaccount_emailus',
  LOCKEDACCOUNT_REPRESENTATIVE = 'lockedaccount_representative',
  LOCKEDACCOUNT_SUMMARY = 'lockedaccount_summary',
  LOCKEDACCOUNT_TITLE = 'lockedaccount_title',
  LOCKEDACCOUNT_VISITASTORE = 'lockedaccount_visitastore',
  LOGIN_CREATEACCOUNTHEADER = 'login_createaccountheader',
  LOGIN_CREATEACCOUNTSUMMARY = 'login_createaccountsummary',
  LOGIN_VERIFYEMAILALERT = 'login_verifyemailalert',
  LOGIN_GETTINGSTARTEDBUTTON = 'login_gettingstartedbutton',
  LOGIN_TITLE = 'login_title',
  LANDING_PAGE_BANNER = 'landing_page_banner',
  LOGIN_BUTTON = 'login_button',
  LOGIN_FORGOTPASSWORDBUTTON = 'login_forgotpasswordbutton',
  MAKEAPAYMENT_BUTTONTEXT = 'makeapayment_buttontext',
  MAKEAPAYMENT_DEBITCARDDESCRIPTION = 'makeapayment_debitcarddescription',
  MAKEAPAYMENT_TITLE = 'makeapayment_title',
  NOLOANFOUND_BUTTON = 'noloanfound_button',
  NOLOANFOUND_PRIMARY_DESCRIPTION = 'noloanfound_primarydescription',
  NOLOANFOUND_SECONDARYDESCRIPTION = 'noloanfound_secondarydescription',
  NOLOANFOUND_TITLE = 'noloanfound_title',
  NOLOANFOUND_FOURTEENDAYTITLE = 'noloanfound_fourteendaytitle',
  NOLOANFOUND_THIRTYONEDAYTITLE = 'noloanfound_thirtyonedaytitle',
  NOLOANS_BACK = 'noloans_back',
  NOLOANS_CALLUS = 'noloans_callus',
  NOLOANS_CTA = 'noloans_cta',
  NOLOANS_EMAILUS = 'noloans_emailus',
  NOLOANS_REPRESENTATIVE = 'noloans_representative',
  NOLOANS_SUMMARY = 'noloans_summary',
  NOLOANS_SPLIT_SUMMARY_ONE = 'no_loans_split_summary_part_one',
  NOLOANS_SPLIT_SUMMARY_TWO = 'no_loans_split_summary_part_two',
  NOLOANS_TITLE = 'noloans_title',
  NOLOANS_VISITASTORE = 'noloans_visitastore',
  OPERATIONS_PHONE_NUMBER = 'operations_phone_number',
  ORIGINATIONCONFIRM_BACKBUTTON = 'originationconfirm_backbutton',
  ORIGINATIONCONFIRM_DESCRIPTION = 'originationconfirm_description',
  ORIGINATIONCONFIRM_DETAILSTITLE = 'originationconfirm_detailstitle',
  ORIGINATIONCONFIRM_NEXTBUTTON = 'originationconfirm_nextbutton',
  ORIGINATIONCONFIRM_TITLE = 'originationconfirm_title',
  ORIGINATIONCONFIRM_AUTHORIZETEXT = 'originationconfirmation_authorizetext',
  PAYMENT_REMAINDER_NOT_SATISFIED = 'payment_remainder_not_satisfied_slot',
  REACTIVATE_HEADER = 'reactivate_header',
  REACTIVATE_DESCRIPTION = 'reactivate_description',
  REACTIVATE_DISCLAIMER = 'reactivate_disclaimer',
  REACTIVATE_FUNDINGCARDNOTE = 'reactivate_fundingcardnote',
  REACTIVATIONTHANKYOU_DESCRIPTION = 'reactivationthankyou_description',
  REACTIVATIONTHANKYOU_DETAILSTITLE = 'reactivationthankyou_detailstitle',
  REACTIVATIONTHANKYOU_HEADER = 'reactivationthankyou_header',
  REACTIVATIONTHANKYOU_PAYMENTDETAILS = 'reactivationthankyou_paymentdetails',
  REACTIVATIONTHANKYOU_PAYMENTAPPLIED = 'reactivationthankyou_paymentapplied',
  REACTIVATIONTHANKYOU_DOWNLOADLOANDOCS = 'reactivationthankyou_downloadloandocs',
  REACTIVATIONTHANKYOU_BUTTON = 'reactivationthankyou_button',
  REACTIVATIONTHANKYOU_PRINTBUTTONTEXT = 'reactivation_thank_you_print_button_text',
  REACTIVATIONORIGINATIONCONFIRM_AUTHORIZETEXT = 'reactivation_originationconfirmation_authorizetext',
  REACTIVATION_ISSUE_TITLE = 'reactivationerror_title',
  REACTIVATION_ISSUE_SUMMARY = 'reactivationerror_summary',
  REACTIVATION_ISSUE_REPRESENTATIVE = 'reactivationerror_representative',
  REACTIVATION_ISSUE_CALLUS = 'reactivationerror_callus',
  REACTIVATION_ISSUE_EMAILUS = 'reactivationerror_emailus',
  REACTIVATION_ISSUE_VISIT = 'reactivationerror_visitastore',
  REACTIVATION_ISSUE_CTA = 'reactivationerror_cta',
  REACTIVATION_ISSUE_BACK = 'reactivationerror_back',
  REFINANCE_DESCRIPTION = 'refinance_description',
  REFINANCE_SECONDARYDESCRIPTION = 'refinance_secondarydescription',
  REFINANCE_TITLE = 'refinance_title',
  REFINANCE_THANKS_POLICY_DESC = 'privacypolicy_description',
  REFINANCETHANKYOU_DESCRIPTION = 'refinancethankyou_description',
  REFINANCETHANKYOU_DETAILSTITLE = 'refinancethankyou_detailstitle',
  REFINANCETHANKYOU_HEADER = 'refinancethankyou_header',
  REFINANCETHANKYOU_PAYMENTDETAILS = 'refinancethankyou_paymentdetails',
  REFINANCETHANKYOU_PAYMENTAPPLIED = 'refinancethankyou_paymentapplied',
  REFINANCETHANKYOU_DOWNLOADLOANDOCS = 'refinancethankyou_downloadloandocs',
  REFINANCETHANKYOU_BUTTON = 'refinancethankyou_button',
  REFINANCETHANKYOU_PRINTBUTTONTEXT = 'cashback___refinance_thank_you_print_button_text',
  REFINANCE_ISSUE_TITLE = 'refinanceerror_title',
  REFINANCE_ISSUE_SUMMARY = 'refinanceerror_summary',
  REFINANCE_ISSUE_REPRESENTATIVE = 'refinanceerror_representative',
  REFINANCE_ISSUE_CALLUS = 'refinanceerror_callus',
  REFINANCE_ISSUE_EMAILUS = 'refinanceerror_emailus',
  REFINANCE_ISSUE_VISIT = 'refinanceerror_visitastore',
  REFINANCE_ISSUE_CTA = 'refinanceerror_cta',
  REFINANCE_ISSUE_BACK = 'refinanceerror_back',
  REPAY_BACKBUTTON = 'repay_backbutton',
  RESETPASSWORD_SUBMITBUTTON = 'resetpassword_submitbutton',
  RESETPASSWORD_SUMMARY = 'resetpassword_summary',
  RESETPASSWORD_TITLE = 'resetpassword_title',
  STORE_CARD_LABEL = 'storecard_label',
  SUCCESSFULPAYMENT_BACKBUTTONTEXT = 'successfulpayment_backbuttontext',
  SUCCESSFULPAYMENT_BUTTONTEXT = 'successfulpayment_buttontext',
  SUCCESSFULPAYMENT_DESCRIPTION = 'successful_payment_description',
  SUCCESSFULPAYMENT_PAYMENTAPPLIEDTITLE = 'successfulpayment_paymentappliedtitle',
  SUCCESSFULPAYMENT_PAYMENTDETAILSTITLE = 'successfulpayment_paymentdetailstitle',
  SUCCESSFULPAYMENT_PAYMENTRECEIVEDTITLE = 'successfulpayment_paymentreceivedtitle',
  SUCCESSFULPAYMENT_TITLE = 'successfulpayment_title',
  SUCCESSFULPAYMENT_PRINTBUTTONTEXT = 'successful_payment_confirmation_print_button_text',
  SUCCESSFULPAYMENT_FINEPRINTTEXT = 'successfulpayment_financechargesfinetext',
  VERGENTSTORE_GOLIVE_POPUP = 'vergent_store_golive_popup',
  VERGENTSTORE_GOLIVE_POPUP_BUTTON = 'vergent_store_golive_popup_button',
  VERIFYEMAIL_RESENDBUTTON = 'verifyemail_resendbutton',
  VERIFYEMAIL_RESENDBUTTONSUCCESS = 'verifyemail_resendbuttonsuccess',
  VERIFYEMAIL_SENDBUTTON = 'verifyemail_sendbutton',
  VERIFYEMAIL_UNVERIFIEDHEADER = 'verifyemail_unverifiedheader',
  VERIFYEMAIL_UNVERIFIEDSUMMARY = 'verifyemail_unverifiedsummary',
  VERIFYEMAIL_VERIFYFAILHEADER = 'verifyemail_verifyfailheader',
  VERIFYEMAIL_VERIFYFAILSUMMARY = 'verifyemail_verifyfailsummary',
  VERIFYEMAIL_VERIFYSUCCESSBUTTON = 'verifyemail_verifysuccessbutton',
  VERIFYEMAIL_VERIFYSUCCESSHEADER = 'verifyemail_verifysuccessheader',
  VERIFYEMAIL_VERIFYSUCCESSSUMMARY = 'verifyemail_verifysuccesssummary',
}

/**
 * Enum of possible ContentTypes
 */
export enum ContentTypes {
  LINKS_LIST_CONTENT = 'links_list_content',
  COPY_CONTENT = 'copy_content',
  TEXT_CONTENT = 'text_content',
  LINK_CONTENT = 'link',
  BUTTON_CONTENT = 'button_link',
  TELEPHONE_LINK_CONTENT = 'telephone_link',
  EMBEDDED_CONTENT = 'embedded_content',
  EMPTY = 'empty',
}

interface ContentValue<T> {
  value: T
}

/**
 * Links list content type
 */
export interface LinksListContent extends ContentItem {
  links: ContentValue<LinkContent[]>
}

/**
 * Link content type
 */
export interface LinkContent extends ContentItem {
  link_text: ContentValue<string>
  link_url: ContentValue<string>
}

/**
 * Copy content type
 * Rich text that will include HTML
 */
export interface CopyContent extends ContentItem {
  copy: ContentValue<string>
}

/**
 * Text content type
 * Text only, no HTML
 */
export interface TextContent extends ContentItem {
  value: ContentValue<string>
}

/**
 * Button content type
 */
export interface ButtonContent extends ContentItem {
  text: ContentValue<string>
  url: ContentValue<string>
}

/**
 * Telephone Link content type
 */
export interface TelephoneLinkContent extends ContentItem {
  telephone_number: ContentValue<number>
  telephone_link_text: ContentValue<string>
}

/**
 * Embedded content type
 */
export interface EmbeddedContent extends ContentItem {
  value: ContentValue<string>
}
