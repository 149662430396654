/**
 * Configuration for refinance stepper
 */
export const RefinanceSteps = [
  'Verify Your Info',
  'Refinance Options',
  'Sign Documents',
]

/**
 * Configuration for reactivation stepper
 */
export const ReactivationSteps = [
  'Verify Your Info',
  'Reactivation Options',
  'Sign Documents',
]

/**
 * Configuration for create account stepper
 */
export const createAccountSteps = [
  'Find Account',
  'Create Account',
  'Verify Email',
]
