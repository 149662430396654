// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
/**
 * Generates a guid
 * @return new guid
 */
export const guid = () => {
  const url = URL.createObjectURL(new Blob())
  const [id] = url.toString().split('/').reverse() // eslint-disable-line
  URL.revokeObjectURL(url)
  return id
}
