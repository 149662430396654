/**
 * Years for drop down list
 * @return array containing last 100 years
 */
export const getYears = () => {
  const now = new Date().getUTCFullYear()
  return Array(101)
    .fill('')
    .map((_v, idx) => now - idx)
}

/**
 * Convert base64 string to bytearray
 * @param base64 Base64 string for the pdf document
 * @return Bytearray
 */
export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

/**
 * Find first difference between two strings
 * @param str1 first string
 * @param str2 second string
 * @return index of first difference
 */
export function findFirstDiffPos(str1: string, str2: string) {
  let i = 0
  if (str1 === str2) return -1
  while (str1[i] === str2[i]) i += 1
  return i
}

/**
 * Count the number of digits in a string
 * @param str string to count
 * @return number of digits
 */
export function getNumberOfDigits(str: string) {
  let digitCount = 0
  for (const char of str) {
    if (char >= '0' && char <= '9') {
      digitCount += 1
    }
  }
  return digitCount
}
