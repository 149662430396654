import React from 'react'

import {largeScreenFormattedDate, mobileScreenFormattedDate} from 'utils/dates'

interface Props {
  dueDate: string | Date | number
}

const FormattedDate: React.FC<Props> = ({dueDate}) => (
  <>
    <span className="d-none d-md-inline">
      {largeScreenFormattedDate(dueDate)}
    </span>
    <span className="d-md-none">{mobileScreenFormattedDate(dueDate)}</span>
  </>
)

/**
 * Dates
 * @return Rendered FormattedDate
 */
export default FormattedDate
