import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'

/**
 * CRN Popup props interface
 */
export interface CRNPopupProps {
  visibility: boolean
  toggleVisiblity(visibility: boolean): void
}

const CRNPopup: React.FC<CRNPopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisiblity(false), [props])
  const crnContent = (
    <>
      <Content type={ContentSlot.CFPB_CRN_POPUP} />
      <p>
        <Button variant="link" onClick={handleClose}>
          Close
        </Button>
      </p>
    </>
  )
  return (
    <>
      <AbstractPopup
        warning
        content={crnContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default CRNPopup
