import React, {useCallback, useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Button, Card, Container, Col, Row} from 'react-bootstrap'
import {useHistory, useLocation} from 'react-router-dom'

import {AnalyticsPageNames} from 'models/Analytics'
import {formatPhone} from 'utils/data-formatting'
import {getTimezoneFromAbbrev} from 'utils/dates'
import dayjs from 'dayjs'
import {CashWiseInformationResponse} from 'models/Edge'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import youngCoupleImage from 'assets/img/young-couple.jpg'
import styles from './landing.module.scss'

const CashwiseWelcomeBack: React.FC = () => {
  const location = useLocation<CashWiseInformationResponse>()

  const {firstName, lastName, storeTimezone, storePhone} = location.state
  const tz = getTimezoneFromAbbrev(storeTimezone)
  const storeOpen = getStoreOpen(location.state)
  const storeClose = getStoreClose(location.state)

  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CASHWISE_WELCOME_BACK,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Context
   */
  const history = useHistory()

  const goBack = useCallback(goBackCallback, [history])

  const closingHour = dayjs()
    .tz(tz)
    .hour((storeClose ? storeClose : 0) + 12)
    .minute(0)
    .second(0)
  const openingHour = dayjs()
    .tz(tz)
    .hour(storeOpen ? storeOpen : 0)
    .minute(0)
    .second(0)
  const todayDate = dayjs(new Date()).tz(tz)
  const isStoreOpen =
    todayDate.isAfter(openingHour) && todayDate.isBefore(closingHour)

  return (
    <>
      <div className="bg-white">
        <div
          className={styles.banner}
          // eslint-disable-next-line react/forbid-dom-props
          style={{backgroundImage: `url(${youngCoupleImage})`}}
        />
        <Container>
          <Row className="justify-content-center">
            <Col md={8} xs={12}>
              <Card className="no-shadow">
                <Card.Header className="text-center">
                  <h1>
                    <Content type={ContentSlot.CASHWISE_WELCOMEBACK_HEADER} />
                    <br />
                    {firstName} {lastName}!
                  </h1>
                </Card.Header>
                {isStoreOpen ? (
                  <>
                    <Card.Body className="text-center">
                      <Content
                        type={ContentSlot.CASHWISE_WELCOMEBACK_OPEN_STORE_BODY}
                      />
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button href={`tel:${storePhone}`} variant="primary">
                        Call {formatPhone(storePhone)} Now
                      </Button>
                    </Card.Footer>
                  </>
                ) : (
                  <>
                    <Card.Body className="text-center">
                      <p>
                        It&#39;s been a while, so we need to quickly verify
                        some information first. Please give us a call at{' '}
                        {formatPhone(storePhone)} from {storeOpen}AM-
                        {storeClose}PM so you can get the money you want as
                        soon as you need!
                      </p>
                    </Card.Body>
                    <Card.Footer>
                      <div className="text-center py-1">
                        <Button href={`tel:${storePhone}`} variant="primary">
                          Call {formatPhone(storePhone)}
                        </Button>
                      </div>
                      <div className="text-center py-1">
                        <Button variant="link" onClick={goBack}>
                          <Content
                            type={
                              ContentSlot.CASHWISE_WELCOMEBACK_CLOSED_STORE_BACK_BUTTON
                            }
                          />
                        </Button>
                      </div>
                    </Card.Footer>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Get the store open hour for the current day based on CashWiseInformationResponse
   * @param cashWiseInfo CashWiseInformationResponse
   * @return storeOpenHour number | undefined
   */
  function getStoreOpen(
    cashWiseInfo: CashWiseInformationResponse,
  ): number | undefined {
    const todayKey = dayjs(new Date())
      .tz(tz)
      .format('dddd')
      .toLocaleLowerCase()
    const openKey = `${todayKey}Open`
    const storeOpenHour = cashWiseInfo[openKey]
    if (typeof storeOpenHour === 'undefined') return undefined
    return storeOpenHour ? parseInt(storeOpenHour.toString()) : undefined
  }

  /**
   * Get the store close hour for the current day based on CashWiseInformationResponse
   * @param cashWiseInfo CashWiseInformationResponse
   * @return storeCloseHour number | undefined
   */
  function getStoreClose(
    cashWiseInfo: CashWiseInformationResponse,
  ): number | undefined {
    const todayKey = dayjs(new Date())
      .tz(tz)
      .format('dddd')
      .toLocaleLowerCase()
    const closeKey = `${todayKey}Close`
    const storeCloseHour = cashWiseInfo[closeKey]
    if (typeof storeCloseHour === 'undefined') return undefined
    return storeCloseHour ? parseInt(storeCloseHour.toString()) : undefined
  }
}

/**
 * Cashwise Welcome Back page
 */
export default CashwiseWelcomeBack
