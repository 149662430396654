import React, {useCallback} from 'react'
import AbstractPopup from 'components/Popup'
import {Button} from 'react-bootstrap'

/**
 * Customer's status 'On Hold' props interface
 */
export interface OnHoldStatusPopupProps {
  visibility: boolean
  toggleVisibility(visibility: boolean): void
}

const OnHoldStatusPopup: React.FC<OnHoldStatusPopupProps> = props => {
  const opsPhoneNumber = '(855) 279-2205'
  const handleClose = useCallback(() => props.toggleVisibility(false), [props])
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (opsPhoneNumber) {
    const onHoldStatusPopup = (
      <div className="pt-2">
        We are currently unable to service your loan. Please call{' '}
        <a href={`tel:${opsPhoneNumber}`}>{opsPhoneNumber}</a> for more
        information.
        <div className="text-center mt-2">
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    )

    return (
      <>
        <AbstractPopup
          content={onHoldStatusPopup}
          handleClose={handleClose}
          visibility={props.visibility}
          warning={false}
        />
      </>
    )
  }
  return null
}

/**
 * Bootstrap modal for On Hold status
 */
export default OnHoldStatusPopup
