import React from 'react'

import {Card, Dropdown, Table} from 'react-bootstrap'
import dayjs from 'dayjs'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {CurrentLoanDetails, Transaction} from 'models/Edge'
import {formatCurrency} from 'utils/data-formatting'
import {largeScreenFormattedDate, mobileScreenFormattedDate} from 'utils/dates'
import styles from './PaymentHistory.module.scss'

interface Props {
  loanData: CurrentLoanDetails
}

const debitCardPaymentType = 'debit card'
const electronicPaymentType = 'electronic payment'

const PaymentHistory: React.FC<Props> = ({loanData}) => {
  // Reorders array to be latest->oldest transactions
  const orderedTransactions = loanData.paymentTransactions.sort(
    (a, b) => b.transactionid - a.transactionid,
  )

  return (
    <>
      {loanData.paymentTransactions.length > 0 ? (
        <Card className="mb-4 pb-0">
          <Card.Header
            className="text-center text-md-left"
            data-ref="paymentHistory"
          >
            <h2 className="border-bottom pb-2 border-medium font-weight-light">
              <Content type={ContentSlot.ACCOUNT_PAYMENTHISTORYHEADER} />
            </h2>
          </Card.Header>
          <Card.Body>
            <Table responsive className={`d-md-table ${styles.docTable}`}>
              <thead className={`${styles.docTableHead}`}>
                <tr>
                  <th className="border-top-0 family-bold">Loan Number</th>
                  <th className="border-top-0 family-bold text-center">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-top-0">
                  <td className="border-top-0">
                    <div className="family-bold d-md-none text-center">
                      Loan Number
                    </div>
                    <Dropdown className={`${styles.dropdown}`}>
                      <Dropdown.Toggle
                        id="paymenthistory-dropdown-toggle"
                        variant="light"
                      >
                        {loanData.publicLoanId}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Table responsive className="d-none d-md-table">
                          <thead>
                            <tr>
                              <th className="border-top-0 family-bold">
                                Posted
                              </th>
                              <th className="border-top-0 family-bold">
                                Amount
                              </th>
                              <th className="border-top-0 family-bold">
                                Payment Type
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderedTransactions.map(
                              (item: Transaction, idx: number) => (
                                <tr key={idx} className="">
                                  <td>
                                    {largeScreenFormattedDate(
                                      dayjs(item.effectiveDate).toDate(),
                                    )}
                                  </td>
                                  <td>
                                    {formatCurrency(Math.abs(item.amount))}
                                  </td>
                                  <td>{getPaymentType(item)}</td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </Table>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="border-top-0">
                    <div className={`${styles.dateTitle}`}>Date</div>
                    {loanData.originationDate ? (
                      <div className={`${styles.dateContent}`}>
                        {new Date(loanData.originationDate).toLocaleDateString(
                          'en-US',
                        )}
                      </div>
                    ) : null}
                  </td>
                </tr>
              </tbody>
              <div className="d-block d-md-none">
                {orderedTransactions.map((item: Transaction, idx: number) => (
                  <p
                    key={idx}
                    className={`py-2 my-0 ${
                      idx > 0 ? 'border-top' : ''
                    } text-center`}
                  >
                    {`${formatCurrency(Math.abs(item.amount))} `}
                    {getPaymentType(item)}
                    {` ${item.transactionType}`}
                    {` on ${mobileScreenFormattedDate(
                      dayjs(item.effectiveDate).toDate(),
                    )}`}
                  </p>
                ))}
              </div>
            </Table>
          </Card.Body>
        </Card>
      ) : null}
    </>
  )
}

/**
 * Returns payment type
 * @param item transaction
 * @return payment type
 */
export function getPaymentType(item: Transaction): string {
  if (
    item.paymentType.toLowerCase() === debitCardPaymentType &&
    item.authorizationType === 'Web'
  ) {
    return 'Online'
  }
  if (
    item.paymentType.toLowerCase() === debitCardPaymentType &&
    item.authorizationType === 'Telephone'
  ) {
    return 'Phone'
  }
  if (item.paymentType.toLowerCase() === debitCardPaymentType) {
    return 'Debit'
  }
  if (item.paymentType.toLowerCase() === 'ach') {
    return 'ACH'
  }
  if (item.paymentType.toLowerCase() === electronicPaymentType) {
    return 'Electronic'
  }
  return item.paymentType
}

/**
 * Payment History Card
 */
export default PaymentHistory
