import React, {useCallback} from 'react'
import {Button, Container, Row} from 'react-bootstrap'
import {useHistory} from 'react-router'

import magnifyingGlassIcon from 'assets/img/magnifyingGlassIcon.png'
import {useAuth} from 'auth'

const NotFound: React.FC = () => {
  const history = useHistory()
  const goHome = useCallback(handleNavigation, [])
  const {isLoggedIn} = useAuth()

  return (
    <Container>
      <Row className="justify-content-center m-1">
        <img
          alt="Not Found Icon"
          className="img-responsive w-50"
          src={magnifyingGlassIcon}
        />
      </Row>
      <Row className="justify-content-center m-1">
        <h1>404!</h1>
      </Row>
      <Row className="justify-content-center m-1">
        <p>Sorry! We couldn&apos;t find this page</p>
      </Row>
      <Row className="justify-content-center mb-3">
        <Button
          className="p-0"
          data-ref="logout"
          variant="link"
          onClick={goHome}
        >
          Home
        </Button>
      </Row>
    </Container>
  )

  /**
   * Simple Function to handle going back to Home or Login
   */
  function handleNavigation() {
    if (isLoggedIn) {
      history.push('/home')
    } else {
      history.push('/login')
    }
  }
}

/**
 * Default 404 Not Found Screen for the Customer Portal
 */
export default NotFound
