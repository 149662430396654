import React from 'react'

import {ContentItem} from '@kentico/kontent-delivery'

interface LinksListProps {
  items: ContentItem[]
}

/**
 * Links List for Footer
 * @return horizontal list of links divided by |
 */
export const LinksList: React.FC<LinksListProps> = ({
  items,
}: LinksListProps) => (
  <>
    {items.map((link: ContentItem, index: number) => (
      <span key={link.link_text.value}>
        <a
          aria-label={link.link_text.value}
          className="text-dark d-inline-block mx-1"
          href={link.link_url.value}
          rel="noopener noreferrer"
          target="_blank"
        >
          {link.link_text.value}
        </a>
        {index !== items.length - 1 && <span> | </span>}
      </span>
    ))}
    {items.length > 0 && <span> | </span>}
    <span key="Licenses and Rates">
      <a
        aria-label="Licenses and Rates"
        className="text-dark d-inline-block mx-1"
        href="https://www.cashstore.com/licenses-and-rates"
        rel="noopener noreferrer"
        target="_blank"
      >
        Licenses and Rates
      </a>
    </span>
  </>
)
