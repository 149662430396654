import React, {useCallback, useContext, useState} from 'react'

import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {Col, Row} from 'react-bootstrap'

import {logUnknownAsException} from 'AppInsights'
import {AnalyticsEventNames} from 'models/Analytics'
import SpinnerButton from 'components/SpinnerButton'
import {ToastContext} from 'components/Toast'
import {useAuth} from 'auth'

const initialFormValues = {
  currentPassword: '',
}

type AccountInfoEditing = typeof initialFormValues

interface Props {
  onSubmit: Function
}

const AccountInfoEditing: React.FC<Props> = ({onSubmit: onSubmitCb}) => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()

  /**
   * Context
   */
  const {pushToast} = useContext(ToastContext)
  const {requestPasswordUpdate} = useAuth()

  /**
   * State
   */
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Callbacks
   */
  const onResetPassword = useCallback(onResetPasswordCallback, [
    appInsightsContext,
    onSubmitCb,
    pushToast,
    requestPasswordUpdate,
  ])

  return (
    <Row>
      <Col xs="auto">
        <SpinnerButton
          block
          color="primary"
          data-ref="submit"
          loading={isLoading}
          size="lg"
          type="button"
          onClick={onResetPassword}
        >
          Reset Password
        </SpinnerButton>
      </Col>
    </Row>
  )

  /**
   * Sends password update request
   */
  async function onResetPasswordCallback() {
    try {
      await requestPasswordUpdate()
      pushToast({
        title: 'Email sent',
        message:
          'You will receive an email with the next steps to update your password',
        variant: 'success',
      })

      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.UPDATE_PASSWORD,
      })

      onSubmitCb()
    } catch (error) {
      pushToast({
        title: 'Unable to update password',
        message: 'There was an error updating the password',
        variant: 'danger',
      })
      logUnknownAsException(error)
    } finally {
      setIsLoading(false)
    }
  }
}

/**
 * Account Info Editing
 */
export default AccountInfoEditing
