import {parseJwt} from 'utils/auth-helpers'
import {LocalStorageKeys} from 'utils/common'

/**
 * Utility to validate if the token on the local storage is valid
 * @return boolean
 */
export const hasValidStoragedToken = () => {
  try {
    const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken)
    const accessTokenData = parseJwt(accessToken ?? '')
    const expiresAt = new Date(accessTokenData.exp * 1000).getTime()
    const now = new Date().getTime()
    const millisToExpiration = expiresAt - now
    return Boolean(accessToken && millisToExpiration > 0)
  } catch {
    return false
  }
}
