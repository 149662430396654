import {parseJwt} from 'utils/auth-helpers'
import {LocalStorageKeys} from 'utils/common'

interface VerifyTokenExpiration {
  logout: () => void
  isLoggedIn: boolean
}

/**
 * Verifies the token expiration when the user info change
 * and set a timeout to logout when the token expires
 * @return NodeJS.Timeout | null
 */
export const verifyTokenExpiration = ({
  logout,
  isLoggedIn,
}: VerifyTokenExpiration) => {
  try {
    const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken)
    if (!accessToken || !isLoggedIn) return null

    const accessTokenData = parseJwt(accessToken)
    const expiresAt = new Date(accessTokenData.exp * 1000).getTime()
    const now = new Date().getTime()
    const millisToExpiration = expiresAt - now

    if (millisToExpiration > 0) {
      return setTimeout(() => {
        logout()
      }, millisToExpiration)
    }

    logout()
    return null
  } catch {
    return null
  }
}
