import * as FullStory from '@fullstory/browser'
import {clearUtmFromLocalStorage} from 'utils/analytics'
import type {History} from 'history'
import {LocalStorageKeys} from 'utils/common'
import {AuthActionsType, AuthActionTypes} from 'auth/useAuthReducer'

interface UtmParams {
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
}

const utmParamKeys: (keyof UtmParams)[] = [
  'utmCampaign',
  'utmContent',
  'utmMedium',
  'utmSource',
]

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

interface LogoutActionParams {
  history: History<History.PoorMansUnknown>
  dispatchAuthState: React.Dispatch<AuthActionsType>
}

/**
 * Logout action
 * @param history react hook
 */
export const logoutAction = ({
  history,
  dispatchAuthState,
}: LogoutActionParams) => {
  // Get utm query params
  const utmQueryParams = (() => {
    try {
      const utmParameters = JSON.parse(
        localStorage.getItem(LocalStorageKeys.UtmParameters) ?? '{}',
      ) as UtmParams

      if (typeof utmParameters !== 'object') return ''

      return utmParamKeys.reduce((utmString, utmKey) => {
        if (utmParameters[utmKey]) {
          const separator = utmString ? '&' : '?'
          return `${utmString}${separator}${camelToSnakeCase(utmKey)}=${
            utmParameters[utmKey]
          }`
        }
        return utmString
      }, '')
    } catch {
      return ''
    }
  })()
  // Clear browser data
  FullStory.anonymize()
  clearUtmFromLocalStorage()
  localStorage.clear()
  // Update State and redirect
  dispatchAuthState({
    type: AuthActionTypes.Logout,
  })
  history.replace(`/login${utmQueryParams}`)
}
