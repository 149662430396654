/**
 * Converts date to current timezone
 * @param date string
 * @return date with the current timezone
 */
export const convertDateToCurrentTimezone = (date: string | number | Date) => {
  if (typeof date !== 'string') return date
  const dateRegex = /\d{4}-\d{2}-\d{2}/
  const dateWithoutTimeZone = dateRegex.exec(date)?.[0]
  if (!dateWithoutTimeZone) return date
  return new Date(`${dateWithoutTimeZone}T00:00:00`)
}
