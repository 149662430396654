import React, {useCallback, useMemo, useEffect, useState} from 'react'

import {Button, Card, Container} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import dayjs from 'dayjs'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import TwoColumnTable, {ListItem} from 'components/TwoColumnTable'
import {RefinanceLoanResponse, OriginateLoanResponse} from 'models/Edge'
import {AnalyticsEventNames} from 'models/Analytics'
import styles from 'pages/ThankYouPage/ThankYouPage.module.scss'
import {formatCurrency} from 'utils/data-formatting'
import {
  getRefinanceData,
  getReactivationData,
  RefinanceData,
  ReactivationData,
  getReactivateResponse,
  getRefinanceResponse,
} from 'utils/cache'
import {queryClient} from 'client'
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai'
import {getConfigValue} from 'utils/environment'

const ThankYouPage: React.FC = () => {
  const history = useHistory()

  const refi = useMemo(getRefinanceData, [])
  const reactivation = useMemo(getReactivationData, [])
  const appInsightsContext = useAppInsightsContext()

  const refinanceSubmissionData:
    | RefinanceLoanResponse
    | undefined = getRefinanceResponse()
  const reactivateSubmissionData:
    | OriginateLoanResponse
    | undefined = getReactivateResponse()
  const [hideDocuments, setHideDocuments] = useState(false)

  const hideRefinanceDetailsConfigEntry = getConfigValue(
    'HIDE_REFINANCE_DETAILS_ON_THANK_YOU_PAGE',
  )
  const hideRefinanceDetails = hideRefinanceDetailsConfigEntry
    ? hideRefinanceDetailsConfigEntry.toLowerCase() === 'true'
    : true

  const handlePrintClick = useCallback(handlePrintCallback, [window])
  const goHome = useCallback(goHomeCallback, [history])

  const toggleDocuments = useCallback(toggleDocumentsCallback, [
    hideDocuments,
    setHideDocuments,
  ])

  const {
    personalDetails,
    paymentDetails,
    appliedDetails,
  } = getConfirmationRowsCallback(
    refi,
    reactivation,
    refinanceSubmissionData,
    reactivateSubmissionData,
  )
  // Clears the cache when user leaves ThankYouPage
  useEffect(
    () => () => {
      queryClient.clear()
    },
    [],
  )
  // Does not allow navigation to this page without having gone through the refinance or reactivate flows
  if (!refinanceSubmissionData && !reactivateSubmissionData) {
    history.push('/home')
  }

  // Capture successful refinance, cashback, or reactivation with AppInsights.
  useEffect(() => {
    if (reactivateSubmissionData?.signedLoanAgreementDocumentUrl) {
      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.COMPLETED_REACTIVATION,
      })
    } else if (
      refinanceSubmissionData?.refiPayOutProcessedDebitcardResponseDto === null
    ) {
      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.COMPLETED_REFINANCE,
      })
    } else {
      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.COMPLETED_CASHBACK,
      })
    }
  }, [appInsightsContext, reactivateSubmissionData, refinanceSubmissionData])
  return hideRefinanceDetails && refi ? (
    <Container>
      <Card className="mt-4 full-page">
        <Card.Header className="text-center">
          <h1>
            <Content type={ContentSlot.REFINANCETHANKYOU_HEADER} />
          </h1>
        </Card.Header>
        <Card.Body className={`${styles.customcardbody} text-center`}>
          <Container className={styles.thankYouButton}>
            <Button data-ref="homeButton" variant="primary" onClick={goHome}>
              <Content type={ContentSlot.REFINANCETHANKYOU_BUTTON} />
            </Button>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  ) : (
    <Container>
      <Card className="my-3 text-center align-items-center">
        <Card.Header className="text-center">
          <h1>
            {refi ? (
              <Content type={ContentSlot.REFINANCETHANKYOU_HEADER} />
            ) : (
              <Content type={ContentSlot.REACTIVATIONTHANKYOU_HEADER} />
            )}
          </h1>
        </Card.Header>
        <Card.Body className={styles.card}>
          <Container
            fluid
            className={`${styles.cardContainer} card-container container-fluid border px-3 rounded`}
            data-ref="confirm-table"
          >
            {refinanceSubmissionData?.refinancedLoanWasCngVariableRateProductLoan && (
              <TwoColumnTable
                list={personalDetails}
                title={
                  refi ? (
                    <Content
                      type={ContentSlot.REFINANCETHANKYOU_DETAILSTITLE}
                    />
                  ) : (
                    <Content
                      type={ContentSlot.REACTIVATIONTHANKYOU_DETAILSTITLE}
                    />
                  )
                }
              />
            )}

            {(refinanceSubmissionData || reactivateSubmissionData) &&
              !refinanceSubmissionData?.refinancedLoanWasCngVariableRateProductLoan && (
                <TwoColumnTable
                  list={personalDetails}
                  title={
                    refi ? (
                      <Content
                        type={ContentSlot.REFINANCETHANKYOU_DETAILSTITLE}
                      />
                    ) : (
                      <Content
                        type={ContentSlot.REACTIVATIONTHANKYOU_DETAILSTITLE}
                      />
                    )
                  }
                />
              )}

            {paymentDetails.length > 0 &&
              !refinanceSubmissionData?.refinancedLoanWasCngVariableRateProductLoan && (
                <TwoColumnTable
                  list={[...paymentDetails, ...appliedDetails]}
                  title={
                    refi ? (
                      <Content
                        type={ContentSlot.REFINANCETHANKYOU_PAYMENTAPPLIED}
                      />
                    ) : (
                      <Content
                        type={ContentSlot.REACTIVATIONTHANKYOU_PAYMENTDETAILS}
                      />
                    )
                  }
                />
              )}

            {appliedDetails.length > 0 && (
              <>
                {/* <TwoColumnTable
                  list={appliedDetails}
                  title={
                    refi ? (
                      <Content
                        type={ContentSlot.REFINANCETHANKYOU_PAYMENTAPPLIED}
                      />
                    ) : (
                      <Content
                        type={ContentSlot.REACTIVATIONTHANKYOU_PAYMENTAPPLIED}
                      />
                    )
                  }
                /> */}

                {refinanceSubmissionData && (
                  <small>
                    <Content
                      type={ContentSlot.SUCCESSFULPAYMENT_FINEPRINTTEXT}
                    />
                  </small>
                )}
              </>
            )}

            <div className={`my-3 ${styles.documentDownload}`}>
              <Button
                className="text-primary p-0"
                data-ref="document-download"
                variant="link"
                onClick={toggleDocuments}
              >
                {refi ? (
                  <div>
                    <Content
                      type={ContentSlot.REFINANCETHANKYOU_DOWNLOADLOANDOCS}
                    />
                    {hideDocuments ? (
                      <AiFillCaretUp color="#5558af" size="0.8rem" />
                    ) : (
                      <AiFillCaretDown color="#5558af" size="0.8rem" />
                    )}
                  </div>
                ) : (
                  <Content
                    type={ContentSlot.REACTIVATIONTHANKYOU_DOWNLOADLOANDOCS}
                  />
                )}
              </Button>
            </div>
            {(refi || reactivation) && hideDocuments && (
              <div className={`my-3 ${styles.documentDownload}`}>
                {(refi
                  ? refinanceSubmissionData
                  : reactivateSubmissionData
                )?.signedLoanAgreementDocumentUrl.map(signedLoan => {
                  const openSignedDocument = () => {
                    const externalSignedLoanAgreement = window.open(
                      signedLoan.documentUrl,
                      '_blank',
                    )
                    if (externalSignedLoanAgreement) {
                      externalSignedLoanAgreement.focus()
                    }
                  }
                  return (
                    <div className="row justify-content-center">
                      <Button
                        className="text-primary p-0"
                        data-ref="document-download"
                        variant="link"
                        onClick={openSignedDocument}
                      >
                        {signedLoan.documentName}
                      </Button>
                    </div>
                  )
                })}
              </div>
            )}
            <div className={`my-3 ${styles.printButton}`}>
              <Button
                className="text-primary p-0"
                variant="link"
                onClick={handlePrintClick}
              >
                {refi ? (
                  <Content
                    type={ContentSlot.REFINANCETHANKYOU_PRINTBUTTONTEXT}
                  />
                ) : (
                  <Content
                    type={ContentSlot.REACTIVATIONTHANKYOU_PRINTBUTTONTEXT}
                  />
                )}
              </Button>
            </div>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Container className={styles.thankYouButton}>
            <Button data-ref="homeButton" variant="primary" onClick={goHome}>
              {refi ? (
                <Content type={ContentSlot.REFINANCETHANKYOU_BUTTON} />
              ) : (
                <Content type={ContentSlot.REACTIVATIONTHANKYOU_BUTTON} />
              )}
            </Button>
          </Container>
          <Container className="card-container my-3 text-center">
            <Content type={ContentSlot.REFINANCE_THANKS_POLICY_DESC} />
          </Container>
        </Card.Footer>
      </Card>
    </Container>
  )

  /**
   * Simple toggle function for local state
   */
  function toggleDocumentsCallback() {
    setHideDocuments(!hideDocuments)
  }

  /**
   * Opens the print dialog
   */
  function handlePrintCallback() {
    window.print()
  }

  /**
   * Clears the cache and redirects the user back to the home screen
   */
  function goHomeCallback() {
    history.push('/home')
  }

  /**
   * Generates content to be shown in thankyou table after request completes
   * @return array of ListItems
   * @param refiData refinance data from cache, undefined for reactivation flow
   * @param reactivationData reactivation data from cache, undefined for refinance flow
   * @param refiResponseData successful refinanced loan data, undefined for reactivation flow
   * @param reactResponseData successful reactivated loan data, undefined for refinance flow
   */
  function getConfirmationRowsCallback(
    refiData: RefinanceData | undefined,
    reactivationData: ReactivationData | undefined,
    refiResponseData: RefinanceLoanResponse | undefined,
    reactResponseData: OriginateLoanResponse | undefined,
  ) {
    const details = {
      personalDetails: [] as ListItem[],
      paymentDetails: [] as ListItem[],
      appliedDetails: [] as ListItem[],
    }
    const shortDayFormat = 'MM/DD/YYYY'
    // Top of Cashback refi screen Refinance Details
    if (refiData && refiResponseData) {
      details.personalDetails = [
        {
          label: 'Loan Number',
          value: `${refiResponseData.loanId}`,
        },
      ]

      if (
        refiResponseData.refiPayOutProcessedDebitcardResponseDto &&
        Boolean(refiData.cashback)
      ) {
        details.personalDetails = [
          ...details.personalDetails,
          {
            label: 'Amount You Will Receive',
            value: formatCurrency(refiData.cashback.cashbackAmount),
          },
          {
            label: 'How You Will Receive Your Cash',
            value: `${refiData.cashback.disbursementType} ending in ${refiData.cashback.disbursementLast4}`,
          },
        ]
      }
      if (refi) {
        details.personalDetails.push({
          label: 'First Payment Due Date',
          value: `${dayjs(refi.refinance.firstPaymentDate).format(
            'dddd',
          )}${' '}${dayjs(refi.refinance.firstPaymentDate).format(
            shortDayFormat,
          )}`,
        })
      }
      if (
        refiResponseData.refiPaymentReceiptDto &&
        refiData.refinance &&
        !refiResponseData.refinancedLoanWasCngVariableRateProductLoan
      ) {
        details.personalDetails.push(
          {
            label: 'Payment Method',
            value: `${refiData.refinance.cardType} ending in ${refiData.refinance.cardTokenLast4}`,
          },
          {
            label: 'Amount Paid By You',
            value: formatCurrency(
              refiResponseData.refiPaymentReceiptDto.paymentAmount,
            ),
          },
        )
      }

      if (!refiResponseData.refinancedLoanWasCngVariableRateProductLoan) {
        details.personalDetails.push({
          label: 'Date',
          value: `${dayjs(Date.now()).format(shortDayFormat)}`,
        })
      }

      if (
        refiResponseData.refiPaymentProcessedDebitcardResponseDto &&
        !refiResponseData.refinancedLoanWasCngVariableRateProductLoan
      ) {
        details.personalDetails.push({
          label: 'Reference Number',
          value: `${refiResponseData.refiPaymentProcessedDebitcardResponseDto.authorizationNumber}`,
        })
      }
      // Bottom of Cashback refi screen How your payment was applied

      if (
        refiResponseData.refiPaymentReceiptDto?.transactions.length &&
        !refiResponseData.refinancedLoanWasCngVariableRateProductLoan
      ) {
        details.paymentDetails = [
          ...details.paymentDetails,
          ...refiResponseData.refiPaymentReceiptDto.transactions.map(
            ({amount, label}) => ({label, value: formatCurrency(amount)}),
          ),
        ]
      }

      details.appliedDetails.push({
        label: 'Loan Balance As Of Today',
        value: `${formatCurrency(
          refiResponseData.refiPaymentReceiptDto?.currentBalance ?? 0,
        )}`,
      })
      // If (
      //   refiResponseData.refiPaymentReceiptDto &&
      //   refiResponseData.loanOriginationReceiptDto
      // ) {
      //   details.appliedDetails = [
      //     {
      //       label: 'Finance Charges*',
      //       value: `${formatCurrency(
      //         refiResponseData.refiPaymentReceiptDto.financeCharges,
      //       )}`,
      //     },
      //   ]
      //   if (refiResponseData.refiPaymentReceiptDto.principalAmount > 0) {
      //     details.appliedDetails.push({
      //       label: 'Principal Amount',
      //       value: `${formatCurrency(
      //         refiResponseData.refiPaymentReceiptDto.principalAmount,
      //       )}`,
      //     })
      //   }
      //   if (refiResponseData.refiPaymentReceiptDto.collectionFees > 0) {
      //     details.appliedDetails.push({
      //       label: 'Collection Fees',
      //       value: `${formatCurrency(
      //         refiResponseData.refiPaymentReceiptDto.collectionFees,
      //       )}`,
      //     })
      //   }
      //   details.appliedDetails.push({
      //     label: 'Loan Balance As Of Today',
      //     value: `${formatCurrency(
      //       refiResponseData.loanOriginationReceiptDto.total,
      //     )}`,
      //   })
      // }
    } else if (reactivationData && reactResponseData) {
      details.personalDetails = [
        {
          label: 'Loan Number',
          value: `${reactResponseData.loanId}`,
        },
        {
          label: 'Amount Financed',
          value: `${formatCurrency(reactivationData.loanAmount)}`,
        },
        {
          label: 'How You Will Receive Your Cash',
          value: `${reactivationData.disbursementCard.cardType} ending in ${reactivationData.disbursementCard.cardTokenLast4}`,
        },
        {
          label: 'Date',
          value: `${dayjs(Date.now()).format('MM/DD/YYYY')}`,
        },
        {
          label: 'Reference Number',
          value: `${reactResponseData.payOutPaymentProcessedDebitcardResponseDto.authorizationNumber}`,
        },
        {
          label: 'First Payment Due Date',
          value: `${dayjs(reactivation?.firstPaymentDate).format(
            'dddd',
          )}${' '}${dayjs(reactivation?.firstPaymentDate).format(
            shortDayFormat,
          )}`,
        },
      ]
    }
    return details
  }
}

/**
 * Refinance Success page
 */
export default ThankYouPage
