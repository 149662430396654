import React, {useCallback} from 'react'
import CashwisePromoCodeForm from 'components/Form/CashwisePromoCodeForm'
import {Button} from 'react-bootstrap'
import AbstractPopup from 'components/Popup/'

/**
 *  FindMyAccountPopupProps
 */
export interface FindMyAccountPopupProps {
  show: boolean
  close(visibility: boolean): void
}

const FindMyAccountPopup: React.FC<FindMyAccountPopupProps> = ({
  close,
  show,
}) => {
  const toggleVisibility = useCallback(() => close(false), [close])

  const content = (
    <div className="text-left p-2" data-ref="find-my-account-modal">
      <h5 className="font-weight-bold">
        Did you receive a letter in the mail with an access code? Enter it
        below to proceed.
      </h5>
      <CashwisePromoCodeForm />
      <Button block className="mt-2" variant="link" onClick={toggleVisibility}>
        Close
      </Button>
    </div>
  )
  return (
    <AbstractPopup
      content={content}
      handleClose={toggleVisibility}
      visibility={show}
      warning={false}
    />
  )
}

/**
 * Modal to be used
 */
export default FindMyAccountPopup
