import {useContext} from 'react'

import {useQuery} from 'react-query'
import {ToastContext} from 'components/Toast'
import {vergentService} from '../vergent-service'

/**
 * Retrieves cached Card types data or re-fetches
 * @return data and fetching status
 */
export function useGetCardType() {
  const {pushToast} = useContext(ToastContext)

  const {data, isLoading} = useQuery<{data: {[key: string]: number}}>({
    queryKey: ['getCardType'],
    staleTime: 24 * (60 * (60 * 1000)),
    queryFn: async () =>
      vergentService.get('/VergentLMS/GetCustomerCardTypes'),
    onError: () => {
      pushToast({
        title: 'Unable to load loan card types',
        variant: 'danger',
      })
    },
  })

  return {
    data,
    isLoading,
  }
}
