import type {UserProfileResponse} from 'models/Edge'
import {VergentError} from 'models/Errors'
import * as FullStory from '@fullstory/browser'
import {vergentService} from 'utils/vergent-service'
import {clearUtmFromLocalStorage} from 'utils/analytics'
import {AuthActionsType, AuthActionTypes} from './useAuthReducer'
import {getIdFromUserNameKey, getUsername} from 'utils/manage-username'
import {hasValidStoragedToken} from 'utils/has-valid-storaged-token'

interface VerifySessionArgs {
  logout: () => void
  dispatchAuthState: React.Dispatch<AuthActionsType>
  isInitialVerification: boolean
  isLoggedIn: boolean
}

const handleInvalidSession = ({
  logout,
  dispatchAuthState,
  isInitialVerification,
}: Omit<VerifySessionArgs, 'isLoggedIn'>) => {
  if (isInitialVerification) {
    FullStory.anonymize()
    clearUtmFromLocalStorage()
    localStorage.clear()
    dispatchAuthState({
      type: AuthActionTypes.Initialize,
      payload: {
        user: null,
        isLoggedIn: false,
      },
    })
  } else {
    logout()
  }
}

/**
 * Verifies the user session by the token expiration and refresh the profile info
 */
export const verifySession = async ({
  logout,
  dispatchAuthState,
  isInitialVerification,
  isLoggedIn,
}: VerifySessionArgs) => {
  if (!isLoggedIn && !isInitialVerification) return
  try {
    if (!hasValidStoragedToken()) {
      handleInvalidSession({logout, dispatchAuthState, isInitialVerification})
      return
    }

    const custId = getIdFromUserNameKey()
    const {
      data: {customerId, email},
    } = await vergentService.get<UserProfileResponse>(
      `/VergentCustomer/UserProfile/${custId}`,
    )

    if (isInitialVerification) {
      dispatchAuthState({
        type: AuthActionTypes.Initialize,
        payload: {
          user: {
            email,
            username: getUsername(String(customerId)),
            personReferenceNumber: String(customerId),
          },
          isLoggedIn: true,
        },
      })
    } else {
      dispatchAuthState({
        type: AuthActionTypes.RefreshUser,
        payload: {
          user: {
            email,
            username: getUsername(String(customerId)),
            personReferenceNumber: String(customerId),
          },
        },
      })
    }
  } catch (error) {
    if (error instanceof VergentError) {
      console.error(error)
    }
    handleInvalidSession({logout, dispatchAuthState, isInitialVerification})
  }
}
