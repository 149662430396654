import {QueryClient} from 'react-query'

/**
 * Query client.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  },
})
