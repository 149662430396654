import React, {useEffect, useState, useCallback} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'

import {ContentSlot} from 'models/Content'
import Content from 'components/Content'
import FindMyAccountPopup from 'components/Popup/FindMyAccountPopup'
import UhOh from 'components/UhOh'
import {AnalyticsPageNames} from 'models/Analytics'
import {getConfigValue} from 'utils/environment'

const NoLoans: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.NO_LOANS,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State
   */
  const [findAccountShown, setFindAccountShown] = useState(false)
  const cashwiseFeatureFlag: boolean =
    getConfigValue('CASHWISE_ENABLED') === 'true'

  /**
   * Callbacks
   */
  const toggleVisibility = useCallback(
    () => setFindAccountShown(prev => !prev),
    [setFindAccountShown],
  )

  return cashwiseFeatureFlag ? (
    <UhOh
      logoutOnBack
      alternativeSummary={
        <>
          <p>
            <Content type={ContentSlot.NOLOANS_SPLIT_SUMMARY_ONE} />
          </p>
          <p className="mt-3 mb-3">
            If you received a letter in the mail with an access code,{' '}
            <span
              className="text-primary cursor-pointer"
              onClick={toggleVisibility}
            >
              click here to enter the code.
            </span>
          </p>
          <p>
            <Content type={ContentSlot.NOLOANS_SPLIT_SUMMARY_TWO} />
          </p>
          {findAccountShown && (
            <FindMyAccountPopup
              close={toggleVisibility}
              show={findAccountShown}
            />
          )}
        </>
      }
      back={ContentSlot.NOLOANS_BACK}
      callUs={ContentSlot.NOLOANS_CALLUS}
      emailUs={ContentSlot.NOLOANS_EMAILUS}
      isIneligible={false}
      representative={ContentSlot.NOLOANS_REPRESENTATIVE}
      title={ContentSlot.NOLOANS_TITLE}
      visitAStore={ContentSlot.NOLOANS_VISITASTORE}
    />
  ) : (
    <UhOh
      logoutOnBack
      back={ContentSlot.NOLOANS_BACK}
      callUs={ContentSlot.NOLOANS_CALLUS}
      cta={ContentSlot.NOLOANS_CTA}
      emailUs={ContentSlot.NOLOANS_EMAILUS}
      isIneligible={false}
      representative={ContentSlot.NOLOANS_REPRESENTATIVE}
      summary={ContentSlot.NOLOANS_SUMMARY}
      title={ContentSlot.NOLOANS_TITLE}
      visitAStore={ContentSlot.NOLOANS_VISITASTORE}
    />
  )
}

/**
 * Screen that shows when your account is locked due to not finding a loan
 */
export default NoLoans
