import React from 'react'
import {LocalStorageKeys} from 'utils/common'
import {AuthActionsType, AuthActionTypes} from '../useAuthReducer'
import type {
  AuthenticateRequest,
  AuthenticateResponse,
  RemoteAddressResponse,
} from 'models/Auth'
import {vergentService} from 'utils/vergent-service'
import {StandardError, VergentError} from 'models/Errors'
import {saveUsername} from 'utils/manage-username'
import axios from 'axios'

interface LoginActionArgs {
  loginRequestBody: AuthenticateRequest
  dispatchAuthState: React.Dispatch<AuthActionsType>
}

/**
 * Login error types
 */
export enum LoginErrorTypes {
  InvalidUserNameOrPassword = 'INVALID_USERNANE_OR_PASSWORD',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
  AccountLockedError = 'ACCOUNT_LOCKED_ERROR',
  MFAError = 'MFA_ERROR',
}

const errorMessages = {
  default: 'There was an error logging in.',
  accountLocked: 'The account is locked.',
}

/**
 * Log in action
 */
export const loginAction = async ({
  loginRequestBody,
  dispatchAuthState,
}: LoginActionArgs) => {
  let isInvalidUsernameOrPassword = false
  let isAccountLocked = false
  let customerId = 0
  let email = null
  // Using the endpoint /VergentAuthenticateUser to get an auth token
  try {
    const {
      data: {ip},
    } = await axios.get<RemoteAddressResponse>(
      'https://api.ipify.org/?format=json',
    )

    const {
      data: {
        token,
        isAccountLocked: _isAccountLocked,
        customerId: _customerId,
        email: _email,
      },
    } = await vergentService.post<AuthenticateResponse>(
      '/VergentAuthenticateUser',
      {...loginRequestBody, remoteAddress: ip},
    )
    if (!token) isInvalidUsernameOrPassword = true
    isAccountLocked = _isAccountLocked
    localStorage.setItem(LocalStorageKeys.AccessToken, token)
    customerId = _customerId
    email = _email
  } catch (error) {
    const {
      errorInstance,
      errorWasHandled,
      errorMessage,
    } = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: LoginErrorTypes.InternalServerError,
        trackableMessage: errorInstance.message,
      })
    }

    throw new StandardError({
      type: LoginErrorTypes.VergentServiceError,
      trackableMessage: errorMessage ?? errorMessages.default,
    })
  }
  // Throw an error if th account is locked
  if (isAccountLocked) {
    localStorage.clear()
    throw new StandardError({
      type: LoginErrorTypes.AccountLockedError,
      trackableMessage: errorMessages.accountLocked,
    })
  }
  // Throw an error if the username or password is invalid
  if (isInvalidUsernameOrPassword) {
    localStorage.clear()
    throw new StandardError({
      type: LoginErrorTypes.InvalidUserNameOrPassword,
      trackableMessage: errorMessages.default,
    })
  }

  try {
    saveUsername(loginRequestBody.userName, String(customerId))

    dispatchAuthState({
      type: AuthActionTypes.Login,
      payload: {
        user: {
          email,
          username: loginRequestBody.userName,
          personReferenceNumber: String(customerId),
        },
      },
    })
  } catch (error) {
    const {
      errorInstance,
      errorWasHandled,
      errorMessage,
    } = error as VergentError

    localStorage.clear()

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: LoginErrorTypes.InternalServerError,
        trackableMessage: errorInstance.message,
      })
    }

    throw new StandardError({
      type: LoginErrorTypes.VergentServiceError,
      trackableMessage: errorMessage ?? errorMessages.default,
    })
  }
}
