/**
 * Request body from the endpoint /VergentCustomer/Customer/Cards
 */
export interface CreditCardRequest {
  cardType: number
  cardHolderName: string
  lastFourCardNumber: string
  expireMonth: number
  expireYear: number
  ccv?: string
  cardReference: string
  isEligibleForDisbursement: boolean
  authToken: string
  isOneTimeDebitCard: boolean
  customerId: number
}

/**
 * Enum of possible credit cards
 */
export enum TypeCreditCard {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  AMERICAN_EXPRESS = 'AMERICAN-EXPRESS',
  DISCOVER = 'DISCOVER',
  DINERS = 'DINERS',
  DINERS_CLUB = 'DINERS-CLUB',
  JCB = 'JCB',
  INSIGHT = 'INSIGHT',
  DEFAULT = 'DEFAULT',
}
