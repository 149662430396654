import React, {useCallback} from 'react'

import {Card, Col, Container, Row} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {formatCurrency} from 'utils/data-formatting'

import {CustomerDetails, ReactivationApprovalDetails} from 'models/Edge'
import {buildReactivationDetails} from 'utils/build-reactivation-details'
import {reactivationCreateLoan} from 'utils/edge'
import {useMutation} from 'react-query'
import SpinnerButton from 'components/SpinnerButton'
import {setReactivationCreateData} from 'utils/cache'

interface Props {
  approvalDetails: ReactivationApprovalDetails[]
  customerDetails: CustomerDetails
}

const InactiveLoanCard: React.FC<Props> = ({
  approvalDetails,
  customerDetails,
}) => {
  const history = useHistory()
  const reactivationForward = useCallback(handleReactivationForward, [])
  const {mutate: reactivationCreateLoanMutation, isLoading} = useMutation(
    reactivationCreateLoan,
    {
      onSuccess: data => {
        setReactivationCreateData({
          applicationId: data.data.applicationId,
          disbursementType: data.data.disbursementType,
          primaryApplicant: data.data.primaryApplicant,
        })
        history.push('/confirm-information')
      },
    },
  )
  return (
    <Card className="full-page text-center">
      <Container>
        <Row noGutters className="justify-content-center">
          <Col xl="6" xs="12">
            <div className="family-bold mb-1">
              <Content type={ContentSlot.NOLOANFOUND_TITLE} />
            </div>
            <div className="h1 family-bold mb-2 text-primary">
              <Content type={ContentSlot.NOLOANFOUND_PRIMARY_DESCRIPTION} />{' '}
              {formatCurrency(
                Math.max.apply(
                  Math,
                  approvalDetails.map(detail => detail.approvedAmount),
                ),
              )}
              !
            </div>
            <div>
              <Content type={ContentSlot.NOLOANFOUND_SECONDARYDESCRIPTION} />
            </div>
          </Col>
        </Row>
        <Row noGutters className="justify-content-center">
          <Col lg="8" md="11" xs="12">
            <SpinnerButton
              loading={isLoading}
              size="lg"
              variant="primary"
              onClick={reactivationForward}
            >
              <Content type={ContentSlot.NOLOANFOUND_BUTTON} />
            </SpinnerButton>
          </Col>
        </Row>
      </Container>
    </Card>
  )

  /**
   * Handles the reactivation forward button
   */
  function handleReactivationForward() {
    const reactivationDetails = buildReactivationDetails(customerDetails)
    reactivationCreateLoanMutation(
      reactivationDetails.primaryApplicant.customerId,
    )
  }
}
/**
 * InactiveLoan page - no active loan at this time for the customer
 */
export default InactiveLoanCard
