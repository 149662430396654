import {queryClient} from 'client'
import {
  CustomerDetailsResponse,
  OriginateLoanResponse,
  PostPaymentResponse,
  ReactivationCreateBody,
  RefinanceLoanResponse,
} from 'models/Edge'

/**
 * Card data for submitting a payment or requesting a disbursement
 */
export interface CardData {
  cardCvv?: string
  cardId?: number
  cardType: string // Pulled from currentLoanDetails.cardType
  cardToken: number // Pulled from currentLoanDetails.collateralCreditCardToken
  cardTokenLast4: string // Pulled from currentLoanDetails.collateralCreditCardTokenLast4
  cardIsFundable?: boolean // Pulled from currentLoanDetails.isFundable
}

/**
 * Data for submitting a payment
 */
export interface PaymentData extends CardData {
  paymentAmount: number
  customerName: string
}

/**
 * Data for offers on reactivation
 */
export interface OfferData {
  id: number
  name: string
  range: {
    min: number
    max: number
  }
}
/**
 * Data for requesting cashback
 */
export interface CashbackPaymentData extends CardData {
  paymentAmount: number
  cashbackAmount: number
  disbursementLast4: string
  disbursementType: string
}

/**
 * Data for requesting refinance
 */
export interface RefinancePaymentData extends CardData {
  paymentAmount: number
  customerName: string
  firstPaymentDate: string
  storeId?: string
  paymentFrequencyId: number
  companyId?: number
  isRefi?: boolean
  loanModelId?: number
  disbursementCardId?: number
  autoPaymentCardId?: number
  autoPaymentCard?: CardData
  autopay?: boolean
  isTitleLoan?: boolean
}

/**
 * Data for requesting refinance
 */
export interface RefinanceData {
  cashback: CashbackPaymentData
  loanApplicationId: number
  loanId: number
  numberOfPeriods: number
  option: string
  personReferenceNumber: string
  personId: string
  productId: number
  refinance: RefinancePaymentData
}

/**
 * Data for requesting reactivation
 */
export interface ReactivationData {
  applicationId: string
  loanModelId: number
  firstPaymentDate: string
  loanAmount: number
  isAutoPay: boolean
  drawAmount: number
  disbursementType: number
  disbursementId: number
  paymentType: number
  fundingMethod: number
  prevLoanId: number
  numberOfPeriods: number
  personReferenceNumber: string
  personId: string
  productId: number
  disbursementCard: CardData
}

/**
 * Interface that matches PaymentReceiptPopUpData
 */
export interface PaymentReceiptPopUpData {
  paymentAmount: number | undefined
  dueDate: Date | string | undefined
}

/**
 * Interface for one time payment data
 */
export interface OneTimePaymentData {
  isOneTimePaymentInitialized: boolean
  cardId: number | undefined
}

const paymentDataQueryKey = 'payment'
const getLoanDetailsQueryKey = 'loan'
const paymentReceiptDataQueryKey = 'paymentReceipt'
const paymentReceiptPopUpDataQueryKey = 'paymentReceiptPopUp'
const refinanceDataQueryKey = 'refinance'
const reactivationDataQueryKey = 'reactivate'
const reactivationOffersQueryKey = 'reactivationOffers'
const refinanceLoanResponse = 'refinanceLoanResponse'
const reactivationLoanResponse = 'reactivationLoanResponse'
const reactivationCreateDataQueryKey = 'reactivationCreate'

interface UpdateLoanDetailsArgs {
  customerId: string
  getNewLoanDetails: (
    prevLoanDetails: CustomerDetailsResponse,
  ) => CustomerDetailsResponse
}

/**
 * Function to update loan details response stored in the cache
 */
export const updateLoanDetails = ({
  customerId,
  getNewLoanDetails,
}: UpdateLoanDetailsArgs) => {
  const prevLoanDetails =
    queryClient.getQueryData([getLoanDetailsQueryKey, customerId]) ?? {}

  queryClient.setQueryData(
    [getLoanDetailsQueryKey, customerId],
    getNewLoanDetails(prevLoanDetails as CustomerDetailsResponse),
  )
}

/**
 * Stores payment receipt data
 * @param paymentReceiptData the payload to be stored in the cache
 */
export function setPaymentReceiptData(
  paymentReceiptData: PostPaymentResponse | undefined,
) {
  queryClient.setQueryData<PostPaymentResponse | undefined>(
    paymentReceiptDataQueryKey,
    paymentReceiptData,
  )
}

/**
 * Stores response from vergen application to use on reactivation flow
 * @param reactivationCreateData response from Vergent Application
 */
export function setReactivationCreateData(
  reactivationCreateData: ReactivationCreateBody | undefined,
) {
  queryClient.setQueryData<ReactivationCreateBody | undefined>(
    reactivationCreateDataQueryKey,
    reactivationCreateData,
  )
}

/**
 * Stores payment receipt data
 * @param paymentReceiptPopUpData the payload to be stored in the cache
 */
export function setPaymentReceiptPopUpData(
  paymentReceiptPopUpData: PaymentReceiptPopUpData | undefined,
) {
  queryClient.setQueryData<PaymentReceiptPopUpData | undefined>(
    paymentReceiptPopUpDataQueryKey,
    paymentReceiptPopUpData,
  )
}

/**
 * Stores a structure with payment info for the payment flow
 * @param payload the payload to be stored in the cache
 */
export function setPaymentData(payload: PaymentData | undefined) {
  queryClient.setQueryData(paymentDataQueryKey, payload)
}

/**
 * Updates the amount only for payment data stored in cache
 * @param paymentAmount the amount to set the cache value to
 * @param customerName the name of the customer
 */
export function setPartialPaymentData(
  paymentAmount: number,
  customerName: string,
) {
  const data = queryClient.getQueryData<PaymentData>(paymentDataQueryKey)
  queryClient.setQueryData(paymentDataQueryKey, {
    ...data,
    paymentAmount,
    customerName,
  })
}

/**
 * Stores the successful response data in the cache
 * @param data The successful response data from the confirm-reactivate/refinance endpoints
 * @param queryString The query string used by the cache
 */
export function setSuccessfulResponse(
  data: RefinanceLoanResponse | OriginateLoanResponse,
  queryString: string,
) {
  queryClient.setQueryData(queryString, data)
}

/**
 * Gets the RefinanceLoanResponse from the cache
 * @return Returns the RefinanceLoanResponse
 */
export function getRefinanceResponse(): RefinanceLoanResponse | undefined {
  return queryClient.getQueryData<RefinanceLoanResponse | undefined>(
    refinanceLoanResponse,
  )
}

/**
 * Get the offers from cache
 * @return Returns the Offers data
 */
export function getReactivationOffers(): OfferData[] | undefined {
  return queryClient.getQueryData<OfferData[] | undefined>(
    reactivationOffersQueryKey,
  )
}

/**
 * Gets the RefinanceLoanResponse from the cache
 * @return Returns the RefinanceLoanResponse
 */
export function getReactivateResponse(): OriginateLoanResponse | undefined {
  return queryClient.getQueryData<OriginateLoanResponse | undefined>(
    reactivationLoanResponse,
  )
}

/**
 * Get the reactivation data to use on reactivation flow
 * @return Returns the reactivation data for reactivation flow
 */
export function getReactivationCreacteData():
  | ReactivationCreateBody
  | undefined {
  return queryClient.getQueryData<ReactivationCreateBody | undefined>(
    reactivationCreateDataQueryKey,
  )
}

/**
 * Retrieves a structure with payment receipt data
 * @return PostPaymentResponse
 */
export function getPaymentReceiptData(): PostPaymentResponse | undefined {
  return queryClient.getQueryData<PostPaymentResponse>(
    paymentReceiptDataQueryKey,
  )
}

/**
 * Retrieves a structure with payment receipt data
 * @return PostPaymentResponse
 */
export function getPaymentReceiptPopUpData():
  | PaymentReceiptPopUpData
  | undefined {
  return queryClient.getQueryData<PaymentReceiptPopUpData>(
    paymentReceiptPopUpDataQueryKey,
  )
}

/**
 * Retrieves a structure with payment info for the payment flow
 * @return PaymentData
 */
export function getPaymentData(): PaymentData | undefined {
  return queryClient.getQueryData<PaymentData>(paymentDataQueryKey)
}

/**
 * Takes payment data from adding a card and applies it to chosen refinance option
 */
export function movePaymentDataIntoRefinanceData() {
  const paymentData = queryClient.getQueryData<PaymentData>(
    paymentDataQueryKey,
  )
  const refinanceData = queryClient.getQueryData<RefinanceData>(
    refinanceDataQueryKey,
  )

  if (refinanceData) {
    const refiData = {
      ...refinanceData.refinance,
      ...paymentData,
    } as RefinancePaymentData
    setRefinanceData(refiData)

    if (refinanceData.option === 'cashback') {
      const cashbackData = {
        ...refinanceData.cashback,
      } as CashbackPaymentData
      setCashbackData(cashbackData)
    }
  }
}

/**
 * Stores a structure with payment info for the Cashback flow
 * @param payload the payload to be stored in the cache
 */
export function setCashbackData(payload: CashbackPaymentData) {
  const data = queryClient.getQueryData<RefinanceData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    cashback: payload,
  })
}

/**
 * Stores a structure with payment info for the Refinance flow
 * @param payload the payload to be stored in the cache
 */
export function setRefinanceData(payload: RefinancePaymentData) {
  const data = queryClient.getQueryData<RefinanceData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    refinance: payload,
  })
}

/**
 * Sotres a structure of reactivation offers
 * @param payload payload to be stored in cache
 */
export function setReactivationOffers(payload: OfferData[]) {
  queryClient.setQueryData(reactivationOffersQueryKey, payload)
}

/**
 * Stores the refinance option that was chosen
 * @param option 'cashback' | 'refinance' | 'reactivate'
 */
export function setRefinanceOption(option: string) {
  const data = queryClient.getQueryData<PaymentData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    option,
  })
}

/**
 * Stores the refinance product id that was chosen
 * @param details all of the required existing details needed for refinance flow
 */
export function setRefinanceDetails({
  loanId,
  numberOfPeriods,
  personReferenceNumber,
  personId,
}: {
  loanId: number
  numberOfPeriods: number
  personReferenceNumber: string
  personId: string | undefined
}) {
  const data = queryClient.getQueryData<PaymentData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    loanId,
    numberOfPeriods,
    personReferenceNumber,
    personId,
  })
}

/**
 * Stores the refinance product id that was chosen
 * @param details all of the required existing details needed for refinance flow
 */
export function setReactivationData({
  loanAmount,
  firstPaymentDate,
  applicationId,
  prevLoanId,
  isAutoPay,
  fundingMethod,
  loanModelId,
  disbursementType,
  drawAmount,
  paymentType,
  disbursementId,
  numberOfPeriods,
  personReferenceNumber,
  personId,
  productId,
  disbursementCard,
}: ReactivationData) {
  queryClient.setQueryData(reactivationDataQueryKey, {
    loanAmount,
    applicationId,
    firstPaymentDate,
    disbursementId,
    isAutoPay,
    fundingMethod,
    loanModelId,
    disbursementType,
    drawAmount,
    paymentType,
    prevLoanId,
    numberOfPeriods,
    personReferenceNumber,
    personId,
    productId,
    disbursementCard,
  })
}

/**
 * Set productId for reactivation
 * @param productId ProductId to set
 */
export function setReactivationProductId(productId: number) {
  const data = queryClient.getQueryData<ReactivationData>(
    reactivationDataQueryKey,
  )
  queryClient.setQueryData(reactivationDataQueryKey, {
    ...data,
    productId,
  })
}

/**
 * Set loan amount for reactivation
 * @param loanAmount Loan amount to set
 */
export function setReactivationLoanAmount(loanAmount: number) {
  const data = queryClient.getQueryData<ReactivationData | undefined>(
    reactivationDataQueryKey,
  )
  queryClient.setQueryData(reactivationDataQueryKey, {
    ...data,
    loanAmount,
  })
}

/**
 * Stores a date for the first payment in a refinance
 * @param payload Date
 */
export function setRefiFirstPaymentDate(payload: string) {
  const data = queryClient.getQueryData<RefinanceData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    refinance: {
      ...data?.refinance,
      firstPaymentDate: payload,
    },
  })
}

/**
 * Stores partial refinance payment data
 * @param payload partial refinance payment data
 */
export function setPartialRefinancePaymentData(
  payload: Partial<RefinancePaymentData>,
) {
  const data = queryClient.getQueryData<RefinanceData>(refinanceDataQueryKey)
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    refinance: {
      ...data?.refinance,
      ...(payload as RefinancePaymentData),
    },
  })
}

/**
 * Retrieves stored data for refinance
 * @return RefinanceData
 */
export function getRefinanceData(): RefinanceData | undefined {
  return queryClient.getQueryData<RefinanceData>(refinanceDataQueryKey)
}

/**
 * Retrieves stored data for refinance
 * @param cashbackAmount to set
 */
export function setCashbackAmount(cashbackAmount: number): void {
  const data = queryClient.getQueryData<RefinanceData | undefined>(
    refinanceDataQueryKey,
  )
  queryClient.setQueryData(refinanceDataQueryKey, {
    ...data,
    cashback: {
      ...data?.cashback,
      cashbackAmount,
    },
  })
}

/**
 * Retrieves stored data for reactivation
 * @return RefinanceData
 */
export function getReactivationData(): ReactivationData | undefined {
  return queryClient.getQueryData<ReactivationData>(reactivationDataQueryKey)
}

/**
 * Clears all "flow" data in the cache
 */
export function clearFlowData() {
  queryClient.removeQueries(paymentDataQueryKey)
  queryClient.removeQueries(paymentReceiptDataQueryKey)
  queryClient.removeQueries(paymentReceiptPopUpDataQueryKey)
  queryClient.removeQueries(refinanceDataQueryKey)
  queryClient.removeQueries(reactivationDataQueryKey)
  queryClient.removeQueries(refinanceLoanResponse)
  queryClient.removeQueries(reactivationLoanResponse)
  queryClient.removeQueries(reactivationCreateDataQueryKey)
}
