import React from 'react'
import styles from './FormTitle.module.scss'

type FormTitleProps = React.HTMLAttributes<HTMLHeadingElement>

const FormTitle = ({className, children, ...properties}: FormTitleProps) => (
  <h1 {...properties} className={`${styles.title} ${className ?? ''}`}>
    {children}
  </h1>
)

/**
 * FormTitle component
 */
export default FormTitle
