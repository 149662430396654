import React from 'react'

import {Row, Col} from 'react-bootstrap'

const AccountInfoReadOnly: React.FC = () => (
  <>
    <Row>
      <Col>
        <p className="mb-1">Password</p>
        <p>
          <b>■ ■ ■ ■ ■ ■ ■ ■ ■ ■</b>
        </p>
      </Col>
    </Row>
  </>
)

/**
 * Account Info Read Only
 */
export default AccountInfoReadOnly
