import axios, {AxiosRequestConfig} from 'axios'
import {getConfigValue} from 'utils/environment'
import {LocalStorageKeys} from 'utils/common'
import {appInsights} from 'AppInsights'

const customerPortalServiceUrl = getConfigValue('VERGENT_SERVICE_URL')
const customerServiceUrl = `${customerPortalServiceUrl}/api/v1`
const vergentServiceBaseUrl = `${customerPortalServiceUrl}/vergent/v1`
const contenType = 'application/json'

/**
 * Vergent service (Axios instance)
 */
export const vergentService = axios.create({
  baseURL: vergentServiceBaseUrl,
  headers: {'Content-type': contenType},
})

vergentService.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken) ?? ''
  config.headers.Authorization = `Bearer ${accessToken}`
  config.headers.OAuthToken = accessToken

  if (config.url?.startsWith(vergentServiceBaseUrl) && appInsights.context) {
    config.headers.session_Id = appInsights.context.getSessionId()
  }

  return config
})

/**
 * Customer Portal service (Axios instance)
 */
export const customerPortalService = axios.create({
  baseURL: customerServiceUrl,
  headers: {'Content-type': contenType},
})

vergentService.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken) ?? ''
  config.headers.Authorization = `Bearer ${accessToken}`

  if (config.url?.startsWith(customerServiceUrl) && appInsights.context) {
    config.headers.session_Id = appInsights.context.getSessionId()
  }

  return config
})
