import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import {Button} from 'react-bootstrap'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import {useLoanData} from 'utils/hooks/useLoanData'
import {
  formatPhone,
  getClosingStoreHours,
  getOpeningStoreHours,
} from 'utils/data-formatting'
/**
 * CRN Popup props interface
 */
export interface RescissionPopupProps {
  visibility: boolean
  toggleVisiblity(visibility: boolean): void
}

const RescissionPopup: React.FC<RescissionPopupProps> = props => {
  const {currentLoan} = useLoanData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)

  const handleClose = useCallback(() => props.toggleVisiblity(false), [props])
  const openingHours = store ? getOpeningStoreHours(store) : ''
  const closingHours = store ? getClosingStoreHours(store) : ''
  const rescissionContent = (
    <div className="pt-2">
      Congrats on your new loan. If you are making a payment within the first
      three days we request that you contact us via phone to make a payment via
      debit card.{' '}
      {store && openingHours && closingHours && (
        <>
          Please contact us at{' '}
          <a href={`tel:${store.phone}`}>{formatPhone(store.phone)}</a>
          {currentLoan?.isOnlineStore
            ? '.'
            : ` from ${openingHours} am to ${closingHours} pm ${store.timezone.name}. `}
        </>
      )}
      {store && !openingHours && !closingHours && (
        <>
          Please contact us at{' '}
          <a href={`tel:${store.phone}`}>{formatPhone(store.phone)}</a> during
          store hours to make a payment on your loan.
        </>
      )}
      <div className="text-center mt-2">
        <Button variant="link" onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  )
  return (
    <>
      <AbstractPopup
        content={rescissionContent}
        handleClose={handleClose}
        visibility={props.visibility}
        warning={false}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default RescissionPopup
