import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'
import {setRefinanceDetails, setRefinanceOption} from 'utils/cache'
import {useHistory} from 'react-router'

interface RefinanceDetails {
  hasCashbackOption: boolean
  hasRefinanceOption: boolean
  loanId: number
  numberOfPeriods: number
  personReferenceNumber: string
  personId: string | undefined
}

/**
 * UPTN Popup Props interface
 */
export interface UPTNPopupProps {
  refinanceDetails?: RefinanceDetails | null
  visibility: boolean
  toggleVisiblity(visibility: boolean): void
}

const UPTNPopup: React.FC<UPTNPopupProps> = props => {
  const history = useHistory()
  const handleClose = useCallback(() => props.toggleVisiblity(false), [props])
  const navigateToRefinancePage = useCallback(
    navigateToRefinancePageCallback,
    [],
  )

  /**
   * Navigates based on refi options
   */
  function navigateToRefinancePageCallback() {
    if (props.refinanceDetails) {
      const {hasCashbackOption} = props.refinanceDetails
      const {hasRefinanceOption} = props.refinanceDetails

      const refiDetails = {
        loanId: props.refinanceDetails.loanId,
        numberOfPeriods: props.refinanceDetails.numberOfPeriods,
        personReferenceNumber: props.refinanceDetails.personReferenceNumber,
        personId: props.refinanceDetails.personId,
      }
      setRefinanceDetails({...refiDetails})

      if (hasCashbackOption && hasRefinanceOption) {
        history.push('/choose-refinance')
      } else if (hasCashbackOption && !hasRefinanceOption) {
        setRefinanceOption('cashback')
        history.push('/confirm-information')
      } else if (!hasCashbackOption && hasRefinanceOption) {
        setRefinanceOption('refinance')
        history.push('/confirm-information')
      }
    }
  }

  const uptnContent = (
    <>
      <Content type={ContentSlot.CFPB_UPTN_POPUP} />
      <p>
        <Button variant="link" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={navigateToRefinancePage}>
          Refinance Now!
        </Button>
      </p>
    </>
  )
  return (
    <>
      <AbstractPopup
        warning
        content={uptnContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default UPTNPopup
