/**
 * Months for drop down list
 */
export const months = [
  {name: 'January', value: '01'},
  {name: 'February', value: '02'},
  {name: 'March', value: '03'},
  {name: 'April', value: '04'},
  {name: 'May', value: '05'},
  {name: 'June', value: '06'},
  {name: 'July', value: '07'},
  {name: 'August', value: '08'},
  {name: 'September', value: '09'},
  {name: 'October', value: '10'},
  {name: 'November', value: '11'},
  {name: 'December', value: '12'},
]

/**
 * States for drop down list
 */
export const states = [
  {name: 'Alabama', abv: 'AL'},
  {name: 'Alaska', abv: 'AK'},
  {name: 'Arizona', abv: 'AZ'},
  {name: 'Arkansas', abv: 'AR'},
  {name: 'California', abv: 'CA'},
  {name: 'Colorado', abv: 'CO'},
  {name: 'Connecticut', abv: 'CT'},
  {name: 'Delaware', abv: 'DE'},
  {name: 'Florida', abv: 'FL'},
  {name: 'Georgia', abv: 'GA'},
  {name: 'Hawaii', abv: 'HI'},
  {name: 'Idaho', abv: 'ID'},
  {name: 'Illinois', abv: 'IL'},
  {name: 'Indiana', abv: 'IN'},
  {name: 'Iowa', abv: 'IA'},
  {name: 'Kansas', abv: 'KS'},
  {name: 'Kentucky', abv: 'KY'},
  {name: 'Louisiana', abv: 'LA'},
  {name: 'Maine', abv: 'ME'},
  {name: 'Maryland', abv: 'MD'},
  {name: 'Massachusetts', abv: 'MA'},
  {name: 'Michigan', abv: 'MI'},
  {name: 'Minnesota', abv: 'MN'},
  {name: 'Mississippi', abv: 'MS'},
  {name: 'Missouri', abv: 'MO'},
  {name: 'Montana', abv: 'MT'},
  {name: 'Nebraska', abv: 'NE'},
  {name: 'Nevada', abv: 'NV'},
  {name: 'New Hampshire', abv: 'NH'},
  {name: 'New Jersey', abv: 'NJ'},
  {name: 'New Mexico', abv: 'NM'},
  {name: 'New York', abv: 'NY'},
  {name: 'North Carolina', abv: 'NC'},
  {name: 'North Dakota', abv: 'ND'},
  {name: 'Ohio', abv: 'OH'},
  {name: 'Oklahoma', abv: 'OK'},
  {name: 'Oregon', abv: 'OR'},
  {name: 'Pennsylvania', abv: 'PA'},
  {name: 'Rhode Island', abv: 'RI'},
  {name: 'South Carolina', abv: 'SC'},
  {name: 'South Dakota', abv: 'SD'},
  {name: 'Tennessee', abv: 'TN'},
  {name: 'Texas', abv: 'TX'},
  {name: 'Utah', abv: 'UT'},
  {name: 'Vermont', abv: 'VT'},
  {name: 'Virginia', abv: 'VA'},
  {name: 'Washington', abv: 'WA'},
  {name: 'Washington DC', abv: 'DC'},
  {name: 'West Virginia', abv: 'WV'},
  {name: 'Wisconsin', abv: 'WI'},
  {name: 'Wyoming', abv: 'WY'},
]
