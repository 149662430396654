import React from 'react'
import {
  Button,
  Container,
  Modal as ReactModal,
  Row,
  Form,
} from 'react-bootstrap'
import {StatusAction} from 'models/Edge'
import SpinnerButton from 'components/SpinnerButton'

import success from 'assets/gif/success.gif'
import deleteIcon from 'assets/img/trash-icon.svg'

interface ModalProps {
  title: string
  open: boolean
  onClose: () => void
  type?: StatusAction
  message: string
  onCancel?: () => void
  onOk?: () => void
  size?: 'sm' | 'lg' | 'xl'
  okButtonText?: string
  cancelButtonText?: string
  okButtonDisabled?: boolean
  cancelButtonDisabled?: boolean
  okButtonLoading?: boolean
  closeButton?: boolean
  // Prop just for test
  returnJustModalContent?: boolean
  okButtonVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
}

const getTypeIcon = (type: StatusAction) => {
  switch (type) {
    case StatusAction.SUCCESS:
      return {
        url: success,
        alt: 'Success gif',
      }
    case StatusAction.WARNING:
      return {
        url: '',
        alt: 'Warning',
      }
    case StatusAction.DELETE:
      return {
        url: deleteIcon,
        alt: 'Trash Icon',
      }
    default:
      return {
        url: '',
        alt: 'Error',
      }
  }
}

const Modal = ({
  open,
  title,
  onClose,
  type,
  message,
  onCancel,
  onOk,
  size,
  okButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  okButtonVariant = 'primary',
  okButtonDisabled,
  cancelButtonDisabled,
  okButtonLoading,
  returnJustModalContent,
}: ModalProps) => {
  const ModalContent = () => (
    <>
      <ReactModal.Header>
        <ReactModal.Title>{title}</ReactModal.Title>
      </ReactModal.Header>
      <ReactModal.Body>
        <Container>
          {type && (
            <Row className="justify-content-center">
              <img alt={getTypeIcon(type).alt} src={getTypeIcon(type).url} />
            </Row>
          )}
          <Row className="justify-content-center">
            <Form.Label>{message}</Form.Label>
          </Row>
          <Row className="d-flex mt-3 justify-content-center">
            {onCancel && (
              <Button
                data-ref="modal-cancel-button"
                disabled={cancelButtonDisabled}
                variant="light"
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
            )}
            {/* eslint-disable-next-line react/forbid-dom-props */}
            {onCancel && onOk && <div style={{width: '10px'}} />}
            {onOk && (
              <SpinnerButton
                data-ref="modal-ok-button"
                disabled={okButtonDisabled}
                loading={okButtonLoading}
                variant={okButtonVariant}
                onClick={onOk}
              >
                {okButtonText}
              </SpinnerButton>
            )}
          </Row>
        </Container>
      </ReactModal.Body>
    </>
  )

  if (returnJustModalContent) {
    return <ModalContent />
  }

  return (
    <ReactModal
      centered
      data-ref="reactModal"
      show={open}
      size={size}
      onHide={onClose}
    >
      <ModalContent />
    </ReactModal>
  )
}

/**
 * Modal component
 * @return Rendered modal
 */
export default Modal
