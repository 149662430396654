import React from 'react'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {CurrentLoanDetails} from 'models/Edge'
import LoanDocuments from '../LoanDocuments'
import PaymentHistory from '../PaymentHistory'

interface Props {
  loanData: CurrentLoanDetails
  customerId: string | undefined
  currentLoanOriginationDate: string | undefined
  currentLoanPublicId: string | undefined
}

const LoanDetails: React.FC<Props> = ({
  loanData,
  customerId,
  currentLoanOriginationDate,
  currentLoanPublicId,
}) => (
  <>
    <h2 className="h1 mt-4 mb-3 text-center text-md-left">
      <Content type={ContentSlot.ACCOUNT_LOANDETAILSHEADER} />
    </h2>
    <LoanDocuments
      currentLoanOriginationDate={currentLoanOriginationDate}
      currentLoanPublicId={currentLoanPublicId}
      customerId={customerId}
      loanData={loanData}
    />
    <PaymentHistory loanData={loanData} />
    <div className="mb-4 px-3 px-lg-4">
      <Content type={ContentSlot.ACCOUNT_REMAININGBALANCE} />
    </div>
  </>
)
/**
 * Payment History Card
 */
export default LoanDetails
