import React, {useEffect, useRef, useState} from 'react'

import {Container} from 'react-bootstrap'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import AccountInfo from 'pages/Account/AccountInfo'
import PaymentMethods from 'pages/Account/PaymentMethods'
import {AnalyticsPageNames} from 'models/Analytics'
import ContactInfo from './ContactInfo'
import {useLoanData} from 'utils/hooks/useLoanData'
import PageSpinner from 'components/PageSpinner'
import LoanSchedule from 'components/LoanSchedule'
import {useLocation} from 'react-router'
import LoanDetails from './LoanDetails'

// Import CommunicationPreferences from 'pages/Account/CommPrefs'

const AccountPage: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.ACCOUNT,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const {data, status, customer} = useLoanData()
  const paymentSectionRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const paymentSectionHash = location.hash
  const [paymentSection, setPaymentSection] = useState(
    paymentSectionRef.current,
  )

  const [flagScroll, setFlagScroll] = useState(true)
  useEffect(() => {
    setPaymentSection(paymentSectionRef.current)
    setFlagScroll(true)
  }, [paymentSection, status])

  if (paymentSectionHash !== '' && data && flagScroll) {
    paymentSection?.scrollIntoView()

    if (window.scrollY !== 0) {
      window.scrollTo({top: window.scrollY - window.scrollY * 0.1})
      setFlagScroll(false)
    }
  }

  return (
    <>
      {status === 'loading' || !data ? (
        <PageSpinner />
      ) : (
        <main>
          <Container>
            <section>
              <h1 className="mt-4 mb-3 text-center text-md-left">
                <Content type={ContentSlot.ACCOUNT_ACCOUNTPAGEHEADER} />
              </h1>
              <AccountInfo />
              <ContactInfo userData={customer} />
              <PaymentMethods
                customerCards={data.customerDetails.customerCards}
                refElement={paymentSectionRef}
              />
            </section>
            <section>
              <LoanDetails
                currentLoanOriginationDate={
                  data.currentLoanDetails.originationDate
                }
                currentLoanPublicId={data.currentLoanDetails.publicLoanId}
                customerId={data.customerDetails.personId}
                loanData={data.currentLoanDetails}
              />
              {data.currentLoanDetails.todaysPayoffAmount > 0 ? (
                <LoanSchedule
                  isAccount
                  isLoading={false}
                  loanSchedule={data.currentLoanDetails.loanSchedule}
                />
              ) : null}
            </section>
          </Container>
        </main>
      )}
    </>
  )
}

/**
 * Account page
 */
export default AccountPage
