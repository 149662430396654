import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  RefObject,
  useMemo,
} from 'react'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Form,
  Formik,
  FormikProps,
  useFormikContext,
  FormikValues,
} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {useMutation} from 'react-query'
import {Col, Row, Form as BootstrapForm} from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'
import {queryClient} from 'client'

import GooglePlaces from 'components/Google/places'
import {ToastContext} from 'components/Toast'
import {
  ContactInfoFormValues,
  VergentUpdateContatctInfoValues,
} from 'models/AccountFormValues'
import {Props as ContactInfoProps} from 'pages/Account/ContactInfo'
import {OnChangeData} from 'models/GooglePlaces'
import {phoneInputMask, zipCodeInputMask} from 'utils/forms/masks'
import {states, months} from 'utils/forms/constants'
import {getYears} from 'utils/forms/helpers'
import {updateCustomerContact, getProfile, getLoanDetails} from 'utils/edge'
import {formatPhone} from 'utils/data-formatting'
import styles from 'components/ContactInfo/ContactInfo.module.scss'
import {AnalyticsEventNames} from 'models/Analytics'
import {useAuth} from 'auth'
import {CustomerDetailsResponse} from 'models/Edge'

dayjs.extend(customParseFormat)

interface UpdateContactInfoProps extends ContactInfoProps {
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef: RefObject<FormikValues> | any
  toggleEditMode: () => void
  navigate?: (values?: VergentUpdateContatctInfoValues) => void
}

const FormSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Street address is required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('City is required'),
  moveInMonth: Yup.string().required('Move in month is required'),
  moveInYear: Yup.string().required('Move in year is required'),
  moveInDate: Yup.string().test(
    'no-future-date',
    'Move in date cannot be in the future',
    (value: string) => {
      const moveInDate = dayjs(value, 'MM/YYYY')
      return moveInDate.diff(new Date(), 'month', true) <= 0
    },
  ),
  postalCode: Yup.string()
    .required('Zip code is required')
    .min(5, 'Zip code must be 5 digits long')
    .max(5, 'Zip code must be 5 digits long')
    .matches(/^[0-9]*$/, 'Zip code can only contain numbers')
    .matches(/^(?!00000)./, 'The Zip code: 00000 is invalid'),
  state: Yup.string().required('State is required'),
  phoneNumber: Yup.string()
    .test(
      'ten-digit-number',
      'Phone number must be 10 digits long',
      (value: string) => {
        if (value === undefined) return true
        return value.replace(/\D/g, '').length === 10
      },
    )
    .required('Phone number is required'),
})

const ContactInfoForm: React.FC<UpdateContactInfoProps> = ({
  setIsSubmitting,
  formRef,
  userData,
  toggleEditMode,
  navigate,
}: UpdateContactInfoProps) => {
  /**
   * State, Hooks
   */
  const [moveInDateDisabled, setMoveInDateDisabled] = useState(true)
  const {pushToast} = useContext(ToastContext)
  const {user} = useAuth()
  const {mutate: updateCustomer} = useMutation(updateCustomerContact, {
    onSuccess: async response => {
      if (userData?.personReferenceNumber && user?.username) {
        const loanData = queryClient.getQueryData<CustomerDetailsResponse>([
          'loan',
          userData.personReferenceNumber,
        ])

        const doesStoreStateMatch =
          loanData?.currentLoanDetails?.storeState === response.values.state
        const hasRefinanceApprovalDetails = Boolean(
          loanData?.currentLoanDetails.refinanceApprovalDetails,
        )

        if (
          (doesStoreStateMatch && !hasRefinanceApprovalDetails) ||
          (!doesStoreStateMatch && hasRefinanceApprovalDetails)
        ) {
          const loanUpdated = await getLoanDetails()
          queryClient.setQueryData<CustomerDetailsResponse>(
            ['loan', userData.personReferenceNumber],
            loanUpdated,
          )
        } else {
          await getProfile(userData.personReferenceNumber, user.username)
        }
      }
      setIsSubmitting(false)
      toggleEditMode()

      if (navigate) {
        navigate(response.values)
      }
    },
    onError: () => {
      setIsSubmitting(false)
      pushToast({
        title: 'Unable to update',
        variant: 'danger',
      })
    },
  })
  const appInsightsContext = useAppInsightsContext()
  /**
   * Memo
   */
  const address = useMemo(() => userData?.customerAddress, [userData])
  const initialMoveInDate = useMemo(
    () =>
      address?.moveInDate ? dayjs(address.moveInDate).format('MM/YYYY') : '',
    [address],
  )
  const initialState = useMemo(
    () =>
      address?.state && states.some(state => state.abv === address.state)
        ? address.state
        : '',
    [address],
  )
  const initialMoveInMonth = useMemo(() => initialMoveInDate.slice(0, 2), [
    initialMoveInDate,
  ])
  const initialMoveInYear = useMemo(() => initialMoveInDate.slice(3), [
    initialMoveInDate,
  ])
  const phone = useMemo(() => userData?.customerPhoneNumber, [userData])
  const initialPhoneNumber = useMemo(
    () => formatPhone(phone?.phoneNumber ?? ''),
    [phone],
  )

  const initialFormValues: ContactInfoFormValues = {
    addressLine1: address?.addressLine1 ?? '',
    addressLine2: address?.addressLine2 ?? '',
    city: address?.city ?? '',
    moveInMonth: initialMoveInMonth,
    moveInYear: initialMoveInYear,
    moveInDate: initialMoveInDate,
    postalCode: address?.postalCode ?? '',
    state: initialState,
    phoneNumber: initialPhoneNumber,
  }

  const hasAddressChanged = useCallback(hasAddressChangedCallback, [])
  const hasMovedInDateChanged = useCallback(hasMovedInDateChangedCallback, [])

  return (
    <>
      {Boolean(userData) && (
        <>
          <Formik
            validateOnBlur
            initialValues={initialFormValues}
            innerRef={formRef}
            validateOnChange={false}
            validationSchema={FormSchema}
            onSubmit={submitFormValues}
          >
            {({
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }: FormikProps<ContactInfoFormValues>) => {
              const updateFullAddress = (data: OnChangeData) => {
                setFieldValue('addressLine1', data.address ?? '')
                setFieldValue('addressLine2', '')
                setFieldValue('city', data.city ?? '')
                setFieldValue('state', data.state ?? '')
                setFieldValue('postalCode', data.zip ?? '')
              }

              return (
                <Form>
                  <SideEffect />
                  <Row className={`${styles.Row}`}>
                    <Col>
                      <GooglePlaces
                        controlId="addressLine1"
                        data-ref="address-line-1"
                        errors={errors.addressLine1 ?? ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        label="Address 1"
                        name="addressLine1"
                        value={values.addressLine1}
                        onChange={updateFullAddress}
                      />
                    </Col>
                  </Row>
                  <Row className={`${styles.Row}`}>
                    <Col>
                      <BootstrapForm.Group controlId="addressLine2">
                        <BootstrapForm.Label>Address 2</BootstrapForm.Label>
                        <BootstrapForm.Control
                          data-ref="address-line-2"
                          name="addressLine2"
                          placeholder="APT, Suite, Unit, etc."
                          type="text"
                          value={values.addressLine2}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.addressLine2 && errors.addressLine2 && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.addressLine2}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row className={`${styles.Row}`}>
                    <Col lg="4" xs="12">
                      <BootstrapForm.Label>City</BootstrapForm.Label>
                      <BootstrapForm.Group controlId="city">
                        <BootstrapForm.Control
                          data-ref="city"
                          name="city"
                          type="text"
                          value={values.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.city && errors.city && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.city}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>

                    <Col lg="4" xs="12">
                      <BootstrapForm.Label>State</BootstrapForm.Label>
                      <BootstrapForm.Group controlId="state">
                        <BootstrapForm.Control
                          as="select"
                          data-ref="state"
                          name="state"
                          value={values.state}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {states.map((state, index) => (
                            <option key={index} value={state.abv}>
                              {state.name}
                            </option>
                          ))}
                        </BootstrapForm.Control>
                        {touched.state && errors.state && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.state}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                    <Col lg="4" xs="12">
                      <BootstrapForm.Group controlId="postalCode">
                        <BootstrapForm.Label>Zip Code</BootstrapForm.Label>
                        <MaskedInput
                          className="form-control"
                          data-ref="postal-code"
                          mask={zipCodeInputMask}
                          name="postalCode"
                          type="tel"
                          value={values.postalCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.postalCode && errors.postalCode && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.postalCode}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12">
                      <h3>Move in Date</h3>
                      {(errors.moveInMonth ||
                        errors.moveInYear ||
                        errors.moveInDate) && (
                        <BootstrapForm.Text className="text-danger">
                          {errors.moveInMonth ??
                            errors.moveInYear ??
                            errors.moveInDate}
                        </BootstrapForm.Text>
                      )}
                    </Col>

                    <Col md="6" xs="12">
                      <BootstrapForm.Group controlId="moveInMonth">
                        <BootstrapForm.Label>Month</BootstrapForm.Label>
                        <BootstrapForm.Control
                          as="select"
                          data-ref="move-in-month"
                          disabled={moveInDateDisabled}
                          name="moveInMonth"
                          value={values.moveInMonth}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option key="default" value="">
                            Select Month
                          </option>
                          {months.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.name}
                            </option>
                          ))}
                        </BootstrapForm.Control>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md="6" xs="12">
                      <BootstrapForm.Group controlId="moveInYear">
                        <BootstrapForm.Label>Year</BootstrapForm.Label>
                        <BootstrapForm.Control
                          as="select"
                          data-ref="move-in-year"
                          disabled={moveInDateDisabled}
                          name="moveInYear"
                          value={values.moveInYear}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option key="default" value="">
                            Select Year
                          </option>
                          {getYears().map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </BootstrapForm.Control>
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row className={`${styles.Row}`}>
                    <Col>
                      <BootstrapForm.Group controlId="phoneNumber">
                        <BootstrapForm.Label>Phone Number</BootstrapForm.Label>
                        <MaskedInput
                          className="form-control"
                          data-ref="phone-number"
                          mask={phoneInputMask}
                          name="phoneNumber"
                          type="tel"
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.phoneNumber}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <FormikErrorFocus
                    align="middle"
                    duration={500}
                    ease="linear"
                    focusDelay={200}
                  />
                </Form>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )

  /**
   * Checks if user has changed address input fields
   * @param values form values
   * @return boolean
   */
  function hasAddressChangedCallback(values: ContactInfoFormValues) {
    return values.addressLine1 === address?.addressLine1 &&
      values.addressLine2 === address.addressLine2 &&
      values.city === address.city &&
      values.postalCode === address.postalCode &&
      (values.state === initialState || values.state === '')
      ? false
      : true
  }

  /**
   * Checks if user has changed phone input field
   * @param values form values
   * @return boolean
   */
  function hasMovedInDateChangedCallback(values: ContactInfoFormValues) {
    return values.moveInDate === initialMoveInDate ? false : true
  }

  /**
   * Conditional update of values based on user inputs
   * @return null
   */
  function SideEffect() {
    const {values, setFieldValue} = useFormikContext<ContactInfoFormValues>()
    useEffect(() => {
      // Sets value for moveInDate only if both moveInMonth & moveInYear exist
      if (
        !moveInDateDisabled &&
        values.moveInMonth.length &&
        values.moveInYear.length
      ) {
        setFieldValue(
          'moveInDate',
          `${values.moveInMonth}/${values.moveInYear}`,
        )
        return
      }
      // Clears out Move In fields if any part of the address is edited
      if (hasAddressChanged(values) && !hasMovedInDateChanged(values)) {
        setMoveInDateDisabled(false)
        setFieldValue('moveInDate', '')
        setFieldValue('moveInMonth', '')
        setFieldValue('moveInYear', '')
      }
    }, [setFieldValue, values])

    return null
  }

  /**
   * Submit form values
   * @param values form values
   */
  function submitFormValues(values: ContactInfoFormValues) {
    const isPhoneNumberUpdated =
      values.phoneNumber === initialPhoneNumber ? false : true
    const isAddressUpdated = hasAddressChanged(values)

    // If nothing is updated, switch back to read-only view
    if (!isPhoneNumberUpdated && !isAddressUpdated) {
      if (navigate) {
        navigate()
        return
      }
      toggleEditMode()
      return
    }

    // Set to middle of month to account for timezone differences
    const submission: VergentUpdateContatctInfoValues = {
      customerId: Number(userData?.personId),
      addressId: userData?.customerAddress.addressId ?? 0,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      postalCode: values.postalCode,
      state: values.state,
      phoneNumberId: userData?.customerPhoneNumber.phoneId ?? 0,
      phoneNumber: values.phoneNumber.replace(/\D/g, ''),
      moveInDateMonth: values.moveInMonth,
      moveInDateYear: values.moveInYear,
    }

    if (userData?.customerAddress.addressId === 0) {
      pushToast({
        title: 'Unable to update',
        message: 'You do not have a registered address',
        variant: 'danger',
      })
      return
    }
    setIsSubmitting(true)
    updateCustomer(submission)

    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.PERSONAL_INFORMATION_EDITED},
      {edited: submission},
    )
  }
}

/**
 * Contact Info Edit Form
 */
export default ContactInfoForm
