import React, {useCallback, useEffect, useState} from 'react'
import {Formik, Form, FormikErrors} from 'formik'
import {
  Container,
  Card,
  Form as BootstrapForm,
  Spinner,
  Row,
  Col,
} from 'react-bootstrap'
import ActionCard from 'components/ActionCard'
import FormTitle from 'components/Form/FormTitle'
import FormButtons from 'components/Form/FormButtons'
import IdCardIcon from './IdCardIcon'
import {
  CONTACT_US_REDIRECT,
  GET_ID_INFORMATION_URL,
  ID_LINK_TOKEN_URL,
  INITIAL_FORM_ERRORS,
  NO_LOAN_REDIRECT,
  TEST_IDS,
} from './constants'
import {IdVerificationFormSchema} from './IdVerification.schema'
import styles from './IdVerification.module.scss'
import {
  GetIdInformationRequest,
  GetIdInformationResponse,
  GetIdLinkTokenRequest,
  GetIdLinkTokenResponse,
  IdVerificationFormValues,
} from 'models/Edge'
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  usePlaidLink,
} from 'react-plaid-link'
import {useHistory, useLocation} from 'react-router-dom'
import {vergentService} from 'utils/vergent-service'
import {getConfigValue} from 'utils/environment'
import {useAuth} from 'auth'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {CustomerSearchRequest, RemoteAddressResponse} from 'models/Auth'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {AnalyticsEventNames, AnalyticsPageNames} from 'models/Analytics'
import {VergentError} from 'models/Errors'
import {MdErrorOutline} from 'react-icons/md'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import Axios from 'axios'
import {getCookie, setCookie} from 'utils/cookies'

const IdVerification: React.FC = () => {
  const history = useHistory()

  const location = useLocation<CustomerSearchRequest>()
  const searchRequest = location.state

  const [plaidToken, setplaidToken] = useState('')
  const [isIdVerified, setIsIdVerified] = useState(false)
  const [isPlaidLoading, setIsPlaidLoading] = useState(false)
  const {user} = useAuth()
  const plaidIdentityValidationAttemptLimit = Number(
    getConfigValue('PLAID_IDENTITY_VALIDATION_ATTEMPT_LIMIT'),
  )
  const PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME =
    'identityValidationAttempts'
  const [
    plaidIdentityVerificationAttempts,
    setPlaidIdentityVerificationAttempts,
  ] = useState<number>(0)

  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.VERIFY_PLAID_IDENTIFICATION,
  )

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const seacrhRequestValuesWithoutSsn = searchRequest
    ? {
        firstName: searchRequest.firstName,
        lastName: searchRequest.lastName,
        ssn: `***-**-${searchRequest.idNumber.substr(
          searchRequest.idNumber.length - 4,
          4,
        )}`,
        phone: searchRequest.phoneNumber,
        dateOfBirth: searchRequest.birthDate,
      }
    : {
        firstName: '',
        lastName: '',
        idNumber: '',
        phone: '',
        dateOfBirth: '',
      }

  useEffect(() => {
    const initialAttempts =
      Number(getCookie(PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME)) || 0

    if (initialAttempts >= plaidIdentityValidationAttemptLimit) {
      history.push(NO_LOAN_REDIRECT)
    } else {
      if (initialAttempts === 0) {
        setCookie({
          name: PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME,
          value: 0,
          daysToExpire: 1,
        })
      }
      setPlaidIdentityVerificationAttempts(initialAttempts)
    }
  }, [history, plaidIdentityValidationAttemptLimit])

  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const idVerificationFormValues: IdVerificationFormValues = {
    isIdVerified,
  }

  const goBack = useCallback(goBackCallback, [history])

  useEffect(() => {
    const initialAttempts =
      Number(getCookie(PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME)) || 0

    if (initialAttempts >= plaidIdentityValidationAttemptLimit) {
      history.push(NO_LOAN_REDIRECT)
      return
    }
    const getPlaidToken = async () => {
      try {
        if (!user?.personReferenceNumber || !user?.secret) {
          goBack()
        } else {
          const requestBody: GetIdLinkTokenRequest = {
            customer: {
              firstName: searchRequest.firstName,
              lastName: searchRequest.lastName,
              idNumber: searchRequest.idNumber,
              phoneNumber: searchRequest.phoneNumber,
              birthDate: searchRequest.birthDate,
            },
            responseTokens: [user.personReferenceNumber, user.secret],
          }
          const response = await vergentService.post<GetIdLinkTokenResponse>(
            ID_LINK_TOKEN_URL,
            requestBody,
          )
          setplaidToken(response.data.linkToken)
        }
      } catch (error) {
        history.push(CONTACT_US_REDIRECT)
      }
    }
    getPlaidToken()
  }, [
    history,
    searchRequest,
    user,
    goBack,
    plaidIdentityValidationAttemptLimit,
  ])

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.push('/find-loan')
  }

  const incrementAttempts = () => {
    const currentAttempt =
      Number(getCookie(PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME)) || 0
    const incrementAttempt = currentAttempt + 1
    setCookie({
      name: PLAID_IDENTITY_VALIDATION_ATTEMPTS_COOKIE_NAME,
      value: incrementAttempt,
      daysToExpire: 1,
    })
    return incrementAttempt
  }

  const handlePlaidSuccess = useCallback(async () => {
    const getPlaidIdInformation = async () => {
      try {
        const {
          data: {ip},
        } = await Axios.get<RemoteAddressResponse>(
          'https://api.ipify.org/?format=json',
        )
        const getIdInformationRequestBody: GetIdInformationRequest = {
          responseTokens: [
            user?.personReferenceNumber ?? '',
            user?.secret ?? '',
          ],
          idNumber: searchRequest.idNumber,
          remoteAddress: ip,
        }
        const getIdInformationResponse = await vergentService.post<
          GetIdInformationResponse
        >(GET_ID_INFORMATION_URL, getIdInformationRequestBody)
        const identification = getIdInformationResponse.data

        if (identification.successfullyVerified) {
          appInsightsContext.trackEvent(
            {name: AnalyticsEventNames.VERIFY_PLAID_IDENTIFICATION_SUCCESS},
            {name: seacrhRequestValuesWithoutSsn},
          )
          setIsIdVerified(true)
        } else {
          const attempts = incrementAttempts()

          if (attempts >= plaidIdentityValidationAttemptLimit) {
            history.push(NO_LOAN_REDIRECT)
          } else {
            history.push(CONTACT_US_REDIRECT)
          }
        }
      } catch (error) {
        const attempts = incrementAttempts()
        const {errorInstance} = error as VergentError

        if (
          attempts >= plaidIdentityValidationAttemptLimit ||
          errorInstance?.response?.status === 429
        ) {
          history.push(NO_LOAN_REDIRECT)
        } else {
          history.push(CONTACT_US_REDIRECT)
        }
      }
    }
    await getPlaidIdInformation()
    setIsPlaidLoading(false)
  }, [
    appInsightsContext,
    history,
    plaidIdentityValidationAttemptLimit,
    seacrhRequestValuesWithoutSsn,
    searchRequest,
    user,
  ])

  const handlePlaidExit = useCallback(
    (error: PlaidLinkError | PlaidLinkOnExitMetadata | null) => {
      setIsPlaidLoading(false)
      if (error) {
        history.push(CONTACT_US_REDIRECT)
      }
    },
    [history],
  )

  const {open, ready, exit} = usePlaidLink({
    token: plaidToken,
    onExit: handlePlaidExit,
    onSuccess: handlePlaidSuccess,
  })

  const handleVerifyIdentityClick = useCallback(() => {
    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.VERIFY_PLAID_IDENTIFICATION_ATTEMPT},
      {name: seacrhRequestValuesWithoutSsn},
    )
    setIsPlaidLoading(true)
    open()
  }, [appInsightsContext, open, seacrhRequestValuesWithoutSsn])

  useEffect(() => {
    const timeout = setTimeout(() => {
      exit({force: true})
    }, Number(getConfigValue('INACTIVITY_TIMER')) * 1000)
    return () => clearTimeout(timeout)
  }, [exit])

  const handleSubmit = () => {
    if (isIdVerified) {
      history.push('/register', searchRequest)
    }
  }

  return (
    <Container>
      <Card className="my-3">
        <Card.Header>
          <FormTitle>ID Verification</FormTitle>
        </Card.Header>
        <Formik
          enableReinitialize
          validateOnChange
          initialErrors={INITIAL_FORM_ERRORS}
          initialValues={idVerificationFormValues}
          validationSchema={IdVerificationFormSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched, isSubmitting, validateForm, initialValues}) => (
            <Form noValidate>
              <InitialValuesObserver
                initialValues={initialValues}
                validateForm={validateForm}
              />
              <Card.Body>
                <div className={styles.body}>
                  <ActionCard
                    actionButtonTestId={TEST_IDS.BUTTONS.VERIFY}
                    actionIcon={
                      isPlaidLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        <FaExternalLinkAlt />
                      )
                    }
                    actionText={isPlaidLoading ? 'Verifying' : 'Verify'}
                    completedActionText="Verified"
                    description="Click “Verify” and follow the prompts. You will need to have your identification to complete this step."
                    disabled={isPlaidLoading || !ready}
                    icon={<IdCardIcon />}
                    isActionCompleted={isIdVerified}
                    title="Verify your ID"
                    onActionClick={handleVerifyIdentityClick}
                  />
                  {touched.isIdVerified && errors.isIdVerified && (
                    <BootstrapForm.Text className="text-danger">
                      {errors.isIdVerified}
                    </BootstrapForm.Text>
                  )}
                  {plaidIdentityVerificationAttempts === 3 && (
                    <Row
                      className="justify-content-center py-3"
                      data-ref="find-my-account-warning"
                    >
                      <Col
                        className="justify-content-center d-flex"
                        lg="1"
                        md="3"
                        sm="3"
                      >
                        <MdErrorOutline color="#efd36d" size="3.5rem" />
                      </Col>
                      <Col className="text-center" md="7" sm="7">
                        <Content type={ContentSlot.FINDLOAN_3ATTEMPTS} />
                      </Col>
                    </Row>
                  )}
                </div>
                <FormButtons
                  backButtonDisabled={isSubmitting}
                  backButtonLabel="Back"
                  backButtonTestId={TEST_IDS.BUTTONS.BACK}
                  nextButtonLabel="Next"
                  nextButtonLoading={isSubmitting}
                  nextButtonTestId={TEST_IDS.BUTTONS.NEXT}
                  onBackClick={goBack}
                />
              </Card.Body>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

interface IdVerificationProps {
  initialValues: IdVerificationFormValues
  validateForm: () => Promise<FormikErrors<IdVerificationFormValues>>
}

const InitialValuesObserver = ({
  validateForm,
  initialValues,
}: IdVerificationProps) => {
  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return <></>
}

/**
 * Id verification page
 */
export default IdVerification
