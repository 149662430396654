import React from 'react'
import {Card, Container} from 'react-bootstrap'
import BuildInfoJson from '../../buildinfo.json'

const BuildInfo: React.FC = () => (
  <Container>
    <Card className="mt-5">
      <Card.Body>
        <Card.Title>Build Info</Card.Title>
        <Card.Text>
          Build Number: {BuildInfoJson.BuildNumber}
          <br />
          Build Commit Id: {BuildInfoJson.BuildCommitId}
        </Card.Text>
      </Card.Body>
    </Card>
  </Container>
)

/**
 * BuildInfo page
 */
export default BuildInfo
