import React, {useCallback, useState} from 'react'

import {Card, Table, Accordion, ListGroup, Collapse} from 'react-bootstrap'
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineExclamationCircle,
} from 'react-icons/ai'

import dayjs from 'dayjs'
import {useLoanData} from 'utils/hooks/useLoanData'
import PDF20031 from '../../utils/document/20210101 CA Installment - 31 day - 200.pdf'
import PDF50031 from '../../utils/document/20210101 CA Installment - 31 day - 500.pdf'
import PDF100031 from '../../utils/document/20210101 CA Installment - 31 day - 1000.pdf'
import PDF20014 from '../../utils/document/20210101 CA Installment - 14 day - 200.pdf'
import PDF50014 from '../../utils/document/20210101 CA Installment - 14 day - 500.pdf'
import PDF100014 from '../../utils/document/20210101 CA Installment - 14 day - 1000.pdf'
import styles from 'components/LoanSchedule/LoanSchedule.module.scss'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {ScheduleItem, TilaDisclosureDetailsDto} from 'models/Edge'
import {formatCurrency, formatToFloat} from 'utils/data-formatting'
import PageSpinner from 'components/PageSpinner'
import {convertDateToCurrentTimezone} from 'utils/convert-date-to-current-timezone'
import {
  daysBetween,
  paymentTimeFrame,
  largeScreenFormattedDate,
  mobileScreenFormattedDate,
} from 'utils/dates'
import PayoffPopup from './PayoffPopup'

interface Props {
  loanSchedule?: ScheduleItem[]
  isLoading: boolean
  isSuccess?: boolean
  error?: Error | null | undefined
  startDate?: Date
  tilaDisclosure?: TilaDisclosureDetailsDto | undefined
  timePeriod?: string | null
  maxLoanAmount?: number
  maxCashBackAmount?: number
  cashBackAmount?: number
  isAccount?: boolean
}

const LoanSchedule: React.FC<Props> = ({
  loanSchedule,
  isLoading,
  startDate,
  tilaDisclosure,
  timePeriod,
  maxLoanAmount,
  maxCashBackAmount,
  cashBackAmount,
  isAccount,
}) => {
  /**
   *  State, Hooks
   */
  const [hideDates, setHideDates] = useState(true)
  const [hideTilaBox, setHideTilaBox] = useState(false)
  const [showPayoffPopUp, setShowPayoffPopup] = useState(false)

  /**
   * Callbacks
   */
  const toggleDates = useCallback(toggleDatesCallback, [
    hideDates,
    setHideDates,
  ])

  const toggleTilaBox = useCallback(toggleTilaBoxCallback, [
    hideTilaBox,
    setHideTilaBox,
  ])

  const togglePayoffPopUp = useCallback(togglePayoffPopUpCallback, [
    showPayoffPopUp,
    setShowPayoffPopup,
  ])

  const {data, customer, currentLoan} = useLoanData()

  const isCngVariableRateProductLoan =
    currentLoan?.isCngVariableRateProductLoan

  if (isLoading) {
    return <PageSpinner />
  }

  if (!loanSchedule) {
    return (
      <Card
        className="my-2 pb-3 pt-3 text-center font-primary"
        data-ref="loanSchedule-placeholder"
      >
        <Content type={ContentSlot.LOANSCHEDULE_PLACEHOLDERTEXT} />
      </Card>
    )
  }

  // Customer cashBack request for reactivation is greater than max cashBack allowed
  let shouldDisableBasedOnLoanAmount = Boolean(maxLoanAmount)

  if (tilaDisclosure !== undefined && maxLoanAmount) {
    shouldDisableBasedOnLoanAmount =
      shouldDisableBasedOnLoanAmount &&
      tilaDisclosure.amountFinanced > maxLoanAmount
  }

  // Customer cashBack request for cashback refi is greater than max cashBack allowed
  const shouldDisableBasedOnCashBackAmount =
    cashBackAmount && maxCashBackAmount && cashBackAmount > maxCashBackAmount

  if (shouldDisableBasedOnLoanAmount || shouldDisableBasedOnCashBackAmount) {
    return (
      <Card
        className="my-2 pb-3 pt-3 text-center font-primary"
        data-ref="loanSchedule-placeholder"
      >
        <Content type={ContentSlot.LOANSCHEDULE_PLACEHOLDERTEXT} />
      </Card>
    )
  }

  // Reorders array to be nearest->farthest transactions
  const orderedTransactions = loanSchedule.sort(
    (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
  )

  // Creates the due date rows
  const fullPaymentDueRows = orderedTransactions.map((transaction, i) => (
    <tr key={i}>
      <td className="text-center px-0">
        <span className="d-none d-md-inline">
          {formatCurrency(Math.abs(transaction.dueAmount))} on{' '}
          {largeScreenFormattedDate(
            convertDateToCurrentTimezone(transaction.dueDate),
          )}
        </span>
        <span className="d-md-none">
          {formatCurrency(Math.abs(transaction.dueAmount))} on{' '}
          {mobileScreenFormattedDate(
            convertDateToCurrentTimezone(transaction.dueDate),
          )}
        </span>
      </td>
    </tr>
  ))

  const firstFiveRows = fullPaymentDueRows.slice(0, 5)

  let tilaBoxes: {
    title: string
    desc: string
    variable: string
  }[] = []

  if (tilaDisclosure !== undefined) {
    tilaBoxes = [
      {
        title: 'ANNUAL PERCENTAGE RATE',
        desc: 'The cost of your credit as a yearly rate.',
        variable: tilaDisclosure.apr
          ? `${formatToFloat(tilaDisclosure.apr)}%`
          : '',
      },
      {
        title: 'FINANCE CHARGE',
        desc: 'The dollar amount the credit will cost you.',
        variable: tilaDisclosure.financeCharge
          ? formatCurrency(tilaDisclosure.financeCharge)
          : '',
      },
      {
        title: 'Amount Financed',
        desc: 'The amount of credit provided to you or on your behalf.',
        variable: tilaDisclosure.amountFinanced
          ? formatCurrency(tilaDisclosure.amountFinanced)
          : '',
      },
      {
        title: 'Total of Payments',
        desc:
          'The amount you will have paid after you have made all payments as scheduled.',
        variable: tilaDisclosure.scheduledTotalPaymentAmount
          ? formatCurrency(tilaDisclosure.scheduledTotalPaymentAmount)
          : '',
      },
    ]
  }

  /**
   * Gets the TX Link at runtime
   * @param disclosure the tiladisclosure property
   * @return TxtLink component
   */
  function getTXLink(disclosure: TilaDisclosureDetailsDto | undefined) {
    if (disclosure !== undefined) {
      // If time period is not returned we calculate it from schedule
      const dueTimePeriod =
        !timePeriod &&
        dayjs(orderedTransactions[1]?.dueDate).diff(
          dayjs(orderedTransactions[0]?.dueDate),
          'day',
        ) === 31
          ? 'Every31Days'
          : timePeriod
      let path = PDF100031
      if (disclosure.amountFinanced <= 200) {
        path = dueTimePeriod === 'Every31Days' ? PDF20031 : PDF20014
      } else if (
        disclosure.amountFinanced <= 500 &&
        disclosure.amountFinanced > 200
      ) {
        path = dueTimePeriod === 'Every31Days' ? PDF50031 : PDF50014
      } else if (disclosure.amountFinanced > 500) {
        path = dueTimePeriod === 'Every31Days' ? PDF100031 : PDF100014
      }
      return (
        <div>
          {`For consumer disclosures, fee schedules, and other required
      notices, see the `}
          <a href={path} rel="noopener noreferrer" target="_blank">
            Texas Loan Handout.
          </a>
        </div>
      )
    }

    return <></>
  }
  return (
    <Accordion>
      <PayoffPopup
        payoffDate={new Date()}
        payoffValue={data?.currentLoanDetails?.todaysPayoffAmount}
        toggleVisiblity={togglePayoffPopUp}
        visility={showPayoffPopUp}
      />
      <Card className="my-2 pb-0">
        <Card.Header className="text-left" data-ref="loanSchedule">
          <h2 className="border-bottom pb-2 border-medium font-weight-light">
            <div className={`d-inline ${styles.PaymentSchHeader}`}>
              <Content type={ContentSlot.ACCOUNT_LOANSCHEDULEHEADER} />
              {isAccount && (
                <AiOutlineExclamationCircle
                  className={`${styles.FAExcl}`}
                  onClick={togglePayoffPopUp}
                />
              )}
            </div>
          </h2>
        </Card.Header>
        <Card.Body>
          {tilaDisclosure !== undefined && (
            <>
              {startDate && (
                <p>
                  The Annual Percentage Rate (APR) for a{' '}
                  {formatCurrency(tilaDisclosure.amountFinanced)} loan for{' '}
                  {daysBetween(
                    startDate,
                    loanSchedule[loanSchedule.length - 1]?.dueDate,
                  )}{' '}
                  days is {formatToFloat(tilaDisclosure.apr)}%. The loan
                  consists of a first payment of{' '}
                  {formatCurrency(loanSchedule[0]?.dueAmount)},{' '}
                  {loanSchedule.length - 2} payments thereafter of{' '}
                  {formatCurrency(loanSchedule[1]?.dueAmount)} due{' '}
                  {paymentTimeFrame(loanSchedule)}, and a final payment of{' '}
                  {formatCurrency(
                    loanSchedule[loanSchedule.length - 1]?.dueAmount,
                  )}{' '}
                  due at maturity.
                </p>
              )}
              <div
                aria-controls="example-collapse-text"
                aria-expanded={hideTilaBox}
                className={`text-left mb-2 h6 ${styles.cardSubtitle}`}
                data-ref="expand-tila-button"
                onClick={toggleTilaBox}
              >
                Federal Truth-In-Lending Disclosures
              </div>
              <Collapse in={hideTilaBox}>
                <ListGroup
                  className="pb-1"
                  data-ref="tila-boxes"
                  horizontal="md"
                >
                  {tilaDisclosure !== undefined &&
                    tilaBoxes.map(box => (
                      <ListGroup.Item
                        key={box.title}
                        className={`w-25 text-center ${styles.listGroupItem}`}
                      >
                        <h4>{box.title}</h4>
                        <p className="pb-1 text-center">{box.desc}</p>
                        <p className="family-bold position-absolute fixed-bottom">
                          {box.variable}
                        </p>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Collapse>
            </>
          )}

          <Table responsive className={`d-md-table ${styles.table}`}>
            <tbody data-ref="paymentDueDates">
              {hideDates ? firstFiveRows : fullPaymentDueRows}
            </tbody>
            <tfoot>
              <tr
                className={`text-center ${styles.expandButton}`}
                data-ref="expand-button"
                onClick={toggleDates}
              >
                <td>
                  {hideDates ? 'See all' : 'See less'}
                  {hideDates ? (
                    <AiFillCaretDown color="#5558af" size="0.8rem" />
                  ) : (
                    <AiFillCaretUp color="#5558af" size="0.8rem" />
                  )}
                </td>
              </tr>
            </tfoot>
          </Table>
          {/* TODO: Review this state validation */}
          {customer?.customerAddress.state === 'TX' &&
          tilaDisclosure !== undefined &&
          !isCngVariableRateProductLoan
            ? getTXLink(tilaDisclosure)
            : null}
        </Card.Body>
      </Card>
    </Accordion>
  )

  /**
   * Simple callback to toggle local state
   */
  function toggleDatesCallback() {
    setHideDates(!hideDates)
  }

  /**
   * Simple callback to toggle local state
   */
  function toggleTilaBoxCallback() {
    setHideTilaBox(!hideTilaBox)
  }

  /**
   * Simple callback to toggle local state
   */
  function togglePayoffPopUpCallback() {
    setShowPayoffPopup(!showPayoffPopUp)
  }
}

/**
 * Loan Schedule Card
 */
export default LoanSchedule
