import React from 'react'

import {Col, Container, Row} from 'react-bootstrap'
import {MdCheckCircle, MdChevronRight, MdError} from 'react-icons/md'
import * as Yup from 'yup'

import {getPasswordRules} from 'models/Passwords'

interface PasswordRulesProps {
  password: string
  error: boolean
}

const PasswordRules: React.FC<PasswordRulesProps> = props => (
  <>
    {getPasswordRules(props.password).map((rule, index) => (
      <Container key={index}>
        <Row className="justify-content-center align-items-center">
          <Col className="align-self-center" xs="auto">
            {rule.passing && <MdCheckCircle color="#59d1a9" size="1.2rem" />}
            {!rule.passing && !props.error && <MdChevronRight size="1.2rem" />}
            {!rule.passing && props.error && (
              <MdError color="#eb445a" size="1.2rem" />
            )}
          </Col>
          <Col className="align-self-center">
            <span>{rule.text}</span>
          </Col>
        </Row>
      </Container>
    ))}
  </>
)

const passwordError = "Doesn't meet requirements"

/**
 * Yup rules for password
 */
export const passwordRules = {
  password: Yup.string()
    .required('Password is required')
    .min(8, passwordError)
    .max(20, passwordError)
    .matches(/\d/, passwordError)
    .matches(
      /^[^`]+$/,
      ' ` is not a valid special character. Please use a special character from the list below. ',
    )
    .matches(
      /^[^']+$/,
      " '  is not a valid special character. Please use a special character from the list below. ",
    )
    .matches(
      /^[^"]+$/,
      ' "  is not a valid special character. Please use a special character from the list below. ',
    )
    .matches(
      /^[^\\]+$/,
      ' \\  is not a valid special character. Please use a special character from the list below. ',
    )
    .matches(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, passwordError)
    .matches(/[a-z]/, passwordError)
    .matches(/[A-Z]/, passwordError),
  confirmPassword: Yup.mixed()
    .required('Passwords must match')
    .test('match', 'Passwords must match', function () {
      // @ts-ignore: disable-next-line
      return this.parent.password === this.parent.confirmPassword
    }),
}

/**
 * Password requirements display
 */
export default PasswordRules
