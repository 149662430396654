import {
  RegisterAccountRequest,
  RegisterAccountResponse,
  RemoteAddressResponse,
} from 'models/Auth'

import {LocalStorageKeys} from 'utils/common'
import {AuthActionsType, AuthActionTypes} from '../useAuthReducer'
import {StandardError, VergentError} from 'models/Errors'
import {vergentService} from 'utils/vergent-service'
import {LoginErrorTypes} from './login-action'
import {saveUsername} from 'utils/manage-username'
import Axios from 'axios'

interface RegisterAccountArgs {
  registerAccountRequestBody: RegisterAccountRequest
  dispatchAuthState: React.Dispatch<AuthActionsType>
}

enum RegisterCodeResponses {
  SuccessOperation = 1,
  ExistingUSer = 2,
  InvalidId = 3,
  UnexpectedResponse = 4,
  InvalidUserName = 5,
}

const errorMessages = {
  default: 'There was an error trying to register your account.',
  invalidUsername: 'Invalid username',
}

/**
 * Register customer account error types
 */
export enum RegisterCustomerAccountErrorTypes {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NoValidUserName = 'NO_VALID_USERNAME',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
  ExistingUser = 'EXISTING_USER',
}

/**
 * Register an account action
 */
export const registerCustomerAccountAction = async ({
  registerAccountRequestBody,
  dispatchAuthState,
}: RegisterAccountArgs) => {
  let shouldReturnSameError = false
  try {
    const {
      data: {ip},
    } = await Axios.get<RemoteAddressResponse>(
      'https://api.ipify.org/?format=json',
    )

    const {
      data: {responseCode, token, customerId, email},
    } = await vergentService.post<RegisterAccountResponse>(
      '/VergentCustomer/register',
      {
        userName: registerAccountRequestBody.userName,
        password: registerAccountRequestBody.password,
        email: registerAccountRequestBody.email,
        responseTokens: registerAccountRequestBody.responseTokens,
        customer: registerAccountRequestBody.customer,
        remoteAddress: ip,
      },
    )

    if (responseCode === RegisterCodeResponses.SuccessOperation) {
      localStorage.setItem(LocalStorageKeys.AccessToken, token)
      saveUsername(registerAccountRequestBody.userName, String(customerId))

      dispatchAuthState({
        type: AuthActionTypes.Login,
        payload: {
          user: {
            email,
            username: registerAccountRequestBody.userName,
            personReferenceNumber: String(customerId),
          },
        },
      })
    } else if (responseCode === RegisterCodeResponses.InvalidUserName) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RegisterCustomerAccountErrorTypes.NoValidUserName,
        errorInstance: new Error(errorMessages.invalidUsername),
      })
    } else if (responseCode === RegisterCodeResponses.ExistingUSer) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RegisterCustomerAccountErrorTypes.ExistingUser,
      })
    } else if (
      responseCode === RegisterCodeResponses.InvalidId ||
      responseCode === RegisterCodeResponses.UnexpectedResponse
    ) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RegisterCustomerAccountErrorTypes.InternalServerError,
        errorInstance: new Error(errorMessages.default),
      })
    }
  } catch (error) {
    if (shouldReturnSameError) throw error

    const {
      errorInstance,
      errorWasHandled,
      errorMessage,
    } = error as VergentError

    localStorage.clear()

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: LoginErrorTypes.InternalServerError,
        errorInstance: new Error(errorInstance.message),
      })
    }

    throw new StandardError({
      type: LoginErrorTypes.VergentServiceError,
      errorInstance: new Error(errorMessage ?? errorMessages.default),
    })
  }
}
