import React from 'react'

import maintenanceImage from 'assets/img/maintenanceImage.png'
import {Button, Col, Container} from 'react-bootstrap'
import styles from 'pages/ServiceOutage/ServiceOutage.module.scss'
import {getConfigValue} from 'utils/environment'

const ServiceOutage: React.FC = () => {
  const prequalifyApplicationUrl: string = getConfigValue(
    'PREQUALIFY_APPLICATION_URL',
  )

  /**
   * Route the user to the pre-qualify application page
   */
  function goToApply(): void {
    window.location.replace(prequalifyApplicationUrl)
  }

  return (
    <div className={styles.card}>
      <Container className={`${styles.text_container}`}>
        <Col>
          <h1 className={styles.headline}>Sorry!</h1>
          <p>
            Our customer portal is temporarily down while we make improvements.
            Please try again later.
          </p>
        </Col>
        <Col>
          <u>
            <strong>Existing Customers:</strong>
          </u>
          <p>
            Our store associates are happy to assist you from 10AM - 6PM
            Mon-Fri or 10AM - 2PM Sat.
          </p>
          <a className="text-light" href="tel:1-888-950-2274">
            <Button
              className={`btn-lg ${styles.service_button}`}
              variant="primary"
            >
              Call 1-888-950-2274
            </Button>
          </a>
          <p className="mt-2">
            Thank you for your patience and being a valued customer of the Cash
            Store!
          </p>
        </Col>
        <Col className="mt-3">
          <u>
            <strong>New Users: </strong>
          </u>
          <p>
            If you&apos;ve never had a loan with us before, apply to receive
            your prequalified amount!
          </p>
          <Button
            className={`btn-lg ${styles.service_button}`}
            variant="primary"
            onClick={goToApply}
          >
            Apply Today!
          </Button>
        </Col>
      </Container>
      <Container>
        <Col>
          <img alt="Not Found Icon" className="w-100" src={maintenanceImage} />
        </Col>
      </Container>
    </div>
  )
}

/**
 *  Service Outage page users are routed to if /health-check returns 500
 *  the page includes various generic CTAs
 */
export default ServiceOutage
