import React, {useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {ContentSlot} from 'models/Content'
import UhOh from 'components/UhOh'
import {useLoanDataNOError} from '../../utils/hooks/useLoanData'
import {useStoreDetails} from '../../utils/hooks/useStoreDetails'
import {AnalyticsPageNames} from 'models/Analytics'

const AccountIssue: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.ACCOUNT_ISSUE,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const {currentLoan, isLoading: loanDataLoading} = useLoanDataNOError()
  const {data: store, isLoading: storeDataLoading} = useStoreDetails(
    currentLoan?.originatingStore,
  )
  const isLoading = loanDataLoading || storeDataLoading
  let isIneligible = false

  if (
    !currentLoan?.refinanceApprovalDetails ||
    !currentLoan.reactivationApprovalDetails
  ) {
    isIneligible = true
  }
  if (isLoading) {
    return <></>
  }
  return (
    <UhOh
      logoutOnBack
      back={ContentSlot.INVALIDFLAG_BACK}
      callUs={ContentSlot.INVALIDFLAG_CALLUS}
      cta={ContentSlot.INVALIDFLAG_CTA}
      emailUs={ContentSlot.INVALIDFLAG_EMAILUS}
      isIneligible={isIneligible}
      representative={ContentSlot.INVALIDFLAG_REPRESENTATIVE}
      storeData={store}
      summary={ContentSlot.INVALIDFLAG_SUMMARY}
      title={ContentSlot.INVALIDFLAG_TITLE}
      visitAStore={ContentSlot.INVALIDFLAG_VISITASTORE}
    />
  )
}

/**
 * Screen that shows when your account is locked due to not finding a loan
 */
export default AccountIssue
