import React, {useEffect} from 'react'

import youngCoupleImage from 'assets/img/young-couple.jpg'
import {Col, Container, Row} from 'react-bootstrap'
import styles from './landing.module.scss'
import CashwisePromoCodeForm from 'components/Form/CashwisePromoCodeForm'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {
  useTrackMetric,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js'
import {AnalyticsPageNames} from 'models/Analytics'

const CashWiseLandingPage: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CASHWISE_LANDING,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  return (
    <div className="pb-3 bg-white">
      <div
        className={styles.banner}
        // eslint-disable-next-line react/forbid-dom-props
        style={{backgroundImage: `url(${youngCoupleImage})`}}
      />
      <Container>
        <Row className="justify-content-center">
          <Col md={8} xs={12}>
            <h1 className="text-primary mt-2">
              <Content
                type={ContentSlot.CASHWISE_LANDING_PAGE_SECTION_HEADER}
              />
            </h1>
            <div className={styles.content}>
              <Content
                type={ContentSlot.CASHWISE_LANDING_PAGE_SECTION_INTRODUCTION}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col className="md-px-0" md={8} xs={12}>
            <CashwisePromoCodeForm />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

/**
 * Once a customer scans a QR code using their mobile device,
 * it will bring them to this landing page to enter their special code and phone number.
 */
export default CashWiseLandingPage
