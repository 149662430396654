import React from 'react'

import {Modal} from 'react-bootstrap'
import styles from 'components/Popup/AbstractPopup.module.scss'
import warningLogo from 'assets/img/warning100x100.png'

/**
 * Abstract Popup Props interface
 */
export interface AbstractPopupProps {
  warning?: boolean
  content: JSX.Element | string
  visibility: boolean
  handleClose(): void
}

const AbstractPopup: React.FC<AbstractPopupProps> = props => (
  <Modal
    centered
    backdrop="static"
    keyboard={false}
    show={props.visibility}
    onHide={props.handleClose}
  >
    <Modal.Body>
      <div className={` ${styles.ModalHeader}`}>
        {props.warning && (
          <>
            <div>
              <img
                alt="Warning"
                className={` ${styles.WarningSign}`}
                src={warningLogo}
              />
            </div>
            <h2 data-ref="warning-modal">IMPORTANT!</h2>
          </>
        )}
        <div>
          <>{props.content}</>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)

/**
 * Bootstrap modal for popup
 */
export default AbstractPopup
