interface SetCookieArgs {
  name: string
  value: string | number
  daysToExpire?: number
}

const DAY_IN_MILISECONDS = 1000 * 60 * 60 * 24

/**
 * Create or update a browser cookie.
 */
export const setCookie = ({name, value, daysToExpire = 1}: SetCookieArgs) => {
  const todayTime = new Date().getTime()
  const expireDate = new Date(todayTime + daysToExpire * DAY_IN_MILISECONDS)
  document.cookie = `${name}=${value}; expires=${expireDate.toUTCString()}`
}

/**
 * Get or update a browser cookie.
 * @param cookieName name of the cookie
 * @return value of the cookie
 */
export const getCookie = (cookieName: string) => {
  const cookies = document.cookie.split(';')
  let cookieValue = ''
  cookies.find(cookie => {
    const [name, value] = cookie.split('=')
    if (name.trim() === cookieName) {
      cookieValue = value
    }
    return name === cookieName
  })
  return cookieValue
}
