import React, {useCallback, useEffect, useState} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Button, Card, Col, Container, Row, Spinner} from 'react-bootstrap'
import {IoIosCheckmarkCircleOutline} from 'react-icons/io'
import {useHistory} from 'react-router-dom'

import {ReactComponent as IconCashout} from 'assets/img/icon-cashout.svg'
import refiIcon from 'assets/img/icon-refinance.png'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'
import styles from 'pages/RefinanceOptions/Options.module.scss'
import {formatCurrency} from 'utils/data-formatting'
import {setRefinanceOption} from 'utils/cache'
import {AnalyticsEventNames, AnalyticsPageNames} from 'models/Analytics'
import {useLoanData} from 'utils/hooks/useLoanData'
import {getConfigValue} from 'utils/environment'
import {RefinanceSteps} from 'components/NumberStepper/Config'
import NumberStepper from 'components/NumberStepper'
import RefinancePayment from '../Refinance'
import CashBackPayment from '../CashBack'

const RefinanceOptions: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.REFINANCE_OPTIONS,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.CHOOSE_REFINANCE_VISIT,
    })
  }, [appInsightsContext])

  const disableCashBackRefiOnDefaultLoans = getConfigValue(
    'DISABLE_CASHBACK_REFI_ON_DEFAULT_LOANS',
  )
  /**
   * State, Hooks
   */
  const {status, refiOptions} = useRefinanceData()

  const [showRefinancePayment, setShowRefinancePayment] = useState(false)
  const [showCashbackPayment, setShowCashbackPayment] = useState(false)
  const [selectedRefiOption, setSelectedRefiOption] = useState('')

  useEffect(() => {
    if (selectedRefiOption === 'refinance') {
      setShowRefinancePayment(true)
      setShowCashbackPayment(false)
    }

    if (selectedRefiOption === 'cashback') {
      setShowCashbackPayment(true)
      setShowRefinancePayment(false)
    }
  }, [selectedRefiOption])

  const {data} = useLoanData()
  // Const {cardInfo} = useLoanData()
  const history = useHistory()
  /**
   * Callbacks
   */
  const chooseCashback = useCallback(chooseCashbackCallback, [history])
  const chooseRefinance = useCallback(chooseRefinanceCallback, [history])

  const isCngVariableRateProductLoan =
    data?.currentLoanDetails.isCngVariableRateProductLoan ?? false
  const requiredToPay = refiOptions?.refinance.requiredToPay ?? 0
  const cashbackRequiredToPay = refiOptions?.cashback.requiredToPay ?? 0

  let refiText
  let cashbackRefiText
  if (isCngVariableRateProductLoan) {
    refiText = `Refinance today for ${formatCurrency(requiredToPay)}`
    cashbackRefiText = `Refinance today for ${formatCurrency(
      cashbackRequiredToPay,
    )} and receive up to `
  } else {
    refiText = `Refinance today by making a payment of only ${formatCurrency(
      requiredToPay,
    )}`
    cashbackRefiText = `Refinance today for only ${formatCurrency(
      cashbackRequiredToPay,
    )} and receive up to `
  }

  return (
    <Container>
      <NumberStepper activeStep={2} steps={RefinanceSteps} />
      <Card className="my-3">
        <Card.Header className="text-center">
          <IoIosCheckmarkCircleOutline className={styles.icon} />
          <h1 className="my-2">
            <Content type={ContentSlot.LOANELIGIBILITY_HEADER} />
          </h1>
          <Content type={ContentSlot.LOANELIGIBILITY_DESCRIPTION} />
        </Card.Header>
        <Card.Body>
          {status === 'loading' && (
            <div className="text-center p-4">
              <Spinner
                animation="border"
                className="text-primary"
                role="output"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          <Row className="justify-content-around align-items-center">
            {disableCashBackRefiOnDefaultLoans === 'true' &&
            data?.currentLoanDetails?.actualLoanStatus === 'Default'
              ? null
              : refiOptions?.cashback && (
                  // Cash back eligible and has a valid card type on file and the card is fundable, then the cash back option is selectable
                  // TODO: related to debit card
                  // CardInfo !== undefined &&
                  // cardInfo.cardType !== null &&
                  // cardInfo.collateralCreditCardTokenLast4 !== null &&
                  // cardInfo.isFundable &&
                  <Col
                    className="d-lg-flex"
                    data-ref="first-refi-option"
                    lg="5"
                    xs="12"
                  >
                    <div className="my-3 m-lg-0 border w-100 rounded p-3 text-center">
                      <IconCashout />
                      <h3 className="family-normal my-2 h4">
                        <Content
                          type={ContentSlot.LOANELIGIBILITY_CASHOUTTITLE}
                        />
                      </h3>
                      <p className={styles.desc}>
                        {cashbackRefiText}
                        <strong>
                          {formatCurrency(refiOptions.cashback.cashbackAmount)}
                        </strong>
                      </p>
                      <Button
                        block
                        data-ref="button-cashback"
                        variant="primary"
                        onClick={chooseCashback}
                      >
                        <Content
                          type={ContentSlot.LOANELIGIBILITY_CASHOUTBUTTONTEXT}
                        />
                      </Button>
                    </div>
                  </Col>
                )}

            {refiOptions?.refinance && (
              <Col
                className="d-lg-flex"
                data-ref="second-refi-option"
                lg="5"
                xs="12"
              >
                <div className="my-3 m-lg-0 border w-100 rounded p-3 text-center">
                  <img
                    alt="Refinance"
                    className={` ${styles.refinanceIcon}`}
                    src={refiIcon}
                  />
                  <h3 className="family-normal my-2 h4">
                    <Content
                      type={ContentSlot.LOANELIGIBILITY_REFINANCETITLE}
                    />
                  </h3>
                  <p className={styles.desc}>{refiText}</p>
                  <Button
                    block
                    data-ref="button-refi"
                    variant="primary"
                    onClick={chooseRefinance}
                  >
                    <Content
                      type={ContentSlot.LOANELIGIBILITY_REFINANCEBUTTONTEXT}
                    />
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
        {showRefinancePayment && (
          <div data-ref="normal-refi">
            <RefinancePayment showNumberStepper={false} />
          </div>
        )}
        {showCashbackPayment && (
          <div data-ref="cashback-refi">
            <CashBackPayment showNumberStepper={false} />
          </div>
        )}
      </Card>
    </Container>
  )

  /**
   * Sets the option in cache and then redirects to next screen
   */
  function chooseCashbackCallback() {
    // Sets the option in cache
    setRefinanceOption('cashback')
    // Sets the option in a local state control prop.
    setSelectedRefiOption('cashback')
  }

  /**
   * Sets the option in cache
   */
  function chooseRefinanceCallback() {
    // Sets the option in cache
    setRefinanceOption('refinance')
    // Sets the option in a local state control prop.
    setSelectedRefiOption('refinance')
  }
}

/**
 * Shows the options available for refinance to user
 */
export default RefinanceOptions
