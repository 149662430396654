import {useEffect, useState} from 'react'

/**
 * Hook for debounce a value
 * @param value any
 * @param delay time in miliseconds
 * @return debounced value
 */
export const useDebounce = <Value>(value: Value, delay: number): Value => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])
  return debouncedValue
}
