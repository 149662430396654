import {useEffect} from 'react'

const FinishSignature = () => {
  useEffect(() => {
    window.opener = null
    window.open('', '_parent', '')
    window.close()
  }, [])

  return null
}

/**
 * Modal to confirm if the user really wants to delete a payment method
 */
export default FinishSignature
