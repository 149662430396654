import React, {useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'

import {ContentSlot} from 'models/Content'
import UhOh from 'components/UhOh'
import {AnalyticsPageNames} from 'models/Analytics'

const AccountLocked: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.ACCOUNT_LOCKED,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  return (
    <UhOh
      logoutOnBack
      back={ContentSlot.LOCKEDACCOUNT_BACK}
      callUs={ContentSlot.LOCKEDACCOUNT_CALLUS}
      cta={ContentSlot.LOCKEDACCOUNT_CTA}
      emailUs={ContentSlot.LOCKEDACCOUNT_EMAILUS}
      isIneligible={false}
      representative={ContentSlot.LOCKEDACCOUNT_REPRESENTATIVE}
      summary={ContentSlot.LOCKEDACCOUNT_SUMMARY}
      title={ContentSlot.LOCKEDACCOUNT_TITLE}
      visitAStore={ContentSlot.LOCKEDACCOUNT_VISITASTORE}
    />
  )
}

/**
 * Screen that shows when your account is locked
 */
export default AccountLocked
