/**
 * Data that can be changed
 */
export interface OnChangeData {
  location?: {
    lat: number
    lng: number
  }
  address?: string
  city?: string
  state?: string
  zip?: string
}

/** Place information. */
export interface Place {
  address_components?: google.maps.GeocoderAddressComponent[]
  geometry?: {
    location: {
      toJSON(): {lat: number; lng: number}
    }
  }
}

/** Options information. */
export interface Options {
  searchBox?: {
    getPlaces(): Place[]
  }
  onChange(data: OnChangeData): void
}

/** USA map boundaries. */
export const US_BOUNDS = {
  east: -67,
  north: 49,
  south: 25,
  west: -125,
}
