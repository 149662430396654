import React, {useState} from 'react'

import {Card, Row, Col, Button} from 'react-bootstrap'
import {GoPencil} from 'react-icons/go'

import ContactReadOnly from 'components/ContactInfo/Read'
import ContactEditForm from 'pages/Account/ContactInfo/ContactEditForm'

import styles from 'components/ContactInfo/ContactInfo.module.scss'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {CustomerDetails} from 'models/Edge'

/**
 * Contact Info props
 */
export interface Props {
  userData?: CustomerDetails
}

const ContactInfo: React.FC<Props> = ({userData}: Props) => {
  const [editMode, setEditMode] = useState(false)

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  return (
    <>
      {Boolean(userData) && (
        <Card className="mb-2 pb-0">
          <a className="anchor" href="#contact-info" id="contact-info">
            contact info
          </a>

          <Card.Header className="text-left" data-ref="contactInfo">
            <h2 className="border-bottom pb-2 border-medium font-weight-light d-flex justify-content-between">
              <Content type={ContentSlot.ACCOUNT_CONTACTINFOHEADER} />
              <Button
                className="p-0"
                data-ref="toggle-edit-contact"
                variant="link"
                onClick={toggleEditMode}
              >
                {(editMode && 'Cancel') || (
                  <>
                    <GoPencil /> Edit
                  </>
                )}
              </Button>
            </h2>
          </Card.Header>

          <Card.Body>
            <Row className={`${styles.Row}`}>
              <Col>
                <p className="mb-1">Name</p>
                <p>
                  <b>
                    {userData?.firstName} {userData?.lastName}
                  </b>
                </p>
              </Col>
            </Row>

            {(editMode && (
              <ContactEditForm
                toggleEditMode={toggleEditMode}
                userData={userData}
              />
            )) || (
              <ContactReadOnly
                // eslint-disable-next-line react/jsx-no-bind
                disableNextButtton={() => undefined}
                showVerifyButton={false}
                userData={userData}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  )
}

/**
 * Contact Info Card
 */
export default ContactInfo
