import React, {ReactChild} from 'react'

import {Col, Row} from 'react-bootstrap'
import FormattedDate from '../FormattedDate'

/**
/**
 * Interface for each row in the table
 */
export interface ListItem {
  label: string
  value: string | number | undefined
}

interface Props {
  empty?: string
  list: ListItem[]
  title: string | ReactChild
}

const TwoColumnTable: React.FC<Props> = ({empty, list, title}) => (
  <Row>
    <Col>
      <h3 className="my-3 family-normal text-left">{title}</h3>
      {list.length > 0 ? (
        list.map((item, idx) => (
          <Row
            key={idx}
            className={`my-3 ${idx === list.length ? 'mb-4' : ''}`}
          >
            <Col className="text-left " lg="6" xs="12">
              <strong>{item.label}</strong>
            </Col>
            {item.value && !item.label.includes('Date') && (
              <Col className="text-left text-lg-right" lg="6" xs="12">
                {item.value}
              </Col>
            )}
            <Col className="text-left text-lg-right" lg="6" xs="12">
              {item.value && item.label.match(/date/gi) && (
                <FormattedDate dueDate={item.value} />
              )}
            </Col>
          </Row>
        ))
      ) : (
        <p className="mb-3">{empty}</p>
      )}
    </Col>
  </Row>
)

/**
 * Renders a simple two column layout aligned to the edges and responsive
 */
export default TwoColumnTable
