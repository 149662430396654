import {vergentService} from 'utils/vergent-service'
import {
  SearchUserNameOrEmailResponse,
  SendResestEmailResponse,
} from 'models/Auth'
import {StandardError, VergentError} from 'models/Errors'
import {getConfigValue} from 'utils/environment'

const appUrl = getConfigValue('APP_URL')

/**
 * Request password update error types
 */
export enum RequestPasswordUpdateErrorTypes {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  VergentServiceError = 'VERGENT_SERVICE_ERROR',
}

/**
 * Sends password reset url to the email of the user
 * @param username from the user
 */
export const requestPasswordUpdateAction = async (username: string) => {
  let shouldReturnSameError = false
  try {
    if (!appUrl || !username) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordUpdateErrorTypes.InternalServerError,
      })
    }

    const {
      data: {resetToken},
    } = await vergentService.get<SearchUserNameOrEmailResponse>(
      `/VergentCustomerPasswordReset/Search/${username}`,
    )
    const formattedResetToken = resetToken.replace(/"/g, '')

    if (!formattedResetToken) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordUpdateErrorTypes.InternalServerError,
      })
    }

    const {
      data: {isEmailSentSuccessfully},
    } = await vergentService.post<SendResestEmailResponse>(
      `/VergentCustomerPasswordReset/SendResetEmail/${formattedResetToken}`,
      `"${appUrl}/reset-password"`,
    )

    if (!isEmailSentSuccessfully) {
      shouldReturnSameError = true
      throw new StandardError({
        type: RequestPasswordUpdateErrorTypes.InternalServerError,
      })
    }
  } catch (error) {
    if (shouldReturnSameError) throw error

    const {errorInstance, errorWasHandled} = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance) {
      throw new StandardError({
        type: RequestPasswordUpdateErrorTypes.InternalServerError,
      })
    }

    throw new StandardError({
      type: RequestPasswordUpdateErrorTypes.VergentServiceError,
    })
  }
}
