/* eslint-disable max-classes-per-file */
import {AxiosError} from 'axios'

/**
 * Struture to standarize the errors on the app
 */
export class StandardError extends Error {
  type?: string | null
  errorInstance?: AxiosError | Error | null
  trackableMessage?: string | null
  auxiliarNumberProp?: number | null
  auxiliarStringProp?: string | null

  constructor({
    type,
    errorInstance,
    trackableMessage,
    auxiliarNumberProp,
    auxiliarStringProp,
  }: StandardErrorConstructorArgs) {
    super(errorInstance?.message ?? undefined)
    this.type = type
    this.errorInstance = errorInstance
    this.trackableMessage = trackableMessage
    this.auxiliarNumberProp = auxiliarNumberProp
    this.auxiliarStringProp = auxiliarStringProp
  }
}

interface StandardErrorConstructorArgs {
  type?: string | null
  errorInstance?: AxiosError | Error | null
  trackableMessage?: string | null
  auxiliarNumberProp?: number | null
  auxiliarStringProp?: string | null
}

/**
 *  Vergent error structure returned from the vergent service
 */
export class VergentError extends Error {
  status: number
  type: string | null
  title: string | null
  instance: string | null
  errors: string[] | null
  errorType: string | null
  errorWasHandled: boolean
  errorMessage: string | null
  correlationId: string | null
  additionalProp1: string | null
  additionalProp2: string | null
  additionalProp3: string | null
  errorInstance: AxiosError | null

  constructor({
    type,
    title,
    errors,
    status,

    instance,
    errorType,
    errorMessage,
    correlationId,
    errorInstance,
    additionalProp1,
    additionalProp2,
    additionalProp3,
    errorWasHandled,
  }: VergentErrorConstructorArgs) {
    super(errorMessage ?? undefined)
    this.type = type
    this.title = title
    this.errors = errors
    this.status = status

    this.instance = instance
    this.errorType = errorType
    this.errorMessage = errorMessage
    this.correlationId = correlationId
    this.additionalProp1 = additionalProp1
    this.additionalProp2 = additionalProp2
    this.additionalProp3 = additionalProp3
    this.errorWasHandled = errorWasHandled
    this.errorInstance = errorInstance
  }
}

/**
 * Vergent error values returned from the vergent service
 */
export interface VergentErrorConstructorArgs {
  status: number
  type: string | null
  title: string | null
  instance: string | null
  errors: string[] | null
  errorType: string | null
  errorWasHandled: boolean
  errorMessage: string | null
  correlationId: string | null
  additionalProp1: string | null
  additionalProp2: string | null
  additionalProp3: string | null
  errorInstance: AxiosError | null
}

/**
 * In an event when an unknown error occurs
 */
export class UnknownError extends StandardError {}
