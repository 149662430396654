/* eslint-disable no-nested-ternary */
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {Routes as Path} from 'models/Routes'
import AccountIssue from 'pages/AccountIssue'
import AccountPage from 'pages/Account'
import AccountLocked from 'pages/AccountLocked'
import AddDebitCard from 'pages/AddDebitCard'
import CashBack from 'pages/CashBack'
import SignDocuments from 'pages/SignDocuments'
import EmployerInfoChanged from 'pages/EmployerInfoChanged'
import Refinance from 'pages/Refinance'
import CreateAccountPage from 'pages/CreateAccount'
import FindLoanPage from 'pages/FindLoan'
import ForgotPassword from 'pages/ForgotPassword'
import HomePage from 'pages/Home'
import LoginPage from 'pages/Login'
import MakePaymentConfirm from 'pages/MakePayment'
import MakePaymentReceipt from 'pages/MakePaymentReceipt'
import NoLoans from 'pages/NoLoans'
import NotFound from 'pages/NotFound'
import Reactivate from 'pages/Reactivate'
import ReactivationIssue from 'pages/ReactivationIssue'
import RefinanceOptionsPage from 'pages/RefinanceOptions'
import RefinanceConfirmInformation from 'pages/ConfirmInfo'
import RefinanceIssue from './pages/RefinanceIssue'
import ThankYouPage from 'pages/ThankYouPage'
import ResetPassword from 'pages/ResetPassword'
import ServiceOutage from 'pages/ServiceOutage'
import ConfirmOrigination from 'pages/ConfirmOrigination'
import ContactUs from 'pages/ContactUs'
import CashwiseWelcomePage from 'pages/Cashwise/welcome-back'
import CashWiseLandingPage from 'pages/Cashwise/landing'
import {useScrollToTop} from 'utils/hooks/useScrollToTop'
import {getConfigValue} from 'utils/environment'
import BuildInfo from 'pages/BuildInfo'
import {useAuth} from './auth'
import AddDebitCardAndPay from './pages/AddDebitCardAndPay'
import IdVerification from 'pages/IdVerification'
import IdVerificationIssue from 'pages/IdVerificationIssue'
import MultiFactorAuthentication from './pages/MultiFactorAuthentication'

const cashwiseEnabled = getConfigValue('CASHWISE_ENABLED') === 'true'

/**
 * Router Option for tracking cashback versus refinance flow
 */
export interface RouteState {
  option: RouteOptions.CASHBACK | RouteOptions.REFINANCE
}

/**
 * Route Options for cashback or refinance flows
 */
export enum RouteOptions {
  CASHBACK = 'cashback',
  REFINANCE = 'refinance',
}

const RedirectToHome = () => <Redirect to={Path.HOME} />
const RedirectToNotFound = () => <Redirect to={Path.NOT_FOUND} />

const Routes: React.FC = () => {
  const {isLoggedIn, isAccountLocked} = useAuth()

  useScrollToTop()

  const publicRoutes = () => [
    <Route key="not-found" exact component={NotFound} path={Path.NOT_FOUND} />,
    <Route
      key="outage"
      exact
      component={ServiceOutage}
      path={Path.SERVICE_OUTAGE}
    />,
    <Route
      key="buildinfo"
      exact
      component={BuildInfo}
      path={Path.BUILDINFO}
    />,
  ]

  const cashwiseRoutes = () => [
    <Route
      key="cashwise-landing"
      exact
      component={CashWiseLandingPage}
      path={Path.CASHWISE_LANDING}
    />,
    <Route
      key="cashwise-success"
      exact
      component={CashwiseWelcomePage}
      path={Path.CASHWISE_WELCOME_BACK}
    />,
  ]

  const lockedAccount = () => (
    <Switch>
      <Route exact component={AccountLocked} path={Path.ACCOUNT_LOCKED} />
      <Redirect to={Path.ACCOUNT_LOCKED} />
    </Switch>
  )

  const loggedInCustomerRoutes = () => (
    <Switch>
      <Route exact component={HomePage} path={Path.HOME} />
      <Route exact component={RedirectToHome} path={Path.BASE} />
      <Route exact component={RedirectToHome} path={Path.LOGIN} />
      <Route exact component={RedirectToHome} path={Path.REGISTER} />
      <Route
        exact
        component={RedirectToHome}
        path={Path.MULTIFACTOR_AUTHENTICATION}
      />
      <Route exact component={AccountPage} path={Path.ACCOUNT} />
      <Route exact component={AccountIssue} path={Path.ACCOUNT_ISSUE} />

      <Route exact component={AddDebitCard} path={Path.ADD_CARD} />
      <Route
        exact
        component={AddDebitCardAndPay}
        path={Path.ADD_CARD_AND_PAY}
      />

      <Route exact component={CashBack} path={Path.CASHBACK} />
      <Route exact component={EmployerInfoChanged} path={Path.EMPLOYER} />
      <Route exact component={Reactivate} path={Path.REACTIVATE} />
      <Route
        exact
        component={ReactivationIssue}
        path={Path.REACTIVATE_ISSUE}
      />
      <Route exact component={RefinanceIssue} path={Path.REFINANCE_ISSUE} />
      <Route
        exact
        component={RefinanceOptionsPage}
        path={Path.CHOOSE_REFINANCE}
      />
      <Route
        exact
        component={MakePaymentConfirm}
        path={Path.CONFIRM_PAYMENT}
      />
      <Route
        exact
        component={MakePaymentReceipt}
        path={Path.PAYMENT_RECEIPT}
      />
      <Route
        exact
        component={RefinanceConfirmInformation}
        path={Path.CONFIRM_INFO}
      />
      <Route
        exact
        component={ConfirmOrigination}
        path={Path.CONFIRM_ORIGINATION}
      />
      <Route exact component={Refinance} path={Path.REFINANCE} />
      <Route exact component={SignDocuments} path={Path.SIGN_DOCUMENTS} />
      <Route exact component={ContactUs} path={Path.CONTACT_US} />
      <Route exact component={ContactUs} path={Path.CONTACT_US_MLA} />
      <Route exact component={ContactUs} path={Path.CONTACT_US_NOAA} />
      <Route exact component={ContactUs} path={Path.CONTACT_US_NOIA} />
      <Route exact component={ResetPassword} path={Path.RESET_PASS} />
      <Route exact component={ThankYouPage} path={Path.REFINANCE_THANKS} />
      <Route exact component={ThankYouPage} path={Path.REACTIVATION_THANKS} />
      <Route exact component={ThankYouPage} path={Path.CASHBACK_THANKS} />
      {publicRoutes()}
      <Route component={RedirectToNotFound} />
    </Switch>
  )

  const loggedOutRoutes = () => (
    <Switch>
      <Route exact component={CreateAccountPage} path={Path.REGISTER} />
      <Route exact component={ForgotPassword} path={Path.FORGOT_PASS} />
      <Route exact component={FindLoanPage} path={Path.FIND_LOAN} />
      <Route exact component={IdVerification} path={Path.ID_VERIFICATION} />
      <Route
        exact
        component={MultiFactorAuthentication}
        path={Path.MULTIFACTOR_AUTHENTICATION}
      />
      <Route
        exact
        component={IdVerificationIssue}
        path={Path.ID_VERIFICATION_ISSUE}
      />
      <Route exact component={ResetPassword} path={Path.RESET_PASS} />
      <Route exact component={LoginPage} path={Path.LOGIN} />
      <Route exact component={LoginPage} path={Path.BASE} />
      <Route exact component={NoLoans} path={Path.NO_LOANS} />
      <Route exact component={AccountIssue} path={Path.ACCOUNT_ISSUE} />
      {publicRoutes()}
      {cashwiseEnabled && cashwiseRoutes()}
      <Route component={RedirectToNotFound} />
    </Switch>
  )

  return (
    <>
      {/*User has locked their account */}
      {isAccountLocked && lockedAccount()}

      {/* User has an account and has connected their customer loan info */}
      {isLoggedIn && loggedInCustomerRoutes()}

      {/* Guest has not created an account */}
      {!isLoggedIn && loggedOutRoutes()}
    </>
  )
}

/**
 * Defines the routes and controls which ones are limited by authentication
 */
export default Routes
