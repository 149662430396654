import React from 'react'

import parse, {HTMLReactParserOptions} from 'html-react-parser'
import Content from '.'

interface EmbeddedRenderableProps {
  contentHtml: string
}

const DATA_CODENAME_KEY = 'data-codename'

interface CustomDomNode {
  attribs: {
    [DATA_CODENAME_KEY]: string
  }
}

/**
 * Allows other Content types to be rendered within this Embedded Content type
 * @return embedded content
 */
export const EmbeddedRenderable: React.FC<EmbeddedRenderableProps> = ({
  contentHtml,
}: EmbeddedRenderableProps) => {
  const htmlString: string = stripParagraphTagFromHtml(contentHtml)
  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (isDomNodeContentComponent(domNode)) {
        return <Content type={domNode.attribs[DATA_CODENAME_KEY]} />
      }
      return domNode
    },
  }
  const doc = parse(htmlString, options)
  return <>{doc}</>
}

/**
 * Verifies that the DOMNode is or is not a Content Component
 * @param domNode DOMNode
 * @return boolean
 */
function isDomNodeContentComponent(
  domNode: unknown,
): domNode is CustomDomNode {
  return Boolean(
    (domNode as Partial<CustomDomNode>).attribs &&
      (domNode as Partial<CustomDomNode>).attribs?.[DATA_CODENAME_KEY],
  )
}

/**
 * Strips Paragraph <p> and </p> tags from an html string
 * @param html string
 * @return string
 */
function stripParagraphTagFromHtml(html: string): string {
  return html.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')
}
