import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import {CurrentLoanDetails, ScheduleItem} from 'models/Edge'
import {CashStoreLocation} from 'models/CashStoreLocation'
import {
  getClosingStoreHours,
  getOpeningStoreHours,
} from 'utils/data-formatting'

dayjs.extend(utc)
dayjs.extend(timezone)

try {
  new Intl.DateTimeFormat('en', {
    timeZone: 'America/Los_Angeles',
    timeZoneName: 'long',
  }).format()
} catch (error) {
  import('date-time-format-timezone')
}

/**
 * Format for dates used globally
 */
export const YYYYMMDD = 'YYYY-MM-DD'

/**
 * Gets a timezone from an abbreviation
 * @param abbr time zone abbreviation
 * @return JS standard timezone
 */
export function getTimezoneFromAbbrev(abbr: string) {
  switch (abbr) {
    case 'pst':
      return 'America/Los_Angeles'
    case 'mst':
      return 'America/Denver'
    case 'cst':
      return 'America/Chicago'
    case 'est':
      return 'America/New_York'
    case 'utc':
      return 'UTC'
    default:
      return 'America/Chicago'
  }
}

/**
 * Asc order function
 * @param date1 First Date to Compare
 * @param date2 Second Date to Compare
 * @return compare return Value
 */
export const dateSortAsc = (date1: string, date2: string) => {
  let returnVal = 0
  const firstDate = new Date(date1)
  const secondDate = new Date(date2)
  if (firstDate > secondDate) {
    returnVal = 1
  }
  if (firstDate < secondDate) {
    returnVal = -1
  }
  return returnVal
}

/**
 * Determines if the duplicate payment warning should be shown.
 * If the user has autopay enabled, if it is after closing on their due date
 * @param loan loan details
 * @param date date to verify
 * @param timezoneAbbreviation string of abbreviation mst, cst, etc
 * @param closingHour closing time in PM
 * @return boolean that determines if warning should be shown
 */
export function shouldShowDuplicatePaymentWarningByStoreHours(
  loan: CurrentLoanDetails,
  date: Date,
  timezoneAbbreviation: string,
  closingHour: number,
) {
  const storeTimezone = getTimezoneFromAbbrev(timezoneAbbreviation)
  const closing = dayjs()
    .tz(storeTimezone)
    .hour(closingHour + 12)
    .minute(0)
    .second(0)
  const now = dayjs(date)
  const tomorrowMidnight = dayjs(date)
    .add(1, 'day')
    .hour(0)
    .minute(0)
    .second(0)

  // The nextPaymentDate will be the upcoming loan payment's graceDueDate
  const nextPaymentDate = dayjs(loan.nextPaymentDate)
  return (
    now.isAfter(closing) &&
    now.isBefore(tomorrowMidnight) &&
    now.isSame(nextPaymentDate, 'day') &&
    loan.isAutoPaymentEnabled &&
    loan.nextPaymentAmount > 0
  )
}

/**
 * Check if today is a dueDate in the user's loanSchedule. If it is, then verify if a payment has been sent today in the payment transactions
 * @param loan Loan Details
 * @param date Date to verify
 * @return boolean
 */
export function shouldShowDuplicatePaymentWarningByPaymentToday(
  loan: CurrentLoanDetails,
  date: Date,
) {
  const now = dayjs(date)

  let isADueDate = false

  loan.loanSchedule.forEach(scheduleItem => {
    const loanScheduleDate = dayjs(scheduleItem.graceDueDate)
    if (loanScheduleDate.isSame(now, 'day')) {
      isADueDate = true
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!isADueDate) {
    return false
  }

  let paymentAlreadyMadeToday = false
  loan.paymentTransactions.forEach(transaction => {
    const transactionDate = dayjs(transaction.postingDate)
    if (transactionDate.isSame(now, 'day')) {
      paymentAlreadyMadeToday = true
    }
  })

  return paymentAlreadyMadeToday
}

/**
 * Helper function to determine whether it is the customer's final pay period
 * but also before their due date
 * @return boolean to be used by state setter
 * @param currentLoan current loan details
 * @param localDate the current date
 * @param storeTimezone The store's timezone
 */
export function isFinalPayPeriodBeforeDueDate(
  currentLoan: CurrentLoanDetails,
  localDate: Date,
  storeTimezone?: string,
): boolean {
  if (currentLoan.loanSchedule.length > 0) {
    const customerStoreDate = dayjs(localDate).tz(
      getTimezoneFromAbbrev(storeTimezone ?? ''),
    )

    // Sorted loan schedule array based on dueDate from farthest => nearest
    const sortedLoanSchedule = currentLoan.loanSchedule.sort(
      (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime(),
    )

    const finalPayPeriodDueDate = dayjs(sortedLoanSchedule[0].dueDate)

    if (
      currentLoan.loanSchedule.length === 1 &&
      customerStoreDate.isBefore(finalPayPeriodDueDate)
    ) {
      return true
    }
    if (currentLoan.loanSchedule.length > 1) {
      // Add 1 day to this date, because otherwise we're comparing to dueDate at 12am, we need to be comparing to following day at 12am
      const secondToLastPaymentDueDate = dayjs(
        sortedLoanSchedule[1].dueDate,
      ).add(1, 'd')
      return (
        customerStoreDate.isBefore(finalPayPeriodDueDate) &&
        customerStoreDate.isAfter(secondToLastPaymentDueDate)
      )
    }
  }

  return false
}

/**
 * * Helper function to determine days between today and last payment day of loan schedule
 * @param first the start date
 * @param second the end date
 * @return number
 */
export function daysBetween(first: Date, second: Date): number {
  const firstPaymentDate = dayjs(first)

  const lastPaymentDate = dayjs(second)

  // Add 1 to the result, .diff() here is not inclusive of "today" as a day
  return lastPaymentDate.diff(firstPaymentDate, 'day') + 1
}

/**
 * * Helper function to determine payment time frame in days between loanSchedule
 * @param scheduleItem array of schedule items
 * @return number
 */
export function paymentTimeFrame(scheduleItem: ScheduleItem[]): string {
  const firstPaymentDate = dayjs(scheduleItem[0]?.dueDate)

  const secondPaymentDate = dayjs(scheduleItem[1]?.dueDate)

  if (secondPaymentDate.diff(firstPaymentDate, 'day') === 14) {
    return 'every two weeks'
  }
  return `monthly`
}

/**
 * * Helper function to create a reusable responsive date format
 * @param dueDate string of date
 * @return number
 */
export function largeScreenFormattedDate(dueDate: Date | number | string) {
  const dateFormat = 'MMM DD, YYYY'
  const dayFormat = 'dddd'
  return dayjs(dueDate).format(`${dayFormat}, ${dateFormat}`)
}

/**
 * * Helper function to create a reusable responsive date format
 * @param dueDate string of date
 * @return number
 */
export function mobileScreenFormattedDate(dueDate: Date | number | string) {
  const shortFormat = 'MM/DD/YYYY'
  const dayFormat = 'dddd'
  return dayjs(dueDate).format(`${dayFormat}, ${shortFormat}`)
}

/**
 * * Helper function to create a reusable responsive date format
 * @param dueDate string of date
 * @return number
 */
export function dateOnlyFormattedDate(dueDate: Date | number | string) {
  const shortFormat = 'MM/DD/YYYY'
  return dayjs(dueDate).format(`${shortFormat}`)
}

/**
 * Helper function to determine whether or not the store passed in to the
 * function is open
 * @return boolean is CashStoreLocation store during open hours
 * @param store CashStoreLocation
 * @param referenceDate Date | undefined
 */
export function isDuringStoreHours(
  store: CashStoreLocation,
  referenceDate: Date = new Date(),
): boolean {
  const referenceOpeningHour = getOpeningStoreHours(store, referenceDate)
  const referenceClosingHour = getClosingStoreHours(store, referenceDate)

  if (referenceOpeningHour && referenceClosingHour) {
    const storeTimezone = getTimezoneFromAbbrev(store.timezone.codename)
    const closingDate = dayjs()
      .tz(storeTimezone)
      .hour(parseInt(referenceClosingHour) + 12)
      .minute(0)
      .second(0)
    const openingDate = dayjs()
      .tz(storeTimezone)
      .hour(parseInt(referenceOpeningHour))
      .minute(0)
      .second(0)
    const todayDate = dayjs(referenceDate).tz(storeTimezone)

    return todayDate.isAfter(openingDate) && todayDate.isBefore(closingDate)
  }
  return false
}

/**
 * Function to return date in format YYYY/MM/DD
 * @param dueDate string of date
 * @return number
 */
export function getFormatedFullDate(dueDate: Date) {
  return dayjs(dueDate).format(YYYYMMDD)
}
