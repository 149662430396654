import React, {useCallback} from 'react'

import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'
import {LoanPaymentFormValues} from 'models/Edge'

/**
 * Delinquent Minimum popup props interface
 */
export interface MinimumPaymentPopupProps {
  paymentFormValues?: LoanPaymentFormValues | null
  visibility: boolean
  toggleVisiblity(visibility: boolean): void
  navigateToConfirmPayment?(values: LoanPaymentFormValues): void
}

const MinimumPaymentPopup: React.FC<MinimumPaymentPopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisiblity(false), [props])

  const navigateToConfirmCallback = useCallback(() => {
    if (props.navigateToConfirmPayment && props.paymentFormValues) {
      props.navigateToConfirmPayment(props.paymentFormValues)
    }
  }, [props])

  const minimumPaymentContent = (
    <>
      <Content
        data-ref="minimumPayment-warning"
        type={ContentSlot.DELINQUENT_MINIMUM_POPUP}
      />
      <div data-ref="minimumPayment-warning" />
      <Button
        data-ref="close-minimumPayment-popup"
        variant="link"
        onClick={handleClose}
      >
        Go Back
      </Button>
      <Button
        data-ref="continue-minimumPayment-popup"
        variant="primary"
        onClick={navigateToConfirmCallback}
      >
        Continue
      </Button>
    </>
  )
  return (
    <>
      <AbstractPopup
        warning
        content={minimumPaymentContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default MinimumPaymentPopup
