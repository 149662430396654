import React from 'react'

import {Button, ButtonProps, Spinner} from 'react-bootstrap'

import styles from 'components/SpinnerButton/SpinnerButton.module.scss'

interface SpinnerButtonProps
  extends ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  loading?: boolean
  color?: string
}

const SpinnerButton: React.FC<SpinnerButtonProps> = props => {
  const {children, loading, disabled, ...rest} = props
  return (
    <Button {...rest} disabled={loading === true || disabled === true}>
      <div className={styles.container}>
        <div
          className={`${styles.spinner} ${
            loading ? styles.visible : styles.hidden
          }`}
        >
          <Spinner animation="border" as="span">
            <span className="sr-only">Loading</span>
          </Spinner>
        </div>
        <div className={loading ? styles.hidden : styles.visible}>
          {children}
        </div>
      </div>
    </Button>
  )
}

/**
 * Bootstrap button with loading prop
 */
export default SpinnerButton
