import {useLoanData} from 'utils/hooks/useLoanData'
import {RefinanceOption, RefinanceType} from 'models/Edge'

/**
 * Retrieves the available options of refinancing for a customer
 * @return specifically 1-2 options in an array
 */
export function useRefinanceData() {
  const {data, status, refinanceApprovalDetails} = useLoanData()

  if (!data || !refinanceApprovalDetails) {
    return {data, status, refiOptions: null}
  }

  const cashbackApproved = refinanceApprovalDetails.hasCashbackOption
  const refinanceApproved = refinanceApprovalDetails.hasRefinanceOption

  let opts = {
    cashback: {} as RefinanceOption,
    refinance: {} as RefinanceOption,
  }

  if (
    (cashbackApproved || refinanceApproved) &&
    data.currentLoanDetails.refinanceApprovalDetails
  ) {
    opts = data.currentLoanDetails.refinanceApprovalDetails.refinanceOptions.reduce(
      (acc, current) => {
        if (current.refinanceType === RefinanceType.CASHBACK) {
          return {
            ...acc,
            cashback: current,
          }
        } else if (
          acc.refinance.requiredToPay > current.requiredToPay ||
          !acc.refinance.requiredToPay
        ) {
          return {
            ...acc,
            refinance: current,
          }
        }

        return acc
      },
      {cashback: {} as RefinanceOption, refinance: {} as RefinanceOption},
    )
  }
  return {data, status, refiOptions: opts, cashbackApproved, refinanceApproved}
}
