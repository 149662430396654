import React from 'react'

import {useLoanData} from 'utils/hooks/useLoanData'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'

const DebugSection: React.FC = () => {
  const {data, currentLoan} = useLoanData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)

  return (
    <div className="p-3">
      <h1 className="display-5">DEBUGGER SECTION:</h1>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 py-6 px-0">
            <p className="lead">
              <code>Edge Service endpoint /loans/details hook</code>
            </p>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
          <div className="col-12 col-md-6 px-0 pl-3 py-6 border-left border-dark">
            <p className="lead">
              <code>
                Edge Service endpoint /stores/{'{'}storeId{'}'} hook
              </code>
            </p>
            <pre>{JSON.stringify(store, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Debugger Section to be visual below the application footer while the debugger env variable is set to true.
 * @return rendered DebugSection
 */
export default DebugSection
