import React from 'react'

import {Container, Row, Col} from 'react-bootstrap'

import styles from 'components/NumberStepper/NumberStepper.module.scss'
import Step from 'components/NumberStepper/NumberStepperStep'

/**
 * Props interface for NumberStepper
 */
export interface Props {
  activeStep: number
  steps: string[]
}

const NumberStepper: React.FC<Props> = ({steps, activeStep}: Props) => (
  <nav>
    <Container fluid className={styles.NumberStepper}>
      <Row className="justify-content-center py-3 py-md-5">
        {steps.map((step, stepIdx) => {
          const currentStep = stepIdx + 1
          return (
            <Col key={stepIdx} xs="4">
              <Step
                active={activeStep === currentStep}
                completed={activeStep > currentStep}
                number={currentStep}
                text={step}
              />
            </Col>
          )
        })}
      </Row>
    </Container>
  </nav>
)

/**
 * Number stepper
 * @return rendered component
 */
export default NumberStepper
