import React, {SVGProps} from 'react'

const IdCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={80}
    viewBox="0 0 80 80"
    width={80}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.792 50.313h11.25a2.813 2.813 0 0 0 0-5.626h-11.25a2.813 2.813 0 0 0-2.813 2.813 2.817 2.817 0 0 0 2.813 2.813ZM31.042 47.5a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5 7.5 7.5 0 1 0 0 15Zm35.625-33.75h-52.5a7.5 7.5 0 0 0-7.5 7.5v37.5a7.5 7.5 0 0 0 7.5 7.5h52.5a7.5 7.5 0 0 0 7.5-7.5v-37.5c0-4.143-3.364-7.5-7.5-7.5Zm1.875 45a1.877 1.877 0 0 1-1.875 1.875h-22.5a9.375 9.375 0 0 0-9.375-9.375h-7.5a9.372 9.372 0 0 0-9.375 9.375h-3.75a1.877 1.877 0 0 1-1.875-1.875v-30h56.25v30Zm-18.75-17.813h11.25a2.813 2.813 0 0 0 0-5.624h-11.25a2.813 2.813 0 0 0-2.813 2.812 2.817 2.817 0 0 0 2.813 2.813Z"
      fill="#5E5E5E"
    />
  </svg>
)

/**
 * IdCardIcon
 */
export default IdCardIcon
