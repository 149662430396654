import React, {useCallback, useEffect, useState} from 'react'

import GlobalToast, {ToastMessage} from 'components/Toast/Toast'
import styles from 'components/Toast/ToastWrapper.module.scss'
import {getConfigValue} from 'utils/environment'
import {guid} from 'utils/guid'

/**
 * Toast context type
 */
export interface ToastContextType {
  toastQueue: ToastMessage[]
  pushToast: (toast: ToastMessage) => void
  clearAllToasts: () => void
}

const toastInterval = Number(getConfigValue('TOAST_INTERVAL'))
const defaultErrorMessage = 'There was an error processing your request'

/**
 * Context that allows you to send a new toast
 */
export const ToastContext = React.createContext({} as ToastContextType)

/**
 * Sets up the Toast Provider that exposes a toast queue to the rest of the application
 * @return component
 */
export const ToastWrapper: React.FC = ({children}) => {
  /**
   * State, Hooks
   */
  const [toastQueue, setToastQueue] = useState([] as ToastMessage[])

  /**
   * Effects, Callbacks
   */
  useEffect(timeoutEffect, [toastQueue])
  const pushToast = useCallback(pushToastCallback, [setToastQueue])
  const clearAllToasts = useCallback(clearToastsCallback, [setToastQueue])
  const removeToastByIndex = useCallback(removeToastByIndexCallback, [
    setToastQueue,
  ])

  return (
    <ToastContext.Provider value={{toastQueue, pushToast, clearAllToasts}}>
      <div className="position-relative h-100">
        {toastQueue.length > 0 && (
          <div className={styles.toastWrapper}>
            {toastQueue.map((toast, index) => (
              <GlobalToast
                key={toast.id}
                index={index}
                toast={toast}
                onToastClose={removeToastByIndex}
              />
            ))}
          </div>
        )}

        <div className="position-relative h-100">{children}</div>
      </div>
    </ToastContext.Provider>
  )

  /**
   * Sets and unsets timer
   * @return function
   */
  function timeoutEffect() {
    const timer = setTimeout(() => {
      if (toastQueue.length > 0) {
        setToastQueue(toastQueue.slice(1))
      }
    }, toastInterval)
    return () => clearTimeout(timer)
  }

  /**
   * Pushes a toast onto the queue
   * @param toast new toast to add
   */
  function pushToastCallback(toast: ToastMessage) {
    setToastQueue(prevToastQueue => {
      const lastToast = prevToastQueue[prevToastQueue.length - 1]
      const isSameToast =
        Boolean(lastToast) &&
        lastToast.title === toast.title &&
        lastToast.message === (toast.message ?? defaultErrorMessage) &&
        lastToast.variant === toast.variant

      return isSameToast
        ? prevToastQueue
        : [
            ...prevToastQueue,
            {
              id: guid(),
              ...toast,
              message: toast.message ?? defaultErrorMessage,
            },
          ]
    })
  }

  /**
   * Removes a toast at a particular index in the queue
   * @param index index to remove
   */
  function removeToastByIndexCallback(index: number) {
    setToastQueue(prevToastQueue => [
      ...prevToastQueue.slice(0, index),
      ...prevToastQueue.slice(index + 1, prevToastQueue.length),
    ])
  }

  /**
   * Removes all toasts
   */
  function clearToastsCallback() {
    setToastQueue([])
  }
}
