import React, {SyntheticEvent, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Row,
  Col,
  Form as BootstrapForm,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import {GiInfo} from 'react-icons/gi'

import Dropdown, {InputOpt} from 'components/Form/Dropdown'
import {
  PaymentMethodTypes,
  LoanPaymentFormValues,
  CustomerCard,
} from 'models/Edge'
import {FormikErrors, FormikTouched} from 'formik'
import MaskedInput from 'react-text-mask'

import styles from 'components/Form/DebitCardsDropdown/DebitCardsDropdown.module.scss'

import {cvvMask} from 'utils/forms/masks'

/**
 * Props for Debit Card
 */
export interface Props {
  cardInfo?: CustomerCard[]
  paymentMethod: string
  cvv?: string
  errors: FormikErrors<LoanPaymentFormValues>
  touched: FormikTouched<LoanPaymentFormValues>
  handleBlur: (event: SyntheticEvent<HTMLInputElement>) => void
  handleChange: (event: SyntheticEvent<HTMLInputElement>) => void
  idSuffix?: string
  isDisbursement?: boolean
  showOneTimeCard?: boolean
  showAddCard?: boolean
  onAddCard?: () => void
  showAddCardAndPay?: boolean
}

const DebitCardsDropdown: React.FC<Props> = ({
  cardInfo,
  handleBlur,
  handleChange,
  paymentMethod,
  cvv,
  errors,
  touched,
  idSuffix = '',
  isDisbursement,
  showOneTimeCard,
  onAddCard,
  showAddCard,
  showAddCardAndPay,
}) => {
  const history = useHistory()

  const customHandleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (
        (event.target as HTMLInputElement).value ===
        PaymentMethodTypes.NEW_CARD
      ) {
        onAddCard?.()
        history.push(`/add-card${isDisbursement ? '#disbursement' : ''}`, {
          from: history.location.pathname,
        })
      } else {
        handleChange(event)
      }
    },
    [handleChange, history, isDisbursement, onAddCard],
  )

  const paymentOptions: InputOpt[] = []

  const oneTimeCard: InputOpt = {
    value: PaymentMethodTypes.ONE_TIME_CARD,
    label: (
      <span className="font-weight-bold text-primary">
        Use a Card for a One-Time Payment
      </span>
    ),
  }
  const addCard: InputOpt = {
    value: PaymentMethodTypes.NEW_CARD,
    label: <span className="font-weight-bold text-primary">Add a Card</span>,
  }
  const addCardAndPay: InputOpt = {
    value: PaymentMethodTypes.ADD_CARD_AND_PAY,
    label: <span className="font-weight-bold text-primary">Add a Card</span>,
  }

  if (showAddCard) {
    paymentOptions.push(addCard)
  }

  if (!isDisbursement && showOneTimeCard) {
    paymentOptions.push(oneTimeCard)
  }

  if (!isDisbursement && showAddCardAndPay) {
    paymentOptions.push(addCardAndPay)
  }

  cardInfo?.forEach(card => {
    if (card.accountNumberMasked) {
      paymentOptions.push({
        value: isDisbursement
          ? card.id.toString()
          : `${PaymentMethodTypes.EXISTING_CARD}:${card.id}`,
        label: (
          <span>
            {card.cardType ?? ''} ending in {card.accountNumberMasked}
          </span>
        ),
      })
    }
  })
  // Only add this option if last 4 digits for an existing debit card exist

  const matchExistingPaymentMethod = /^(.+?):/
  const isExistingCard = matchExistingPaymentMethod.exec(paymentMethod)

  const showError =
    (isDisbursement &&
      touched.disbursementMethod &&
      errors.disbursementMethod) ||
    (!isDisbursement && touched.paymentMethod && errors.paymentMethod)

  const error = isDisbursement
    ? errors.disbursementMethod
    : errors.paymentMethod

  return (
    <>
      <Row>
        <Col>
          <BootstrapForm.Group controlId={`paymentMethod${idSuffix}`}>
            <BootstrapForm.Label>
              {isDisbursement
                ? 'Select the debit card you would like to use for your disbursement and automatic payments.*'
                : 'Payment Method.*'}
            </BootstrapForm.Label>

            <Dropdown
              formValue={paymentMethod}
              handleBlur={handleBlur}
              handleChange={customHandleChange}
              id={
                isDisbursement
                  ? `disbursementMethod${idSuffix}`
                  : `paymentMethod${idSuffix}`
              }
              isDisbursement={isDisbursement}
              name={isDisbursement ? 'disbursementMethod' : 'paymentMethod'}
              options={paymentOptions}
            />
            {showError && (
              <BootstrapForm.Text className="text-danger">
                {error}
              </BootstrapForm.Text>
            )}
          </BootstrapForm.Group>
        </Col>
      </Row>
      {isExistingCard && !isDisbursement && (
        <Row>
          <Col>
            <BootstrapForm.Group controlId={`paymentCvv${idSuffix}`}>
              <BootstrapForm.Label>
                CVV
                <OverlayTrigger
                  overlay={
                    <Popover className={styles.cvvTooltip} id="tooltip-right">
                      <Popover.Content className="px-1 pl-2">
                        CVV is the 3-digit security code on the back of your
                        card.
                      </Popover.Content>
                    </Popover>
                  }
                  placement="right"
                >
                  <sup className="family-bold h6">
                    <GiInfo />
                  </sup>
                </OverlayTrigger>
              </BootstrapForm.Label>
              <MaskedInput
                className="form-control fs-exclude"
                data-ref="cvv"
                id={`paymentCvv${idSuffix}`}
                mask={cvvMask}
                name="cvv"
                type="tel"
                value={cvv}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.cvv && errors.cvv && (
                <BootstrapForm.Text className="text-danger">
                  {errors.cvv}
                </BootstrapForm.Text>
              )}
            </BootstrapForm.Group>
          </Col>
        </Row>
      )}
    </>
  )
}

/**
 * Payment form for loans
 */
export default DebitCardsDropdown
