import React from 'react'

import {Card, Col, Container, Row} from 'react-bootstrap'
import {MdCall, MdMail, MdPlace} from 'react-icons/md'
import {Link} from 'react-router-dom'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {useAuth} from 'auth'

import styles from './UhOh.module.scss'
import {formatPhone} from '../../utils/data-formatting'
import {CashStoreLocation} from '../../models/CashStoreLocation'

interface UhOhProps {
  title: ContentSlot
  summary?: ContentSlot
  alternativeSummary?: React.ReactNode
  cta?: ContentSlot
  representative: ContentSlot
  callUs: ContentSlot
  emailUs: ContentSlot
  visitAStore: ContentSlot
  back: ContentSlot
  logoutOnBack?: boolean
  storeData?: CashStoreLocation | null | undefined
  isIneligible: boolean
}

const UhOh: React.FC<UhOhProps> = ({
  title,
  summary,
  alternativeSummary,
  cta,
  representative,
  callUs,
  emailUs,
  visitAStore,
  back,
  logoutOnBack,
  storeData = null,
  isIneligible = false,
}) => {
  /**
   * Callbacks
   */
  const {logout} = useAuth()

  let callUsElement

  if (storeData === undefined || storeData === null) {
    callUsElement = <Content type={callUs} />
  } else {
    const phoneAriaLabel = formatPhone(storeData.phone)
    callUsElement = (
      <a className="text-dark" href={`tel:${storeData.phone}`}>
        {phoneAriaLabel}
      </a>
    )
  }

  return (
    <Container>
      <Card className="mt-4 full-page" data-ref="uh-oh">
        <Card.Header className="text-center">
          <h1>
            <Content type={title} />
          </h1>
          {isIneligible === true && (
            <div>
              <p className="mb-3">
                We are unable to service your loan at this time. Please contact
                your local store for more information.
              </p>
              <p>
                If your title loan with the Cash Store is paid in full but you
                have not yet received your title, please contact your local
                store for more information.
              </p>
            </div>
          )}
          {alternativeSummary && (
            <div className={styles.paragraph}>{alternativeSummary}</div>
          )}
          {summary && isIneligible === false && (
            <span className={styles.paragraph}>
              <Content type={summary} />
            </span>
          )}
        </Card.Header>
        <Card.Body>
          {cta && (
            <Row className="justify-content-center m-2">
              <Col
                className="border-bottom pb-4 d-flex justify-content-center"
                md="6"
                sm="12"
              >
                <Content type={cta} />
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <span
                className={`${styles.paragraph} font-weight-bold h4 text-center`}
              >
                <Content type={representative} />
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex flex-column align-items-center" md={4}>
              <p>
                <MdCall size="1.4rem" /> Call Us
              </p>
              <span className={styles.paragraph}>{callUsElement}</span>
            </Col>
            <Col className="d-flex flex-column align-items-center" md={4}>
              <p>
                <MdMail size="1.4rem" /> Email Us
              </p>
              <span className={styles.paragraph}>
                <Content type={emailUs} />
              </span>
            </Col>
            <Col className="d-flex flex-column align-items-center" md={4}>
              <p>
                <MdPlace size="1.4rem" /> Visit a Store
              </p>
              <span className={styles.paragraph}>
                <Content type={visitAStore} />
              </span>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="mt-3">
          <Container>
            <Row className="justify-content-center">
              {logoutOnBack ? (
                <Link to="#" onClick={logout}>
                  <Content type={back} />
                </Link>
              ) : (
                <Content primaryLinkColor type={back} />
              )}
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </Container>
  )
}

/**
 * Generic screen used for many unhappy paths and error states
 */
export default UhOh
