import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import styles from 'pages/Home/BannerInfo/Banner.module.scss'
import warnningLogo from 'assets/img/warningicon.png'
import {useAuth} from 'auth'

interface BannerProps {
  verbiage: string | JSX.Element
  showWarning?: boolean
}

const BannerInfo: React.FC<BannerProps> = ({
  verbiage,
  showWarning = false,
}) => {
  /**
   * State, Hooks
   */
  const {isLoggedIn} = useAuth()

  /**
   * Callbacks
   */

  return (
    <header className={`${styles.BannerHeader}`}>
      {isLoggedIn && (
        <Container fluid className={styles.Banner}>
          <Row className="align-items-center">
            {showWarning ? (
              <Col
                className={`${styles.WarningCol}`}
                lg={3}
                md={3}
                xl={4}
                xs={2}
              >
                {' '}
                <img
                  alt="Warning"
                  className={`${styles.WarningSign}`}
                  src={warnningLogo}
                />
              </Col>
            ) : (
              <Col
                className={`${styles.WarningCol}`}
                lg={3}
                md={2}
                xl={4}
                xs={2}
              >
                {' '}
              </Col>
            )}

            <Col className="text-center pr-1" lg={6} md={7} xl={5} xs={9}>
              <span className={`${styles.LogoText}`}>
                <strong>{verbiage}</strong>
              </span>
            </Col>
            <Col
              className={`${styles.WarningCol}`}
              lg={3}
              md={2}
              xl={3}
              xs={1}
            >
              {' '}
            </Col>
          </Row>
        </Container>
      )}
    </header>
  )
}

/**
 * Global Header
 * @return rendered header based on user authentication
 */
export default BannerInfo
