/* eslint-disable no-console */
declare global {
  interface Window {
    appConfig?: {[key: string]: string}
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let appSettings: any

/**
 * Loads Configuration
 */
export const loadConfiguration = () => {
  try {
    const request = new XMLHttpRequest()
    request.open('GET', '/assets/appSettings.php', false)
    request.send()

    if (request.responseText.startsWith('<')) {
      //  This is the actual PHP file and not the contents.
      return
    }

    appSettings = JSON.parse(request.responseText)
  } catch {
    console.error(`Oops, something went wrong. ErrorCode: LC-001`)
  }
}

loadConfiguration()

/**
 * Returns a the config variable set in the release variable group, or the corresponding env variable if it is set
 * @param key variable name
 * @return string variable value
 */
// @ts-ignore
export const getConfigValue = (key: string): string => {
  if (appSettings?.[key]) {
    return appSettings[key]
  }
  return (
    process.env[`REACT_APP_${key}`] ??
    (window.appConfig ? window.appConfig[key] : '')
  )
}
