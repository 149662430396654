import * as detectBrowser from 'detect-browser'
/**
 * Determine if user is browsing with IE 11
 * @return boolean
 */
export function checkIsIE() {
  const ua = window.navigator.userAgent

  if (
    ua.indexOf('AppleWebKit') > 0 &&
    ua.indexOf('Lunascape') > 0 &&
    ua.indexOf('Sleipnir') > 0
  ) {
    return false
  }

  const array = /(msie|rv:?)\s?([\d.]+)/.exec(ua)
  const version = array ? parseInt(array[2]) : 0

  return version === 11 ? true : false
}

/**
 * Boolean value for global use
 */
export const isIE11 = checkIsIE()

/**
 * Determine if browser is in supported list
 * @return boolean
 */
export function CheckIdBrowserIsSupported() {
  const supportedBrowsers: string[] = [
    'chrome',
    'edge-chromium',
    'safari',
    'firefox',
    'edge',
    'edge-ios',
    'ios',
  ]

  const browser = detectBrowser.detect()
  const ua = window.navigator.userAgent

  return (
    (browser?.name !== undefined &&
      supportedBrowsers.includes(browser.name)) ||
    ua.includes('jsdom')
  )
}

// Temporary disable to be removed after a fix for 3711
/**
 * Boolean value for global use
 */
// export const isBrowserSupported = CheckIdBrowserIsSupported()
export const isBrowserSupported = true
