const USERNAME_KEY_PREFIX = 'username_'

/**
 * Saves the username from the local storage
 * @param username from the auth info
 * @param personReferenceNumber from the auth info
 * @return void
 */
export const saveUsername = (
  username: string,
  personReferenceNumber: string,
) =>
  localStorage.setItem(
    `${USERNAME_KEY_PREFIX}${personReferenceNumber}`,
    username,
  )

/**
 * Gets the username from the local storage
 * @param personReferenceNumber from the auth info
 * @return void
 */
export const getUsername = (personReferenceNumber: string) =>
  localStorage.getItem(`${USERNAME_KEY_PREFIX}${personReferenceNumber}`)

/**
 * Gets customerId based off the username key in local storage.
 * @return customerId
 */
export const getIdFromUserNameKey = () => {
  let custId = 0
  for (const [key] of Object.entries(localStorage)) {
    if (key.startsWith(USERNAME_KEY_PREFIX)) {
      custId = Number(key.substring(USERNAME_KEY_PREFIX.length))
      break
    }
  }
  return custId
}
