import {getConfigValue} from '../../utils/environment'
import {hasValidStoragedToken} from 'utils/has-valid-storaged-token'

interface ResetModalTimersData {
  setShowModal(showModal: boolean): void
  setInactivityTimer(
    inactivityTimer: (timer: NodeJS.Timer | undefined) => NodeJS.Timer,
  ): void
  setModalTimer(
    modalTimer: (timer: NodeJS.Timer | undefined) => NodeJS.Timer | undefined,
  ): void
}

interface HandleModalExtendClickData {
  resetModalTimers(): void
  setShowModal(showModal: boolean): void
}

interface HandleModalTimerData {
  modalTimer: NodeJS.Timer | undefined
  showModal: boolean
  logout(): void
  setModalTimer(
    modalTimer: (timer: NodeJS.Timer | undefined) => NodeJS.Timer,
  ): void
  setShowModal(showModal: boolean): void
}

interface HandleModalTimerTimeoutData {
  logout(): void
  setShowModal(showModal: boolean): void
}

interface HandleModalTimerResetsData {
  inactivityTimer: NodeJS.Timer | undefined
  isLoggedIn: boolean
  resetModalTimers(): void
  setInactivityTimer(
    inactivityTimer: (
      timer: NodeJS.Timer | undefined,
    ) => NodeJS.Timer | undefined,
  ): void
  setModalTimer(
    modalTimer: (timer: NodeJS.Timer | undefined) => NodeJS.Timer | undefined,
  ): void
}

interface OnBeforeunloadData {
  isLoggedIn: boolean
  logout(): void
}

/**
 * Create reset modal timers callback to hide modal and reset timers
 * @return Reset modal timers callback
 */
export const createResetModalTimersCallback = ({
  setShowModal,
  setInactivityTimer,
  setModalTimer,
}: ResetModalTimersData) => () => {
  setShowModal(false)
  setInactivityTimer(timer => {
    if (timer) {
      clearTimeout(timer)
    }
    return setTimeout(() => {
      setShowModal(true)
    }, Number(getConfigValue('INACTIVITY_TIMER')) * 1000)
  })
  setModalTimer(timer => {
    if (timer) {
      clearTimeout(timer)
    }
    return undefined
  })
}

/**
 * Create handle modal extend click callback to hide modal and reset timers
 * @return Handle modal extend click callback
 */
export const createHandleModalExtendClickCallback = ({
  resetModalTimers,
  setShowModal,
}: HandleModalExtendClickData) => () => {
  setShowModal(false)
  resetModalTimers()
}

/**
 * Create handle modal timer effect timeout for testing
 * @return Timeout callback for testing
 */
export const createHandleModalTimerEffectTimeout = ({
  logout,
  setShowModal,
}: HandleModalTimerTimeoutData) => () => {
  setShowModal(false)
  logout()
}

/**
 * Create handle modal timer effect to hide modal and logout user after 60 seconds
 * @return Handle modal timer effect
 */
export const createHandleModalTimerEffect = ({
  logout,
  modalTimer,
  setModalTimer,
  setShowModal,
  showModal,
}: HandleModalTimerData) => () => {
  if (showModal && !modalTimer) {
    setModalTimer(() =>
      setTimeout(
        createHandleModalTimerEffectTimeout({logout, setShowModal}),
        60000,
      ),
    )
  }
}

/**
 * Create handle modal timer resets effect to reset the modal timers
 * @return Handle modal timer resets effect
 */
export const createHandleModalTimerResetsEffect = ({
  inactivityTimer,
  isLoggedIn,
  resetModalTimers,
  setInactivityTimer,
  setModalTimer,
}: HandleModalTimerResetsData) => () => {
  if (isLoggedIn && (inactivityTimer || !inactivityTimer)) {
    resetModalTimers()
  } else {
    setInactivityTimer(timer => {
      if (timer) clearTimeout(timer)
      return undefined
    })
    setModalTimer(timer => {
      if (timer) clearTimeout(timer)
      return undefined
    })
  }
}

/**
 * Create OnBeforeunload callback to logout user when browser is closed
 * @return OnBeforeunload callback
 */
export const createOnBeforeunloadCallback = ({
  isLoggedIn,
  logout,
}: OnBeforeunloadData) => () => {
  if (isLoggedIn && !hasValidStoragedToken()) {
    logout()
  }
}
