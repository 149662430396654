import React, {SyntheticEvent} from 'react'
import {Form} from 'react-bootstrap'
import {formatCurrency} from 'utils/data-formatting'
import styles from './OfferSelector.module.scss'

interface OfferType {
  id: number
  name: string
  testId?: string
  range: {
    min: number
    max: number
  }
}

interface OfferSelectorProps {
  disabled?: boolean
  offers: OfferType[]
  selectedOfferId: number
  setSelectedOfferId: (offerId: number) => void
}

/**
 * Offer Selector
 * @return Offer Selector component
 */
export const OfferSelector = ({
  offers,
  disabled,
  selectedOfferId,
  setSelectedOfferId,
}: OfferSelectorProps) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setSelectedOfferId(
      Number(((e.target as unknown) as {value: string}).value),
    )
  }

  return (
    <div className={styles.container}>
      <Form.Label>Select an Offer</Form.Label>
      <div className={styles.inputs} onChange={handleChange}>
        {offers.map(offer => (
          <Offer
            key={offer.id}
            disabled={disabled}
            id={offer.id}
            name={offer.name}
            range={offer.range}
            selected={selectedOfferId === offer.id}
            testId={offer.testId}
          />
        ))}
      </div>
    </div>
  )
}

interface OfferProps extends OfferType {
  selected: boolean
  disabled?: boolean
}

const Offer = ({name, range, selected, id, disabled, testId}: OfferProps) => (
  <div className={`${styles.offer}${selected ? ` ${styles.active}` : ''}`}>
    <input
      checked={selected}
      data-ref={testId}
      disabled={disabled}
      id={id.toString()}
      name="offer"
      type="radio"
      value={id}
    />
    <span className={styles.offer_name}>{name}</span>
    <span className={styles.offer_range}>
      {formatCurrency(range.min)}-{formatCurrency(range.max)}
    </span>
  </div>
)
