/**
 * Document already signed error
 */
export const documentAlreadySignedError = 'Documents have already been signed'
/**
 * Document not signed constant
 */
export const documentNotSignedError =
  'LoanApplication Documents have not been signed.'
/**
 * Document error page header
 */
export const docErrorHeader = 'Loan Origination Failed'
/**
 * Document error refi message
 */
export const docErrorRefiMessage =
  'Your loan was not booked due to an issue with your card or with our document signing provider. Please try again.'
/**
 * Document error reactivation message
 */
export const docErrorReactivationMessage =
  'Your loan was not booked due to an issue with our document signing provider. If you would like to originate a loan, please start over.'
