import React, {useCallback} from 'react'
import AbstractPopup from 'components/Popup'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {Button} from 'react-bootstrap'
import styles from 'components/Popup/UnsupportedBrowserPopup/UnsupportedBrowserPopup.module.scss'

/**
 * UNsupported Browser popup props interface
 */
export interface UnsupportedBrowserPopupProps {
  visibility: boolean
  toggleVisiblity(visibility: boolean): void
}

const UnsupportedBrowserPopup: React.FC<UnsupportedBrowserPopupProps> = props => {
  const handleClose = useCallback(() => props.toggleVisiblity(true), [props])

  const upsupportedPopupContent = (
    <>
      <div className={styles.Container}>
        <Content
          data-ref="unsupportedBrowser-warning"
          type={ContentSlot.UNSUPPORTED_BROWSER_POPUP}
        />
        <div data-ref="unsupportedBrowser-warning" />
        <Button
          data-ref="close-unsupportedBrowser-popup"
          variant="primary"
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </>
  )

  return (
    <>
      <AbstractPopup
        warning
        content={upsupportedPopupContent}
        handleClose={handleClose}
        visibility={props.visibility}
      />
    </>
  )
}

/**
 * Bootstrap modal for popup
 */
export default UnsupportedBrowserPopup
