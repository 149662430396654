import {Page} from 'models/Page'
import {
  BsHouseDoorFill,
  BsPersonFill,
  BsQuestionCircleFill,
  BsEnvelopeFill,
} from 'react-icons/bs'

/**
 * All app pages
 */
export const appPages: Page[] = [
  {
    url: '/home',
    title: 'Home',
    icon: BsHouseDoorFill,
  },
  {
    url: '/account',
    title: 'My Account',
    icon: BsPersonFill,
  },
]

/**
 * External Pages
 */
export const externalPages = [
  {
    url: 'https://www.cashstore.com/customer-portal-faq',
    title: 'Customer Portal FAQs',
    icon: BsQuestionCircleFill,
  },
  {
    url: 'https://www.cashstore.com/contact-us',
    title: 'Contact Us',
    icon: BsEnvelopeFill,
  },
]
