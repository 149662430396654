import React, {useCallback, useEffect, useState} from 'react'

import {Card, Col, Row, Button, Form as BootstrapForm} from 'react-bootstrap'
import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import * as Yup from 'yup'
import MaskedInput from 'react-text-mask'
import {useHistory} from 'react-router'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {
  CustomerCard,
  CustomerStatusType,
  LoanPaymentFormValues,
  PaymentMethodTypes,
} from 'models/Edge'
import {formatCurrency, formatCurrencyToFloat} from 'utils/data-formatting'
import {currencyMask} from 'utils/forms/masks'
import {setPaymentData, setPartialPaymentData} from 'utils/cache'
import DebitCardsDropdown from 'components/Form/DebitCardsDropdown'
import MinimumPaymentPopup from 'components/Popup/MinimumPaymentPopup'

import styles from 'pages/Home/PaymentCard/PaymentCard.module.scss'
import MinimumPaymentError from 'pages/Home/PaymentCard/MinimumPaymentError'
import RescissionPopup from 'components/Popup/RescissionPopup'
import OnHoldStatusPopup from 'components/Popup/OnHoldStatusPopup'

/**
 * Types
 */
interface PaymentCardProps {
  amountToBecomeCurrent?: number
  cardInfo?: CustomerCard[]
  maxPayment: number
  customerName: string
  upcomingPayment: number
  state: string
  isFinalPayPeriodBeforeDueDate?: boolean
  isLoanRescindable?: boolean
  customerStatus?: string
  idSuffix: string
  actualLoanStatus: string
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  amountToBecomeCurrent = undefined,
  cardInfo = undefined,
  customerName,
  maxPayment,
  upcomingPayment,
  state,
  isFinalPayPeriodBeforeDueDate,
  isLoanRescindable,
  customerStatus,
  idSuffix = '',
  actualLoanStatus,
}) => {
  /**
   * Hooks
   */
  const history = useHistory()

  const [showMinimumPaymentPopup, setShowMinimumPaymentPopup] = useState(false)
  const [showRescissionPopup, setShowRescissionPopup] = useState(false)
  const [showOnHoldStatusPopup, setOnHoldStatusPopup] = useState(false)
  const [
    paymentFormValues,
    setPaymentFormValues,
  ] = useState<LoanPaymentFormValues | null>(null)

  /**
   * Callbacks
   */
  const submitFormValues = useCallback(submitFormValuesCallback, [
    cardInfo,
    showMinimumPaymentPopup,
  ])
  const handleShowMinimumPayment = useCallback(toggleMinimumPaymentCallback, [
    showMinimumPaymentPopup,
  ])

  const handleShowRescission = useCallback(toggleRescissionCallback, [
    showRescissionPopup,
  ])

  const handleShowOnHoldStatus = useCallback(toggleOnHoldStatusPopupCallback, [
    showOnHoldStatusPopup,
  ])

  /**
   * Variables
   */
  let minimumPaymentRequired = false
  let payAmount = upcomingPayment

  if (
    (actualLoanStatus === 'Delinquent' || actualLoanStatus === 'Current') &&
    amountToBecomeCurrent !== undefined &&
    amountToBecomeCurrent > 0
  ) {
    // Delinquent Minimum => delinquent amount
    minimumPaymentRequired = true
    payAmount = amountToBecomeCurrent
  }

  if (actualLoanStatus === 'Default') {
    // Default Minimum => pay off amount
    // OR
    // Final pay period + before due date Minimum => pay off as of today
    payAmount = maxPayment
    if (isFinalPayPeriodBeforeDueDate) {
      minimumPaymentRequired = true
    }
  }

  const formattedMaxPayment = formatCurrency(maxPayment)

  const upcomingAmount = maxPayment < payAmount ? maxPayment : payAmount
  const formattedUpcomingPayment = formatCurrency(upcomingAmount)

  const initialFormValues: LoanPaymentFormValues = {
    paymentAmount: formattedUpcomingPayment,
    paymentMethod: PaymentMethodTypes.NULL,
    cvv: '',
  }

  const minPayment = payAmount

  const FormSchema = Yup.object().shape({
    paymentAmount: Yup.string()
      .required('Payment amount is required')
      .test(
        'max-loan-payment',
        `You can only pay up to ${formattedMaxPayment}`,
        (value: string) => {
          if (value === undefined) return true
          return formatCurrencyToFloat(value) <= maxPayment
        },
      )
      .test(
        'min-loan-payment',
        `Amount must be greater than $0`,
        (value: string) => {
          if (value === undefined) return true
          return formatCurrencyToFloat(value) > 0
        },
      ),
    paymentMethod: Yup.string().required('Payment method is required'),
    cvv: Yup.string().when('paymentMethod', (paymentMethod: string) => {
      const matchExistingPaymentMethod = /^(.+?):/
      const isExistingCard = matchExistingPaymentMethod.exec(paymentMethod)

      if (isExistingCard) {
        return Yup.string()
          .required('CVV is required')
          .test(
            'three-digit-number',
            'CVV number must be 3 digits long',
            (value: string) => {
              if (value === undefined) return true
              return value.replace(/\D/g, '').length === 3
            },
          )
      }
    }),
  })

  let paymentTitle = <></>
  // Setting the initial forms value. If the loan is in a "Default" state the
  // amountToDisplay will be the formattedMaxPayment, otherwise it will be the
  // upcoming payment amount.
  let amountToDisplay = formattedUpcomingPayment
  if (
    actualLoanStatus === 'Default' &&
    (state === 'TX' || state === 'NM' || state === 'ID')
  ) {
    paymentTitle = (
      <h2 className="mb-0">
        <BootstrapForm.Label id={styles.customHeaderText}>
          Pay Off Today
        </BootstrapForm.Label>
      </h2>
    )
    amountToDisplay = formattedMaxPayment
  } else {
    paymentTitle = (
      <h2 className="mb-0">
        <Content type={ContentSlot.MAKEAPAYMENT_TITLE} />
      </h2>
    )
  }
  initialFormValues.paymentAmount = amountToDisplay
  useEffect(() => {
    setPaymentData(undefined)
  }, [])
  return (
    <Card className="payment">
      {showMinimumPaymentPopup && (
        <MinimumPaymentPopup
          navigateToConfirmPayment={submitFormValues}
          paymentFormValues={paymentFormValues}
          toggleVisiblity={setShowMinimumPaymentPopup}
          visibility={showMinimumPaymentPopup}
        />
      )}
      {showRescissionPopup && (
        <RescissionPopup
          toggleVisiblity={setShowRescissionPopup}
          visibility={showRescissionPopup}
        />
      )}
      {showOnHoldStatusPopup && (
        <OnHoldStatusPopup
          toggleVisibility={setOnHoldStatusPopup}
          visibility={showOnHoldStatusPopup}
        />
      )}
      <Card.Header className="text-left px-3">{paymentTitle}</Card.Header>
      <Card.Body className="my-2 px-3">
        <Formik
          validateOnBlur
          validateOnChange
          initialValues={initialFormValues}
          validationSchema={FormSchema}
          onSubmit={submitFormValues}
        >
          {({
            values,
            handleBlur,
            handleChange,
            touched,
            errors,
          }: FormikProps<LoanPaymentFormValues>) => (
            <Form>
              <Row>
                <Col>
                  <BootstrapForm.Group controlId={`paymentAmount${idSuffix}`}>
                    <BootstrapForm.Label>Payment Amount</BootstrapForm.Label>
                    <MaskedInput
                      className="form-control"
                      data-ref="payment-amount"
                      id={`paymentAmount${idSuffix}`}
                      inputMode="decimal"
                      mask={currencyMask}
                      name="paymentAmount"
                      placeholder={formattedUpcomingPayment}
                      type="text"
                      value={values.paymentAmount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.paymentAmount && errors.paymentAmount && (
                      <BootstrapForm.Text className="text-danger">
                        {errors.paymentAmount}
                      </BootstrapForm.Text>
                    )}
                    {minimumPaymentRequired && (
                      <MinimumPaymentError
                        minimumPayment={minPayment}
                        paymentFormValues={values}
                      />
                    )}
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DebitCardsDropdown
                    showOneTimeCard
                    cardInfo={cardInfo}
                    cvv={values.cvv}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    idSuffix={idSuffix}
                    paymentMethod={values.paymentMethod}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col className="mb-3 mb-md-0">
                  <Button
                    block
                    color="primary"
                    data-ref="submit"
                    size="lg"
                    type="submit"
                  >
                    <Content type={ContentSlot.MAKEAPAYMENT_BUTTONTEXT} />
                  </Button>
                </Col>
              </Row>
              <FormikErrorFocus
                align="middle"
                duration={500}
                ease="linear"
                focusDelay={200}
              />
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )

  /**
   * Submit form values
   * @param values form values
   * @return url redirect
   */
  function submitFormValuesCallback(values: LoanPaymentFormValues) {
    const {cvv} = values
    const paymentAmount = formatCurrencyToFloat(values.paymentAmount)

    if (isLoanRescindable && !showRescissionPopup) {
      handleShowRescission(values)
      return
    }

    if (paymentAmount < minPayment && !showMinimumPaymentPopup) {
      handleShowMinimumPayment(values)
      return
    }

    if (
      customerStatus === CustomerStatusType.OnHold &&
      !showOnHoldStatusPopup
    ) {
      handleShowOnHoldStatus(values)
      return
    }
    const formatedPaymentMethods = values.paymentMethod.replace(/:.*$/, '')
    if (cardInfo) {
      const currentCard = cardInfo.find(
        card =>
          card.id === Number(values.paymentMethod.replace(/[^0-9.]/g, '')),
      )
      switch (formatedPaymentMethods) {
        case PaymentMethodTypes.EXISTING_CARD:
          setPaymentData({
            cardCvv: cvv,
            cardType: currentCard?.cardType ?? '',
            cardToken: currentCard?.cardTypeId ?? 0,
            cardId: currentCard?.id ?? 0,
            cardTokenLast4: currentCard?.accountNumberMasked ?? '',
            cardIsFundable: currentCard?.isExpired,
            paymentAmount,
            customerName,
          })
          return history.push('/payment-confirm')
        case PaymentMethodTypes.ONE_TIME_CARD:
          setPartialPaymentData(paymentAmount, customerName)
          return history.push('/add-card-and-pay#one-time', {
            from: history.location.pathname,
          })
        default:
      }
    }
  }

  /**
   * Simple toggle callback that sets the LoanPaymentFormValues into state
   * @param paymentValues LoanPaymentFormValues to be used by Delinquent Minimum Popup
   */
  function toggleMinimumPaymentCallback(paymentValues: LoanPaymentFormValues) {
    setPaymentFormValues(paymentValues)
    setShowMinimumPaymentPopup(!showMinimumPaymentPopup)
  }

  /**
   * Simple toggle callback that sets the LoanPaymentFormValues into state
   * @param paymentValues LoanPaymentFormValues to be used by Delinquent Minimum Popup
   */
  function toggleRescissionCallback(paymentValues: LoanPaymentFormValues) {
    setPaymentFormValues(paymentValues)
    setShowRescissionPopup(!showRescissionPopup)
  }

  /**
   * Simple toggle callback that sets the LoanPaymentFormValues into state
   * @param paymentValues LoanPaymentFormValues to be used by On Hold Popup
   */
  function toggleOnHoldStatusPopupCallback(
    paymentValues: LoanPaymentFormValues,
  ) {
    setPaymentFormValues(paymentValues)
    setOnHoldStatusPopup(!showOnHoldStatusPopup)
  }
}

/**
 * Payment form for loans
 */
export default PaymentCard
