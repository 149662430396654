import React, {useCallback, useMemo, useState} from 'react'

import {Card, Col, Row} from 'react-bootstrap'
import {AiFillCaretDown} from 'react-icons/ai'
import {BsFillClockFill} from 'react-icons/bs'
import {MdCall, MdLocationOn} from 'react-icons/md'

import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {formatPhone} from 'utils/data-formatting'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'

interface CashStoreCardProps {
  storeNumber?: string
}

const CashStoreCard: React.FC<CashStoreCardProps> = props => {
  /**
   * State, Hooks
   */
  const [hideHours, setHideHours] = useState(true)
  const {data: store} = useStoreDetails(props?.storeNumber)

  /**
   * Callbacks, Memos
   */
  const storeHours = useMemo(storeHoursMemo, [store])

  const toggleHours = useCallback(toggleHoursCallback, [
    hideHours,
    setHideHours,
  ])

  // If the store info returned is null or undefined, do not render the card
  if (!store) {
    return <></>
  }

  const storeImage = (
    <>
      {Boolean(store?.mainPhoto) && (
        <img alt="Cash Store" className="mw-100" src={store.mainPhoto?.url} />
      )}
    </>
  )
  return (
    // CSS class .d-block below is required for IE11 flex-column height issue
    <Card className="px-3 d-block">
      <Row>
        <Col>
          <h3 className="pb-0">
            <Content type={ContentSlot.STORE_CARD_LABEL} />
          </h3>
        </Col>
      </Row>
      <Row className="d-block d-md-none">{storeImage}</Row>
      <Row className="pt-2 pt-md-0">
        <Col className="d-none d-md-block">{storeImage}</Col>
        <Col>
          <Row>
            <Col xs={1}>
              <MdLocationOn size="1.2em" />
            </Col>
            <Col>
              <a
                aria-label={store.address}
                className="text-dark"
                href={store.gmbLocationUrl}
              >
                {store.address}
                <br />
                {store.city}, {store.state} {store.zip}
              </a>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={1}>
              <MdCall size="1.2em" />
            </Col>
            <Col>
              <a
                aria-label={formatPhone(store.phone)}
                className="text-dark"
                href={`tel:${store.phone}`}
              >
                {store.phone && formatPhone(store.phone)}
              </a>
            </Col>
          </Row>
          <Row className="py-2 cursor-pointer" onClick={toggleHours}>
            <Col>
              <BsFillClockFill />
              <span className="px-1">Store Hours</span>
              <AiFillCaretDown size="0.8rem" />
            </Col>
          </Row>
          {hideHours ||
            storeHours.map(day => (
              <Row key={day.name}>
                <>
                  <Col className="text-capitalize text-nowrap">{day.name}</Col>
                  <Col className="text-nowrap" xs={5}>
                    {day.hours}
                  </Col>
                </>
              </Row>
            ))}
        </Col>
      </Row>
    </Card>
  )

  /**
   * Simple toggle function for local state
   */
  function toggleHoursCallback() {
    setHideHours(!hideHours)
  }

  /**
   * Converts the store hours into a meaningful format
   * @return an array with the day of the week and hours
   */
  function storeHoursMemo() {
    return [
      {
        name: 'Monday',
        hours: getStoreHourDisplay(store?.mondayOpen, store?.mondayClose),
      },
      {
        name: 'Tuesday',
        hours: getStoreHourDisplay(store?.tuesdayOpen, store?.tuesdayClose),
      },
      {
        name: 'Wednesday',
        hours: getStoreHourDisplay(
          store?.wednesdayOpen,
          store?.wednesdayClose,
        ),
      },
      {
        name: 'Thursday',
        hours: getStoreHourDisplay(store?.thursdayOpen, store?.thursdayClose),
      },
      {
        name: 'Friday',
        hours: getStoreHourDisplay(store?.fridayOpen, store?.fridayClose),
      },
      {
        name: 'Saturday',
        hours: getStoreHourDisplay(store?.saturdayOpen, store?.saturdayClose),
      },
      {
        name: 'Sunday',
        hours: getStoreHourDisplay(store?.sundayOpen, store?.sundayClose),
      },
    ]
  }

  /**
   * Gets the hours formatted
   * @param open open time
   * @param close close time
   * @return formatted string of hours
   */
  function getStoreHourDisplay(open?: number, close?: number) {
    return open ? `${open} - ${close ? close : ''}` : 'Closed'
  }
}

/**
 * Displays information for a store's location
 */
export default CashStoreCard
