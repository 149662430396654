/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers, FormikErrors, FormikProps} from 'formik'
import {Container, Card, Form as BootstrapForm} from 'react-bootstrap'
import ActionCard from 'components/ActionCard'
import FormTitle from 'components/Form/FormTitle'
import FormButtons from 'components/Form/FormButtons'
import styles from './MultiFactorAuthentication.module.scss'
import IdCardIcon from '../IdVerification/IdCardIcon'
import {useHistory, useLocation} from 'react-router'
import {vergentService} from 'utils/vergent-service'
import {
  AuthenticateAfterMFARequest,
  AuthenticateMFAResponse,
  AuthenticateRequest,
  RemoteAddressResponse,
} from 'models/Auth'
import {StandardError, VergentError} from 'models/Errors'
import {LoginErrorTypes} from 'auth/actions/login-action'
import {useAuth} from 'auth'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {AnalyticsEventNames, AnalyticsExceptionNames} from 'models/Analytics'
import SpinnerButton from 'components/SpinnerButton'
import {ToastContext} from 'components/Toast'
import {Routes as Path} from 'models/Routes'
import axios from 'axios'

interface MultiFactorAuthenticationProps {
  customerId: number
  email: string
  phone: string | undefined
  responseCode: number
  userName: string
  guid: string
  isAccountLocked: boolean
}

interface VerifyFormValues {
  oneTimePassCode: string
}

const initialFormValues: VerifyFormValues = {
  oneTimePassCode: '',
}

const MultiFactorAuthentication = () => {
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()
  const initialValues = {}
  const goBack = useCallback(goBackCallback, [history])

  const {loginAfterMFA} = useAuth()
  const appInsightsContext = useAppInsightsContext()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const errorMessages = {
    default: 'There was an error verifying One-time Passcode.',
  }

  const location = useLocation<MultiFactorAuthenticationProps>()
  if (
    location.state === undefined ||
    !location.state.guid ||
    !location.state.customerId ||
    location.state.customerId === 0
  ) {
    history.push(Path.LOGIN)
  }

  const handleSubmit = async (values: VerifyFormValues) => {
    setIsSubmitting(true)
    try {
      const {
        data: {ip},
      } = await axios.get<RemoteAddressResponse>(
        'https://api.ipify.org/?format=json',
      )

      const {
        data: {guid, responseCode, token},
      } = await vergentService.post<AuthenticateMFAResponse>(
        '/VergentAuthenticateUser/Verify',
        {
          mfaToken: location.state.guid,
          oneTimePassCode: values.oneTimePassCode,
          remoteAddress: ip,
        },
      )

      if (responseCode === 2 || !token) {
        throw new StandardError({
          type: LoginErrorTypes.MFAError,
          trackableMessage: errorMessages.default,
        })
      }
      const authAfterMFARequest: AuthenticateAfterMFARequest = {
        userName: location.state.userName,
        token,
        customerId: location.state.customerId,
        email: location.state.email,
        isAccountLocked: location.state.isAccountLocked,
      }
      loginAfterMFA(authAfterMFARequest)
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.LOGIN_ATTEMPT_SUCCESS},
        {name: location.state.userName},
      )
    } catch (error) {
      const {type, trackableMessage} = error as StandardError

      if (trackableMessage) {
        appInsightsContext.trackException(
          {exception: Error(AnalyticsExceptionNames.VERIFY_MFA_FAILURE)},
          {message: trackableMessage},
        )
      }

      if (type === LoginErrorTypes.AccountLockedError) {
        history.push('/locked')
      } else {
        const isVerifyPassCodeError = type === LoginErrorTypes.MFAError

        pushToast({
          title: 'Unable to login',
          message: isVerifyPassCodeError
            ? errorMessages.default
            : 'There was an error logging in',
          variant: 'danger',
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.push('/login')
  }

  return (
    <Container>
      <Card className="mt-4 full-page">
        <Card.Header>
          <FormTitle>One Time Passcode</FormTitle>
        </Card.Header>
        {/* <Card.Body>
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialFormValues}
            validationSchema=""
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              isValid,
              touched,
              validateForm,
              setFieldValue,
              setFieldTouched,
            }) => {
              const handleVerifyClick = () => {}

              return (
                <Form noValidate>
                  <Card.Body>
                    <div className={styles.body}>
                      <ActionCard
                        isActionCompleted
                        actionText="One Time Passcode"
                        description="One Time Passcode"
                        icon={<IdCardIcon />}
                        inputPlaceHolder="Enter code here.."
                        inputType="text"
                        title="We need to verify your account. Please enter the code sent to you"
                        onActionClick={handleVerifyClick}
                      />
                    </div>
                    <FormButtons
                      backButtonDisabled={isSubmitting}
                      backButtonLabel="Back"
                      nextButtonDisabled={isSubmitting || !isValid}
                      nextButtonLabel="Next"
                      nextButtonLoading={isSubmitting}
                      onBackClick={goBack}
                    />
                  </Card.Body>
                </Form>
              )
            }}
          </Formik>
        </Card.Body> */}

        <Card.Body>
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialFormValues}
            validationSchema=""
            onSubmit={handleSubmit}
          >
            {(formProps: FormikProps<VerifyFormValues>) => (
              <Form className={styles.verifyForm}>
                <div className={styles.content}>
                  <IdCardIcon />
                  <div>
                    We need to verify your account. Please enter the code sent
                    to your email address.
                  </div>
                  <BootstrapForm.Group controlId="oneTimePassCode">
                    <BootstrapForm.Label color="dark">
                      One Time Passcode
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      autoComplete="oneTimePassCode"
                      data-ref="oneTimePassCode"
                      maxLength={16}
                      name="oneTimePassCode"
                      type="text"
                      value={formProps.values.oneTimePassCode}
                      onChange={formProps.handleChange}
                    />
                    {formProps.touched.oneTimePassCode &&
                      formProps.errors.oneTimePassCode && (
                        <BootstrapForm.Text className="text-danger">
                          {formProps.errors.oneTimePassCode}
                        </BootstrapForm.Text>
                      )}
                  </BootstrapForm.Group>
                  <SpinnerButton
                    block
                    color="primary"
                    data-ref="next"
                    loading={isSubmitting}
                    size="lg"
                    type="submit"
                  >
                    Next
                  </SpinnerButton>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  )
}

/**
 * MFA page
 */
export default MultiFactorAuthentication
