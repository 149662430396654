import {ReactPlugin} from '@microsoft/applicationinsights-react-js'
import {AnalyticsEventNames} from 'models/Analytics'
import dayjs from 'dayjs'

interface UtmParams {
  utmCampaign?: string
  utmContent?: string
  utmMedium?: string
  utmSource?: string
  ['first_visit']?: string
}

/**
 * Grabs the UTM parameters in window.location and stores them in localStorage for later use
 */
export function stashUtmParameters() {
  // Determine if any UTM parameters are included in the URL
  const {location} = window
  const queryParams = new URLSearchParams(location.search)

  const utmCampaign = queryParams.get('utm_campaign')
  const utmContent = queryParams.get('utm_content')
  const utmMedium = queryParams.get('utm_medium')
  const utmSource = queryParams.get('utm_source')

  const utmValues: UtmParams = {
    ...(utmCampaign !== null && {utmCampaign}),
    ...(utmContent !== null && {utmContent}),
    ...(utmMedium !== null && {utmMedium}),
    ...(utmSource !== null && {utmSource}),
  }

  if (Object.keys(utmValues).length > 0) {
    utmValues.first_visit = dayjs().format()
    localStorage.setItem('utm_parameters', JSON.stringify(utmValues))
  }
}

/**
 * Gets saved UTM parameters
 * @return the utm parameters stored from stashUtmParameters() above
 */
export function getUtmParametersFromLocalStorage() {
  return JSON.parse(localStorage.getItem('utm_parameters') ?? '{}')
}

/**
 * Helper function to call an event and clear the localStorage
 * @param appInsightsContext we must pass the context, we can't access this hook unless inside a react component
 * @param params the UTM parameters captured earlier in App.tsx by calling stashUtmParameters()
 */
export function trackUtmEvent(
  appInsightsContext: ReactPlugin,
  params: object,
) {
  if (Object.keys(params).length > 0) {
    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.UTM_REFERRAL},
      {...params},
    )
  }
}

/**
 * Helper function, clears any utm parameters stored in localstorage
 */
export function clearUtmFromLocalStorage() {
  localStorage.removeItem('utm_parameters')
}

/**
 * Returns analytics event based on what flow the user is in
 * @param key the key tells the function what flow the user is going through
 * @return string
 */
export function getConfirmInfoPageViewEventName(key: string) {
  switch (key) {
    case 'refinance':
      return AnalyticsEventNames.PRINCIPAL_CONFIRM_INFO_VISIT
    case 'cashback':
      return AnalyticsEventNames.CASHBACK_CONFIRM_INFO_VISIT
    case 'reactivation':
      return AnalyticsEventNames.REACTIVATION_CONFIRM_INFO_VISIT
    default:
      return ''
  }
}

/**
 * Returns analytics event based on what flow the user is in
 * @param key the key tells the function what flow the user is going through
 * @return string
 */
export function getAddDebitCardPageViewEventName(key?: string) {
  switch (key) {
    case 'refinance':
      return AnalyticsEventNames.PRINCIPAL_ADD_DEBIT_CARD_VISIT
    case 'cashback':
      return AnalyticsEventNames.CASHBACK_ADD_DEBIT_CARD_VISIT
    default:
      return AnalyticsEventNames.ADD_DEBIT_CARD_VISIT
  }
}

/**
 * Returns analytics event based on what flow the user is in
 * @param key the key tells the function what flow the user is going through
 * @return string
 */
export function getConfirmOriginationPageViewEventName(key: string) {
  switch (key) {
    case 'refinance':
      return AnalyticsEventNames.PRINCIPAL_CONFIRM_ORIGINATION_VISIT
    case 'cashback':
      return AnalyticsEventNames.CASHBACK_CONFIRM_ORIGINATION_VISIT
    case 'reactivation':
      return AnalyticsEventNames.REACTIVATION_CONFIRM_ORIGINATION_VISIT
    default:
      return ''
  }
}

/**
 * Returns analytics event based on what flow the user is in
 * @param key the key tells the function what flow the user is going through
 * @return string
 */
export function getSignDocumentsPageViewEventName(key: string) {
  switch (key) {
    case 'refinance':
      return AnalyticsEventNames.PRINCIPAL_SIGN_DOCUMENTS_VISIT
    case 'cashback':
      return AnalyticsEventNames.CASHBACK_SIGN_DOCUMENTS_VISIT
    case 'reactivation':
      return AnalyticsEventNames.REACTIVATION_SIGN_DOCUMENTS_VISIT
    default:
      return ''
  }
}
