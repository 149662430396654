// AppInsights.js
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin} from '@microsoft/applicationinsights-react-js'
import {globalHistory} from '@reach/router'
import {getConfigValue} from 'utils/environment'

const instrumentationKey = getConfigValue('APPINSIGHTS_INSTRUMENTATION_KEY')
const reactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights

if (instrumentationKey) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {history: globalHistory},
      },
    },
  })
  appInsights.loadAppInsights()
}

/**
 * Logs exceptions in try-catch blocks to AppInsights (in a nice typescript-valid way)
 * @param error instance
 * @param metadata object
 */
function logUnknownAsException(error: unknown, metadata?: object) {
  if (error instanceof Error) {
    appInsights.trackException({exception: error}, metadata)
  } else {
    appInsights.trackException({exception: new Error(String(error))}, metadata)
  }
}

/**
 * Export the necessary parts
 */
export {reactPlugin, appInsights, logUnknownAsException}
