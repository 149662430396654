import React from 'react'

import {Toast} from 'react-bootstrap'

/**
 * Toast message interface
 */
export interface ToastMessage {
  id?: string // Optional because we will generate it in pushToast
  title: string
  message?: string
  variant: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info'
}

interface GlobalToastProps {
  toast: ToastMessage
  onToastClose: (index: number) => void
  index: number
}

const darkBackgrounds = ['primary', 'danger']

const GlobalToast: React.FC<GlobalToastProps> = props => {
  const {toast, onToastClose: onToastCloseCb, index} = props
  const textColor = darkBackgrounds.includes(toast.variant) ? 'light' : 'dark'

  /**
   * Used to call the ToastWrappers closeByIndex function
   */
  function onToastClose() {
    onToastCloseCb(index)
  }

  return (
    <Toast
      key={props.toast.id}
      className={`bg-${toast.variant}`}
      onClose={onToastClose}
    >
      <Toast.Header className={`bg-${toast.variant} text-${textColor}`}>
        <strong className="mr-auto">{toast.title}</strong>
      </Toast.Header>
      <Toast.Body className={`text-${textColor}`}>{toast.message}</Toast.Body>
    </Toast>
  )
}

/**
 * Single toast message
 * @param props toast, onToastClose, and index
 * @return toast
 */
export default GlobalToast
